import { Typography } from "@mui/material"
import type { FC, ReactNode } from "react"
import React from "react"
import { useCommonStyles, useStyles } from "utils"

import { APP_TOP_BAR_HEIGHT } from "../features/Theme/theme"

interface ScreenTopBarProps {
  icon: JSX.Element
  title: string
  right?: ReactNode
  topActionBar?: JSX.Element
}

/**
 * TopBar used in the ScreenContainer
 */
export const TopBar: FC<ScreenTopBarProps> = ({ icon, title, right, topActionBar }) => {
  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing }) => ({
      mainDiv: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: `${APP_TOP_BAR_HEIGHT + (topActionBar ? 48 : 0)}px`,
        position: "sticky",
        width: "auto",
      },
      innerDiv: {
        display: "flex",
        flex: 1,
        height: `${APP_TOP_BAR_HEIGHT}px`,
        justifyContent: "space-between",
        padding: spacing(1, 2),
      },
    }),
    [topActionBar],
  )

  return (
    <div style={s.mainDiv}>
      <div style={s.innerDiv}>
        <div style={cs.alignItemsCenter}>
          {icon}
          <Typography sx={{ marginLeft: "1.5rem" }} variant="h6" component="span">
            {title}
          </Typography>
        </div>
        {right}
      </div>
      {topActionBar}
    </div>
  )
}
