import type { DivisionId, UniverseId } from "@newpv/js-common"
import type { SaveHandler } from "ra-core"
import { useCreateController, useEditController } from "react-admin"

export type AdminRole = "divisionsupervisor" | "universeadmin"
// TODO: update js-common with new AdminRole
export interface RoleContent {
  role: AdminRole
  universeId: UniverseId
  divisionsIds?: DivisionId[]
}

// TODO: update js-common with new User
export interface Administrator {
  id: string
  firstName?: string
  lastName: string
  role: RoleContent
  email: string
}

export type FormAdministrator = Omit<Administrator, "role" | "id"> &
  Omit<RoleContent, "divisionsIds"> & { divisionsIds: Record<DivisionId, boolean> }

export type EditableAdministrator = Omit<Administrator, "role"> & { roles: RoleContent[] }

type AdministratorForm = Administrator & { universeId: UniverseId; divisionsIds: DivisionId[] }
export interface AdministratorInfos {
  administratorData?: EditableAdministrator
  isLoading: boolean
  onDelete?: () => Promise<void>
  save?: SaveHandler<any>
  saving?: boolean
}

/** Create an administrator - wrapper around useCreateController from React Admin */
export const useAdministratorCreate = (): AdministratorInfos => {
  const { isLoading, save, saving } = useCreateController<AdministratorForm>({
    mutationOptions: { meta: { idField: "adminId" } },
    resource: "universe/user",
  })

  return {
    isLoading,
    save,
    saving,
  }
}

export const useAdministratorEdit = (): AdministratorInfos => {
  const {
    save,
    record: administratorData,
    isLoading,
    saving,
  } = useEditController<EditableAdministrator>({
    resource: "universe/user",
    mutationMode: "optimistic",
    queryOptions: {
      meta: {
        idField: "userId",
      },
    },
  })

  return {
    administratorData,
    isLoading,
    save,
    saving,
  }
}
