import { localInit } from "@newpv/js-common"
import Axios from "axios"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import _ from "lodash"
import * as queryString from "query-string"
import type { DataProvider } from "ra-core"
import type { FC, ReactElement } from "react"
import React, { useEffect } from "react"
import type { Store } from "react-admin"
import {
  Admin,
  combineDataProviders,
  CustomRoutes,
  Notification,
  Resource,
  useLocaleState,
  usePermissions,
} from "react-admin"
import { Route, useNavigate } from "react-router-dom"
import AdminPanelScreen from "screens/AdminPanelScreen/AdminPanelScreen"
import { logger } from "utils/logger"

import { apiUrl, bffUrl, contentUrl } from "./features/Constants"
import Login from "./features/Layout/Login"
import type { Permission } from "./features/Providers"
import {
  dataProvider,
  dataProviderToken,
  i18nProvider,
  useAuthProvider,
} from "./features/Providers"
import { myTheme } from "./features/Theme/theme"
import { queryClient } from "./index"
import AddLearnerManualScreen from "./screens/AddLearnerManualScreen/AddLearnerManualScreen"
import AddLearnerScreen from "./screens/AddLearnerScreen/AddLearnerScreen"
import AuthenticationModeScreen from "./screens/AuthenticationModeScreen/AuthenticationModeScreen"
import EvalListScreen from "./screens/EvalScreen/EvalListScreen"
import ConfirmImportLearnerScreen from "./screens/ImportLearnerScreen/ConfirmImportLearnerScreen"
import ImportLearnerScreen from "./screens/ImportLearnerScreen/ImportLearnerScreen"
import { Layout } from "./screens/Layout/Layout"
import { LicensesListScreen } from "./screens/LicensesScreen/LicensesScreen"
import OralScreen from "./screens/OralScreen/OralScreen"
import RuleListScreen from "./screens/RuleScreen/RuleListScreen"
import StatsScreen from "./screens/StatsScreen/StatsScreen"
import StudentListScreen from "./screens/StudentScreen/StudentListScreen"
import datadog from "./utils/datadog.web"

require("dayjs/locale/fr")
dayjs.extend(utc)
datadog()
localInit({
  contentUrl,
  apiUrl,
  logger,
  axios: Axios.create({
    paramsSerializer: params => queryString.stringify(params, { arrayFormat: "none" }),
  }),
  axiosCache: Axios.create(),
  nocache: true,
})

const DefaultPage = (): null => {
  const navigate = useNavigate()
  const { permissions } = usePermissions()

  useEffect(() => {
    navigate(permissions?.includes("licenses") ? "/student-licenses" : "/stats")
  }, [navigate, permissions])

  return null
}

const dataProviderMixer = (): DataProvider =>
  combineDataProviders(resource => {
    const isBff =
      _.isEqual(resource, "scenarios") ||
      _.startsWith(resource, "users/") ||
      _.startsWith(resource, "stats/")

    return dataProvider(isBff ? bffUrl : apiUrl)
  })

const AdminNotification = (): ReactElement => <Notification autoHideDuration={5000} />

const App: FC<{ store: Store }> = ({ store }) => {
  const [locale] = useLocaleState()

  useEffect(() => {
    dayjs.locale(locale)
  }, [locale])
  return (
    <Admin
      theme={myTheme}
      layout={Layout}
      loginPage={Login}
      catchAll={DefaultPage}
      i18nProvider={i18nProvider}
      queryClient={queryClient}
      requireAuth={true}
      notification={AdminNotification}
      dataProvider={dataProviderMixer()}
      authProvider={useAuthProvider()}
      store={store}
    >
      {(permissions?: Permission[]) =>
        !dataProviderToken.current ? null : (
          <>
            {permissions?.includes("stats") ? (
              <>
                <Resource name="stats" list={StatsScreen} />
                <Resource name="evaluations" list={EvalListScreen} />
                <Resource name="apprenants" list={StudentListScreen} />
                <Resource name="rules" list={RuleListScreen} />
                <Resource name="mon-oral" list={OralScreen} />
              </>
            ) : null}
            {permissions?.includes("ENT") ? null : (
              <>
                <CustomRoutes>
                  <Route path="/adminPanel/divisions/*" element={<AdminPanelScreen />} />
                  <Route path="/adminPanel/groups/*" element={<AdminPanelScreen />} />
                  <Route path="/adminPanel/licenses/*" element={<AdminPanelScreen />} />
                  <Route path="/adminPanel/administrators/*" element={<AdminPanelScreen />} />
                </CustomRoutes>
                <CustomRoutes noLayout={true}>
                  <Route path="/addLearner" element={<AddLearnerScreen />} />
                  <Route path="/addLearnerManual" element={<AddLearnerManualScreen />} />
                  <Route path="/authenticationMode" element={<AuthenticationModeScreen />} />
                  <Route path="/importLearner" element={<ImportLearnerScreen />} />
                  <Route path="/confirmImportLearner" element={<ConfirmImportLearnerScreen />} />
                </CustomRoutes>
              </>
            )}
            {permissions?.includes("licenses") ? (
              <>
                <Resource name="student-licenses" list={LicensesListScreen} />
              </>
            ) : null}
          </>
        )
      }
    </Admin>
  )
}

export default App
