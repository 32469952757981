import { Tab, Tabs } from "@mui/material"
import { isEmpty } from "@newpv/js-common"
import { Empty } from "components"
import DivisionIdProvider from "features/Providers/DivisionIdProvider"
import LocalGroupListProvider from "features/Providers/LocalGroupListProvider"
import _ from "lodash"
import type { FC } from "react"
import React, { useCallback, useMemo } from "react"
import { usePermissions, useTranslate } from "react-admin"
import { useLocation, useNavigate } from "react-router-dom"
import { useCommonStyles, useStyles } from "utils"

import { useDivisions } from "../Layout/hooks/useDivisions"
import AdministratorList from "./administrators/AdministratorList"
import { DivisionList } from "./divisions/DivisionList"
import GroupTable from "./groups/GroupList"
import { AdminLicensesScreen } from "./licenses/AdminLicensesScreen"

const TAB_TO_LOCATION = {
  UNIVERSE: [
    "adminPanel/divisions",
    "adminPanel/groups",
    "adminPanel/licenses",
    "adminPanel/administrators",
  ],
  DIVISION: ["adminPanel/divisions", "adminPanel/groups", "adminPanel/licenses"],
  EMPTY: ["adminPanel/divisions", "adminPanel/administrators"],
}
const AdminPanelScreen: FC = () => {
  const t = useTranslate()
  const navigate = useNavigate()
  const location = useLocation()
  const { divisions } = useDivisions()
  const { permissions } = usePermissions()
  const isUniverseAdmin = permissions.includes("universeAdmin")

  const cs = useCommonStyles()
  const s = useStyles(({ spacing }) => ({
    mainDiv: {
      marginTop: spacing(4),
    },
    tab: {
      minHeight: 50,
      minWidth: 200,
      padding: spacing(2),
    },
    tabs: {
      height: "fit-content",
    },
  }))

  const tabNames = useMemo(
    () =>
      isEmpty(divisions)
        ? isUniverseAdmin
          ? TAB_TO_LOCATION.EMPTY
          : [TAB_TO_LOCATION.EMPTY[0]]
        : isUniverseAdmin
        ? TAB_TO_LOCATION.UNIVERSE
        : TAB_TO_LOCATION.DIVISION,
    [divisions, isUniverseAdmin],
  )
  const tabValue = _.findIndex(tabNames, item => location.pathname.includes(item)) ?? 0

  const getCompFromTabValue = useCallback(
    (tab: number) => {
      switch (tabNames?.[tab]) {
        case "adminPanel/divisions":
          return <DivisionList />

        case "adminPanel/groups":
          return (
            <LocalGroupListProvider>
              <GroupTable />
            </LocalGroupListProvider>
          )

        case "adminPanel/licenses":
          return <AdminLicensesScreen />

        case "adminPanel/administrators":
          return <AdministratorList />

        default:
          return <Empty />
      }
    },
    [tabNames],
  )

  const topBar = (
    <Tabs textColor="inherit" value={tabValue} sx={s.tabs}>
      {_.map(tabNames, locationStr => (
        <Tab
          key={locationStr}
          style={s.tab}
          onClick={() => navigate(`/${locationStr}`, { replace: true })}
          label={t(`adminPanel.tabs.${locationStr}`)}
        />
      ))}
    </Tabs>
  )

  return (
    <DivisionIdProvider>
      <div style={s.mainDiv}>
        {topBar}
        <div style={cs.fullView}>{getCompFromTabValue(tabValue)}</div>
      </div>
    </DivisionIdProvider>
  )
}

export default AdminPanelScreen
