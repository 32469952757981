import { Grid, Typography } from "@mui/material"
import { ConfirmDialog } from "components/ConfirmDialog"
import _ from "lodash"
import type { FC } from "react"
import React, { useCallback, useEffect, useState } from "react"
import {
  email,
  RadioButtonGroupInput,
  required,
  TextInput,
  useInput,
  useTranslate,
} from "react-admin"
import { useFormContext } from "react-hook-form"
import { useCommonStyles, useStyles } from "utils"

import { ns } from "./constants"

const ConnectionTypeGroup: FC = () => {
  const t = useTranslate()
  const { field } = useInput({ source: "connectionType" })
  const { setValue } = useFormContext()
  const [openModal, setOpenModal] = useState(false)

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing, palette: { primary }, typography: { subtitle1 } }) => ({
      muiInput: {
        "& .MuiFormLabel-root": cs.muiFormLabel12,
        "& .MuiInputBase-root": subtitle1,
      },
      textInput: {
        flex: 1,
        marginTop: spacing(2),
        display: field.value === "code" ? "none" : "flex",
      },
      radio: {
        "& .MuiFormControlLabel-root": {
          marginBottom: 2,
        },
        "& .MuiRadio-root.Mui-checked": {
          color: primary[700],
        },
        "& .MuiRadio-root": cs.secondaryTextColor,
      },
    }),
    [cs.muiFormLabel12, cs.secondaryTextColor, field.value],
  )

  const onClose = useCallback(() => {
    setOpenModal(false)
    field.onChange("email")
  }, [field])

  useEffect(() => {
    if (field.value === "code") {
      setOpenModal(true)
    }
  }, [field.value])

  return (
    <>
      <ConfirmDialog
        dialogStyle={{ width: "430px" }}
        namespace="code"
        {...{ onClose }}
        onConfirm={() => {
          setValue("email", null, { shouldDirty: true })
          setOpenModal(false)
        }}
        open={openModal}
      />
      <Grid item xs={12}>
        <Typography variant="body2">{t(`${ns}.connectionType`)}</Typography>
        <Grid item xs={12}>
          <TextInput
            label={t(`${ns}.emailLabelSelected`)}
            name="email"
            source="email"
            size="medium"
            style={s.textInput}
            sx={s.muiInput}
            format={value => _.deburr(value)}
            validate={
              field.value === "email"
                ? [required(`${ns}.validation.email`), email(`${ns}.error.email`)]
                : undefined
            }
            variant="outlined"
          />
        </Grid>
        <RadioButtonGroupInput
          name="connectionType"
          source="connectionType"
          choices={[
            { id: "email", label: t(`${ns}.email`), description: t(`${ns}.emailDescription`) },
            { id: "code", label: t(`${ns}.code`), description: t(`${ns}.codeDescription`) },
          ]}
          row={true}
          sx={s.radio}
          optionText={choice => (
            <div>
              <Typography variant="subtitle1">{choice.label}</Typography>
              <Typography style={cs.secondaryTextColor} variant="body2">
                {choice.description}
              </Typography>
            </div>
          )}
          optionValue="id"
          label={false}
          helperText={false}
        />
      </Grid>
    </>
  )
}

export default ConnectionTypeGroup
