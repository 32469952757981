import type { BackDivision, Division } from "@newpv/js-common"
import { axios, toDivision } from "@newpv/js-common"
import _ from "lodash"
import { useCallback, useEffect } from "react"
import { useStore } from "react-admin"
import { getAuth, useDivisionIds, useMode, useToken, useUniverseId } from "utils"

import { apiUrl } from "../../../features/Constants"

export const useDivisions = (): {
  divisions: Division[]
  refreshDivisions: () => Promise<void>
} => {
  const [divisions, setDivisions] = useStore<Division[]>("divisions")
  const [divisionIds] = useDivisionIds()
  const [universeId] = useUniverseId()
  const [token] = useToken()
  const [mode] = useMode()

  const setDivisionsIdDiff = useCallback(
    (newDivisions: Division[]) => {
      setDivisions(oldDivisions =>
        !_.isEqual(newDivisions, oldDivisions) ? newDivisions : oldDivisions,
      )
    },
    [setDivisions],
  )

  const refreshDivisions = useCallback(async () => {
    if (universeId != null) {
      // for licenses, for ED
      if (mode === "licenses") {
        const rawDivisions = await axios.get<BackDivision[]>(
          `${apiUrl}/ecoledirecte/universe/${universeId}/division`,
          {
            ...getAuth(token),
          },
        )
        setDivisionsIdDiff(rawDivisions.data.map(toDivision))
      } else {
        // for stats
        // this endpoint was changed to send back only the user's divisions, no need to rely on divisionIds anymore
        const rawDivisions = await axios.get<{ results: BackDivision[] }>(
          `${apiUrl}/universe/universe/${universeId}/division`,
          {
            params: {
              pageSize: -1,
              _order: "ASC",
              _sort: "name",
            },
            ...getAuth(token),
          },
        )
        setDivisionsIdDiff(rawDivisions.data.results.map(toDivision))
      }
    }
  }, [mode, setDivisionsIdDiff, token, universeId])

  useEffect(() => {
    ;(async () => refreshDivisions())()
  }, [divisionIds, universeId, token, mode, refreshDivisions])

  return { divisions, refreshDivisions }
}
