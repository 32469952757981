import CheckIcon from "@mui/icons-material/Check"
import { Button, Typography } from "@mui/material"
import type { UserId } from "@newpv/js-common"
import _ from "lodash"
import type { Dispatch, FC, SetStateAction } from "react"
import { useMemo } from "react"
import React from "react"
import { useListContext, useTranslate } from "react-admin"
import { useStyles } from "utils"

import type { ResourceSummary, UserWithLicenses } from "./AdminLicensesScreen"
import AdminSelectAllButton from "./AdminSelectAllButton"

interface Props {
  onSubmit: () => void
  newlySelectedIds: UserId[]
  newlyUnselectedIds: UserId[]
  setNewlySelectedIds: Dispatch<SetStateAction<UserId[]>>
  setNewlyUnselectedIds: Dispatch<SetStateAction<UserId[]>>
  selectedResource?: ResourceSummary
  isSubmitting?: boolean
}
export const ButtonContainer: FC<Props> = ({
  onSubmit,
  newlySelectedIds,
  newlyUnselectedIds,
  isSubmitting,
  setNewlyUnselectedIds,
  setNewlySelectedIds,
  selectedResource,
}) => {
  const { data: currentUsersOnPage } = useListContext<UserWithLicenses>()
  const t = useTranslate()

  const s = useStyles(({ spacing }) => ({
    button: {
      boxShadow: "none",
      display: "flex",
      gap: spacing(1),
      height: "36px",
    },
    buttonContainer: {
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      margin: spacing(1, 0),
      width: "100%",
    },
    saveContainer: {
      display: "flex",
      gap: spacing(1),
      padding: spacing(1),
    },
    summary: {
      margin: spacing(1),
    },
  }))

  const allUsersOnPageIds = useMemo(
    () => currentUsersOnPage?.map(user => user.id) ?? [],
    [currentUsersOnPage],
  )

  const initiallySelectedIdsOnPage = useMemo(
    () => currentUsersOnPage?.filter(user => user.selectedLicense).map(user => user.id) ?? [],
    [currentUsersOnPage],
  )

  const selectedUsersOnPageAndNewly = useMemo(
    () =>
      _(initiallySelectedIdsOnPage).concat(newlySelectedIds).difference(newlyUnselectedIds).value(),
    [initiallySelectedIdsOnPage, newlySelectedIds, newlyUnselectedIds],
  )

  const nbOfLicensesToRemove = newlyUnselectedIds.length
  // considering other pages too
  const nbOfNewlySelectedLicenses = newlySelectedIds.length

  // all users on page that are not already selected, either from a previous affectation (initiallySelectedIdsOnPage) or newly selected
  const unselectedUsersOnPage = useMemo(
    () => _.difference(allUsersOnPageIds, selectedUsersOnPageAndNewly),
    [allUsersOnPageIds, selectedUsersOnPageAndNewly],
  )

  const usersWithoutDefinitiveLicenseOnPage = useMemo(
    () =>
      currentUsersOnPage?.filter(user => !user.selectedLicense?.isDefinitive).map(user => user.id),
    [currentUsersOnPage],
  )

  return (
    <div style={s.buttonContainer}>
      <AdminSelectAllButton
        {...{
          setNewlyUnselectedIds,
          setNewlySelectedIds,
          unselectedUsersOnPage,
          nbOfLicensesToRemove,
          nbOfNewlySelectedLicenses,
          initiallySelectedIdsOnPage,
          allUsersOnPageIds,
          usersWithoutDefinitiveLicenseOnPage,
        }}
        nbOfAvailableLicenses={selectedResource?.availableLicensesCount ?? 0}
        disabled={selectedResource == null}
      />
      <div style={s.saveContainer}>
        <Typography style={s.summary} color="text[primary]" variant="body1">
          {t("adminPanel.licenses.newlySelectedSummary", nbOfNewlySelectedLicenses)}{" "}
          {t("adminPanel.licenses.newlyUnselectedSummary", nbOfLicensesToRemove)}
        </Typography>
        <Button
          disabled={isSubmitting || (_.isEmpty(newlySelectedIds) && _.isEmpty(newlyUnselectedIds))}
          style={s.button}
          variant="contained"
          onClick={onSubmit}
        >
          <CheckIcon />
          {t("license.save")}
        </Button>
      </div>
    </div>
  )
}
