import DownloadIcon from "@mui/icons-material/Download"
import { Typography } from "@mui/material"
import { Document, Font, Image, Page, PDFDownloadLink, Text, View } from "@react-pdf/renderer"
import dayjs from "dayjs"
import _ from "lodash"
import type { ReactNode } from "react"
import { useMemo } from "react"
import { useTranslate } from "react-admin"
import { useWatch } from "react-hook-form"
import { useStyles } from "utils"

import logo from "../../assets/images/logo_PV.png"
import { DATE_FORMAT_LNG, DATE_TIME_FORMAT } from "../../features/Constants"

export interface ConnectionData {
  connections: number[][]
  firstName: string
  lastName: string
  scenarioName: string
  domainName: string
}

const PdfContent = ({
  data,
  commonContent,
}: {
  data: ConnectionData
  commonContent: {
    title: string
    startDate: string
    endDate: string
    duration: string
    exportPeriod: string
    lastName: string
    firstName: string
    from: string
    to: string
    total: string
  }
}): ReactNode => {
  const s = useStyles(({ spacing, palette }) => ({
    innerContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    page: {
      padding: spacing(4, 2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    image: {
      width: 147,
      height: 126,
      alignSelf: "flex-start",
    },
    title: {
      fontSize: 20,
      textAlign: "center",
      fontFamily: "Montserrat",
      margin: "auto",
      marginBottom: spacing(1),
    },
    userInfo: {
      fontSize: 11,
      margin: spacing(1, 2),
      fontFamily: "Montserrat",
    },
    topContainer: { marginLeft: spacing(2), marginBottom: spacing(2), width: "80%" },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      width: "80%",
      borderLeft: "1px solid grey",
      borderBottom: "1px solid grey",
    },
    firstRow: { borderTop: "1px solid grey", backgroundColor: palette.grey[300] },
    tableCell: {
      borderRight: "1px solid grey",
      flex: 1,
      textAlign: "center",
      fontSize: 11,
      padding: spacing(1),
      fontFamily: "Montserrat",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      right: spacing(2),
      textAlign: "center",
      color: "grey",
    },
    titleContainer: {
      marginBottom: spacing(3),
    },
    total: {
      fontFamily: "Montserrat",
      fontSize: 11,
      textAlign: "right",
      width: "80%",
      marginVertical: spacing(2),
    },
    nameContainer: {
      display: "flex",
      flexDirection: "column",
    },
    nameSection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      width: "100%",
    },
    userInfoContainer: { display: "flex", flexDirection: "column" },
  }))

  Font.register({
    family: "Montserrat",
    src: "https://fonts.gstatic.com/s/montserrat/v10/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf",
  })

  const totalDuration = data.connections.reduce(
    (acc, [startDate, endDate]) => acc + endDate - startDate,
    0,
  )

  return (
    <Document>
      <Page style={s.page}>
        <Image style={s.image} src={logo} />
        <View style={s.titleContainer}>
          <Text style={s.title}>{commonContent.title}</Text>
          <Text style={s.title}>{data.scenarioName}</Text>
        </View>
        <View style={s.topContainer}>
          <View style={s.nameSection}>
            <View style={s.nameContainer}>
              <Text style={s.userInfo}>{commonContent.lastName}</Text>
              <Text style={s.userInfo}>{commonContent.firstName}</Text>
            </View>
            <View style={s.nameContainer}>
              <Text style={s.userInfo}>{data.lastName}</Text>
              <Text style={s.userInfo}>{data.firstName}</Text>
            </View>
          </View>
          <View style={s.innerContainer}>
            <Text style={s.userInfo}>{commonContent.exportPeriod}</Text>
            <View style={s.userInfoContainer}>
              <View style={s.innerContainer}>
                <Text style={s.userInfo}>{commonContent.from}</Text>
                <Text style={s.userInfo}>
                  {data.connections?.[0]?.[0]
                    ? dayjs(data.connections[0][0]).format(DATE_FORMAT_LNG)
                    : "-"}
                </Text>
              </View>
              <View style={s.innerContainer}>
                <Text style={s.userInfo}>{commonContent.to}</Text>
                <Text style={s.userInfo}>
                  {_.last(data.connections)
                    ? dayjs(_.last(data.connections)?.[1]).format(DATE_FORMAT_LNG)
                    : "-"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ ...s.tableRow, ...s.firstRow }} fixed>
          <Text style={s.tableCell}>{commonContent.startDate}</Text>
          <Text style={s.tableCell}>{commonContent.endDate}</Text>
          <Text style={s.tableCell}>{commonContent.duration}</Text>
        </View>
        {data.connections.map(([startDate, endDate], index) => (
          <View key={index} wrap={false} style={s.tableRow}>
            <Text style={s.tableCell}>{dayjs(startDate).format(DATE_TIME_FORMAT)}</Text>
            <Text style={s.tableCell}>{dayjs(endDate).format(DATE_TIME_FORMAT)}</Text>
            <Text style={s.tableCell}>
              {dayjs
                .duration(endDate - startDate, "ms")
                .format(
                  (endDate - startDate) % 3600000 === 0
                    ? "H [h] "
                    : endDate - startDate >= 3600000
                    ? "H [h] m [min]"
                    : "m [min]",
                )}
            </Text>
          </View>
        ))}
        <Text style={s.total}>{`${commonContent.total} ${dayjs
          .duration(totalDuration)
          .format(totalDuration >= 3600000 ? "H [h] m [min]" : "m [min]")}`}</Text>
        <Text style={s.pageNumber} render={({ pageNumber }) => `${pageNumber}`} fixed />
      </Page>
    </Document>
  )
}

const namespace = "students.studentModal.connectionExportPdf"
export const PdfDownload = ({ data }: { data: ConnectionData }): ReactNode => {
  const t = useTranslate()

  const startDate = useWatch({ name: "startDate" })
  const dueDate = useWatch({ name: "dueDate" })
  const exportPeriod = useWatch({ name: "exportPeriod" })

  const filteredData = useMemo(
    () =>
      exportPeriod === "all"
        ? data
        : {
            ...data,
            connections:
              data.connections.filter(
                ([start, end]) =>
                  start >= dayjs(startDate).valueOf() && end <= dayjs(dueDate).valueOf(),
              ) ?? [],
          },
    [exportPeriod, data, startDate, dueDate],
  )

  const commonContent = useMemo(
    () => ({
      title: t(`${namespace}.title`),
      startDate: t(`${namespace}.startDate`),
      endDate: t(`${namespace}.endDate`),
      duration: t(`${namespace}.duration`),
      exportPeriod: t(`${namespace}.exportPeriod`),
      lastName: t(`${namespace}.lastName`),
      firstName: t(`${namespace}.firstName`),
      from: t(`${namespace}.from`),
      to: t(`${namespace}.to`),
      total: t(`${namespace}.total`),
    }),
    [t],
  )

  const s = useStyles(({ spacing, palette: { surface, text } }) => ({
    icon: { marginRight: spacing(1) },
    button: {
      border: "1px solid",
      borderColor: surface.outline,
      borderRadius: spacing(1),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: text.primary,
      padding: spacing(1, 2),
      margin: spacing(1, 2),
      textDecoration: "none",
      textTransform: "uppercase",
      textAlign: "center",
    },
    label: {
      fontSize: "12px",
    },
  }))

  return (
    <div>
      <PDFDownloadLink
        document={<PdfContent {...{ commonContent }} data={filteredData} />}
        fileName={t(`${namespace}.pdfName`, {
          fullName: (data.firstName ? `${data.firstName} ` : "") + `${data.lastName}`,
          domainName: data.domainName,
        })}
        style={s.button}
      >
        <DownloadIcon style={s.icon} />
        <Typography variant="body1" style={s.label}>
          {t(`${namespace}.button`)}
        </Typography>
      </PDFDownloadLink>
    </div>
  )
}
