import { Checkbox } from "@mui/material"
import type { User } from "@newpv/js-common"
import _ from "lodash"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useMemo } from "react"
import { useNotify, useRecordContext } from "react-admin"
import type { RAFieldProps } from "utils"
import type { License } from "utils/License"

interface Props {
  selectedIds: string[]
  setSelectedIds: Dispatch<SetStateAction<string[]>>
  licensesList: License[]
  availableLicenses: number
}

export const CheckboxField: FC<Partial<RAFieldProps<User>> & Props> = ({
  selectedIds,
  setSelectedIds,
  licensesList,
  availableLicenses,
}) => {
  const record = useRecordContext<User>()
  const notify = useNotify()

  const userId = record.id

  const checked = useMemo(
    () => !!_.find(selectedIds, elem => elem === userId),
    [selectedIds, userId],
  )
  const disabled = useMemo(
    () => _.find(licensesList, l => l.userId === userId)?.definitive ?? false,
    [licensesList, userId],
  )
  const formerLicenses = useMemo(() => licensesList?.map(el => el.userId) ?? [], [licensesList])

  const nbOfLicensesToRemove = _.difference(formerLicenses, selectedIds).length
  const nbOfLicensesToAdd = _.difference(selectedIds, formerLicenses).length

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (
      !selectedIds.includes(userId) &&
      availableLicenses + nbOfLicensesToRemove - nbOfLicensesToAdd === 0
    ) {
      notify("ra.notification.assign_licenses_err", { type: "error", autoHideDuration: 1500 })

      return
    }
    setSelectedIds(
      event.target.checked ? prev => [...prev, userId] : _.without(selectedIds, userId),
    )
  }

  return <Checkbox {...{ onChange, disabled, checked }} />
}
