import { Divider, Typography, useTheme } from "@mui/material"
import type { FC } from "react"
import React from "react"
import { Button } from "react-admin"
import { useStyles } from "utils"

interface Props {
  label: string
  onClick: () => void
  description: string
  title: string
  disabled?: boolean
}
const ConfirmActionRow: FC<Props> = ({ label, onClick, title, description, disabled }) => {
  const {
    palette: { text },
  } = useTheme()
  const s = useStyles(
    ({ spacing }) => ({
      button: {
        alignSelf: "center",
        marginLeft: spacing(2),
        minWidth: 200,
        padding: spacing(1.5, 2.5),
      },
      row: {
        display: "flex",
        flexDirection: "row",
      },
    }),
    [],
  )

  return (
    <>
      <Divider />
      <div style={s.row}>
        <div>
          <Typography variant="h6">{title}</Typography>
          <Typography color={text.secondary} variant="subtitle1">
            {description}
          </Typography>
        </div>
        <Button
          disableElevation={true}
          style={s.button}
          variant="contained"
          {...{ label, onClick, disabled }}
        />
      </div>
    </>
  )
}

export default ConfirmActionRow
