import { Typography } from "@mui/material"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useCommonStyles, useStyles } from "utils"

interface Props {
  isList?: boolean
  title?: string
  subtitle?: string
  value?: number
}

const emptyNs = "common.empty"

/**
 * Empty component if nothing to render
 */
export const Empty: FC<Props> = ({ isList = false, subtitle, title, value = 0 }) => {
  const t = useTranslate()

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing }) => ({
      container: {
        flex: 1,
        display: value === 1 ? "none" : "flex",
        margin: spacing(2),
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: isList ? undefined : spacing(4),
      },
    }),
    [isList, value],
  )

  return (
    <div style={s.container}>
      {isList ? null : (
        <Typography variant={isList ? "h6" : "body1"} paragraph>
          {title ?? t(`${emptyNs}.title`)}
        </Typography>
      )}
      <Typography variant={isList ? "subtitle1" : "body2"} style={cs.centerText}>
        {subtitle ? subtitle : t(`${emptyNs}.description`)}
      </Typography>
    </div>
  )
}
