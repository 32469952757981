import type { RaRecord } from "ra-core/dist/cjs/types"
import type { ReactElement } from "react"
import React from "react"
import { useRecordContext, useTranslate } from "react-admin"
import type { RAFieldProps } from "utils"
import { formatDuration } from "utils"

export const TimeField = <T extends RaRecord = RaRecord>({
  source,
}: RAFieldProps<T>): null | ReactElement => {
  const record = useRecordContext<T>()
  const t = useTranslate()

  return !record ? null : <span>{formatDuration({ t, duration: record?.[source] })}</span>
}
