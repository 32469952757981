import { MILLISECONDS_IN_15_MINUTES } from "../features/Constants"
import RangeSelectionModel from "./RangeSelectionModel"

export const convertToQuarters = (connections: number[][]): number[][] => {
  const model = new RangeSelectionModel()

  connections.forEach(([spanStart, spanEnd]) =>
    // select a span of "quarters" in the model
    model.select(
      Math.floor(spanStart / MILLISECONDS_IN_15_MINUTES),
      Math.floor(spanEnd / MILLISECONDS_IN_15_MINUTES),
    ),
  )

  return model.selection.map(([start, end]) => [
    start * MILLISECONDS_IN_15_MINUTES,
    (end + 1) * MILLISECONDS_IN_15_MINUTES,
  ])
}
