import { Typography } from "@mui/material"
import type { FC, PropsWithChildren } from "react"
import React from "react"
import { useCommonStyles } from "utils"

interface Props {
  title: string
  hideSeparator?: boolean
}
const AsideSection: FC<PropsWithChildren<Props>> = ({ children, hideSeparator, title }) => {
  const cs = useCommonStyles()

  return (
    <>
      <div style={cs.asideContainer}>
        <Typography variant="subtitle1">{title}</Typography>
        {children}
      </div>
      {hideSeparator ? null : <div style={cs.separator} />}
    </>
  )
}

export default AsideSection
