import { parseInt10 } from "@newpv/js-common"

/**
 * URLs
 * */
export const bffUrl = process.env.REACT_APP_BFF_URL ?? "https://bff-dev.woonoz-dev.fr"

export const authUrl = process.env.REACT_APP_AUTH_URL ?? "https://auth-test.woonoz-dev.fr"

export const apiUrl = process.env.REACT_APP_API_URL ?? "https://api-dev.woonoz-dev.fr"

// TODO: change fallback here - latest does not exist in production
export const contentVersion = process.env.REACT_APP_CONTENT_VERSION ?? "latest"
export const contentUrl =
  (process.env.REACT_APP_CONTENT_URL ?? "https://d1dmkakbvilt86.cloudfront.net") +
  `/${contentVersion}`

export const apprentissageUrl =
  process.env.REACT_APP_APPRENTISSAGE_URL ?? "https://apprentissage.woonoz-dev.fr"

export const ddSampleRate = parseInt10(process.env.REACT_APP_DD_SAMPLE_RATE ?? "0")

/**
 * Date & time formats
 */
export const DATE_FORMAT = "DD/MM"
export const DATE_FORMAT_SHT = "DD/MM/YY"
export const DATE_FORMAT_LNG = "DD/MM/YYYY"
export const HOUR_FORMAT = "HH:mm"
export const HOUR_FORMAT_LNG = "HH:mm:ss"
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm"
// noinspection JSUnusedGlobalSymbols
export const DURATION_FORMAT = "HH [h] mm [min]"

export const MILLISECONDS_IN_15_MINUTES = 900_000

/** Datadog config */
export const datadogClientToken = "pub202df316721617ea498636a9295d3c34"

export const mandatoryLinks = [
  { label: "legalMentions", url: "https://www.projet-voltaire.fr/mentions-legales/" },
  {
    label: "gdprLabel",
    url: "https://www.projet-voltaire.fr/pv-wp/wp-content/uploads/2020/08/Projet-Voltaire-Politique-de-confidentialit%C3%A9-GAR.docx.pdf",
  },
  {
    label: "generalConditions",
    url: "https://www.projet-voltaire.fr/pv-wp/wp-content/uploads/2020/08/Projet-Voltaire-CGU.pdf",
  },
]

export const iconSize = {
  EXTRA_SMALL: 16,
  SMALL: 24,
  MEDIUM: 32,
  LARGE: 48,
  EXTRA_LARGE: 64,
}

export const boxSize = {
  height: 687,
  width: 912,
}

export const BAR_HEIGHT = 72
