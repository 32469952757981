export interface UserError {
  firstName: string
  lastName: string
  email: string
}

export type ErrorCode = "duplicateEmail" | "mandatoryEmail" | "invalidEmail" | "mandatoryLastName"

export interface ErrorLine {
  data: UserError
  errorCodes: ErrorCode[]
}

export interface ImportResult {
  readyToImport: number
  imported: boolean
  errorLines: ErrorLine[]
}

export interface ImportError {
  code: string
  error: ErrorEvent
}

export function isImportError(result: ImportResult | ImportError): result is ImportError {
  return (result as ImportError).code !== undefined
}
