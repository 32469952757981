import GetAppIcon from "@mui/icons-material/GetApp"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import type { Resource } from "@newpv/js-common"
import _ from "lodash"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { Fragment, useCallback, useMemo } from "react"
import { usePermissions, useTranslate } from "react-admin"
import { useStyles } from "utils"

import type { File } from "../StatsExport"
import { exportNs } from "../StatsExport"
import type { DataType } from "../utils/ExportsUtils"

export const ExportCard: FC<
  File & {
    setDataType: Dispatch<SetStateAction<"csv" | "pdf" | DataType | undefined>>
    loading: boolean
    setExportScope: Dispatch<React.SetStateAction<"universe" | "division" | undefined>>
    isScenarioEmpty: boolean
    resources?: Resource[]
  }
> = ({
  preview,
  dataType,
  type = "csv",
  setDataType,
  loading,
  actions = ["division"],
  setExportScope,
  isScenarioEmpty,
  resources,
}) => {
  const t = useTranslate()
  const { permissions } = usePermissions()
  const {
    palette: { text: themeText },
  } = useTheme()
  const s = useStyles(
    ({ spacing, palette: { surface, action } }) => ({
      container: {
        margin: spacing(1),
        position: "relative",
        minWidth: "326px",
        flex: 1,
      },
      card: {
        height: "288px",
        borderRadius: "16px",
        margin: 0,
      },
      backgroundImg: {
        alignItems: "center",
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 65.62%, rgba(0, 0, 0, 0.1) 100%), url(${preview}), #FAFAFA`,
        backgroundSize: "cover",
        display: "flex",
        height: "150px",
        justifyContent: "center",
        width: "100%",
      },
      button: {
        borderColor: surface.outline,
      },
      disabled: {
        backgroundColor: action.disabledBackground,
      },
      buttonContainer: {
        position: "absolute",
        bottom: spacing(1),
        left: spacing(3),
        display: "flex",
        justifyContent: "flex-start",
        gap: spacing(1),
      },
    }),
    [preview],
  )

  const allowedActions = useMemo(
    () =>
      permissions?.includes("universeAdmin")
        ? isScenarioEmpty
          ? ["universe"]
          : actions
        : ["division"],
    [permissions, isScenarioEmpty, actions],
  )
  const isDisabled = useCallback(
    (action: "division" | "universe") =>
      (_.isUndefined(resources) || _.isEmpty(resources)) && action === "division",
    [resources],
  )

  const onClick = useCallback(
    async action => {
      setDataType(dataType ?? type)
      setExportScope(action)
    },
    [setDataType, dataType, type, setExportScope],
  )

  const renderActionButton = useCallback(
    (action: "division" | "universe") => {
      const disabled = isDisabled(action)
      const color = disabled ? themeText.disabled : themeText.secondary
      const variantColor = disabled ? themeText.disabled : themeText.primary
      const buttonText = t(`${exportNs}.common.${action}`)
      const Wrapper = !disabled ? Fragment : Tooltip

      return (
        <Wrapper title={t(`${exportNs}.common.tooltip`)}>
          <span>
            <Button
              key={action}
              onClick={() => onClick(action)}
              variant="outlined"
              disabled={disabled}
              style={{ ...(disabled ? s.disabled : {}), ...s.button }}
              startIcon={<GetAppIcon sx={{ color }} />}
            >
              <Typography variant="button" color={variantColor}>
                {buttonText}
              </Typography>
            </Button>
          </span>
        </Wrapper>
      )
    },
    [
      isDisabled,
      s.button,
      s.disabled,
      themeText.disabled,
      themeText.secondary,
      themeText.primary,
      t,
      onClick,
    ],
  )

  return (
    <div style={s.container}>
      <Card style={s.card} variant="outlined">
        <div style={s.backgroundImg}>{loading ? <CircularProgress /> : null}</div>
        <CardContent>
          <Typography variant="subtitle2">
            {t(`${exportNs}.${dataType ?? "report"}.title`)}
          </Typography>
          <Typography variant="body2">
            {t(`${exportNs}.${dataType ?? "report"}.subtitle`)}
          </Typography>
        </CardContent>
        {actions ? (
          <CardActions style={s.buttonContainer}>
            {allowedActions.map(action => renderActionButton(action as "division" | "universe"))}
          </CardActions>
        ) : null}
      </Card>
    </div>
  )
}
