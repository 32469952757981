import { ConfirmDialog } from "components/ConfirmDialog"
import RadioChoiceScreen from "components/RadioChoiceScreen"
import _ from "lodash"
import type { FC } from "react"
import React, { useCallback, useState } from "react"
import { useTranslate } from "react-admin"
import { useLocation, useNavigate } from "react-router-dom"

import ScreenWrapper from "../AddLearnerScreen/ScreenWrapper"
import type { AuthChoice } from "./constants"
import { ns } from "./constants"

const AuthenticationModeScreen: FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const t = useTranslate()

  const choiceState = useState<AuthChoice>("email")
  const [openModal, setOpenModal] = useState(false)

  const onClose = useCallback(() => {
    setOpenModal(false)
  }, [])

  const onConfirm = useCallback(
    () =>
      navigate("/importLearner", {
        state: {
          authMode: choiceState[0],
          divisionId: state?.divisionId,
          divisionName: state?.divisionName,
        },
      }),
    [choiceState, navigate, state],
  )

  return (
    <>
      <ConfirmDialog
        dialogStyle={{ width: "430px" }}
        namespace="code"
        open={openModal}
        {...{ onClose, onConfirm }}
      />
      <ScreenWrapper
        topProps={{ title: `${ns}.headerTitle` }}
        bottomProps={{
          next: {
            disabled: _.isEmpty(choiceState[0]),
            label: t(`${ns}.next`),
            onClick: () => (choiceState[0] === "code" ? setOpenModal(true) : onConfirm()),
          },
          showPrevious: true,
        }}
      >
        <RadioChoiceScreen<AuthChoice>
          radios={{
            left: {
              description: t(`${ns}.emailDescription`),
              label: t(`${ns}.emailLabel`),
              value: "email",
            },
            right: {
              description: t(`${ns}.codeDescription`),
              label: t(`${ns}.codeLabel`),
              value: "code",
            },
          }}
          state={choiceState}
          subtitle={t(`${ns}.subtitle`)}
          title={t(`${ns}.title`)}
        />
      </ScreenWrapper>
    </>
  )
}

export default AuthenticationModeScreen
