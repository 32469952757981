import type { OverridableComponent } from "@mui/material/OverridableComponent"
import type { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon"
import type { Dispatch, FC, SetStateAction } from "react"
import React from "react"
import { MenuItemLink, useTranslate } from "react-admin"
import { useStyles } from "utils"

export interface Route {
  path?: string
  name: string
  Icon: OverridableComponent<SvgIconTypeMap> & { muiName: string }
}

interface MenuItemProps extends Route {
  selectedRoute: string
  setSelectedRoute: Dispatch<SetStateAction<string>>
}

export const MenuItem: FC<MenuItemProps> = ({
  Icon,
  name,
  path,
  selectedRoute,
  setSelectedRoute,
}) => {
  const isSelected = selectedRoute === path
  const t = useTranslate()

  const s = useStyles(
    ({
      typography: { body2 },
      palette: {
        primary,
        text: { disabled, primary: primaryText, secondary: secondaryText },
      },
    }) => ({
      icon: {
        color: isSelected ? primary["600"] : disabled,
      },
      menuItem: {
        ...body2,
        color: isSelected ? primaryText : secondaryText,
        height: "56px",
        ...(isSelected ? { backgroundColor: primary["50"] } : {}),
        "&:hover": {
          backgroundColor: primary["50"],
        },
      },
    }),
    [isSelected],
  )

  return (
    <MenuItemLink
      key={name}
      disabled={!path}
      sx={s.menuItem}
      to={`..${path}`}
      primaryText={t(`${name}.title`)}
      leftIcon={<Icon sx={s.icon} />}
      onClick={() => {
        setSelectedRoute(name)
      }}
    />
  )
}
