import { Typography } from "@mui/material"
import type { ISOTime, ResourceId, UniverseId, User, UserId } from "@newpv/js-common"
import { axios } from "@newpv/js-common"
import { MuiSelect } from "components"
import { ConfirmDialog } from "components/ConfirmDialog"
import _ from "lodash"
import type { FC } from "react"
import { useCallback } from "react"
import React, { useEffect, useMemo, useState } from "react"
import { List, useNotify, useRefresh, useTranslate } from "react-admin"
import { useQueryClient } from "react-query"
import { Route, Routes } from "react-router-dom"
import {
  getAuth,
  useCommonStyles,
  useQueryRequest,
  useStyles,
  useToken,
  useUniverseId,
} from "utils"
import delay from "utils/delay"

import { apiUrl } from "../../../features/Constants"
import { AdminLicenseDataGrid } from "./AdminLicenseDatagrid"
import { ButtonContainer } from "./ButtonContainer"
import { CustomFilters } from "./CustomFilters"
import { LicenseUserDetailsScreen } from "./LicenseUserDetailsScreen"

const errorCodes = [
  "NO_ACTIVE_LICENSE_PACK",
  "ALREADY_HAVE_LICENSE",
  "NOT_ENOUGH_LICENSES",
  "DO_NOT_HAVE_LICENSE",
]
export type LicenseId = string
export interface ResourceSummary {
  packLicenseId: LicenseId
  universeId: UniverseId
  resourceId: ResourceId
  resourceName: string
  availableLicensesCount: number
}

export interface LicenseInfo {
  startDate: ISOTime
  endDate: ISOTime
  resourceId: ResourceId
  isDefinitive: boolean
  resourceName: string
}

export type UserWithLicenses = Pick<User, "firstName" | "lastName"> & {
  userId: UserId
  id: UserId
  email?: string
  code?: string
  divisionName: string
  licenseCount: number
  selectedLicense: LicenseInfo
  licenses: LicenseInfo[]
}

export const AdminLicensesScreen: FC = () => {
  const t = useTranslate()
  const notify = useNotify()
  const [token] = useToken()
  const [universeId] = useUniverseId()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const [newlySelectedIds, setNewlySelectedIds] = useState<UserId[]>([])
  const [newlyUnselectedIds, setNewlyUnselectedIds] = useState<UserId[]>([])
  const [localResourceId, setLocalResourceId] = useState<string>("")

  const route = `${apiUrl}/universe/${universeId}/licenses/learners`

  // reinitialise on resource change
  useEffect(() => {
    setNewlyUnselectedIds([])
    setNewlySelectedIds([])
  }, [localResourceId])

  const refresh = useRefresh()
  const queryClient = useQueryClient()

  /** DATA FETCHING */
  const { data: resources, refetch: refetchResourcesSummary } = useQueryRequest<
    ResourceSummary[]
  >(async () => {
    const result = await axios.get<ResourceSummary[]>(
      `${apiUrl}/universe/${universeId}/licenses/packs`,
      getAuth(token),
    )

    return result.data
  }, ["universeId", universeId, "resources", localResourceId])

  const selectedResource = useMemo(
    () => _.find(resources, resource => resource.resourceId === localResourceId),
    [localResourceId, resources],
  )

  useEffect(() => {
    if (resources?.length === 1) {
      setLocalResourceId(resources[0].resourceId)
    }
  }, [resources])

  /** FUNCTIONS */

  const onCloseConfirmDialog = useCallback(() => setOpenConfirmDialog(false), [])
  const openDialog = useCallback(() => setOpenConfirmDialog(true), [])
  const onConfirmSave = async (): Promise<void> => {
    if (!localResourceId) {
      return
    }

    setIsSubmitting(true)

    try {
      const commonReqData = { resourceId: localResourceId }

      // we need to delete first
      // to make room for additions, if we already reached the limit
      if (!_.isEmpty(newlyUnselectedIds)) {
        await axios.delete(route, {
          params: {
            ...commonReqData,
            // singular because in URL, it's userId=…&userId=… | API obligation
            userId: newlyUnselectedIds,
          },
          // auth headers
          ...getAuth(token),
        })
      }

      if (!_.isEmpty(newlySelectedIds)) {
        await axios.post(route, { ...commonReqData, userIds: newlySelectedIds }, getAuth(token))
      }

      await refetchResourcesSummary()
      refresh()
      queryClient.removeQueries([`universe/${universeId}/licenses/learners`, "getOne"])
      await delay(200)

      setNewlySelectedIds([])
      setNewlyUnselectedIds([])
    } catch (err) {
      notify(
        errorCodes.includes(err.response?.data?.code)
          ? `adminPanel.licenses.errors.${err.response.data.code}`
          : "ra.notification.http_error",
        {
          type: "error",
          autoHideDuration: 2000,
        },
      )
    }

    setIsSubmitting(false)
    onCloseConfirmDialog()
  }

  const cs = useCommonStyles()
  const s = useStyles(({ spacing, palette: { secondary, text } }) => ({
    boxGroup: {
      margin: spacing(1, 2, 0, 2),
      maxWidth: "350px",
      minWidth: "114px",
    },
    explanation: {
      color: text.secondary,
      margin: spacing(1),
      whiteSpace: "pre-line",
    },
    licenseSummaryContainer: {
      height: "min-content",
      padding: spacing(1, 2),
      width: "100%",
    },
    licenseSummaryCount: {
      backgroundColor: secondary[50],
      borderRadius: "8px",
      color: text.primary,
      height: "min-content",
      padding: spacing(2, 3),
      width: "fit-content",
    },
    list: {
      "& .RaList-main": {
        boxShadow: "none",
      },
      "& .RaList-content": {
        borderRadius: "2px",
      },
      "& .RaBulkActionsToolbar-toolbar": {
        visibility: "hidden",
      },
      "& .RaDatagrid-tableWrapper": {
        borderRadius: "2px",
        boxShadow: 4,
        overflowX: "hidden",
      },
    },
  }))

  return (
    <div style={cs.fullView}>
      <MuiSelect
        id="resources"
        variant="outlined"
        disabled={(resources?.length ?? 0) === 0}
        value={localResourceId ?? ""}
        boxStyle={s.boxGroup}
        onValueChange={elem => {
          setLocalResourceId(elem)
        }}
        items={_.concat(
          [{ value: "", text: t("adminPanel.licenses.allResources") }],
          resources?.map(elem => ({ value: elem.resourceId, text: elem.resourceName })) ?? [],
        )}
        displayEmpty={true}
      />
      <div style={s.licenseSummaryContainer}>
        <div style={s.licenseSummaryCount}>
          <Typography variant="subtitle1" style={{ whiteSpace: "pre-wrap" }}>
            {selectedResource
              ? t(
                  "adminPanel.licenses.licensesSummary",
                  selectedResource?.availableLicensesCount ?? 0,
                )
              : t("adminPanel.licenses.selectALicense")}
          </Typography>
        </div>
        <Typography style={s.explanation} variant="body2">
          {t("adminPanel.licenses.explanation")}
        </Typography>
      </div>

      <List<Omit<User, "roles" | "inscriptionDate">>
        actions={<CustomFilters {...{ localResourceId }} />}
        sx={s.list}
        empty={false}
        exporter={false}
        queryOptions={{
          onError: () => notify("ra.notification.http_error", { type: "error" }),
          meta: { idField: "userId" },
        }}
        resource={`universe/${universeId}/licenses/learners`}
        filter={{ resourceId: localResourceId }}
        disableSyncWithLocation={true}
        sort={{ field: "lastName", order: "ASC" }}
      >
        <div style={{ position: "relative" }}>
          <ButtonContainer
            {...{
              newlySelectedIds,
              newlyUnselectedIds,
              selectedResource,
              isSubmitting,
              setNewlyUnselectedIds,
              setNewlySelectedIds,
            }}
            onSubmit={openDialog}
          />
          <AdminLicenseDataGrid
            {...{
              newlySelectedIds,
              setNewlySelectedIds,
              newlyUnselectedIds,
              setNewlyUnselectedIds,
              selectedResource,
            }}
            nbOfAvailableLicenses={selectedResource?.availableLicensesCount ?? 0}
          />
        </div>
      </List>
      <Routes>
        <Route path="/:id" element={<LicenseUserDetailsScreen />} />
      </Routes>
      <ConfirmDialog
        open={openConfirmDialog}
        onConfirm={onConfirmSave}
        onClose={onCloseConfirmDialog}
        namespace="adminPanel.licenses"
        overrideMessage={t("adminPanel.licenses.modal.confirmModifications", {
          newlySelected: newlySelectedIds.length,
          newlyUnselected: newlyUnselectedIds.length,
        })}
      />
    </div>
  )
}
