import { Button, Tooltip, Typography } from "@mui/material"
import type { User } from "@newpv/js-common"
import _ from "lodash"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useCallback, useMemo } from "react"
import { useListContext, useNotify, useTranslate } from "react-admin"
import { useStyles } from "utils"
import type { License } from "utils/License"

interface Props {
  licensesList?: License[]
  selectedIds: string[]
  setSelectedIds: Dispatch<SetStateAction<string[]>>
  availableLicenses: number
}

const SelectAllButton: FC<Props> = ({
  selectedIds,
  setSelectedIds,
  licensesList,
  availableLicenses,
}) => {
  /** HOOKS */
  // withPagination
  const { data: currentUsersOnPage } = useListContext<Omit<User, "roles" | "inscriptionDate">>()
  const notify = useNotify()
  const t = useTranslate()

  /** VARIABLES */
  const formerUsersWithLicenses = useMemo(
    () => licensesList?.map(el => el.userId) ?? [],
    [licensesList],
  )

  const allUsersOnPageIds = useMemo(
    () => currentUsersOnPage?.map(user => user.id) ?? [],
    [currentUsersOnPage],
  )

  const nbOfLicensesToRemove = _.difference(formerUsersWithLicenses, selectedIds).length

  // all users on page that are not already selected, either from a previous affectation (formerUsersWithLicenses) or from an individual selection
  const unselectedUsersOnPage = useMemo(
    () => _.difference(allUsersOnPageIds, selectedIds),
    [allUsersOnPageIds, selectedIds],
  )

  const allSelected = unselectedUsersOnPage.length === 0

  const usersWithoutDefinitiveLicenseOnPage = useMemo(
    () =>
      allUsersOnPageIds?.filter(
        userId => !_.find(licensesList, l => l.userId === userId)?.definitive,
      ),
    [allUsersOnPageIds, licensesList],
  )

  // consider other pages too
  const nbOfNewlySelectedLicenses = useMemo(
    () => _.difference(selectedIds, formerUsersWithLicenses).length,
    [formerUsersWithLicenses, selectedIds],
  )

  // there could be not enough licenses if we already selected other students one by one - possibly on other pages
  const notEnoughLicensesForAllUsersOnPage =
    availableLicenses +
      nbOfLicensesToRemove -
      nbOfNewlySelectedLicenses -
      unselectedUsersOnPage.length <
    0

  /** FUNCTIONS */
  const onSelectAllClick = useCallback(() => {
    // if "select all" action
    if (!allSelected) {
      if (notEnoughLicensesForAllUsersOnPage) {
        notify("ra.notification.not_enough_licenses_err", {
          type: "error",
          autoHideDuration: 1500,
        })
        return
      }
      setSelectedIds(prev => [...prev, ...unselectedUsersOnPage])
      return
    }

    // if "unselect all" action
    setSelectedIds(prev => _.difference(prev, usersWithoutDefinitiveLicenseOnPage))
    return
  }, [
    allSelected,
    setSelectedIds,
    usersWithoutDefinitiveLicenseOnPage,
    notEnoughLicensesForAllUsersOnPage,
    notify,
    unselectedUsersOnPage,
  ])

  const s = useStyles(
    ({ spacing, palette: { primary, secondary } }) => ({
      button: {
        alignSelf: "center",
        borderColor: allSelected ? secondary["800"] : primary["800"],
        color: allSelected ? secondary["800"] : primary["800"],
        height: "40px",
        margin: spacing(1),
        padding: "10px",
      },
    }),
    [allSelected],
  )

  return !_.isUndefined(usersWithoutDefinitiveLicenseOnPage?.length) &&
    !_.isUndefined(unselectedUsersOnPage) &&
    currentUsersOnPage?.length > 0 ? (
    <div style={{ display: "flex" }}>
      <Tooltip
        title={
          !allSelected && notEnoughLicensesForAllUsersOnPage
            ? t("license.notEnoughTooltip")
            : !allSelected
            ? t("license.assignAllTooltip")
            : t("license.unassignAllTooltip")
        }
        enterDelay={500}
      >
        <div style={{ display: "flex" }}>
          <Button
            disabled={
              !allSelected &&
              (notEnoughLicensesForAllUsersOnPage || unselectedUsersOnPage.length === 0)
            }
            style={s.button}
            variant="outlined"
            onClick={onSelectAllClick}
          >
            <Typography variant="button">
              {allSelected
                ? t("license.unselectAll", {
                    total: usersWithoutDefinitiveLicenseOnPage?.length,
                  })
                : t("license.selectAll", { total: unselectedUsersOnPage.length })}
            </Typography>
          </Button>
        </div>
      </Tooltip>
    </div>
  ) : null
}

export default SelectAllButton
