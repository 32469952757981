/**
 * Must be sorted alphabetically
 */
export const commonNs = "common"

export const fr = {
  "Request failed with status code 401": "La connexion a expiré. Merci de vous reconnecter.",
  error: {
    supervisor: "Vous ne pouvez pas accéder à l’outil de suivi avec un compte apprenant.",
    noRole: "Vous n'avez pas de division attribuée. Veuillez contacter le support.",
    logout: "Vous avez été déconnecté(e).",
    login: "Identifiant ou mot de passe incorrect.\n",
    entLogout: "Vous pouvez fermer cette page et vous reconnecter depuis votre ENT.",
    refreshInitial:
      "La réinitialisation n’a pas pu être effectuée correctement. Veuillez réessayer dans quelques minutes.",
  },
  common: {
    delay: "Durée",
    durationFormats: {
      exports: "HH:mm:ss",
      long: "H [h] m [min]",
      shortMin: "m [min]",
      short: "m [min] s [s]",
    },
    empty: {
      description: "Aucune donnée n'est disponible ici pour le moment.",
      title: "Pas de donnée",
    },
    evaluations: "Évaluations",
    grade: "Note",
    score: "Score",
    duration: "Durée",
    notImplemented: "Pas encore implémenté",
    ok: "OK",
    students: "Apprenants",
    name: "Nom",
    firstName: "Prénom",
    email: "Adresse électronique",
    code: "Code d'accès",
    next: "Suivant",
    previous: "Retour",
    copy: "Copier",
    durationFormat: "H [h] m [min]",
    durationMin: "m [min]",
    emptyScreen: {
      title: "Ce contenu n'est encore disponible.",
      listTitle:
        "Si vos apprenants rencontrent des difficultés pour accéder à l'application Projet Voltaire:",
      list: {
        step1: "Créer au moins 1 division depuis le panneau d'administration.",
        step2: "Ajouter au moins 1 apprenant à cette division.",
        step3: "Affecter au moins 1 licence.",
      },
      description:
        "Les contenus seront visibles dès qu'au moins 1 apprenant commencera à s'entraîner.",
      divisionAdminTitle:
        "Il sera accessible dès qu'au moins 1 apprenant commencera à s'entraîner.",
      divisionAdminDescription:
        "Si vos apprenants rencontrent des difficultés pour accéder à l'application Projet Voltaire, veuillez contacter votre administrateur d'univers.",
    },
  },
  code: {
    modal: {
      title: "Connexion par code d'accès",
      message:
        "L'utilisation des codes d'accès est conseillée uniquement en l'absence d'un accès aux adresses électroniques des apprenants. Il est de votre responsabilité de transmettre ces codes d'accès de manière sécurisée.\n" +
        "\n" +
        "Pour une sécurité optimale, privilégiez la connexion via adresse électronique et mot de passe.\n",
      question: "Souhaitez-vous tout de même activer la connexion par code d'accès ?",
      no: "Annuler",
      yes: "Activer la connexion par code",
    },
    codeCopied: "Code copié",
    copyCodeError: "Le code n'a pu être copié",
  },
  adminPanel: {
    title: "Panneau d'administration",
    openPanel: "Ouvrir le panneau d'administration",
    tabs: {
      "adminPanel/divisions": "Divisions",
      "adminPanel/groups": "Groupes",
      "adminPanel/administrators": "Administrateurs",
      "adminPanel/licenses": "Apprenants et licences",
    },
    divisions: {
      new: "Création d'une division",
      newSuccess: "La division a été créée avec succès",
      editSuccess: "La division a été mise à jour avec succès",
      divisionCreationError:
        "Il y a eu un problème lors de la création ou l'édition de la division",
      DUPLICATE_NAME: "Ce nom est déjà utilisé par une autre division",
      divisionDeleteError: "Il y a eu un problème lors de la suppression de la division",
      deleteSuccess: "La division a été supprimée avec succès",
      create: "Créer une division",
      details: "Détails d'une division",
      empty: {
        title: "Vous n'avez pas encore créé de division.",
        subtitle: "La liste de vos divisions s'affichera ici.",
      },
      nbLearners: "Nombre d'apprenants",
      downloadCodes: "Télécharger les codes",
      addLearners: "Ajouter des apprenants",
      divisionAccess: "Ajouter des apprenants depuis une division",
      placeholder: "Rechercher une division…",
      createdBy: "Créée par",
      generalTab: {
        title: "Général",
        id: "Identifiant de la division",
        creationDate: "Date d'ouverture",
        owner: "Créée par",
        export: {
          title: "Codes d'accès des apprenants",
          divisionName: "Division",
          lastName: "Nom",
          firstName: "Prénom",
          code: "Code d'accès",
          exportError: "Il y a eu un problème lors du téléchargement des codes",
        },
      },
      accessRights: {
        divOwner: "Créée par",
        universeadmin: "Administrateur d'univers",
        divisionsupervisor: "Administrateur de division",
      },
      authTab: { title: "Droits d'accès" },
      modal: {
        yes: "Supprimer",
        no: "Annuler",
        title: "Supprimer une division",
        question: "",
        message: "Êtes-vous certain de vouloir supprimer cette division ?",
      },
    },
    groups: {
      empty: "Vous n’avez pas encore créé de groupe.",
      emptySubtitle: "La liste de vos groupes s'affichera ici.",
      name: "Nom",
      nbOfLearners: "Nombre d'apprenants",
      create: "Créer un groupe",
      new: "Nouveau groupe",
      details: "Détails d'un groupe",
      members: "Membres du groupe",
      noSelect: "Cet apprenant est déjà membre d'un autre groupe",
      addToGroup: "Ajouter à un groupe",
      groupDeleteSuccess: "Le groupe a été supprimé avec succès",
      groupDeleteError: "Il y a eu un problème lors de la suppression du groupe",
      newSuccess: "Le groupe a été créé avec succès",
      editSuccess: "Le groupe a été mis à jour avec succès",
      groupCreationEditionError: "Il y a eu un problème lors de la création ou l'édition du groupe",
      modal: {
        title: "Supprimer le(s) groupe(s)",
        message: "Voulez-vous supprimer ce(s) groupe(s) ? Cette action est irréversible.",
        question: "",
        no: "Annuler",
        yes: "Supprimer",
      },
      addToGroupModal: { title: "Ajouter à un groupe", no: "Annuler", yes: "Valider" },
      allGroups: "Tous les apprenants",
      withoutGroups: "Sans groupe",
      selectGroup: "Choisir un groupe",
      DUPLICATE_NAME: "Ce nom est déjà utilisé par un autre groupe",
    },
    licenses: {
      allResources: "Sélectionnez une ressource",
      columns: {
        division: "Division",
        endDate: "Date de fin",
        licenseCount: "Nb. de licences",
        attributionDate: "Date d'affectation",
      },
      allDivisions: "Toutes les divisions",
      allGroups: "Tous les groupes",
      empty: "Vous n'avez pas encore ajouté d'apprenants.",
      licensesSummary:
        "Il reste %{smart_count} licence disponible |||| Il reste %{smart_count} licences disponibles",
      selectALicense: "Sélectionnez une ressource à affecter.",
      assignationSummary:
        "%{newlySelected} licence(s) assignée(s) %{newlyUnselected} licence(s) retirée(s)",
      newlySelectedSummary:
        "%{smart_count} licence assignée |||| %{smart_count} licences assignées",
      newlyUnselectedSummary:
        "%{smart_count} licence retirée |||| %{smart_count} licences retirées",
      explanation:
        "Vérifiez que les utilisateurs ont accès à toutes les licences qui leur sont nécessaires pour leur apprentissage. \n" +
        "Attention ces licences sont disponibles pour l'ensemble de l'univers et pas uniquement pour cette division.",
      seeOnlyNoLicense: "Voir seulement les apprenants sans licence",
      tooltip:
        "Une affectation est définitive si l'inscription a été effectuée il y a plus de 15 jours ou si l'apprenant s'est déjà connecté.",
      modal: {
        title: "Confirmer",
        message:
          "Vous êtes sur le point d'assigner et/ou de retirer des licences pour la ressource sélectionnée.",
        question: "",
        no: "Annuler",
        yes: "Enregistrer",
        confirmModifications:
          "Vous êtes sur le point d'enregistrer les modifications suivantes : %{newlySelected} nouvelle(s) licence(s) assignée(s), %{newlyUnselected} licence(s) retirée(s). Souhaitez-vous continuer ?",
      },
      details: "Détail d'un apprenant",
      generalTab: {
        title: "Général",
        userInfo: "Informations personnelles",
        regenerate: "Regénérer code d’accès",
        displayCode: "Code généré: %{code}",
      },
      licenseTab: { title: "Licences" },
      errors: {
        NO_ACTIVE_LICENSE_PACK: "Pas de licenses trouvées pour cette ressource",
        ALREADY_HAVE_LICENSE:
          "Certains des apprenants sélectionnés ont déjà une licence pour cette ressource",
        NOT_ENOUGH_LICENSES: "Il n'y a pas assez de licences disponibles",
        DO_NOT_HAVE_LICENSE:
          "Certains des apprenants sélectionnés n'ont pas de licence pour cette ressource",
      },
    },
    administrators: {
      error: {
        email: "Veuillez saisir une adresse e-mail valide.",
      },
      CONFLICT: "Cette adresse électronique est déjà utilisée.",
      ALREADY_EXISTS: "Cette adresse électronique est déjà utilisée.",
      all: "Toutes",
      administratorCreationError: "Il y a eu un problème lors de la création.",
      create: "Ajouter un administrateur",
      details: "Détail d’un administrateur",
      division: "Division",
      divisionAdmin: "Administrateur de division",
      divisionDescription: "Gestion des divisions attribuées",
      divisionsNumber: "Nb de divisions administrées",
      email: "Adresse électronique",
      empty: "Pas d'administrateurs",
      identification: "Identification",
      newSuccess: "L'utilisateur a été créé avec succès",
      permission: "Droits d'accès",
      personalInfos: "Informations personnelles",
      placeholder: "Rechercher un administrateur …",
      revoke: "Révoquer tous les droits",
      role: "Rôle",
      sendHelperText:
        "Le compte n’a pas encore été activé. Voulez-vous renvoyer le lien d’activation ?",
      sendLabel: "Renvoyer",
      universeAdmin: "Administrateur d'univers",
      universe: "Univers",
      universeDescription:
        "Gestion de toutes les divisions et des administrateurs, statistiques d'univers",
      modal: {
        message:
          "Voulez-vous vraiment révoquer les droits de cet administrateur ? Vous pourrez le rajouter par la suite.",
        no: "Annuler",
        question: "",
        title: "Révoquer les droits d'accès",
        yes: "Révoquer les droits",
      },
    },
    ariaLabels: { closePanel: "Fermer le panneau d'administration" },
  },
  addLearner: {
    headerTitle: "Ajouter des apprenants",
    title: "Comment voulez-vous ajouter des apprenants ?",
    subtitle:
      "Ajoutez la liste complète des apprenants depuis un fichier existant ou manuellement.",
    import: "Import de fichier",
    importDescription: "Choisissez un fichier Excel (.XSLX) depuis votre ordinateur.",
    manual: "Ajout manuel",
    manualDescription: "Ajoutez vos apprenants un par un grâce à un formulaire.",
  },
  addLearnerManual: {
    headerTitle: "Ajouter des apprenants",
    title: "Ajouter manuellement un apprenant",
    subtitle: "Remplissez le formulaire afin de créer un nouvel apprenant.",
    personalInfo: "Informations personnelles",
    lastName: "Nom",
    firstName: "Prénom",
    obligatory:
      "*Le nom est obligatoire mais peut être remplacé par un pseudonyme si vous souhaitez que l’apprenant reste anonyme. Le prénom est optionnel.",
    connectionType: "Identification",
    email: "Adresse électronique et mot de passe",
    emailDescription: "Les apprenants devront choisir un mot de passe à leur première connexion.",
    code: "Connexion par code d’accès",
    codeDescription:
      "Cochez la case ci-dessus pour que vos apprenants se connectent avec un code d’accès fixe plutôt qu’avec leur adresse mail et un mot de passe.",
    emailLabelSelected: "Adresse électronique",
    saveAndAdd: "Enregistrer et ajouter un autre apprenant",
    saveAndClose: "Enregistrer et terminer",
    validation: {
      lastName: "Veuillez entrer un nom ou un pseudonyme",
      email:
        "Veuillez entrer une adresse électronique valide pour activer la connexion par mot de passe.",
    },
    error: {
      email: "Veuillez saisir une adresse e-mail valide.",
      CONFLICT: "Adresse électronique déjà utilisée.",
      ALREADY_EXISTS:
        "Cette adresse est déjà utilisée, veuillez la changer. Si vous souhaitez modifier un apprenant existant, vous pouvez le faire depuis l'écran 'Apprenants et Licences' dans le panneau d’administration.",
      ERR_BAD_REQUEST: "Erreur de saisie. Veuillez vérifier les données et réessayer.",
      ERR_NETWORK: "Erreur de réseau. Veuillez vérifier votre connexion.",
    },
    success: "L'apprenant a été créé avec succès",
  },
  authenticationMode: {
    headerTitle: "Importer des apprenants",
    title: "Quel mode d’authentification souhaitez-vous ?",
    subtitle: "Ajoutez la liste complète des apprenants depuis un fichier existant.",
    emailLabel: "Connexion par adresse électronique et mot de passe",
    emailDescription:
      "Les apprenants recevront un courriel pour créer leur mot de passe.\n" +
      "Solution à privilégier, recommandée pour une sécurité optimale.",
    codeLabel: "Connexion par code d’accès fixe",
    codeDescription:
      "Il est de votre responsabilité de transmettre ces codes de manière sécurisée. Recommandé si vous n’avez pas accès aux adresses électroniques.",
    next: "Suivant",
  },
  importLearner: {
    headerTitle: "Importer des apprenants",
    title: "Charger le fichier d'import",
    subtitle:
      "Si vous avez déjà un fichier avec la liste de vos apprenants, vous pouvez l’importer directement. Sinon, téléchargez le modèle de fichier et remplissez-le.",
    file: {
      title: "Glisser et déposer ou choisir un fichier.",
      subtitle: "Choisissez un fichier .xlsx depuis votre ordinateur.",
    },
    noFile: {
      title: "Vous n’avez pas de fichier tout prêt ?",
      subtitle: "Téléchargez le modèle et remplissez-le avec les informations de vos apprenants.",
      button: "Télécharger le modèle",
      instruction:
        "Le fichier doit respecter l’ordre et le nom des colonnes du fichier modèle. Le nom est obligatoire mais peut être remplacé par un pseudonyme si vous souhaitez que les apprenants restent anonymes. Le prénom est optionnel.",
    },
    success: "Le fichier est conforme et ne contient pas d'erreur.",
    count_error: "%{smart_count} erreur non résolue |||| %{smart_count} erreurs non résolues",
    error: "Une erreur s’est produite. Veuillez vérifier le format du fichier.",
    moreError: "Plus de 10 erreurs ont été détectées dans le fichier d’import.",
    confirm: "Confirmer l'import",
  },
  confirmImportLearner: {
    title: "Confirmation de l’import des apprenants",
    totalLearners: "%{total} apprenants créés",
    delay:
      "Un délai de plusieurs minutes est possible avant que les comptes des apprenants soient effectivement créés.",
    code: "Distribution des codes",
    codeDescription:
      "Il est de votre responsabilité de transmettre ces codes de manière sécurisée. Vous pouvez télécharger tous les codes pour les imprimer.",
    licence: "Affectation de licences",
    licenceDescription:
      "Afin que vos apprenants puissent accéder à l’application, vous devez leur affecter des licences. Vous pourrez le faire dans un second temps depuis l’écran “Licences” du Panneau d’administration",
    buttonLicence: "Affecter des licences",
    buttonCode: "Télécharger les codes",
    close: "Fermer",
  },
  discover: {
    title: "Découvrir",
  },
  eval: {
    empty: "Créez une évaluation pour les apprenants.",
    emptySubtitle: "Planifiez les différentes sessions d’évaluation.",
    emptyNoEval: "Aucune évaluation disponible",
    emptySubtitleNoEval: "Cette ressource ne comporte pas d'évaluation prédéfinie.",
    emptyLearner: "Il n'y a pas d'apprenant dans cette division.",
    emptySubtitleLearner:
      "Ajoutez des apprenants dans votre division afin de créer des évaluations.",
    emptyScenario: "Les apprenants ne se sont pas encore connectés.",
    emptyScenarioSubtitle:
      "Vous pourrez créer des évaluations lorsque les apprenants auront commencé leur entraînement.",
    emptyNoDivision: "Vous devez créer une division pour accéder à cette fonctionnalité.",
    evalModal: {
      details: "Détails",
      generalTab: {
        calcMethodInfos: {
          example:
            "Score de l'évaluation : 36/60\nNote sur 20 : 12/20\nNote sur 20 bonifiée : 14/20",
          title: "Méthode de calcul : exemple",
          tooltip:
            "L'apprenant est à 8 points de 20/20 et peut donc prétendre à 4 points bonus.\nL'apprenant a 50 % d'avancement dans le parcours d'entraînement et obtient 2 points bonus.",
        },
        calcMethodTitles: {
          bonusdisabled: "Note sur 20",
          bonusenabled: "Note sur 20 bonifiée",
        },
        calculationMethod: "Méthode de calcul",
        calculMethodInfo: "Informations sur la méthode de calcul",
        chooseContent: "Sélectionnez une évaluation",
        content: "Contenu",
        createFirstSession:
          "Créez une première session d'évaluation pour pouvoir ajouter des apprenants.",
        includeNote: "Inclure la note dans le rapport d'apprentissage",
        learners: "%{nbr} / %{total} apprenants",
        mandatorySessions: "Créer une première session d'évaluation est obligatoire.",
        sessions: "Sessions",
        sessionError: "Une évaluation doit contenir au moins une session.",
        sessionTime: "Du %{startDay} - %{startTime} au %{dueDay} - %{dueTime}",
        title: "Général",
      },
      new: "Nouvelle évaluation",
      resultsTab: {
        learner: "Apprenant",
        title: "Résultats",
      },
    },
    create: "Créer une évaluation",
    sessionModal: {
      begin: "Début",
      details: "Détails de la session",
      hour: "Heure",
      namePlaceholder: "Nom…",
      new: "Nouvelle session",
      sessionEnd: "Fin de la session",
      sessionStart: "Début de la session",
      users: "Utilisateurs",
      retry: "Relancer",
      retryTooltip: "Relancer une session d'évaluation pour cet apprenant",
      noRetryTooltip: "Une session est déjà prévue ou en cours",
      retrySessionName: "Session de rattrapage %{date}",
      extraTime: "Tiers temps",
      selectAll: "Tout sélectionner",
      unselectAll: "Tout désélectionner",
    },
    title: "Évaluations",
  },
  fields: {
    dateIsPassed: "La date de fin d'une session ne peut pas être antérieure à aujourd'hui.",
    startDateLimit: "La date de début doit être ultérieure à la date d'aujourd'hui.",
    wrongEndDate: "La date de fin d'une session doit être postérieure à sa date de début.",
    wrongStartDate: "La date de début d'une session doit être antérieure à sa date de fin.",
    invalidDates: "Les dates ou heures sont invalides.",
  },
  license: {
    save: "Enregistrer les modifications",
    selectAll: "Assigner une licence à tous les apprenants (+%{total})",
    unselectAll: "Retirer les licences de tous les apprenants (-%{total})",
    notEnoughTooltip:
      "Il n'y a pas assez de licences disponibles pour en assigner à tous les apprenants de la page.",
    assignAllTooltip:
      "Assigner une licence à tous les apprenants de la page qui n'en ont pas encore",
    unassignAllTooltip:
      "Retirer les licences de tous les apprenants de la page, sauf ceux qui ont une licence définitive.",
    student: {
      title: "Licences apprenants",
    },
    teacher: {
      title: "Licences enseignants",
    },
  },
  licensesSummary: {
    main: "Il reste %{available} licences disponibles.\n\nLicences assignées à de nouveaux apprenants : %{newlySelected}\nLicences retirées : %{newlyUnselected}",
    explanation:
      "Une affectation est définitive si l'inscription a été effectuée il y a plus de 15 jours ou si l'apprenant s'est déjà connecté.",
  },
  menu: {
    class: "Division",
    explore: "Explorer",
    licenses: "Licences",
    logout: "Déconnexion",
    scenario: "Scénario",
    titles: {
      evaluations: "Évaluations",
      oral: "Mon Oral",
      stats: "Statistiques",
      students: "Apprenants",
      rules: "Règles",
    },
    version: "Portail de Suivi v%{version}",
    no_resources: "Aucun apprenant ne s'est encore connecté.",
    legalMentions: "Mentions légales",
    gdprLabel: "RGPD",
    generalConditions: "CGU",
  },
  oral: {
    body: "• Améliorent leurs qualités d'orateur\n\n• Préparent les épreuves orales de fin d'année (collège, lycée, supérieur)\n\n• Renforcent leur aptitude à énoncer un discours argumentaire oral élaboré et structuré\n\n• Apprennent à dérouler un argumentaire oral qui atteint l'effet escompté\n\n• Apprennent à évaluer une intervention orale et à développer une écoute active\n\n• S'entraînent à l'exercice de l'entretien professionnel, du débat, etc.\n",
    button: "Accéder à Mon Oral",
    subtitle: "Avec Mon Oral, vos apprenants :",
    title: "Mon Oral",
  },
  ra: {
    notification: {
      eval_update_err:
        "Vous ne pouvez pas changer la méthode de calcul d'une évaluation contenant des données de progression.",
      eval_delete_err:
        "Vous ne pouvez pas supprimer une évaluation contenant des données de progression.",
      eval_session_err: "Une évaluation doit contenir au moins une session.",
      eval_session_err_2:
        "Au moins un apprenant a déjà passé l'évaluation, la session ne peut pas être supprimée.",
      assign_licenses_err: "Il n'y a plus de licences disponibles.",
      not_enough_licenses_err: "Il n'y a pas assez de licences disponibles pour cette action.",
      learner_update_err: "Il y a eu un souci lors de la mise à jour de l'apprenant.",
    },
    message: {
      loading: "Les données sont en cours de chargement, merci de bien vouloir patienter.",
    },
    saved_queries: {
      label: "Mes filtres",
      query_name: "Nom du filtre",
      new_label: "Enregistrer mon filtre",
      new_dialog_title: "Ajouter le filtre en cours à mes filtres",
      remove_label: "Retirer de mes filtres",
      remove_label_with_name: 'Retirer "%{name}" de mes filtres',
      remove_dialog_title: "Effacer de mes filtres ?",
      remove_message: "Êtes-vous sûr(e) de vouloir supprimer ce filtre de votre liste de filtres ?",
      help: "Filtrez la liste et ajoutez ce filtre à votre liste",
    },
  },
  resources: {
    evaluations: {
      fields: {
        dueDate: "Date de fin",
        evaluatedLearner: "Apprenants ayant passé l'éval",
        startDate: "Date de début",
        totalLearners: "Apprenants concernés",
      },
    },
    licenses: {
      fields: {
        empty: "Aucun apprenant dans cette division",
        emptySubtitle: `La liste de tous vos apprenants apparaîtra ici.`,
        licenses: "Licences",
        license: "Licence",
        assignmentDate: "Date d’affectation de la licence",
      },
    },
    users: {
      fields: {
        stats: {
          group: "Groupe",
          completionPercentage: "Progression",
          initialCompletionPercentage: "Règles déjà sues",
          lastUseDate: "Dernière utilisation",
          trainingDuration: "Temps d'entraînement",
          tooltip: {
            completionPercentage:
              "Il s’agit de l’avancement de l’apprenant dans le parcours." +
              "Cet indicateur indique, à date, la part des règles initialement maîtrisées ainsi que des règles acquises grâce à l’entrainement," +
              " sur l’ensemble des règles proposées dans le parcours. L'indicateur passe d'orange à vert lorsqu'il atteint 100 %.",
            initialCompletionPercentage:
              "Il s’agit de la part des règles initialement maîtrisées par l’apprenant avant l’entraînement sur l’ensemble des règles du parcours." +
              " Cet indicateur se met à jour à mesure que l’apprenant rencontre les règles dans le parcours. Il passe d'orange à vert lorsque toutes les règles ont été rencontrées.",
          },
        },
      },
    },
    rules: {
      fields: {
        title: "Nom",
        moduleTitle: "Module",
        levelTitle: "Niveau",
        initialKnown: "Taux de maîtrise initial",
        eventualKnown: "Taux de maîtrise atteint",
      },
    },
  },
  stats: {
    noData: "Les apprenants ne se sont pas encore entraînés sur cette ressource.",
    noDataDivision:
      "Les apprenants doivent terminer un premier niveau d’entraînement pour afficher leur progression.",
    noDataSubtitle:
      "Vous pourrez ici comparer la progression des apprenants division par division, lorsque les apprenants auront commencé leur entraînement.",
    noDataSubtitleDivision:
      "Vous pourrez ici suivre la progression des apprenants lorsqu’ils auront commencé leur entraînement.",
    create: "Assigner des licences",
    dashboardTab: {
      evaluations: {
        beginning: "Début",
        mean: "Moyenne",
        name: "Titre",
      },
      extraTime: "Tiers temps",
      manageDivision: "Gérer la division",
      initial: "Évaluation initiale",
      intervals: {
        full: "Depuis le début",
        period: "%{nbrOfDays} jours",
      },
      progress: "Progression du niveau moyen",
      seeMore: "Voir plus",
      student: {
        level: "Niveau atteint",
      },
    },
    /**
     * This section may contain some duplicates names which should remain there.
     * */
    exportsTab: {
      common: {
        allModules: "%{scenario} - Tous modules & évaluations",
        autoValidated: "Validée d'office",
        division: "Division",
        divisionsNumber: "Nb de divisions administrées",
        divisionsupervisor: "Administrateur de division",
        email: "E-mail",
        endDate: "Date de fin",
        evalDuration: "Temps évaluation %{evaluation}",
        evalScore: "Score évaluation %{evaluation}",
        firstName: "Prénom",
        gradeOn20: "Note sur 20",
        gradeRatio: "Score",
        id: "Identifiant",
        initialEvaluationDuration: "Temps évaluation initiale",
        initialEvaluationScore: "Score évaluation initiale",
        initialLevel: "Niveau initial",
        inscriptionDate: "Date d'inscription",
        isInitialKnow: "Statut initial",
        known: "Sue",
        lastName: "Nom",
        lastUseDate: "Dernière utilisation",
        level: "Niveau",
        levelReached: "Niveau atteint",
        module: "Module",
        notKnown: "Non sue",
        practiceTest: "%{moduleTitle} - Test blanc",
        ruleTitle: "Libellé règle",
        role: "Fonction",
        scenario: "Parcours",
        specificModule: "%{scenario} - %{module}",
        startDate: "Date de début",
        totalDuration: "Temps total passé",
        trainingDuration: "Durée d'entraînement",
        trainingLevel: "Niveau d'entraînement",
        trainingTime: "Temps d'entraînement",
        universe: "Univers",
        universeadmin: "Administrateur d'univers",
        userName: "Nom d'utilisateur",
        tooltip:
          "Vous pourrez exporter ces données une fois que les apprenants auront commencé leur entraînement.",
      },
      detailedUserStats: {
        subtitle: "Détails des scores et du temps passé pour chaque apprenant",
        title: "Statistiques utilisateurs détaillées",
      },
      initialGaps: {
        subtitle: "Erreurs commises par chaque apprenant avant l'entraînement",
        title: "Lacunes initiales",
      },
      periodOfUse: {
        subtitle: "Heures de connexion des utilisateurs",
        title: "Période d'utilisation",
      },
      report: {
        subtitle: "Bilan des apprentissages d'un utilisateur",
        title: "Rapport d'apprentissage",
      },
      userStats: {
        subtitle: "Scores et temps passé pour chaque apprenant",
        title: "Statistiques utilisateurs",
      },
      adminList: {
        subtitle: "Rôle et coordonnées des administrateurs",
        title: "Liste des administrateurs",
      },
    },
    universeTab: {
      detailedStats: "Statistiques de progression globale",
      division: "Nom de la division",
      nbOfLearners: "Nombre d'apprenants",
      empty: "Pas encore de statistiques pour cette ressource",
      learners: "apprenants",
      averageTraining: "d'entrainement en moyenne",
      averageProgress: "de progression moyenne",
      hours: "%{smart_count} heure |||| %{smart_count} heures",
      percentage: "%{value} %",
      header0: "Division",
      header1: "Apprenants actifs",
      header1Info: "Il s’agit du nombre d’apprenants qui se sont déjà entraînés au moins une fois.",
      header2: "Apprenants qui se sont entraînés au moins 1h",
      header3: "Entraînement moyen pour les apprenants actifs",
      header4: "Progression pour les apprenants actifs",
      preKnownRules: "Règles maîtrisées initialement (moyenne)",
      knownRules: "Règles acquises en entraînement (moyenne)",
      totalRules: "Règles à maîtriser (moyenne)",
      tooltip_preKnown: "%{value} % de règles maîtrisées initialement",
      tooltip_known: "%{value} % de règles acquises en entraînement",
      tooltip_total: "%{value} % de règles restant à maîtriser",
    },
    menu: {
      universe: "Univers",
      division: "Division",
      exports: "Exports",
    },
    title: "Statistiques",
  },
  students: {
    empty: "Les apprenants ne se sont pas encore connectés.",
    search: "Rechercher un apprenant…",
    studentModal: {
      title: "Apprenant",
      download: "Télécharger le rapport d’apprentissage",
      progressTab: {
        noRoute: "L'apprenant n’a pas encore passé son évaluation initiale.",
        globalProgress: "Progression globale",
        levels: "Niveaux",
        levelSubtitle: "%{rules} règles en %{delay}",
        levelSubtitle_noDelay: "%{rules} règles",
        levelSubtitle_notOpen: "",
        pickLevelInfo: "Cliquez sur un niveau pour voir les règles associées.",
        ruleTitle: "Règle %{ruleNbr}",
        title: "Progression",
        trainingTime: "Temps d'entraînement",
        masteredRules: "Règles maîtrisées",
        informationValidation:
          "Les règles présentes dans le niveau de validation dépendent des erreurs effectuées dans les niveaux précédents.",
        validateTitle: "Niveau validé d'office",
        validateSubtitle: "L’apprenant n’a fait aucune erreur dans les niveaux précédents.",
      },
      resultsTab: {
        eval: "Évaluation",
        reset: "Réinitialiser l’évaluation initiale",
        title: "Résultats",
        modal: {
          yes: "Réinitialiser",
          no: "Annuler",
          title: "Réinitialisation",
          message:
            "Attention, la réinitialisation de l'évaluation initiale implique une réinitialisation de l'apprentissage de votre apprenant. Cette action est définitive.",
          question:
            "Voulez-vous réinitialiser l’évaluation initiale ainsi que l’apprentissage de l’apprenant ?",
        },
      },
      statsTab: {
        difficultRule: "Règles ayant posé le plus de difficultés",
        initialLevel: "Niveau initial",
        mean: "Moyenne pour la division",
        reachedLevel: "Niveau atteint",
        title: "Statistiques",
        training: "Entraînement",
        value: "Valeur pour l’apprenant",
        connectionDetail: "Détail des connexions",
        allConnections: "Exporter toutes les sessions",
        precisePeriod: "Exporter un intervalle précis",
      },
      connectionExportPdf: {
        title: "Détail des connexions",
        startDate: "Date de début",
        endDate: "Date de fin",
        duration: "Durée",
        exportPeriod: "PÉRIODE D'EXPORT",
        lastName: "NOM",
        firstName: "PRÉNOM",
        from: "DU",
        to: "AU",
        total: "TOTAL",
        button: "Télécharger le détail des connexions",
        pdfName: "Détail des connexions - %{fullName} - %{domainName}",
      },
      extraTime: {
        extraTime: "Tiers temps",
        setExtraTime:
          "Octroyer un tiers temps à un apprenant lui donnera du temps supplémentaire pour les évaluations. Cette action n'aura pas d'impact sur les évaluations déjà passées. Voulez-vous continuer ?",
        unsetExtraTime:
          "Êtes-vous certain de vouloir enlever le tiers temps à cet apprenant ?" +
          "\nCette action n'aura pas d'impact sur les évaluations déjà passées.",
      },
    },
    title: "Apprenants",
  },
  rules: {
    empty: "Pas de règles",
    search: "Rechercher une règle…",
    selectLevel: "Choisir un niveau",
    description:
      "Ce tableau récapitule l’ensemble des règles abordées dans le parcours d’apprentissage. Il vous permet notamment de déterminer quelles règles ont été les plus difficiles à acquérir par vos apprenants et méritent une attention particulière. Grâce au mode présentiel, vous pouvez concevoir un exercice de révision personnalisé à proposer en atelier ou en classe. Pour l’activer et le lancer, sélectionnez les règles sur lesquelles vous souhaitez interroger vos apprenants.",
    title: "Règles",
    bulkButton: "Lancer le mode présentiel",
    allLevels: "%{title} - tous les niveaux",
    tooltip: {
      eventualKnown:
        "Il s’agit de la part des apprenants qui maîtrisaient la règle avant l’entraînement ainsi que ceux qui l’ont acquise grâce à l’entraînement.\n" +
        "Exemple : Si une règle affiche « 15/20 » cela signifie que 15 apprenants maîtrisaient déjà ou ont acquis la règle et que 20 apprenants au total l’ont rencontrée. L’indicateur devient vert lorsque tous les apprenants ont acquis la règle.",
      initialKnown:
        "Il s’agit de la part des apprenants n'ayant fait aucune erreur sur la règle, lors de sa première apparition au cours de l'entraînement.\n" +
        "Exemple : Si une règle affiche « 5/20 » cela signifie que 5 apprenants n’ont fait aucune erreur sur cette règle et que 20 apprenants au total l’ont rencontrée. L’indicateur devient vert lorsque tous les apprenants ont rencontré la règle.",
    },
  },
}
