import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import type { DivisionProgressionForScenarioRule } from "@newpv/js-common"
import _ from "lodash"
import type { FC } from "react"
import { useCallback } from "react"
import { Button, useListContext, useTranslate } from "react-admin"

import { apprentissageUrl } from "../../../features/Constants"

const PresentialBulkActionButton: FC = () => {
  const t = useTranslate()
  const { selectedIds, data } = useListContext<DivisionProgressionForScenarioRule>()

  const onClick = useCallback(() => {
    const selectedIdSet = new Set(selectedIds)
    const rulesByLevels = _(data)
      .filter(dv => selectedIdSet.has(dv.id))
      .groupBy(d => d.levelId)
      .mapValues(dv => dv.map(v => v.ruleId))
    window.open(
      `${apprentissageUrl}/presential?rules=${encodeURIComponent(JSON.stringify(rulesByLevels))}`,
      "_blank",
    )
  }, [data, selectedIds])

  return <Button label={t("rules.bulkButton")} startIcon={<PlayArrowIcon />} {...{ onClick }} />
}

export default PresentialBulkActionButton
