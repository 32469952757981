import type { MinimalUserData } from "@newpv/js-common"
import type { UseGetListHookValue } from "react-admin"
import { useGetList } from "react-admin"

import { useDivisionId } from "./storage"

/** Fetches all students from the current division, and returns the query result - No pagination */
const useStudentsFromDivision = (): UseGetListHookValue<MinimalUserData> => {
  const [divisionId] = useDivisionId()

  return useGetList<MinimalUserData>(`universe/division/${divisionId}/learner`, {
    meta: {
      all: true,
    },
  })
}

export default useStudentsFromDivision
