import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material"
import type { CSSProperties, FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useStyles } from "utils"

interface Props {
  dialogStyle?: CSSProperties
  namespace: string
  onClose: () => void
  onConfirm: () => Promise<void> | void
  open: boolean
  overrideMessage?: string
}

export const ConfirmDialog: FC<Props> = ({
  namespace: ns,
  onClose,
  onConfirm,
  open,
  overrideMessage,
  dialogStyle,
}) => {
  /**
   * Contexts section
   * */
  const t = useTranslate()

  const s = useStyles(
    ({ spacing, palette: { secondary } }) => ({
      button: {
        color: secondary.main,
      },
      dialog: {
        marginBottom: spacing(2),
        minHeight: "182px",
        overflowY: "hidden",
        padding: 0,
        paddingBottom: spacing(2.5),
        paddingTop: spacing(2.5),
      },
      dialogDiv: {
        ...dialogStyle,
        borderRadius: "16px",
        maxHeight: "340px",
        padding: spacing(2),
        width: "350px",
      },
      question: {
        fontWeight: "Bold",
      },
      rowDescription: {
        marginBottom: spacing(2),
        marginTop: spacing(2),
      },
      title: {
        alignItems: "center",
      },
    }),
    [dialogStyle],
  )

  return (
    <Dialog
      {...{ open, onClose }}
      // Cannot use the "sx" or "style" prop, as the PaperStyle override this style
      PaperProps={{ style: s.dialogDiv }}
    >
      <div style={s.title}>
        <Typography variant="h6">{t(`${ns}.modal.title`)}</Typography>
      </div>
      <DialogContent style={s.dialog}>
        <div style={s.rowDescription}>
          <Typography variant="subtitle1">{overrideMessage ?? t(`${ns}.modal.message`)}</Typography>
        </div>
        <div>
          <Typography style={s.question} variant="subtitle1">
            {t(`${ns}.modal.question`)}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t(`${ns}.modal.no`)}</Button>
        <Button style={s.button} onClick={onConfirm}>
          {t(`${ns}.modal.yes`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
