import { Checkbox, FormControl, TextField, Typography } from "@mui/material"
import _ from "lodash"
import type { Dispatch, ReactElement, SetStateAction } from "react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import type { InputProps } from "react-admin"
import { useInput, useTranslate } from "react-admin"
import { BUTTON_DIV_HEIGHT } from "screens/EvalScreen/EvalDetailsScreen"
import { useCommonStyles, useStyles } from "utils"

import { sessionNs } from "../../../features/i18n"

interface Props<T extends { id: string }> {
  source: string
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
  filteredOptions?: T[]
  disableGlobally?: boolean
  disableBulkSelect?: boolean
  disableOption?: (elem: T) => boolean
  renderListItem: (elem: T, disabled: boolean) => ReactElement
}

export const SearchableCheckboxList = <T extends { id: string }>({
  searchText,
  setSearchText,
  filteredOptions = [],
  disableGlobally,
  disableOption,
  disableBulkSelect,
  renderListItem,
  ...inputProps
}: Props<T> & InputProps): ReactElement => {
  const { field, fieldState } = useInput(inputProps)
  const t = useTranslate()

  const [hasDoneInit, setHasDoneInit] = useState(false)

  const [initiallySelectedOptionIds, setInitiallySelectedOptionIds] = useState<string[]>([])

  const selectedOptions: string[] = useMemo(
    () =>
      _.map(field.value ?? [], (elem: T | string) => (_.isObject(elem) ? elem?.id : elem)).sort(),
    [field.value],
  )

  // == allSelectableIds if no filter
  const filteredSelectableIds = useMemo(
    () =>
      filteredOptions
        .filter(
          option => !disableOption?.(option) || initiallySelectedOptionIds?.includes(option.id),
        )
        .map(elem => elem.id)
        .sort(),
    [filteredOptions, disableOption, initiallySelectedOptionIds],
  )

  const isBulkCheckboxChecked = useMemo(
    () =>
      _.isEmpty(_.difference(filteredSelectableIds, selectedOptions)) &&
      !_.isEmpty(filteredSelectableIds),
    [filteredSelectableIds, selectedOptions],
  )

  useEffect(() => {
    if (!hasDoneInit) {
      setInitiallySelectedOptionIds(selectedOptions)
      field.onChange(selectedOptions)
      setHasDoneInit(true)
    }
  }, [field, selectedOptions, hasDoneInit])

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing, palette }) => ({
      topDiv: {
        alignItems: "center",
        borderBottom: "1px solid black",
        borderBottomColor: palette.divider,
        display: "flex",
        flexDirection: "row",
        margin: spacing(0, 1),
      },
      formControl: {
        flex: 1,
        minHeight: 0,
        overflow: "clip",
        width: "100%",
      },
      formInnerDiv: {
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
      },
      list: {
        display: "flex",
        flexDirection: "column",
        overflowX: "clip",
        overflowY: "auto",
        paddingBottom: BUTTON_DIV_HEIGHT,
      },
      listItem: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        paddingLeft: spacing(1),
      },
      textDiv: {
        flex: 1,
        paddingRight: spacing(2),
      },
    }),
    [],
  )

  const onCheckboxValueChange = useCallback(
    (val: React.ChangeEvent<HTMLInputElement>) => {
      const previouslySelectedOptions = selectedOptions

      // difference(ArrayA, ArrayB) is "ArrayA-ArrayB"
      const selectedOptionsAfterBulkUnselect = _.difference(
        previouslySelectedOptions,
        filteredOptions
          .filter(
            option => !disableOption?.(option) || !initiallySelectedOptionIds?.includes(option.id),
          )
          .map(elem => elem.id),
      ).sort()

      const selectedOptionsAfterBulkSelect = _.union(
        previouslySelectedOptions,
        filteredSelectableIds,
      ).sort()

      field.onChange(
        val.target.checked ? selectedOptionsAfterBulkSelect : selectedOptionsAfterBulkUnselect,
      )
    },
    [
      disableOption,
      field,
      filteredOptions,
      filteredSelectableIds,
      initiallySelectedOptionIds,
      selectedOptions,
    ],
  )

  return (
    <FormControl style={s.formControl}>
      {/* @ts-ignore error.message.message exists */}
      {fieldState.error?.message?.message ? (
        <Typography variant="caption" style={{ marginLeft: 16, color: "red" }}>
          {/* @ts-ignore error.message.message exists */}
          {fieldState.error?.message?.message}
        </Typography>
      ) : null}
      <div style={s.formInnerDiv}>
        <TextField
          sx={cs.marginHorizontal16}
          value={searchText}
          variant="outlined"
          // TODO: here, maybe change the placeholder?
          placeholder={t(`${sessionNs}.namePlaceholder`)}
          InputProps={{ style: cs.textFieldHeight }}
          onChange={e => {
            setSearchText(e.target.value)
          }}
        />
        {disableBulkSelect ? null : (
          <div style={s.topDiv}>
            <Checkbox
              checked={isBulkCheckboxChecked}
              onChange={onCheckboxValueChange}
              disabled={disableGlobally}
            />
            <div style={s.textDiv}>
              <Typography variant="button">
                {isBulkCheckboxChecked
                  ? t(`${sessionNs}.unselectAll`)
                  : t(`${sessionNs}.selectAll`)}
              </Typography>
            </div>
          </div>
        )}

        <div style={s.list}>
          {filteredOptions?.map((elem, idx) => {
            const isOptionSelected = selectedOptions?.includes(elem.id)

            const disabled = disableGlobally || (disableOption?.(elem) ?? false)

            return (
              <div
                key={`${elem.id}-${idx}`}
                style={s.listItem}
                onClick={
                  disabled
                    ? undefined
                    : () => {
                        field.onChange(
                          (isOptionSelected
                            ? _.without(selectedOptions, elem.id)
                            : [...selectedOptions, elem.id]
                          ).sort(),
                        )
                      }
                }
              >
                <Checkbox disabled={disabled} checked={isOptionSelected} name={elem.id} />
                {renderListItem(elem, disabled)}
              </div>
            )
          })}
        </div>
      </div>
    </FormControl>
  )
}
