import _ from "lodash"
import polyglotI18nProvider from "ra-i18n-polyglot"
import englishMessages from "ra-language-english"
import frenchMessages from "ra-language-french"

import { fr } from "../i18n"

const messages = {
  fr: _.merge({}, frenchMessages, fr),
  en: englishMessages,
}

export const i18nProvider = polyglotI18nProvider(
  locale => ({
    ...messages[locale],
  }),
  "fr",
)
