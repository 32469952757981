import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
} from "@mui/material"
import type { LevelRevision, LevelStatic, ModuleProgression } from "@newpv/js-common"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useCommonStyles, useStyles } from "utils"

import LevelGraph from "./LevelGraph"
import LevelRulesList from "./LevelRulesList"

interface Props {
  moduleTitle?: string
  onClose: () => void
  open: boolean
  progress?: ModuleProgression
  selectedFormattedLevel: (LevelStatic & { concernedLevels?: LevelStatic[] }) | LevelRevision
}

export const RulesDialog: FC<Props> = ({
  moduleTitle,
  onClose,
  open,
  progress,
  selectedFormattedLevel,
}) => {
  /**
   * Contexts section
   * */
  const t = useTranslate()

  /**
   * Hooks section
   * */

  const isSmallWidth = useMediaQuery(theme => (theme as any).breakpoints.down("lg"))
  /**
   * Styling section
   * */
  const cs = useCommonStyles()
  const s = useStyles(
    ({ palette: { background }, spacing }) => ({
      dialog: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: isSmallWidth ? "column" : "row",
        height: "511px",
        maxHeight: `calc(100vh - ${spacing(16)})`,
        padding: 0,
        paddingTop: spacing(1),
        maxWidth: 880,
      },
      title: {
        alignItems: "center",
        backgroundColor: background.secondary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: spacing(2),
      },
    }),
    [isSmallWidth],
  )

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
      }}
      maxWidth={false}
      // Cannot use the "sx" or "style" prop, as the PaperStyle override this style
      PaperProps={{ style: { ...cs.borderRadius } }}
    >
      <div style={s.title}>
        {!selectedFormattedLevel?.title ? null : (
          <>
            <Typography variant="h6">{selectedFormattedLevel?.title}</Typography>
            <Typography variant="subtitle1">{moduleTitle}</Typography>
          </>
        )}
      </div>
      <DialogContent style={s.dialog}>
        <LevelGraph {...{ progress, selectedFormattedLevel }} />
        <LevelRulesList {...{ selectedFormattedLevel }} progress={progress?.levels} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("ra.action.close")}</Button>
      </DialogActions>
    </Dialog>
  )
}
