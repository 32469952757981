import CloseIcon from "@mui/icons-material/Close"
import { IconButton, Typography } from "@mui/material"
import { type UniverseId } from "@newpv/js-common"
import type { AppBarProps } from "ra-ui-materialui/dist/cjs/layout/AppBar"
import type { Dispatch, FC, SetStateAction } from "react"
import React from "react"
import { AppBar as RAAppBar, Link, useGetOne, useTranslate } from "react-admin"
import { useCommonStyles, useStyles, useUniverseId } from "utils"

export const AdminAppBar: FC = (
  props: AppBarProps & { setMenuOpen: Dispatch<SetStateAction<boolean>> },
) => {
  const t = useTranslate()
  const [universeId] = useUniverseId()

  const { data: currentUniverse } = useGetOne<{ id: UniverseId; name: string }>(
    `universe/universe`,
    {
      id: universeId,
      meta: { idField: "universeId" },
    },
  )

  const cs = useCommonStyles()
  const s = useStyles(({ palette: { text }, spacing }) => ({
    appBar: {
      "& .RaAppBar-toolbar": {
        margin: spacing(2),
        padding: 0,
      },
      "& .RaAppBar-root": {
        boxShadow: "none",
        transform: "none",
        visible: "visible",
      },
      "& .RaAppBar-menuButton": {
        display: "none",
      },
      color: text.primary,
      width: "100%",
    },
    iconWrap: {
      margin: 0,
      marginRight: spacing(2),
      padding: 0,
    },
    universeName: {
      color: text.secondary,
      overflow: "hidden",
    },
    titleContainer: {
      alignItems: "flex-start",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
      maxHeight: "100%",
      maxWidth: "100%",
    },
  }))

  return (
    <RAAppBar sx={s.appBar} {...props}>
      <Link to="..">
        <IconButton style={s.iconWrap} aria-label={t("adminPanel.ariaLabels.closePanel")}>
          <CloseIcon style={cs.secondaryTextColor} />
        </IconButton>
      </Link>
      <div style={s.titleContainer}>
        <Typography variant="h6">{t("adminPanel.title")}</Typography>
        {currentUniverse ? (
          <Typography
            style={s.universeName}
            variant="subtitle1"
          >{`${currentUniverse.name} (${currentUniverse?.id})`}</Typography>
        ) : null}
      </div>
    </RAAppBar>
  )
}
