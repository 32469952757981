import { Tab, Tabs, Typography } from "@mui/material"
import { AsideView } from "components"
import type { FC } from "react"
import { useCallback, useMemo } from "react"
import React, { useEffect, useState } from "react"
import { useRefresh, useShowController, useTranslate } from "react-admin"
import { useNavigate } from "react-router-dom"
import { useCommonStyles, useStyles, useUniverseId } from "utils"

import type { UserWithLicenses } from "./AdminLicensesScreen"
import { GeneralTab } from "./GeneralTab"
import { LicenseTab } from "./LicenseTab"

interface Props {
  isCreation?: boolean
}

const licenseNs = "adminPanel.licenses"

export const LicenseUserDetailsScreen: FC<Props> = () => {
  /**
   * Contexts
   * */
  const t = useTranslate()
  const refresh = useRefresh()
  const navigate = useNavigate()
  const [universeId] = useUniverseId()

  /**
   * States
   * */
  const [tabIndex, setTabIndex] = useState(0)
  const [open, setOpen] = useState(false)

  const tabs = useMemo(
    () => [t(`${licenseNs}.generalTab.title`), t(`${licenseNs}.licenseTab.title`)],
    [t],
  )

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: number): void => {
    setTabIndex(newValue)
  }, [])

  const onClose = (): void => {
    setOpen(false)
    setTimeout(() => {
      navigate("..", { replace: true })
      refresh()
    }, 300)
  }

  /**
   * The following hook is necessary to trigger the opening animation
   * */
  useEffect((): void => {
    setOpen(true)
  }, [])

  const cs = useCommonStyles()
  const s = useStyles(({ spacing }) => ({
    student: {
      overflow: "hidden",
      padding: spacing(2, 2),
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }))

  const { record: learnerData, refetch: refetchLearnerData } = useShowController<UserWithLicenses>({
    resource: `universe/${universeId}/licenses/learners`,
    queryOptions: {
      meta: { idField: "userId" },
    },
  })

  return (
    <>
      <AsideView {...{ open, onClose }} title={t(`${licenseNs}.details`)}>
        <div style={cs.mainDiv}>
          <Typography style={s.student} variant="body1">{`${learnerData?.lastName} ${
            learnerData?.firstName ?? ""
          }`}</Typography>
          <Tabs
            value={tabIndex}
            variant="fullWidth"
            textColor="inherit"
            onChange={handleChange}
            style={cs.marginBottom16}
          >
            {tabs.map((elem, index) => (
              <Tab
                key={index}
                label={
                  <Typography style={cs.screenTitle} variant="subtitle1">
                    {elem}
                  </Typography>
                }
                id={`tab-licenseDetail-${index}`}
                aria-controls={`licenseDetail-panel-${index}`}
              />
            ))}
          </Tabs>
          {tabIndex === 0 ? (
            <GeneralTab
              {...{
                learnerData,
                refetchLearnerData,
              }}
            />
          ) : (
            <LicenseTab
              {...{
                learnerData,
              }}
            />
          )}
        </div>
      </AsideView>
    </>
  )
}
