import { Button, Typography } from "@mui/material"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useStyles, useToken } from "utils"

import { bffUrl } from "../../../features/Constants"

const MonOral: FC = () => {
  const t = useTranslate()
  const [token] = useToken()

  const launchApp = (): void => {
    window.open(
      `${bffUrl}/mon-oral?token=${encodeURIComponent(token)}&connector=mon-oral-suivi`,
      "_blank",
    )
  }

  const s = useStyles(({ spacing, palette: { background, primary, text } }) => ({
    title: {
      fontWeight: "bold",
      margin: spacing(3, 0, 6, 0),
      width: "100%",
    },
    container: {
      alignItems: "center",
      background: background.paper,
      borderRadius: "16px",
      display: "flex",
      flexDirection: "column",
      maxWidth: "700px",
      padding: spacing(4),
    },
    buttonSx: {
      backgroundColor: primary[400],
      "&:hover": {
        backgroundColor: primary.dark,
        color: primary.light,
      },
    },
    button: {
      alignItems: "center",
      borderRadius: "8px",
      color: text.primary,
      margin: spacing(4, 0),
      padding: spacing(2),
      textColor: text.primary,
    },
  }))

  return (
    <div style={s.container}>
      <Typography style={s.title} variant="body1">
        {t("oral.subtitle")}
      </Typography>
      <Typography style={{ whiteSpace: "pre-wrap" }} variant="body2">
        {t("oral.body")}
      </Typography>
      <Button style={s.button} sx={s.buttonSx} onClick={launchApp}>
        {t("oral.button")}
      </Button>
    </div>
  )
}

export default MonOral
