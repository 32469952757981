import type { Step } from "@newpv/js-common"
import jsonExport from "jsonexport/dist"
import { downloadCSV } from "react-admin"
/**
 * Generate the report PDF for a division/scenario combination
 *
 * @param fileName
 * @param blob The BLOB of the generated source file from the API
 */
export const generateReportPdf = (fileName: string, blob: Blob): void => {
  /**
   * Create a link pointing to the ObjectURL containing the blob.
   */
  const data = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = data
  link.download = `${fileName}.pdf`
  link.click()
  setTimeout(() => {
    /**
     * For Firefox, it is necessary to delay revoking the ObjectURL
     */
    window.URL.revokeObjectURL(data)
  }, 100)
}

/**
 * The BOM allow a csv to understand UTF-8 characters
 */
export const BOM = "\uFEFF"
export const ROW_DELIMITER = ";"

/**
 * CSV exporter
 *
 * @param fileName
 * @param data The file lines
 * @param headers
 * @param rename The header translated names
 */
export const exporter = (
  fileName: string,
  data: any[],
  headers: string[],
  rename: string[],
): void => {
  jsonExport(
    data,
    {
      rename,
      headers,
      rowDelimiter: ROW_DELIMITER,
    },
    (err, csv) => {
      downloadCSV(`${BOM} ${csv}`, fileName)
    },
  )
}

export const HEADERS = {
  adminList: ["universe", "division", "id", "lastName", "firstName", "email", "role"],
  initialGaps: ["division", "lastName", "firstName", "userName", "ruleTitle", "isInitialKnow"],
  periodOfUse: ["division", "userName", "lastName", "firstName", "startDate", "endDate"],
  detailedUserStats: [
    "universe",
    "division",
    "lastName",
    "firstName",
    "userName",
    "level",
    "lastUseDate",
    "totalDuration",
    "levelReached",
    "gradeRatio",
    "gradeOn20",
    "scenario",
  ],
  userStats: [
    "universe",
    "division",
    "lastName",
    "firstName",
    "userName",
    "inscriptionDate",
    "module",
    "lastUseDate",
    "totalDuration",
    "initialEvaluationScore",
    "initialEvaluationDuration",
    "initialLevel",
    "trainingDuration",
    "levelReached",
  ],
} as const

const DataKeys = Object.keys(HEADERS) as Array<keyof typeof HEADERS>

/**
 * Convert HEADERS Keys defined above to a type of data
 */
export type DataType = (typeof DataKeys)[number]

/**
 * Verify the type of data passed. Returns true if verified, false otherwise.
 *
 * @param x The common string
 * @param to The desired type, which must be a DataType
 * @returns boolean
 */
export const isOfDataType = <Target extends DataType>(
  x: string | undefined,
  to: Target,
): x is Target => DataKeys.includes(to)

/**
 * Get the closest possible value for the user training before the targeted date
 *
 * @param steps The complete user steps
 * @param date The targeted date
 */
export const getClosest = (steps: Step[], date: number): Step =>
  steps.reduce((prev, curr) =>
    Math.abs(curr.date - date) < Math.abs(prev.date - date) && date > curr.date ? curr : prev,
  )
