import { Grid, Typography } from "@mui/material"
import type { FC } from "react"
import React from "react"
import { required, TextInput, useTranslate } from "react-admin"
import { useCommonStyles, useStyles } from "utils"

import { ns } from "./constants"

const PersonalInformationGroup: FC = () => {
  const t = useTranslate()

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing, typography: { subtitle1 } }) => ({
      helperText: {
        paddingBottom: spacing(2),
        paddingTop: spacing(1),
      },
      muiInput: {
        "& .MuiFormLabel-root": cs.muiFormLabel12,
        "& .MuiInputBase-root": subtitle1,
      },
    }),
    [cs.muiFormLabel12],
  )

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2">{t(`${ns}.personalInfo`)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          helperText={false}
          label={t(`${ns}.lastName`)}
          name="lastName"
          size="medium"
          source="lastName"
          style={cs.fullFlex}
          sx={s.muiInput}
          validate={required(`${ns}.validation.lastName`)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          helperText={false}
          label={t(`${ns}.firstName`)}
          name="firstName"
          size="medium"
          source="firstName"
          style={cs.fullFlex}
          sx={s.muiInput}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography style={s.helperText} variant="body2">
          {t(`${ns}.obligatory`)}
        </Typography>
      </Grid>
    </>
  )
}

export default PersonalInformationGroup
