import type { AxiosError } from "axios"
import { useLogout, useNotify } from "react-admin"
import type { QueryKey, UseQueryOptions, UseQueryResult } from "react-query"
import { useQuery } from "react-query"

/**
 * Handle server request, with a notification display system
 *
 * @param request Promisified function which will be handled by the hook
 * @param queryKey React Admin cache key for the query (in the form
 * [collection, variable1, variableValue1, v2, vV2, …])
 *
 * @example
 * const { data } = useQueryRequest(
 *     async () => {
 *       const dataToFetch = await axios.get<any, AxiosResponse<{ data: DataType[] }>>(
 *         `${URL}/route/${elem.id}`,
 *       )
 *       return dataToFetch.data
 *     },
 *     ["route", "elem", elem.id],
 *   )
 * */
export function useQueryRequest<T>(
  request: (...args: any) => Promise<any>,
  queryKey: unknown[] = [],
  options?: Omit<UseQueryOptions<T, unknown, T, QueryKey>, "queryKey" | "queryFn">,
  hideNotification?: boolean,
): UseQueryResult<T, unknown> {
  const notify = useNotify()
  const logout = useLogout()

  return useQuery<T>(queryKey, request, {
    onError: (err: AxiosError) => {
      if (!hideNotification) {
        notify("ra.notification.http_error", { type: "error" })
      }
      if (err.response?.status === 401) {
        // noinspection JSIgnoredPromiseFromCall
        logout()
      }
    },
    ...options,
  })
}
