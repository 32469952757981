import { Grid, Typography, useTheme } from "@mui/material"
import type {
  BOEvaluation,
  Division,
  DivisionProgression,
  EvaluationId,
  InitialEvaluationSummary,
  Scenario,
} from "@newpv/js-common"
import { fetchOneScenario, isNotNullish } from "@newpv/js-common"
import { axios } from "@newpv/js-common"
import type { AxiosResponse } from "axios"
import type { ChartData } from "components"
import { Card, Chart, MuiSelect } from "components"
import EmptyScreen from "components/EmptyScreen"
import type { FC } from "react"
import React, { useEffect, useMemo, useState } from "react"
import { useGetList, useGetOne, useTranslate } from "react-admin"
import {
  getAuth,
  useCommonStyles,
  useDivisionId,
  useQueryRequest,
  useScenarioId,
  useStyles,
  useToken,
  useUniverseId,
} from "utils"

import { bffUrl } from "../../features/Constants"
import { commonNs } from "../../features/i18n/fr"
import { APP_BAR_HEIGHT, APP_TOP_BAR_HEIGHT } from "../../features/Theme/theme"
import { ElementStats } from "./components/ElementStats"
import { SubStatsHeader } from "./components/SubStatsHeader"

export type Period = "0" | "7" | "15" | "30" | "90"

const PERIODS = ["0", "7", "15", "30", "90"]

export const dashboardNs = "stats.dashboardTab"

export interface StatsTabProps {
  isScenarioEmpty?: boolean
  selectedTab: "universe" | "division" | "exports"
}

export const Dashboard: FC<StatsTabProps> = ({ selectedTab }) => {
  /**
   * Hooks section
   * */
  const t = useTranslate()
  const [interval, setInterval] = useState<Period>("30")
  const [universeId] = useUniverseId()
  const [divisionId] = useDivisionId()
  const [scenarioId] = useScenarioId()
  const [token] = useToken()
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme()

  const { data: evalList, isLoading: evalLoading } = useGetList<BOEvaluation>(
    `evaluation/universe/${universeId}/evaluation`,
    {
      meta: {
        idField: "evaluationId",
        all: true,
      },
      filter: { divisionId, scenarioId },
    },
  )

  const { data: initialEvaluationStats, isLoading: isInitialEvaluationLoading } = useGetList<
    InitialEvaluationSummary & { id: EvaluationId }
  >(
    `stats/division/${divisionId}/initialEvaluation`,
    {
      meta: {
        idField: "evaluationId",
      },
      filter: { scenarioId },
    },
    { enabled: !!divisionId && !!scenarioId },
  )

  /**
   * Data related functions
   * */
  // TODO: why not global scenarioId?
  const [scenario, setScenario] = useState<Scenario>()

  useEffect(() => {
    if (!scenarioId) {
      return
    }
    fetchOneScenario(undefined, scenarioId).then(setScenario)
  }, [scenarioId])

  const { data: classElem } = useGetOne<Division>(
    `universe/division`,
    {
      id: divisionId ?? "",
      meta: { idField: "divisionId" },
    },
    { enabled: !!divisionId },
  )
  const [graphData, setGraphData] = useState<ChartData[]>()

  const {
    data: divisionStats,
    isLoading,
    isFetching,
    isRefetching,
  } = useQueryRequest<DivisionProgression>(
    async () => {
      if (!divisionId || !scenarioId) {
        return
      }
      const rawDivisionProgress = await axios.get<any, AxiosResponse<DivisionProgression>>(
        `${bffUrl}/stats/division/${divisionId}?scenarioId=${scenarioId}`,
        getAuth(token),
      )

      return rawDivisionProgress.data
    },
    ["divisionStats", "scenarioId", scenarioId, "divisionId", divisionId],
    { enabled: !!divisionId && !!scenarioId },
  )

  useEffect(() => {
    setGraphData(old => old?.map(elem => ({ ...elem })))
    setTimeout(() => {
      setGraphData(
        divisionStats?.graph?.map(({ date, percentage }, index) => ({
          date,
          percentage,
          id: `step-${index}`,
        })) ?? [],
      )
    }, 10)
  }, [divisionStats])

  const loading =
    isLoading || evalLoading || isFetching || isRefetching || isInitialEvaluationLoading

  const ChartComp = useMemo(
    () => (
      <Chart width={880} data={graphData} height={300} nbrOfDays={interval} isLoading={loading} />
    ),
    [graphData, interval, loading],
  )

  /**
   * Stats sections to avoid JSX repetitions
   * */
  const sections = [
    {
      title: t(`${commonNs}.students`),
      more: "/apprenants",
      data: divisionStats?.divisionUsersProgress,
      statsTitles: {
        first: t(`${commonNs}.name`),
        second: t(`${commonNs}.firstName`),
        third: t(`${dashboardNs}.student.level`),
      },
    },
    {
      title: t(`${commonNs}.evaluations`),
      more: "/evaluations",
      data: [...(evalList ?? []), ...(initialEvaluationStats ?? [])].filter(e => isNotNullish(e)),
      statsTitles: {
        first: t(`${dashboardNs}.evaluations.name`),
        second: t(`${dashboardNs}.evaluations.beginning`),
        third: t(`${dashboardNs}.evaluations.mean`),
      },
    },
  ]

  /**
   * Styling
   * */
  const cs = useCommonStyles()
  const s = useStyles(
    ({
      spacing,
      palette: {
        background,
        text: { primary },
        divider,
      },
    }) => ({
      button: {
        color: secondary,
        margin: spacing(0, 1),
        borderColor: divider,
      },
      text: {
        color: primary,
      },
      scenarioName: {
        margin: spacing(2),
      },
      statsCardLeft: { marginLeft: spacing(1) },
      statsCardSx: {
        "& .MuiCardContent-root": {
          display: "flex",
          flexDirection: "column",
          padding: 0,
        },
      },
      subtitle: { paddingTop: spacing(2), marginBottom: spacing(2) },
      listDiv: {
        backgroundColor: background.secondary,
        ...(selectedTab === "division" ? {} : { display: "none" }),
        flexDirection: "column",
        height: `calc(100vh - ${APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT}px - ${spacing(6)})`,
        maxHeight: `calc(100vh - ${APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT}px - ${spacing(6)})`,
        width: "100%",
      },
      list: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        maxHeight: "100%",
        overflowY: "scroll",
      },
      statsMainDiv: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flex: 1,
        height: "380px",
        maxHeight: "380px",
      },
      grid: {
        alignItems: "center",
        alignSelf: "center",
        display: "flex",
        justifyContent: "space-around",
        maxWidth: "888px",
        paddingBottom: spacing(2),
      },
      titleCard: {
        display: "flex",
        height: "100px",
        justifyContent: "center",
        margin: spacing(2, 1, 0, 1),
        padding: spacing(2),
        width: "100%",
      },
      titleCardContent: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: spacing(1),
        gap: spacing(1),
      },
      chartCard: {
        display: "flex",
        minHeight: "350px",
        justifyContent: "center",
        margin: spacing(2, 1),
        width: "100%",
      },
      chartDiv: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },
      statsCard: {
        display: "flex",
        height: "100%",
        maxHeight: "380px",
        justifyContent: "center",
        margin: spacing(0, 1, 0, 1),
        width: "100%",
      },
    }),
    [secondary, selectedTab],
  )

  return sections[0]?.data || sections[1]?.data ? (
    <div style={s.listDiv}>
      <div style={s.list}>
        <Grid container sx={s.grid}>
          <Card style={s.titleCard} variant="outlined" contentStyle={s.titleCardContent}>
            <Typography variant="h5" textAlign="center">
              {classElem?.name}
            </Typography>
            <Typography variant="subtitle1" color={secondary} textAlign="center">
              {scenario?.name}
            </Typography>
          </Card>
          <Card style={s.chartCard} variant="outlined">
            <div style={s.chartDiv}>
              <Typography variant="body1">{t(`${dashboardNs}.progress`)}</Typography>
              <MuiSelect
                id="groups"
                variant="outlined"
                value={interval}
                onValueChange={val => {
                  setInterval(val)
                }}
                items={PERIODS.map(item => ({
                  value: item,
                  text:
                    item === "0"
                      ? t(`${dashboardNs}.intervals.full`)
                      : t(`${dashboardNs}.intervals.period`, { nbrOfDays: item }),
                }))}
              />
            </div>
            {ChartComp}
          </Card>
          <div style={s.statsMainDiv}>
            {sections.map(({ data, ...elem }, index) => (
              <Card
                variant="outlined"
                sx={s.statsCardSx}
                style={{ ...s.statsCard, ...cs.marginLeft }}
                contentStyle={cs.fullWidth}
                key={`${index}`}
              >
                <SubStatsHeader {...elem} />
                <ElementStats {...{ data, loading, moreUrl: elem.more }} />
              </Card>
            ))}
          </div>
        </Grid>
      </div>
    </div>
  ) : (
    <EmptyScreen title={t("stats.noDataDivision")} subtitle={t("stats.noDataSubtitleDivision")} />
  )
}
