import "./style/EvalDetailsScreen.css"

import { Tab, Tabs, Typography } from "@mui/material"
import Button from "@mui/material/Button"
import type { Evaluation, TmpSession, UserId } from "@newpv/js-common"
import { AsideView, Separator } from "components"
import _ from "lodash"
import type { FC } from "react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Form,
  Loading,
  required,
  SaveButton,
  TextInput,
  useListContext,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin"
import { useNavigate } from "react-router-dom"
import { useCommonStyles, useStyles } from "utils"

import { evalNs } from "../../features/i18n"
import { APP_BAR_HEIGHT } from "../../features/Theme/theme"
import { CalcMethodDialog } from "./components/CalcMethodDialog"
import { GeneralTab } from "./GeneralTab"
import { ResultsTab } from "./ResultsTab"
import { useEvalCreate, useEvalEdit } from "./useEvalFunctions"

interface Props {
  isCreate?: boolean
  isInitialEvaluation?: boolean
}

export const TOP_HEIGHT = 517
export const BUTTON_DIV_HEIGHT = 68

const ns = "eval.evalModal"

export const EvalDetailsScreen: FC<Props> = ({ isCreate = false, isInitialEvaluation }) => {
  /**
   * Contexts
   * */
  const t = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const navigate = useNavigate()

  const { data: listContextData } = useListContext()

  /**
   * States
   * */
  const [open, setOpen] = useState(false)
  const [sessionOpen, setSessionOpen] = useState(false)
  const [value, setValue] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [newEvalSessions, setNewEvalSessions] = useState<TmpSession[]>([])
  const [retryLearnerId, setRetryLearnerId] = useState<UserId>()

  /**
   * Functions
   * */
  const { save, evalSessions, evalData, onDelete, isLoading, learners, saving } = (
    isCreate ? useEvalCreate : useEvalEdit
  )(isInitialEvaluation)
  const onModalClose = (): void => {
    setModalOpen(false)
  }

  const onClose = (): void => {
    setOpen(false)
    setTimeout(() => {
      navigate("..", { replace: true })
      refresh()
    }, 300)
  }

  /**
   * The following hook is necessary to trigger the opening animation
   * */
  useEffect((): void => {
    setOpen(true)
  }, [])

  const cs = useCommonStyles()
  const s = useStyles(
    ({
      typography: { subtitle1 },
      palette: {
        common: { white },
      },
      spacing,
    }) => ({
      input: {
        margin: spacing(0, 2, 0, 2),
        maxWidth: "100%",
      },
      inputsDiv: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
      },
      mainDiv: {
        alignSelf: "flex-start",
        backgroundColor: white,
        display: "flex",
        flex: 1,
        flexDirection: "column",
        // TODO: remove calc max height here
        maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        maxWidth: "400px",
        overflowY: "auto",
        width: "100%",
      },
      buttons: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        minHeight: BUTTON_DIV_HEIGHT,
        paddingLeft: spacing(2),
      },
      initialEvalTitle: {
        marginBottom: "1rem",
        marginLeft: "0.5rem",
      },
      muiInput: {
        "& .MuiInputBase-root": subtitle1,
        "& .MuiFormLabel-root": cs.muiFormLabel12,
      },
    }),
    [cs.muiFormLabel12],
  )

  const tabs = useMemo(
    () =>
      isInitialEvaluation
        ? [t(`${ns}.resultsTab.title`)]
        : [t(`${ns}.generalTab.title`), t(`${ns}.resultsTab.title`)],
    [isInitialEvaluation, t],
  )

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }, [])

  const usersWithFutureSessionsPlanned = useMemo(
    () =>
      learners?.reduce((accumulator, examinationResult) => {
        // if it's true at any moment, we stop replacing it
        if (!accumulator[examinationResult.userId]) {
          accumulator[examinationResult.userId] =
            !examinationResult.sessionIsOver && examinationResult.status !== "graded"
        }
        return accumulator
      }, {}),
    [learners],
  )

  // The following rule is necessary to avoid form errors on SelectInput component
  // noinspection RequiredAttributes
  return (
    <>
      <AsideView
        {...{ open, onClose }}
        onDelete={isInitialEvaluation ? undefined : onDelete}
        title={t(`${evalNs}.${isCreate ? "new" : "details"}`)}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Form
            id="save_eval"
            mode="onChange"
            className="form"
            defaultValues={evalData}
            onSubmit={data => {
              const newData: Partial<Evaluation> = isCreate
                ? {
                    ...data,
                    sessions: _.map(newEvalSessions, elem => _.omit(elem, ["tmpId"])),
                  }
                : data
              save?.(newData, {
                onSuccess: onClose,
                onError: e => {
                  notify(
                    `ra.notification.${e.response?.data?.code ? "eval_update_err" : "http_error"}`,
                    { type: "error" },
                  )
                },
              })
            }}
          >
            <CalcMethodDialog onClose={onModalClose} open={modalOpen} />
            <div style={s.mainDiv}>
              <div style={cs.marginTop16}>
                <div style={s.inputsDiv}>
                  {isInitialEvaluation ? (
                    <Typography style={s.initialEvalTitle} variant="h6">
                      {t("stats.dashboardTab.initial")}
                    </Typography>
                  ) : (
                    <TextInput
                      source="name"
                      size="medium"
                      color="primary"
                      variant="outlined"
                      style={s.input}
                      sx={s.muiInput}
                      label={t("common.name")}
                      validate={required("ra.validation.required")}
                    />
                  )}
                </div>
              </div>
              <Tabs
                value={value}
                variant="fullWidth"
                aria-label="eval-tabs"
                textColor="inherit"
                onChange={handleChange}
              >
                {tabs.map((elem, index) => (
                  <Tab
                    key={index}
                    label={elem}
                    id={`tab-${index}`}
                    disabled={isCreate && index === 1}
                    aria-controls={`tab-panel-${index}`}
                  />
                ))}
              </Tabs>
              {!isInitialEvaluation ? (
                <GeneralTab
                  {...{
                    value,
                    isCreate,
                    evalSessions,
                    setModalOpen,
                    retryLearnerId,
                    setRetryLearnerId,
                    newEvalSessions,
                    setNewEvalSessions,
                    sessionOpen,
                    setSessionOpen,
                    usersWithFutureSessionsPlanned,
                  }}
                />
              ) : null}
              <ResultsTab
                {...{
                  learners,
                  isInitialEvaluation,
                  setRetryLearnerId,
                  setSessionOpen,
                  usersWithFutureSessionsPlanned,
                }}
                value={isInitialEvaluation ? 1 : value}
                calculationMethod={evalData?.calculationMethod}
                initialEvaluationResults={
                  isInitialEvaluation ? listContextData?.[0].learnerResults : undefined
                }
                evalDuration={evalData?.duration}
              />
              <Separator />
              {!isInitialEvaluation ? (
                <div style={s.buttons}>
                  <SaveButton
                    icon={<></>}
                    disabled={(isCreate ? newEvalSessions : evalSessions)?.length === 0 || saving}
                  />
                  <Button style={cs.marginLeft16} onClick={onClose}>
                    {t("ra.action.cancel")}
                  </Button>
                </div>
              ) : null}
            </div>
          </Form>
        )}
      </AsideView>
    </>
  )
}
