import { Button, Typography } from "@mui/material"
import type { BackDivision, Division, UniverseId } from "@newpv/js-common"
import { axios } from "@newpv/js-common"
import { NotImplemented } from "components"
import AsideSection from "components/AsideSection"
import _ from "lodash"
import type { FC } from "react"
import React, { useMemo, useState } from "react"
import type { InputProps } from "react-admin"
import {
  email,
  required,
  SaveButton,
  TextInput,
  useGetOne,
  usePermissions,
  useTranslate,
} from "react-admin"
import { useFormContext } from "react-hook-form"
import {
  getAuth,
  useCommonStyles,
  useQueryRequest,
  useStyles,
  useToken,
  useUniverseId,
  useUserInfos,
} from "utils"

import { apiUrl } from "../../../features/Constants"
import { BUTTON_DIV_HEIGHT } from "../divisions/DivisionDetailsScreen"
import { ns } from "./constants"
import PermissionCheckboxGroup from "./PermissionCheckboxGroup"
import RoleRadioButtonGroup from "./RoleRadioButtonGroup"
import type { EditableAdministrator, RoleContent } from "./useAdministratorFunctions"

interface Props {
  administratorData?: EditableAdministrator
  isCreation?: boolean
  onClose: () => void
  saving?: boolean
}

export const DetailsContent: FC<Props> = ({ isCreation, onClose, saving, administratorData }) => {
  const t = useTranslate()

  const [userInfos] = useUserInfos()
  const { permissions } = usePermissions()
  const isUniverseAdmin = permissions.includes("universeAdmin")
  const [token] = useToken()
  const [universeId] = useUniverseId()

  const { formState } = useFormContext()
  const { data: divisions } = useQueryRequest<Division[]>(async () => {
    if (!universeId) {
      return
    }
    const rawDivisions = await axios.get<{ results: BackDivision[] }>(
      `${apiUrl}/universe/universe/${universeId}/division`,
      {
        params: {
          pageSize: -1,
        },
        ...getAuth(token),
      },
    )
    return rawDivisions.data.results?.map(rd => ({ id: rd.divisionId, name: rd.name }))
  }, ["universeId", universeId])
  const { data: universe } = useGetOne<{ id: UniverseId; name: string }>(`universe/universe`, {
    id: universeId,
    meta: { idField: "universeId" },
  })
  const [open, setOpen] = useState(false)

  const editable = isUniverseAdmin && isCreation && userInfos.userId !== administratorData?.id

  const roleContent = useMemo(
    () => _.find(administratorData?.roles, (rc: RoleContent) => rc.universeId === universeId),
    [administratorData?.roles, universeId],
  )

  const cs = useCommonStyles()
  const s = useStyles(
    ({
      typography: { subtitle1 },
      spacing,
      palette: {
        divider,
        secondary,
        common: { white },
      },
    }) => ({
      aside: {
        marginBottom: spacing(16),
        overflowY: "auto",
      },
      buttonContainer: {
        alignItems: "center",
        backgroundColor: white,
        borderTop: "1px solid",
        borderTopColor: divider,
        bottom: 0,
        display: "flex",
        gap: spacing(2),
        height: BUTTON_DIV_HEIGHT,
        justifyContent: "flex-start",
        paddingLeft: spacing(2),
        position: "absolute",
        width: "100%",
      },
      emailContainer: {
        paddingLeft: spacing(1),
      },
      muiInput: {
        "& .MuiFormLabel-root": cs.muiFormLabel12,
        "& .MuiInputBase-root": subtitle1,
      },
      input: {
        width: `calc(100% - ${spacing(4)})`,
      },
      sendContainer: {
        alignSelf: "center",
        borderColor: secondary[500],
        borderRadius: "8px",
        borderStyle: "solid",
        borderWidth: 1,
        display: "flex",
        flexDirection: "column",
        gap: spacing(2),
        padding: spacing(2),
      },
      sendButton: {
        backgroundColor: secondary[500],
        padding: spacing(1, 2),
      },
    }),
    [cs.muiFormLabel12],
  )

  const inputProps = useMemo(
    () =>
      ({
        color: "primary",
        size: "medium",
        style: s.input,
        sx: s.muiInput,
        variant: "outlined",
      }) as Partial<InputProps>,
    [s.input, s.muiInput],
  )

  return (
    <div style={s.aside}>
      <div style={cs.asideContainer}>
        {isCreation ? null : (
          <div style={s.sendContainer}>
            <Typography variant="subtitle1">{t(`${ns}.sendHelperText`)}</Typography>
            <Button style={s.sendButton} onClick={() => setOpen(true)}>
              {t(`${ns}.sendLabel`)}
            </Button>
          </div>
        )}
        <Typography variant="subtitle1">{t(`${ns}.identification`)}</Typography>
        {isCreation ? (
          <TextInput
            label={t(`${ns}.email`)}
            source="email"
            validate={[required("ra.validation.required"), email(`${ns}.error.email`)]}
            {...inputProps}
          />
        ) : (
          <div style={s.emailContainer}>
            <Typography style={cs.secondaryTextColor} variant="body2">
              {t(`${ns}.email`)}
            </Typography>
            <Typography variant="body2">{administratorData?.email}</Typography>
          </div>
        )}
      </div>
      <div style={cs.separator} />
      <AsideSection title={t(`${ns}.personalInfos`)}>
        <>
          <TextInput
            label={t(`common.name`)}
            defaultValue={administratorData?.lastName}
            source="lastName"
            validate={required("ra.validation.required")}
            {...inputProps}
          />
          <TextInput
            helperText={false}
            // We want to prevent undefined or empty string values
            defaultValue={
              isCreation ? administratorData?.firstName : administratorData?.firstName || "-"
            }
            label={t(`common.firstName`)}
            source="firstName"
            {...inputProps}
          />
        </>
      </AsideSection>
      <AsideSection title={t(`${ns}.role`)}>
        <RoleRadioButtonGroup {...{ roleContent, editable }} />
      </AsideSection>
      {divisions && universe ? (
        <AsideSection title={t(`${ns}.permission`)} hideSeparator={true}>
          <PermissionCheckboxGroup {...{ divisions, universe, roleContent }} />
        </AsideSection>
      ) : null}
      <div style={s.buttonContainer}>
        <SaveButton
          disableElevation={true}
          disabled={saving || !formState.isValid}
          icon={<></>}
          label="ra.action.save"
          style={cs.paddingButton}
        />
        <Button style={cs.paddingButton} onClick={onClose}>
          {t("ra.action.cancel")}
        </Button>
      </div>
      <NotImplemented {...{ open, setOpen }} />
    </div>
  )
}

export default DetailsContent
