import FileUploadIcon from "@mui/icons-material/FileUpload"
import NoteAddIcon from "@mui/icons-material/NoteAdd"
import RadioChoiceScreen from "components/RadioChoiceScreen"
import _ from "lodash"
import type { FC } from "react"
import React, { useCallback, useState } from "react"
import { useTranslate } from "react-admin"
import { useLocation, useNavigate } from "react-router-dom"
import { useCommonStyles } from "utils"

import type { Choice } from "./constants"
import { ns } from "./constants"
import ScreenWrapper from "./ScreenWrapper"

const AddLearnerScreen: FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const t = useTranslate()

  const choiceState = useState<Choice>()

  const cs = useCommonStyles()

  const onClick = useCallback(() => {
    navigate(choiceState[0] === "manual" ? "/addLearnerManual" : "/authenticationMode", {
      state: { divisionId: state?.divisionId, divisionName: state?.divisionName },
    })
  }, [choiceState, navigate, state])

  return (
    <ScreenWrapper
      bottomProps={{
        next: {
          disabled: _.isEmpty(choiceState[0]),
          label: t(`common.next`),
          onClick,
        },
      }}
    >
      <RadioChoiceScreen<Choice>
        state={choiceState}
        title={t(`${ns}.title`)}
        subtitle={t(`${ns}.subtitle`)}
        radios={{
          left: {
            description: t(`${ns}.importDescription`),
            icon: <FileUploadIcon sx={cs.secondaryTextColor} />,
            label: t(`${ns}.import`),
            value: "import",
          },
          right: {
            description: t(`${ns}.manualDescription`),
            icon: <NoteAddIcon sx={cs.secondaryTextColor} />,
            label: t(`${ns}.manual`),
            value: "manual",
          },
        }}
      />
    </ScreenWrapper>
  )
}

export default AddLearnerScreen
