import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import { Button, IconButton, Typography, useMediaQuery } from "@mui/material"
import type { Resource, ScenarioId } from "@newpv/js-common"
import { MuiSelect } from "components"
import _ from "lodash"
import type { AppBarProps } from "ra-ui-materialui/dist/cjs/layout/AppBar"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useEffect, useMemo } from "react"
import { AppBar as RAAppBar, Link, usePermissions, useTranslate } from "react-admin"
import {
  useCommonStyles,
  useDivisionId,
  useResourceId,
  useScenarioId,
  useScenarioName,
  useStyles,
} from "utils"
import { logger } from "utils/logger"
import { useResources } from "utils/useResources"

import { LogoIcon } from "../../assets/icons"
import type { Permission } from "../../features/Providers"
import { useDivisions } from "./hooks/useDivisions"

export function getScenarioId(resource: Resource | undefined): ScenarioId | undefined {
  return resource && resource.mappingKind === "scenarioId" ? Number(resource.mappingId) : undefined
}

export const AppBar: FC = ({
  setMenuOpen,
  ...props
}: AppBarProps & { setMenuOpen: Dispatch<SetStateAction<boolean>> }) => {
  const t = useTranslate()

  const [divisionId, setDivisionId] = useDivisionId()
  const [resourceId, setResourceId] = useResourceId()
  const [_scenarioId, setScenarioId] = useScenarioId()
  const [_scenarioName, setScenarioName] = useScenarioName()
  const { divisions } = useDivisions()

  const { permissions } = usePermissions<Permission[]>()

  const emptyResources = useMemo(
    () => [
      {
        value: 0,
        text: t("menu.no_resources"),
      },
    ],
    [t],
  )

  const { resources } = useResources()

  useEffect(() => {
    if (divisions != null && !divisions.find(d => d.id === divisionId)) {
      logger("Reset divisionId to ", divisions?.[0]?.id)
      setDivisionId(divisions?.[0]?.id)
    }
  }, [divisionId, divisions, setDivisionId])

  useEffect(() => {
    if (resources != null && !resources.find(rsc => rsc.resourceId === resourceId)) {
      const resource = resources?.[0]
      const resourceScenarioId = getScenarioId(resource)
      logger("Reset resourceId to", resource?.resourceId, typeof resource?.resourceId)
      logger("Reset scenarioId to", resourceScenarioId, typeof resourceScenarioId)
      setResourceId(resource?.resourceId)
      setScenarioId(resourceScenarioId)
      setScenarioName(resource?.resourceName)
    }
  }, [resources, setResourceId, setScenarioId, setScenarioName, resourceId])

  const isSmallWidth = useMediaQuery(theme => (theme as any).breakpoints.down("lg"))

  const cs = useCommonStyles()
  const s = useStyles(
    ({
      palette: {
        text: { primary, secondary },
        common: { black },
        divider,
      },
      spacing,
    }) => ({
      button: {
        borderColor: divider,
        color: secondary,
        margin: spacing(0, 1),
      },
      text: {
        color: primary,
      },
      leftDiv: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
      },
      boxResource: {
        minWidth: "275px",
      },
      boxGroup: {
        margin: spacing(0, 1),
        maxWidth: "250px",
        minWidth: "114px",
      },
      appBar: {
        width: "100%",
        "& .MuiIconButton-root": {
          color: black,
          height: "40px",
          width: "40px",
        },
        "& .RaAppBar-root": {
          boxShadow: "none",
          transform: "none",
          visible: "visible",
        },
        "& .RaAppBar-toolbar": {
          display: "flex",
          width: "100%",
        },
      },
    }),
  )

  useEffect(() => {
    setMenuOpen(!!props.open)
  }, [props.open, setMenuOpen])

  return (
    <RAAppBar sx={s.appBar} {...props}>
      <div style={s.leftDiv}>
        <div style={cs.marginHorizontal16}>
          <LogoIcon />
        </div>
        {(divisions?.length ?? 0) === 0 ? null : (
          <MuiSelect
            id="divisions"
            variant="outlined"
            disabled={(divisions?.length ?? 0) === 1}
            value={divisionId ?? ""}
            boxStyle={s.boxGroup}
            onValueChange={elem => {
              setDivisionId(elem)
            }}
            items={divisions?.map(elem => ({ value: elem.id, text: elem.name }))}
          />
        )}
        <MuiSelect
          id="resource"
          variant="outlined"
          value={resourceId ?? "0"}
          boxStyle={s.boxResource}
          onValueChange={elem => {
            const resource = resources?.find(sc => sc.resourceId === elem)
            setResourceId(elem)
            setScenarioId(getScenarioId(resource))
            setScenarioName(resource?.resourceName ?? "")
          }}
          items={
            _.isEmpty(resources)
              ? emptyResources
              : resources?.map(resource => ({
                  value: resource.resourceId,
                  text: resource.resourceName,
                }))
          }
          disabled={(resources?.length ?? 0) <= 1}
        />
      </div>
      <div style={cs.fullFlex} />
      {permissions?.includes("ENT") ? null : (
        <Link to="/adminPanel/divisions">
          {isSmallWidth ? (
            <IconButton aria-label={t("adminPanel.openPanel")}>
              <ManageAccountsIcon />
            </IconButton>
          ) : (
            <Button sx={s.button} variant="outlined" startIcon={<ManageAccountsIcon />}>
              <Typography sx={s.text} variant="button">
                {t("adminPanel.title")}
              </Typography>
            </Button>
          )}
        </Link>
      )}
    </RAAppBar>
  )
}
