import { Card as MuiCard, CardContent } from "@mui/material"
import type { CardProps } from "@mui/material/Card/Card"
import type { CardContentProps } from "@mui/material/CardContent/CardContent"
import type { FC } from "react"
import React from "react"
import { useStyles } from "utils"

/**
 * Add a shadow to the standard MUI Card component
 */
export const Card: FC<CardProps & { contentStyle?: CardContentProps["style"] }> = ({
  children,
  style,
  contentStyle,
  ...props
}) => {
  const s = useStyles(
    () => ({
      card: {
        ...style,
        borderRadius: "16px",
        height: "100%",
      },
    }),
    [style],
  )

  return (
    <MuiCard variant="outlined" style={s.card} {...props}>
      <CardContent style={contentStyle}>{children}</CardContent>
    </MuiCard>
  )
}
