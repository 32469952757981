import AddIcon from "@mui/icons-material/Add"
import DownloadIcon from "@mui/icons-material/Download"
import { Button, CircularProgress, Typography, useTheme } from "@mui/material"
import type { BackDivision, DivisionId } from "@newpv/js-common"
import { apiUrl, axios } from "@newpv/js-common"
import type { AxiosResponse } from "axios"
import dayjs from "dayjs"
import { DATE_TIME_FORMAT } from "features/Constants"
import type { Permission } from "features/Providers"
import type { FC } from "react"
import React, { useCallback } from "react"
import type { SaveHandler } from "react-admin"
import {
  Form,
  required,
  SaveButton,
  TextInput,
  useNotify,
  usePermissions,
  useTranslate,
} from "react-admin"
import { useNavigate } from "react-router-dom"
import { getAuth, useQueryRequest, useStyles, useToken, useUniverseId } from "utils"
import { useCommonStyles } from "utils"
import delay from "utils/delay"
import { logger } from "utils/logger"
import useLearnerCodes from "utils/useLearnerCodes"

import { useDivisions } from "../../Layout/hooks/useDivisions"

interface Props {
  onClose: () => void
  isCreation?: boolean
  save?: SaveHandler<BackDivision>
  divisionData?: Partial<BackDivision & { id: DivisionId }>
  saving?: boolean
}

export const ns = "adminPanel.divisions.generalTab"

export const GeneralTab: FC<Props> = ({ isCreation, onClose, saving, divisionData, save }) => {
  const navigate = useNavigate()
  const t = useTranslate()
  const { permissions } = usePermissions<Permission[]>()

  const isUniverseAdmin = permissions.includes("universeAdmin")

  const notify = useNotify()
  const [universeId] = useUniverseId()
  const [token] = useToken()

  const { refreshDivisions } = useDivisions()
  const {
    palette: { text: themeText },
  } = useTheme()

  const { data: codeCount } = useQueryRequest<number>(
    async () => {
      const codeCountResult = await axios.get<any, AxiosResponse<number>>(
        `${apiUrl}/universe/division/${divisionData?.divisionId}/learner/code/count`,
        getAuth(token),
      )

      return codeCountResult.data
    },
    ["codeCount", divisionData],
    { enabled: !isCreation && divisionData?.divisionId != null },
  )

  const { areCodesForDivisionLoading, exportCodes } = useLearnerCodes(
    divisionData?.divisionId,
    divisionData?.name,
  )

  const cs = useCommonStyles()
  const s = useStyles(
    ({ typography: { subtitle1 }, spacing, palette: { text, surface } }) => ({
      input: {
        margin: spacing(0, 2, 0, 2),
        width: `calc(100% - ${spacing(4)})`,
      },
      button: {
        padding: spacing(1, 1.5),
      },
      downloadButton: {
        borderColor: surface.outline,
        color: areCodesForDivisionLoading ? text.disabled : text.primary,
        marginBottom: spacing(2),
      },
      buttonRight: {
        marginLeft: spacing(2),
        padding: spacing(1.5, 2, 1.5, 2),
      },
      main: {
        height: "100%",
        marginTop: spacing(2),
      },
      muiInput: {
        "& .MuiInputBase-root": subtitle1,
        "& .MuiFormLabel-root": cs.muiFormLabel12,
      },
      saveButton: {
        boxShadow: "none",
        padding: spacing(1.5, 2, 1.5, 2),
      },
    }),
    [areCodesForDivisionLoading, cs.muiFormLabel12],
  )

  const onSuccess = useCallback(async () => {
    onClose()
    notify(`adminPanel.divisions.${isCreation ? "newSuccess" : "editSuccess"}`, {
      type: "success",
    })
    refreshDivisions()
    await delay(200)
  }, [isCreation, notify, onClose, refreshDivisions])

  return (
    <div id="division-panel-0" style={s.main}>
      <Form
        id="save_division"
        mode="onChange"
        className="form"
        defaultValues={divisionData}
        onSubmit={data => {
          save?.(isCreation ? { ...data, universeId } : data, {
            onSuccess,
            onError: e => {
              logger("division creation error", { e })
              notify(
                e.response?.data?.code
                  ? `adminPanel.divisions.${
                      e.response.data.code === "DUPLICATE_NAME"
                        ? e.response.data.code
                        : "divisionCreationError"
                    }`
                  : "ra.notification.http_error",
                {
                  type: "error",
                },
              )
            },
          })
        }}
      >
        <div style={cs.upponFormContainer}>
          <TextInput
            source="name"
            size="medium"
            color="primary"
            variant="outlined"
            style={s.input}
            sx={s.muiInput}
            label={t("common.name")}
            validate={required("ra.validation.required")}
            disabled={!isUniverseAdmin}
          />
          {isCreation ? null : (
            <div style={cs.marginHorizontal16}>
              <div style={cs.marginBottom16}>
                <Typography variant="body2" color={themeText.secondary}>
                  {t(`${ns}.id`)}
                </Typography>
                <Typography variant="body2">{divisionData?.id ?? "-"}</Typography>
              </div>
              <div style={cs.marginBottom16}>
                <Typography variant="body2" color={themeText.secondary}>
                  {t(`${ns}.owner`)}
                </Typography>
                <Typography variant="body2">
                  {divisionData?.ownerFirstName || divisionData?.ownerLastName
                    ? `${divisionData.ownerFirstName} ${divisionData.ownerLastName}`
                    : "-"}
                </Typography>
                <Typography variant="body2">{divisionData?.ownerEmail || "-"}</Typography>
              </div>
              <div style={cs.marginBottom16}>
                <Typography variant="body2" color={themeText.secondary}>
                  {t(`${ns}.creationDate`)}
                </Typography>
                <Typography variant="body2">
                  {divisionData?.createdAt
                    ? dayjs(divisionData.createdAt).format(DATE_TIME_FORMAT)
                    : "-"}
                </Typography>
              </div>
              <div style={cs.marginBottom16}>
                <Typography variant="body2" color={themeText.secondary}>
                  {t(`adminPanel.divisions.nbLearners`)}
                </Typography>
                <Typography variant="body2">{divisionData?.nbLearners ?? "-"}</Typography>
              </div>
              {(codeCount ?? 0) > 0 ? (
                <Button
                  disabled={areCodesForDivisionLoading}
                  disableElevation={true}
                  onClick={exportCodes}
                  startIcon={
                    areCodesForDivisionLoading ? (
                      <CircularProgress size={20} sx={cs.secondaryTextColor} />
                    ) : (
                      <DownloadIcon sx={cs.secondaryTextColor} />
                    )
                  }
                  style={{ ...s.button, ...s.downloadButton }}
                  variant="outlined"
                >
                  {t(`adminPanel.divisions.downloadCodes`)}
                </Button>
              ) : null}
              {divisionData ? (
                <Button
                  disableElevation={true}
                  onClick={() =>
                    navigate("/addLearner", {
                      state: {
                        divisionId: divisionData.divisionId,
                        divisionName: divisionData.name,
                      },
                    })
                  }
                  startIcon={<AddIcon sx={cs.secondaryTextColor} />}
                  style={s.button}
                  variant="contained"
                >
                  {t(`adminPanel.divisions.addLearners`)}
                </Button>
              ) : null}
            </div>
          )}
        </div>
        {isUniverseAdmin ? (
          <div style={cs.asideButtons}>
            <SaveButton
              icon={<></>}
              disabled={saving}
              style={s.saveButton}
              label={isCreation ? "ra.action.create" : "ra.action.save"}
            />
            <Button style={s.buttonRight} onClick={onClose}>
              {t("ra.action.cancel")}
            </Button>
          </div>
        ) : undefined}
      </Form>
    </div>
  )
}
