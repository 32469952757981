import { Snackbar } from "@mui/material"
import type { Dispatch, FC, SetStateAction } from "react"
import React from "react"
import { useTranslate } from "react-admin"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

/**
 * View displayed if a feature is not yet implemented
 */
export const NotImplemented: FC<Props> = ({ open, setOpen }) => {
  const t = useTranslate()

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      autoHideDuration={6000}
      onClose={() => {
        setOpen(false)
      }}
      message={t("common.notImplemented")}
    />
  )
}
