import { CircularProgress, Typography } from "@mui/material"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useStyles } from "utils"

/**
 * Progress view shown while fetching or updating data
 */
export const Progress: FC = () => {
  const t = useTranslate()

  const s = useStyles(({ spacing }) => ({
    progress: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      height: "auto",
      justifyContent: "center",
      padding: spacing(2),
      textAlign: "center",
    },
    subtitle: {
      marginTop: spacing(1),
    },
  }))

  return (
    <div style={s.progress}>
      <CircularProgress />
      <Typography style={s.subtitle} variant="h5">
        {t("ra.page.loading")}
      </Typography>
      <Typography style={s.subtitle} variant="body2">
        {t("ra.message.loading")}
      </Typography>
    </div>
  )
}
