import type { FC, PropsWithChildren } from "react"
import { useCommonStyles, useStyles } from "utils"

import type { BottomBarProps } from "./AddLearnerBottomBar"
import AddLearnerBottomBar from "./AddLearnerBottomBar"
import type { TopProps } from "./AddLearnerTopBar"
import AddLearnerTopBar from "./AddLearnerTopBar"

interface Props {
  bottomProps?: BottomBarProps
  topProps?: TopProps
}
const ScreenWrapper: FC<PropsWithChildren<Props>> = ({ bottomProps, children, topProps }) => {
  const cs = useCommonStyles()
  const s = useStyles(({ palette: { background } }) => ({
    screen: {
      backgroundColor: background.default,
      display: "flex",
      flexDirection: "column",
      height: "99.5vh",
      justifyContent: "space-between",
      overflowY: "hidden",
      position: "relative",
    },
  }))

  return (
    <div style={s.screen}>
      <AddLearnerTopBar {...topProps} />
      <div style={cs.childrenContainer}>{children}</div>
      <AddLearnerBottomBar {...bottomProps} />
    </div>
  )
}

export default ScreenWrapper
