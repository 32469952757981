import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined"
import { ContentContainer, ScreenContainer } from "components"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useCommonStyles, useStyles } from "utils"

import { APP_BAR_HEIGHT, APP_TOP_BAR_HEIGHT } from "../../features/Theme/theme"
import MonOral from "./components/MonOral"

const OralScreen: FC = () => {
  const t = useTranslate()
  const cs = useCommonStyles()
  const s = useStyles(
    ({
      palette: {
        background: { secondary },
      },
    }) => ({
      div: {
        alignItems: "center",
        backgroundColor: secondary,
        display: "flex",
        flexDirection: "column",
        height: `calc(100vh - ${APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT}px)`,
        justifyContent: "center",
        maxHeight: `calc(100vh - ${APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT}px})`,
        overflow: "auto",
        width: "100%",
      },
    }),
    [],
  )

  return (
    <ScreenContainer
      icon={<ModeCommentOutlinedIcon sx={cs.primary600} fontSize="large" />}
      title={t("oral.title")}
    >
      <ContentContainer>
        <div style={s.div}>
          <MonOral />
        </div>
      </ContentContainer>
    </ScreenContainer>
  )
}

export default OralScreen
