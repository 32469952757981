import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Grid, Typography, useTheme } from "@mui/material"
import type {
  LevelProgression,
  LevelRevision,
  LevelStatic,
  ModuleProgression,
} from "@newpv/js-common"
import { Card } from "components"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useCallback } from "react"
import { useCommonStyles, useStyles } from "utils"

import { ICON_SIZE } from "../../../features/Theme/theme"
import { useLevelItem } from "../hooks/useLevelItem"

interface LevelProps {
  level?: LevelStatic
  onClick: () => void
  progression?: LevelProgression
}

interface Props {
  progress?: ModuleProgression
  level: (LevelStatic & { concernedLevels?: LevelStatic[] }) | LevelRevision
  setSelectedFormattedLevel: Dispatch<
    SetStateAction<(LevelStatic & { concernedLevels?: LevelStatic[] }) | LevelRevision | undefined>
  >
}

const LevelItem: FC<LevelProps> = ({ progression, level, onClick }) => {
  /**
   * Hooks section
   * */

  const { LevelIcon, iconColor, subtitle } = useLevelItem(level, progression)
  const theme = useTheme()

  /**
   * Styling
   * */
  const cs = useCommonStyles()
  const s = useStyles(
    ({
      spacing,
      palette: {
        text,
        common: { white },
      },
    }) => ({
      levelIcon: {
        color: white,
        fontSize: "16px",
      },
      card: {
        borderRadius: "8px",
        minHeight: "56px",
        width: "100%",
      },
      chevronIcon: {
        color: text.secondary,
        fontSize: ICON_SIZE,
      },
      iconDiv: {
        alignItems: "center",
        backgroundColor: iconColor,
        borderRadius: "50px",
        display: "flex",
        height: "24px",
        justifyContent: "center",
        width: "24px",
      },
      cardContent: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        height: "100%",
        minHeight: "56px",
        // This padding syntax is necessary to nullify the default card padding
        padding: spacing(0, 0, 0, 1),
      },
    }),
    [iconColor],
  )

  return (
    <Grid item xs={12} zeroMinWidth onClick={onClick} style={cs.cursor}>
      <Card style={s.card} variant="outlined" contentStyle={s.cardContent}>
        <Grid item xs={1.5}>
          <div style={s.iconDiv}>
            <LevelIcon sx={s.levelIcon} />
          </div>
        </Grid>
        <Grid item xs={9} zeroMinWidth>
          <Typography noWrap variant="subtitle1">
            {level?.title}
          </Typography>
          <Typography noWrap variant="body2" color={theme.palette.text.secondary}>
            {subtitle}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <ChevronRightIcon sx={s.chevronIcon} />
        </Grid>
      </Card>
    </Grid>
  )
}

export const StudentLevels: FC<Props> = ({ level, progress, setSelectedFormattedLevel }) => {
  /**
   * Hooks section
   * */
  const { LevelIcon, iconColor, subtitle } = useLevelItem(level, progress?.levels[level?.id])

  const onClick = useCallback(
    (levelElem?: (LevelStatic & { concernedLevels?: LevelStatic[] }) | LevelRevision) => () => {
      setSelectedFormattedLevel(levelElem)
    },
    [setSelectedFormattedLevel],
  )

  /**
   * Styling
   * */
  const cs = useCommonStyles()
  const s = useStyles(
    ({
      spacing,
      palette: {
        common: { white },
      },
    }) => ({
      levelIcon: {
        color: white,
        fontSize: "16px",
      },
      card: {
        flex: 1,
        display: "flex",
        borderRadius: "8px",
      },
      mainGrid: {
        paddingTop:
          level.type === "finalRevision" || "concernedLevels" in level ? spacing(3) : spacing(2),
      },
      iconDiv: {
        backgroundColor: iconColor,
        borderRadius: "50px",
        width: "24px",
        height: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      cardContent: {
        flex: 1,
        padding: 0,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        margin: spacing(1),
      },
    }),
    [iconColor, level],
  )

  return (
    <Grid container spacing={2} style={s.mainGrid}>
      {"concernedLevels" in level ? (
        <Grid item xs={8} style={cs.displayFlex}>
          <Grid container rowSpacing={2}>
            {level.concernedLevels?.map((l, index) =>
              !l ? null : (
                <LevelItem
                  level={l}
                  onClick={onClick(l)}
                  key={`${l.id}-${index}`}
                  progression={progress?.levels[l.id]}
                />
              ),
            )}
          </Grid>
        </Grid>
      ) : null}
      <Grid
        item
        style={cs.cursor}
        onClick={onClick(level)}
        xs={"concernedLevels" in level ? 4 : 12}
      >
        <Card style={s.card} variant="outlined" contentStyle={s.cardContent}>
          <div style={s.iconDiv}>
            <LevelIcon sx={s.levelIcon} />
          </div>
          <Typography textAlign="center" variant="subtitle1">
            {level.title}
          </Typography>
          <Typography textAlign="center" variant="body2">
            {subtitle}
          </Typography>
        </Card>
      </Grid>
    </Grid>
  )
}
