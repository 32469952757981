import { Grid } from "@mui/material"
import type { FC } from "react"
import React, { useMemo, useState } from "react"
import { useStyles } from "utils"

import { APP_BAR_HEIGHT, APP_TOP_BAR_HEIGHT } from "../../features/Theme/theme"
import type { StatsTabProps } from "./Dashboard"

export const exportNs = "stats.exportsTab"

import { usePermissions } from "react-admin"
import { useResources } from "utils/useResources"

import detailedUserStats from "../../assets/images/detailedUserStatsPreview.png"
import initialGaps from "../../assets/images/initialGapsPreview.png"
import periodOfUse from "../../assets/images/periodOfUsePreview.png"
import reportPreview from "../../assets/images/reportPreview.png"
import userStatsPreview from "../../assets/images/userStatsPreview.png"
import { ExportCard } from "./components/ExportCard"
import { useExports } from "./hooks/useExports"
import type { DataType } from "./utils/ExportsUtils"

export interface File {
  preview: any
  dataType?: DataType
  type?: "csv" | "pdf"
  headersInsertIdx?: number
  actions?: Array<"division" | "universe">
  universeAdminAccess?: boolean
}

const files: File[] = [
  {
    preview: reportPreview,
    type: "pdf",
  },
  {
    dataType: "userStats",
    preview: userStatsPreview,
    headersInsertIdx: 9,
  },
  {
    dataType: "detailedUserStats",
    preview: detailedUserStats,
  },
  {
    dataType: "initialGaps",
    preview: initialGaps,
  },
  {
    dataType: "periodOfUse",
    preview: periodOfUse,
  },
  {
    dataType: "adminList",
    preview: periodOfUse,
    actions: ["division", "universe"],
    universeAdminAccess: true,
  },
]

export const StatsExport: FC<StatsTabProps> = ({ selectedTab, isScenarioEmpty = false }) => {
  const { permissions } = usePermissions()
  const isUniverseAdmin = permissions?.includes("universeAdmin")
  const s = useStyles(
    ({
      spacing,
      palette: {
        background: { secondary },
      },
    }) => ({
      grid: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        maxHeight: "100%",
        width: "100%",
        overflowY: "auto",
        gap: spacing(2),
        padding: spacing(4),
      },
      mainDiv: {
        ...(selectedTab === "exports" ? {} : { display: "none" }),
        backgroundColor: secondary,
        height: `calc(100vh - ${APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT}px - ${spacing(6)})`,
        maxHeight: `calc(100vh - ${APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT}px - ${spacing(6)})`,
      },
    }),
    [selectedTab],
  )

  const [dataType, setDataType] = useState<DataType | "csv" | "pdf" | undefined>()
  const [exportScope, setExportScope] = useState<"division" | "universe" | undefined>()
  const { resources } = useResources()

  const { isEvalListFetching, isModuleInfosFetching, isUserListFetching, isUniverseInfoFetching } =
    useExports(dataType, setDataType, exportScope)

  const isLoadingMap = useMemo(
    () => ({
      initialGaps: isUserListFetching,
      periodOfUse: isUserListFetching,
      detailedUserStats: isUserListFetching || isModuleInfosFetching || isUniverseInfoFetching,
      userStats: isEvalListFetching || isUserListFetching || isUniverseInfoFetching,
      pdf: false,
    }),
    [isEvalListFetching, isModuleInfosFetching, isUserListFetching, isUniverseInfoFetching],
  )

  const filteredFiles = useMemo(
    () =>
      isUniverseAdmin
        ? !isScenarioEmpty
          ? files
          : files.filter(file => file.universeAdminAccess)
        : files.filter(file => !file.universeAdminAccess),
    [isScenarioEmpty, isUniverseAdmin],
  )

  return (
    <div style={s.mainDiv}>
      <Grid container sx={s.grid}>
        {filteredFiles.map(file => (
          <ExportCard
            {...file}
            {...{ isScenarioEmpty, setExportScope, setDataType, resources }}
            key={file.dataType ?? file.type}
            loading={dataType === file.dataType && isLoadingMap[file.dataType ?? "pdf"]}
          />
        ))}
      </Grid>
    </div>
  )
}
