import { useTheme } from "@mui/material"
import { Empty } from "components"
import { commonNs } from "features/i18n/fr"
import type { FC } from "react"
import React from "react"
import { Datagrid, FunctionField, TextField, useListContext, useTranslate } from "react-admin"

const DivisionDatagrid: FC = () => {
  const t = useTranslate()
  const { data, isLoading } = useListContext()
  const {
    palette: { primary },
  } = useTheme()

  return (
    <Datagrid
      data={data}
      isLoading={isLoading}
      rowClick={id => id.toString()}
      empty={<Empty isList={true} subtitle={t("adminPanel.divisions.empty.title")} />}
      bulkActionButtons={false}
    >
      <TextField variant="subtitle1" source="name" label={t(`${commonNs}.name`)} sortBy="name" />
      <TextField
        sortable={false}
        source="nbLearners"
        label={t(`adminPanel.divisions.nbLearners`)}
        variant="subtitle1"
      />
      <FunctionField
        label={t(`adminPanel.divisions.createdBy`)}
        color={primary["700"]}
        render={record =>
          record.ownerFirstName || record.ownerLastName
            ? `${record.ownerFirstName} ` + `${record.ownerLastName}`
            : "-"
        }
      />
    </Datagrid>
  )
}

export default DivisionDatagrid
