import { useTheme } from "@mui/material"

/**
 * Returns the appropriate color for the targeted grade
 *
 * @param {number} base The radix number
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGradeColor = () => {
  const {
    palette: {
      text: { secondary },
      success: { main: success },
      warning: { main: warning },
    },
  } = useTheme()

  const getPercentColor = (percent = 0, base = 100): string =>
    percent <= base * 0.6 ? warning : percent <= base * 0.8 ? secondary : success

  return { getPercentColor }
}
