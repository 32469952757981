import type { CSSProperties, FC, ReactNode } from "react"
import React from "react"
import { useStyles } from "utils"

import { APP_BAR_HEIGHT, APP_TOP_BAR_HEIGHT, SCREEN_TOP_BAR_HEIGHT } from "../features/Theme/theme"

interface Props {
  style?: CSSProperties
  children?: ReactNode
  hasTopBar?: boolean
}

/**
 * Normalized container used in all screens
 */
export const ContentContainer: FC<Props> = ({ children, style, hasTopBar }) => {
  const s = useStyles(
    ({ palette }) => ({
      main: {
        alignItems: "center",
        background: palette.background.default,
        display: "flex",
        flexGrow: 1,
        height: `calc(100vh - ${
          APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT + (hasTopBar ? SCREEN_TOP_BAR_HEIGHT : 0)
        }px)`,
        justifyContent: "center",
        maxHeight: `calc(100vh - ${
          APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT + (hasTopBar ? SCREEN_TOP_BAR_HEIGHT : 0)
        }px)`,
        width: "auto",
        ...style,
      },
    }),
    [hasTopBar, style],
  )

  return <div style={s.main}>{children}</div>
}
