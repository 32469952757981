import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined"
import ErrorIcon from "@mui/icons-material/Error"
import { Box, CircularProgress, Typography } from "@mui/material"
import { isEmpty } from "@newpv/js-common"
import type { FC } from "react"
import React, { useCallback, useMemo, useState } from "react"
import { FileField, FileInput, useInput, useNotify, useTranslate } from "react-admin"
import { useFormContext } from "react-hook-form"
import { useCommonStyles, useStyles } from "utils"

import { iconSize } from "../features/Constants"

interface Props {
  ns: string
  onRemove?: () => void
  source: string
  validation: (e: File, dryRun: boolean) => Promise<{ valid: boolean; readyToImport?: number }>
}

const FileInputField: FC<Props> = ({ ns, onRemove, source, validation }) => {
  const t = useTranslate()
  const notify = useNotify()
  const { setValue } = useFormContext()
  const [isValid, setIsValid] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing, palette: { action, background, success, warning, text } }) => ({
      container: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        padding: spacing(3, 4, 3, 4),
      },
      helperText: {
        color: isValid ? success.main : warning.main,
      },
      helperTextContainer: {
        alignItems: "center",
        display: "flex",
        flex: 1,
        flexDirection: "row",
        gap: spacing(1),
        marginTop: spacing(2),
      },
      helperTextIcon: {
        color: success.main,
      },
      icon: {
        color: text.secondary,
        height: iconSize.EXTRA_LARGE,
        width: iconSize.EXTRA_LARGE,
      },
      input: {
        "& .previews": {
          alignSelf: "flex-end",
          right: spacing(1),
          top: spacing(1),
          position: "absolute",
        },
        "& .RaFileInput-dropZone": {
          backgroundColor: background.secondary,
          borderColor: action.outlined,
          borderRadius: 4,
          borderStyle: "solid",
          borderWidth: 1,
          padding: 0,
        },
        ".RaFileInputPreview-removeIcon": cs.secondaryTextColor,
      },
      subtitle: {
        color: text.secondary,
        textAlign: "left",
      },
      text: {
        textAlign: "left",
      },
      textContainer: {
        display: "flex",
        flexDirection: "column",
        gap: spacing(1),
        paddingLeft: spacing(4),
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    }),
    [cs.secondaryTextColor, isValid],
  )

  const onChange = useCallback(
    async (result: any) => {
      if (result instanceof File) {
        setLoading(true)
        const res = await validation(result, true)
        setValue("learners", result)
        setIsValid(res.valid)
        setLoading(false)
      }
    },
    [setValue, validation],
  )

  const { field } = useInput<File>({ source, onChange })

  const placeholder = useMemo(
    () => (
      <div style={s.container}>
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <CloudUploadOutlinedIcon style={s.icon} />
        )}
        <div style={s.textContainer}>
          <Typography noWrap={!!field.value} style={s.text} variant="h6">
            {field.value ? (field.value as File).name : t(`${ns}.file.title`)}
          </Typography>
          {isEmpty(field.value) ? (
            <Typography style={s.subtitle} variant="subtitle1">
              {t(`${ns}.file.subtitle`)}
            </Typography>
          ) : null}
        </div>
      </div>
    ),
    [field.value, loading, ns, s.container, s.icon, s.subtitle, s.text, s.textContainer, t],
  )

  const helperText = useMemo(
    () =>
      isEmpty(field.value) || loading ? (
        false
      ) : (
        <div style={s.helperTextContainer}>
          {isValid ? (
            <CheckIcon style={s.helperTextIcon} />
          ) : (
            <ErrorIcon style={cs.warningMainColor} />
          )}
          <Typography style={s.helperText} variant="body1">
            {t(`${ns}.${isValid ? "success" : "error"}`)}
          </Typography>
        </div>
      ),
    [
      cs.warningMainColor,
      field.value,
      isValid,
      loading,
      ns,
      s.helperText,
      s.helperTextContainer,
      s.helperTextIcon,
      t,
    ],
  )

  return (
    <FileInput
      accept=".xlsx"
      disabled={loading}
      label={false}
      maxSize={1000000}
      options={{
        disabled: loading,
        onDropRejected: () => notify(`${ns}.error`, { type: "warning" }),
      }}
      removeIcon={() => (loading ? undefined : <ClearIcon />)}
      sx={s.input}
      {...field}
      {...{ helperText, placeholder, source, onRemove }}
    >
      <FileField source="src" />
    </FileInput>
  )
}

export default FileInputField
