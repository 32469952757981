import GroupsIcon from "@mui/icons-material/Groups"
import { Button, Typography } from "@mui/material"
import type { User } from "@newpv/js-common"
import { axios } from "@newpv/js-common"
import { ScreenContainer } from "components"
import _ from "lodash"
import type { FC } from "react"
import React, { useEffect, useMemo, useState } from "react"
import {
  List,
  Loading,
  Resource,
  ResourceContextProvider,
  SearchInput,
  useNotify,
  useTranslate,
} from "react-admin"
import {
  getAuth,
  useCommonStyles,
  useDivisionId,
  useQueryRequest,
  useResourceId,
  useStyles,
  useToken,
  useUniverseId,
} from "utils"
import type { License, LicenseSummary } from "utils/License"

import { apiUrl } from "../../features/Constants"
import { commonNs } from "../../features/i18n/fr"
import { LicenseDataGrid } from "./components/LicenseDataGrid"
import SelectAllButton from "./components/SelectAllButton"

const route = `${apiUrl}/ecoledirecte/license`

export const LicensesTable: FC = () => {
  const t = useTranslate()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [universeId] = useUniverseId()
  const [divisionId] = useDivisionId()
  const [resourceId] = useResourceId()
  const [token] = useToken()

  const cs = useCommonStyles()
  const s = useStyles(({ spacing, palette: { secondary } }) => ({
    explanation: {
      margin: spacing(1),
      whiteSpace: "pre-line",
    },
    licenseSummaryContainer: {
      height: "min-content",
      padding: spacing(2),
      width: "100%",
    },
    licenseSummaryCount: {
      backgroundColor: secondary[50],
      borderRadius: "8px",
      height: "min-content",
      padding: spacing(2, 3),
      width: "fit-content",
    },
    list: {
      "& .RaList-main": {
        boxShadow: "none",
      },
      "& .RaList-content": {
        borderRadius: "2px",
      },
      "& .RaBulkActionsToolbar-toolbar": {
        visibility: "hidden",
      },
      "& .RaDatagrid-tableWrapper": {
        borderRadius: "2px",
        boxShadow: 4,
        overflowX: "hidden",
      },
    },
  }))

  /** DATA FETCHING */
  const { data: licensesList, refetch } = useQueryRequest<License[]>(async () => {
    if (!divisionId || !resourceId) {
      return
    }
    const list = await axios.get<{ results: License[] }>(route, {
      params: { resourceId, divisionId },
      ...getAuth(token),
    })

    return list.data.results
  }, ["licenseList", "resourceId", resourceId, "divisionId", divisionId])

  /** Licenses summary */
  const { data: licensesSummary, refetch: refetchLicensesSummary } =
    useQueryRequest<LicenseSummary>(async () => {
      if (!universeId || !resourceId) {
        return
      }
      const summary = await axios.get<LicenseSummary>(`${apiUrl}/ecoledirecte/license-summary`, {
        params: { resourceId, universeId },
        ...getAuth(token),
      })

      return summary.data
    }, ["licenseSummary", "resourceId", resourceId, "universeId", universeId])

  /** EFFECTS */
  useEffect(() => {
    setSelectedIds(licensesList?.map(license => license.userId) ?? [])
  }, [licensesList])

  const formerLicenses = useMemo(() => licensesList?.map(el => el.userId) ?? [], [licensesList])

  /** FUNCTIONS */
  const onSubmit = async (): Promise<void> => {
    try {
      setLoading(true)

      const commonReqData = { universeId, resourceId, divisionId }

      const licensesToRemove = _.difference(formerLicenses, selectedIds)
      const licensesToAdd = _.difference(selectedIds, formerLicenses)

      // we need to delete first
      // to make room for additions, if we already reached the limit
      if (!_.isEmpty(licensesToRemove)) {
        await axios.delete(route, {
          params: {
            ...commonReqData,
            usersId: licensesToRemove,
          },
          // auth headers
          ...getAuth(token),
        })
      }

      if (!_.isEmpty(licensesToAdd)) {
        await axios.post(route, { ...commonReqData, usersId: licensesToAdd }, getAuth(token))
      }

      await refetch()
      await refetchLicensesSummary()
    } catch (err) {
      notify(
        err.response.data.message === "No licenses left"
          ? "ra.notification.assign_licenses_err"
          : "ra.notification.http_error",
        {
          type: "error",
          autoHideDuration: 1500,
        },
      )
    } finally {
      setLoading(false)
    }
  }

  return !divisionId || !licensesList ? (
    <Loading />
  ) : (
    <ScreenContainer
      icon={<GroupsIcon fontSize="large" />}
      title={t(`${commonNs}.students`)}
      right={
        <Button
          disabled={
            loading || _.isEqual(licensesList?.map(elem => elem.userId).sort(), selectedIds.sort())
          }
          style={cs.alignButton}
          variant="contained"
          onClick={onSubmit}
        >
          {t("license.save")}
        </Button>
      }
    >
      <div style={cs.fullView}>
        {licensesSummary ? (
          <div style={s.licenseSummaryContainer}>
            <div style={s.licenseSummaryCount}>
              <Typography variant="subtitle1" style={{ whiteSpace: "pre-wrap" }}>
                {t("licensesSummary.main", {
                  total: licensesSummary?.total,
                  available: licensesSummary?.available,
                  newlySelected: _.difference(selectedIds, formerLicenses).length,
                  newlyUnselected: _.difference(formerLicenses, selectedIds).length,
                })}
              </Typography>
            </div>
            <Typography style={s.explanation} variant="body2">
              {t("licensesSummary.explanation")}
            </Typography>
          </div>
        ) : null}

        <List<Omit<User, "roles" | "inscriptionDate">>
          actions={false}
          sx={s.list}
          empty={false}
          exporter={false}
          queryOptions={{
            onError: () => notify("ra.notification.http_error", { type: "error" }),
          }}
          resource={`ecoledirecte/division/${divisionId}/learner`}
          // "source" has to be "search" to correctly filter in the API
          filters={[<SearchInput variant="outlined" alwaysOn source="search" key={0} />]}
          sort={{ field: "lastName", order: "ASC" }}
          disableSyncWithLocation={true}
        >
          <>
            <SelectAllButton
              {...{ selectedIds, setSelectedIds, licensesList }}
              availableLicenses={licensesSummary?.available ?? 0}
            />
            <LicenseDataGrid
              {...{ selectedIds, setSelectedIds, licensesList }}
              availableLicenses={licensesSummary?.available ?? 0}
            />
          </>
        </List>
      </div>
    </ScreenContainer>
  )
}

export const LicensesListScreen: FC = () => (
  // to target BFF? - TODO: see if can be removed
  <ResourceContextProvider value="users">
    <Resource name="users" list={LicensesTable} />
  </ResourceContextProvider>
)
