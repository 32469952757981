import CheckIcon from "@mui/icons-material/Check"
import StarBorder from "@mui/icons-material/StarBorder"
import TripOriginOutlinedIcon from "@mui/icons-material/TripOriginOutlined"
import { useTheme } from "@mui/material"
import type { LevelProgression, LevelRevision, LevelStatic } from "@newpv/js-common"
import _ from "lodash"
import { useMemo } from "react"
import { useTranslate } from "react-admin"
import { formatDuration } from "utils"

import { PROGRESS_NS } from "../ProgressTab"

// eslint-disable-next-line no-shadow
enum LevelStartedStatus {
  NOT_STARTED,
  STARTED,
  COMPLETED,
  KNOWN,
}

/**
 * Hook used by the both level cards
 *
 * @param level The level to use
 * @param progression The level progression
 *
 * @return Returns the icon color, logo and subtitle for a level
 */
export const useLevelItem = (
  level?:
    | (LevelStatic & {
        concernedLevels: Array<LevelStatic | undefined>
      })
    | LevelStatic
    | LevelRevision,
  progression?: LevelProgression,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  const {
    palette: { primary, grey, warning },
  } = useTheme()
  const t = useTranslate()

  /**
   * Calculate the correct rules values for correct display
   * */
  const completedRules = _.filter(progression?.rules, elem => elem?.completionPercentage >= 100)

  const startedLevel = (progression?.trainingSessions ?? 0) > 0

  const knownRules = _.filter(progression?.rules, elem => elem?.isKnown === true)

  const levelStatus = useMemo(
    () =>
      (progression?.completionPercentage ?? 0) >= 100
        ? completedRules.length === knownRules.length
          ? LevelStartedStatus.KNOWN // If all rules are completed and known
          : LevelStartedStatus.COMPLETED // If all rules are completed but at least one is not known
        : startedLevel
        ? LevelStartedStatus.STARTED
        : LevelStartedStatus.NOT_STARTED,
    [progression?.completionPercentage, completedRules.length, knownRules.length, startedLevel],
  )

  const iconItem =
    levelStatus === LevelStartedStatus.KNOWN
      ? { iconColor: primary["500"], LevelIcon: StarBorder }
      : levelStatus === LevelStartedStatus.STARTED
      ? { iconColor: warning["400"], LevelIcon: TripOriginOutlinedIcon }
      : levelStatus === LevelStartedStatus.COMPLETED
      ? { iconColor: primary["400"], LevelIcon: CheckIcon }
      : {
          iconColor:
            _.isEmpty(progression?.revisionRuleIds) && progression != null
              ? primary["500"]
              : grey["300"],
          LevelIcon:
            level?.type !== "static" &&
            progression != null &&
            _.isEmpty(progression?.revisionRuleIds)
              ? StarBorder
              : TripOriginOutlinedIcon,
        }

  const levelRulesNbr =
    (level as LevelStatic)?.rulesNbr ?? progression?.revisionRuleIds?.flat().length ?? 0

  const subtitle = t(
    `${PROGRESS_NS}.levelSubtitle${
      levelRulesNbr === -1 ? "_notOpen" : !progression?.trainingDuration ? "_noDelay" : ""
    }`,
    {
      rules: `${completedRules.length ?? 0}${
        levelStatus >= LevelStartedStatus.COMPLETED || levelRulesNbr === 0
          ? ""
          : `/${levelRulesNbr}`
      }`,
      delay: formatDuration({ t, duration: progression?.trainingDuration, alt: "00 h 00" }),
    },
  )

  const graphData = useMemo(
    () =>
      progression?.graph?.map(({ date, percentage }, index) => ({
        date,
        percentage,
        id: `step-${index}`,
      })) ?? [],
    [progression?.graph],
  )

  return {
    ...iconItem,
    subtitle,
    levelRulesNbr,
    completedRules,
    graphData,
    totalTrainingTime:
      progression && level
        ? formatDuration({
            t,
            duration: progression?.trainingDuration,
            customFormat:
              progression?.trainingDuration >= 360000
                ? "common.durationFormat"
                : "common.durationMin",
          })
        : formatDuration({ t, duration: 0, customFormat: "common.durationMin" }),
  }
}
