import BlockIcon from "@mui/icons-material/Block"
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined"
import RefreshIcon from "@mui/icons-material/Refresh"
import { Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material"
import type {
  CalculationMethod,
  InitialEvalLearnerResult,
  LearnerExaminationResult,
  User,
  UserId,
} from "@newpv/js-common"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useCallback } from "react"
import { useTranslate } from "react-admin"
import { formatDuration, useCommonStyles, useGradeColor, useStyles } from "utils"

interface Props {
  learner:
    | (Omit<InitialEvalLearnerResult, "id"> & { userId: UserId })
    | (LearnerExaminationResult & {
        sessionIsOver: boolean
        hasFutureSessionsPlanned: boolean
      } & Partial<User>)

  calculationMethod?: CalculationMethod
  isInitialEvaluation?: boolean
  setRetryLearnerId: (learnerId: string) => void
  setSessionOpen: Dispatch<SetStateAction<boolean>>
  hasFutureSessionPlanned: boolean
  evalDuration?: number
}

export function isInitialEvaluationResult(
  learnerResult:
    | (Omit<InitialEvalLearnerResult, "id"> & { userId: UserId })
    | (LearnerExaminationResult & {
        sessionIsOver: boolean
        hasFutureSessionsPlanned: boolean
      } & Partial<User>),
  isInitialEvaluation,
): learnerResult is Omit<InitialEvalLearnerResult, "id"> & { userId: UserId } {
  return isInitialEvaluation
}

export const ResultItem: FC<Props> = ({
  learner: learnerExaminationResult,
  calculationMethod,
  isInitialEvaluation,
  setRetryLearnerId,
  setSessionOpen,
  hasFutureSessionPlanned,
  evalDuration = 0,
}) => {
  const t = useTranslate()

  const isInitialEvaluationLearner = isInitialEvaluationResult(
    learnerExaminationResult,
    isInitialEvaluation,
  )

  // hasFutureSessionPlanned = there is an unfinished session for which the user has no grade
  // so they cannot retry the eval yet
  const forbiddenToRetryEval = isInitialEvaluationLearner || hasFutureSessionPlanned

  const learnerId = learnerExaminationResult.userId

  const { getPercentColor } = useGradeColor()
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme()

  const gradeColor = getPercentColor(
    isInitialEvaluationLearner
      ? learnerExaminationResult?.score ?? 0
      : learnerExaminationResult?.grade &&
        learnerExaminationResult?.grade.rulesKnown &&
        learnerExaminationResult?.grade.totalRules
      ? (learnerExaminationResult.grade.rulesKnown / learnerExaminationResult.grade.totalRules) *
        100
      : 0,
  )

  const createRetrySession = useCallback((): void => {
    if (learnerId) {
      setSessionOpen(true)
      setRetryLearnerId(learnerId)
    }
  }, [learnerId, setRetryLearnerId, setSessionOpen])

  const notStarted = !isInitialEvaluationLearner
    ? learnerExaminationResult.status === "notstarted"
    : false

  const cs = useCommonStyles()
  const s = useStyles(({ spacing }) => ({
    sndText: {
      fontStyle: "italic",
    },
    main: {
      padding: spacing(1.5, 0),
    },
    name: {
      overflow: "hidden",
      paddingRight: spacing(2),
    },
    item: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
    },
  }))

  const commonXs = isInitialEvaluationLearner ? 2.5 : 2

  const extraTimeFactor = learnerExaminationResult?.extraTime ? 1 + 1 / 3 : 1
  const duration = isInitialEvaluationLearner
    ? formatDuration({ t, duration: learnerExaminationResult.trainingDuration })
    : notStarted
    ? "-"
    : formatDuration({
        t,
        duration:
          (learnerExaminationResult.consumedTime != null
            ? Math.min(learnerExaminationResult.consumedTime, evalDuration * extraTimeFactor)
            : 0) * 1000,
      })

  /**
   * If the evaluations have not been started by the learner, print a "-" instead of the related data
   * */
  return (
    <Grid container style={s.main}>
      <Grid
        style={{ ...s.item, justifyContent: "flex-start" }}
        item
        xs={isInitialEvaluationLearner ? 7 : 6}
      >
        <Typography style={s.name} variant="subtitle1">
          {`${learnerExaminationResult.lastName?.toLocaleUpperCase()} ${
            learnerExaminationResult.firstName ?? ""
          }`}
        </Typography>
      </Grid>
      <Grid style={s.item} item xs={commonXs}>
        {learnerExaminationResult?.extraTime ? (
          <Tooltip title={t("eval.sessionModal.extraTime")}>
            <MoreTimeOutlinedIcon
              sx={{ color: secondary, fontSize: "medium", marginRight: "4px" }}
            />
          </Tooltip>
        ) : null}
        <Typography
          variant="subtitle2"
          color={notStarted ? undefined : secondary}
          style={s.sndText}
        >
          {duration}
        </Typography>
      </Grid>
      <Grid style={s.item} item xs={commonXs}>
        <Typography
          variant="subtitle2"
          color={notStarted ? undefined : gradeColor}
          style={s.sndText}
        >
          {notStarted ||
          (isInitialEvaluationLearner && learnerExaminationResult?.score == null) ||
          (!isInitialEvaluationLearner &&
            (!calculationMethod || learnerExaminationResult.grade == null))
            ? "-"
            : isInitialEvaluationLearner
            ? `${learnerExaminationResult?.score} %`
            : `${learnerExaminationResult.grade?.[
                calculationMethod === "bonusenabled" ? "gradeOn20Improved" : "gradeOn20"
              ]}/20`}
        </Typography>
      </Grid>
      {isInitialEvaluationLearner ? null : (
        <Grid style={s.item} item xs={2}>
          <Tooltip
            title={
              !forbiddenToRetryEval
                ? t("eval.sessionModal.retryTooltip")
                : t("eval.sessionModal.noRetryTooltip")
            }
          >
            <IconButton onClick={!forbiddenToRetryEval ? createRetrySession : undefined}>
              {!forbiddenToRetryEval ? (
                <RefreshIcon sx={cs.secondaryTextColor} />
              ) : (
                <BlockIcon sx={cs.secondaryTextColor} />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}
