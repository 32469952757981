import { Empty } from "components"
import type { Dispatch, FC, SetStateAction } from "react"
import * as React from "react"
import { Datagrid, useTranslate } from "react-admin"
import type { License } from "utils/License"

import { commonNs } from "../../../features/i18n/fr"
import { NameField } from "../../StudentScreen/components/NameField"
import { CheckboxField } from "./CheckboxField"
import { LicenseDateField } from "./LicenseDateField"

/*
interface Props {
  licenseType: "student" | "teacher"
}
*/

const ns = "resources.licenses.fields"

interface DataProps {
  licensesList?: License[]
  selectedIds: string[]
  setSelectedIds: Dispatch<SetStateAction<string[]>>
  availableLicenses: number
}

export const LicenseDataGrid: FC<DataProps> = ({
  setSelectedIds,
  selectedIds,
  licensesList = [],
  availableLicenses,
}) => {
  const t = useTranslate()

  return (
    <Datagrid
      empty={<Empty isList={true} subtitle={t("students.empty")} />}
      bulkActionButtons={false}
    >
      <CheckboxField
        {...{ selectedIds, setSelectedIds, licensesList, availableLicenses }}
        label={t(`${ns}.licenses`)}
      />
      <NameField source="lastName" label={t(`${commonNs}.name`)} />
      <NameField source="firstName" label={t(`${commonNs}.firstName`)} emptyText="-" />
      <LicenseDateField licenses={licensesList} source="id" label={t(`${ns}.assignmentDate`)} />
    </Datagrid>
  )
}
