import type { BackDivision, DivisionId } from "@newpv/js-common"
import { isEmpty } from "@newpv/js-common"
import EmptyScreen from "components/EmptyScreen"
import type { FC } from "react"
import React from "react"
import {
  CreateButton,
  FilterLiveSearch,
  List,
  useNotify,
  usePermissions,
  useTranslate,
} from "react-admin"
import { Route, Routes } from "react-router-dom"
import { useStyles, useUniverseId } from "utils"

import { useDivisions } from "../../Layout/hooks/useDivisions"
import DivisionDatagrid from "../DivisionDatagrid"
import { DivisionDetailsScreen } from "./DivisionDetailsScreen"

const ns = "adminPanel.divisions"
export const DivisionList: FC = () => {
  const t = useTranslate()
  const notify = useNotify()
  const [universeId] = useUniverseId()
  const { divisions } = useDivisions()
  const { permissions } = usePermissions()

  const s = useStyles(
    ({ spacing }) => ({
      buttonRight: {
        boxShadow: "none",
        height: "36px",
        position: "absolute",
        right: spacing(1),
        top: -40,
        zIndex: 2,
      },
      main: {
        height: "100%",
        position: "relative",
        width: "100%",
        ...(isEmpty(divisions)
          ? {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }
          : undefined),
      },
    }),
    [divisions],
  )

  return (
    <div style={s.main}>
      {permissions.includes("universeAdmin") && !isEmpty(divisions) ? (
        <CreateButton
          label="adminPanel.divisions.create"
          resource="adminPanel/divisions"
          variant="contained"
          style={s.buttonRight}
        />
      ) : null}
      <List<BackDivision & { id: DivisionId }>
        actions={false}
        resource={`universe/universe/${universeId}/division`}
        queryOptions={{
          meta: { idField: "divisionId" },
          onError: () => notify("ra.notification.http_error", { type: "error" }),
        }}
        exporter={false}
        emptyWhileLoading={true}
        empty={
          <EmptyScreen
            button={
              <CreateButton
                icon={<></>}
                label={`${ns}.create`}
                resource="adminPanel/divisions"
                variant="contained"
              />
            }
            subtitle={t(`${ns}.empty.subtitle`)}
            title={t(`${ns}.empty.title`)}
          />
        }
        sort={{ field: "name", order: "ASC" }}
        // "source" has to be "search" to correctly filter in the API
        filters={[
          <FilterLiveSearch
            key={0}
            alwaysOn={true}
            placeholder={t(`adminPanel.divisions.placeholder`)}
            name="search"
            source="search"
          />,
        ]}
      >
        <DivisionDatagrid />
      </List>
      <Routes>
        <Route path="/:id" element={<DivisionDetailsScreen />} />
        {permissions.includes("universeAdmin") ? (
          <Route path="/create" element={<DivisionDetailsScreen isCreation={true} />} />
        ) : null}
      </Routes>
    </div>
  )
}
