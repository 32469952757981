import DownloadIcon from "@mui/icons-material/Download"
import { Button, Grid, Typography, useTheme } from "@mui/material"
import { axios, isEmpty, logger } from "@newpv/js-common"
import type { AxiosResponse } from "axios"
import A from "axios"
import { NotImplemented } from "components"
import BoxWrapper from "components/BoxWrapper"
import FileInputField from "components/FileInputField"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useCallback, useMemo, useState } from "react"
import { Form, SaveButton, useNotify, useRefresh, useTranslate } from "react-admin"
import { useWatch } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import { useCommonStyles, useStyles, useToken, useUniverseId } from "utils"

// @ts-ignore
import Model from "../../assets/files/modele.xlsx"
// @ts-ignore
import ModelCode from "../../assets/files/modele_code.xlsx"
import type { ConnectionType } from "../AddLearnerManualScreen/constants"
import ScreenWrapper from "../AddLearnerScreen/ScreenWrapper"
import { IMPORT_URL, ns, source } from "./constants"
import ErrorGrid from "./ErrorGrid"
import type { ErrorLine, ImportError, ImportResult } from "./interfaces"
import { isImportError } from "./interfaces"

interface Props {
  authMode?: ConnectionType
  errorLines: ErrorLine[] | undefined
  valid?: boolean
  setErrorLines: Dispatch<SetStateAction<ErrorLine[] | undefined>>
  validation: (e: File, dryRun: boolean) => Promise<{ valid: boolean; readyToImport?: number }>
}
const ImportLearnerContent: FC<Props> = ({
  authMode,
  errorLines,
  valid,
  setErrorLines,
  validation,
}) => {
  const t = useTranslate()
  const {
    palette: { text },
  } = useTheme()

  const importedFile = useWatch({ name: source })

  const [open, setOpen] = useState(false)

  const cs = useCommonStyles()
  const s = useStyles(({ spacing, palette: { action } }) => ({
    button: {
      alignSelf: "flex-start",
      borderColor: action.outlined,
      borderStyle: "solid",
      borderWidth: 1,
      padding: spacing(1, 2),
    },
    fileGrid: {
      position: "relative",
    },
    gridContainer: {
      display: "flex",
      gap: spacing(8),
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      gap: spacing(1),
    },
  }))

  const rightElement = useMemo(
    () => (
      <SaveButton
        alwaysEnable={!isEmpty(importedFile) && isEmpty(errorLines) && valid}
        disableElevation={true}
        disabled={isEmpty(importedFile) || !isEmpty(errorLines) || !valid}
        form="import-learner"
        icon={<div />}
        label={t(`${ns}.confirm`)}
        variant="contained"
      />
    ),
    [errorLines, valid, importedFile, t],
  )

  return (
    <ScreenWrapper
      bottomProps={{ rightElement, showPrevious: true }}
      topProps={{ title: `${ns}.headerTitle` }}
    >
      <div style={cs.childrenContainer}>
        <BoxWrapper>
          <Typography variant="h4">{t(`${ns}.title`)}</Typography>
          <Typography color={text.secondary} style={cs.marginSubtitle} variant="subtitle1">
            {t(`${ns}.subtitle`)}
          </Typography>
          <Grid container style={s.gridContainer}>
            <Grid item={true} xs={5} style={s.fileGrid}>
              <FileInputField {...{ ns, source, validation }} onRemove={() => setErrorLines([])} />
            </Grid>
            <Grid item={true} xs={5} style={s.gridItem}>
              <Typography variant="h6">{t(`${ns}.noFile.title`)}</Typography>
              <Typography color={text.secondary} variant="subtitle1">
                {t(`${ns}.noFile.subtitle`)}
              </Typography>
              <Button
                href={authMode === "email" ? Model : ModelCode}
                download={`Modele_import_dapprenants_-_connexion_avec_${
                  authMode === "email" ? "adresse_electronique_et_mot_de_passe" : "code_dacces"
                }.xlsx`}
                startIcon={<DownloadIcon style={cs.secondaryTextColor} />}
                style={s.button}
                variant="text"
              >
                {t(`${ns}.noFile.button`)}
              </Button>
              <Typography color={text.secondary} variant="body2">
                {t(`${ns}.noFile.instruction`)}
              </Typography>
            </Grid>
          </Grid>
          {isEmpty(errorLines) ? null : <ErrorGrid {...{ authMode, errorLines }} />}
        </BoxWrapper>
      </div>
      <NotImplemented {...{ open, setOpen }} />
    </ScreenWrapper>
  )
}

const ImportLearnerScreen: FC = () => {
  const notify = useNotify()
  const [token] = useToken()
  const [universeId] = useUniverseId()
  const navigate = useNavigate()
  const { state } = useLocation()
  const refresh = useRefresh()

  const [errorLines, setErrorLines] = useState<ErrorLine[] | undefined>([])
  const [valid, setValid] = useState(false)

  // True or false (false if error from axios result (xlsx) or axios request (try catch))
  const validation = useCallback(
    async (file: File, dryRun: boolean): Promise<{ valid: boolean; readyToImport?: number }> => {
      try {
        const result: AxiosResponse<ImportResult | ImportError> = await axios.postForm(
          IMPORT_URL,
          {
            file,
            connectionType: state.authMode,
            divisionId: state.divisionId,
            dryRun,
            roleType: "learner",
            universeId,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (isImportError(result.data)) {
          return { valid: false }
        } else {
          setErrorLines(result.data.errorLines)
          setValid(isEmpty(result.data.errorLines) && result.data.readyToImport !== 0)
          return {
            valid: isEmpty(result.data.errorLines) && result.data.readyToImport !== 0,
            readyToImport: result.data.readyToImport,
          }
        }
      } catch (e: any) {
        logger("Import validation error", e)
        if (A.isAxiosError(e)) {
          setValid(false)
          if (!dryRun) {
            notify(`${ns}.error`, { type: "warning" })
          }
          return { valid: false }
        }
      }
      setValid(false)
      return { valid: false }
    },
    [state.authMode, state.divisionId, notify, token, universeId],
  )

  const onSubmit = useCallback(
    async (values: { learners: File }) => {
      const result = await validation(values.learners, false)
      if (result.valid) {
        navigate("/confirmImportLearner", {
          state: {
            connectionType: state?.authMode,
            divisionId: state?.divisionId,
            divisionName: state?.divisionName,
            learners: result.readyToImport,
          },
        })
        refresh()
      }
    },
    [navigate, refresh, state?.authMode, state?.divisionId, state?.divisionName, validation],
  )
  return (
    <Form id="import-learner" {...{ onSubmit }} defaultValues={{ learners: null }}>
      <ImportLearnerContent
        authMode={state.authMode}
        {...{ errorLines, setErrorLines, validation, valid }}
      />
    </Form>
  )
}

export default ImportLearnerScreen
