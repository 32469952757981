import { TextField } from "@mui/material"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import type { FC } from "react"
import React, { useEffect } from "react"
import { useInput, useLocaleState } from "react-admin"
import { useWatch } from "react-hook-form"
import { useCommonStyles } from "utils"

import type { DateAndTimeProps } from "./DateInput"

export const TimeInput: FC<DateAndTimeProps> = ({ maxDate, minDate, ...rest }) => {
  const [locale] = useLocaleState()
  const { field } = useInput(rest)

  const startDate = useWatch({ name: "startDate" })
  const dueDate = useWatch({ name: "dueDate" })
  const hasError = dayjs(startDate).isAfter(dueDate)

  useEffect(() => {
    dayjs.locale(locale)
  }, [locale])

  const cs = useCommonStyles()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <TimePicker
        {...field}
        disabled={rest.disabled}
        InputProps={{ style: cs.textFieldHeight }}
        minTime={minDate ? dayjs(minDate) : undefined}
        maxTime={maxDate ? dayjs(maxDate) : undefined}
        disableIgnoringDatePartForTimeValidation={true}
        renderInput={params => <TextField {...params} error={hasError} variant="outlined" />}
      />
    </LocalizationProvider>
  )
}
