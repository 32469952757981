import CheckIcon from "@mui/icons-material/Check"
import { Typography, useTheme } from "@mui/material"
import BoxWrapper from "components/BoxWrapper"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useLocation, useNavigate } from "react-router-dom"
import { useStyles } from "utils"
import useLearnerCodes from "utils/useLearnerCodes"

import { iconSize } from "../../features/Constants"
import ScreenWrapper from "../AddLearnerScreen/ScreenWrapper"
import ConfirmActionRow from "./ConfirmActionRow"

const ns = "confirmImportLearner"
const ConfirmImportLearnerScreen: FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const t = useTranslate()
  const {
    palette: { text: globalThemeText },
  } = useTheme()

  const s = useStyles(({ spacing, palette: { success, text } }) => ({
    check: {
      alignSelf: "center",
      color: success.main,
      height: iconSize.SMALL,
      marginRight: spacing(2),
      width: iconSize.SMALL,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      rowGap: spacing(4),
    },
    info: {
      alignSelf: "center",
      color: text.secondary,
      height: iconSize.SMALL,
      marginRight: spacing(2),
      width: iconSize.SMALL,
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
  }))

  const { areCodesForDivisionLoading, exportCodes } = useLearnerCodes(
    state?.divisionId,
    state?.divisionName,
  )

  return (
    <ScreenWrapper
      bottomProps={{
        next: { hideIcon: true, label: t(`${ns}.close`), onClick: () => navigate("..") },
      }}
    >
      <BoxWrapper>
        <div style={s.column}>
          <Typography variant="h4">{t(`${ns}.title`)}</Typography>
          <div style={s.row}>
            <CheckIcon style={s.check} />
            <div>
              <Typography variant="h6">
                {t(`${ns}.totalLearners`, { total: state.learners })}
              </Typography>
              <Typography color={globalThemeText.secondary} variant="subtitle1">
                {t(`${ns}.delay`)}
              </Typography>
            </div>
          </div>
          {state.connectionType === "code" ? (
            <ConfirmActionRow
              description={t(`${ns}.codeDescription`)}
              disabled={areCodesForDivisionLoading}
              label={t(`${ns}.buttonCode`)}
              onClick={exportCodes}
              title={t(`${ns}.code`)}
            />
          ) : null}
          <ConfirmActionRow
            description={t(`${ns}.licenceDescription`)}
            label={t(`${ns}.buttonLicence`)}
            onClick={() => navigate("/adminPanel/licenses")}
            title={t(`${ns}.licence`)}
          />
        </div>
      </BoxWrapper>
    </ScreenWrapper>
  )
}

export default ConfirmImportLearnerScreen
