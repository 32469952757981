import type { BackDivision, Division, DivisionId } from "@newpv/js-common"
import type { SaveHandler } from "ra-core"
import {
  useCreateController,
  useDelete,
  useEditController,
  useNotify,
  useRefresh,
} from "react-admin"
import { useNavigate } from "react-router-dom"
export interface DivisionInfos {
  save?: SaveHandler<BackDivision>
  onDelete?: () => Promise<void>
  divisionData?: Partial<BackDivision & { id: DivisionId }>
  saving?: boolean
  isLoading: boolean
}

/** Create a division - wrapper around useCreateController from React Admin */
export const useDivisionCreate = (): DivisionInfos => {
  const {
    save,
    record: divisionData,
    isLoading: isEvalLoading,
    saving,
  } = useCreateController<BackDivision & { id: DivisionId }>({
    resource: `universe/division`,
    mutationOptions: { meta: { idField: "divisionId" } },
  })

  return {
    save,
    saving,
    isLoading: isEvalLoading,
    divisionData,
  }
}

/** Edit a division - wrapper around useEditController from React Admin */
export const useDivisionEdit = (): DivisionInfos => {
  /**
   * Contexts and hooks
   * */
  const notify = useNotify()
  const refresh = useRefresh()
  const navigate = useNavigate()
  const [deleteOne] = useDelete()

  const {
    save,
    record: divisionData,
    isLoading: isDivisionLoading,
    saving,
  } = useEditController<Division>({
    resource: `universe/division`,
    mutationMode: "optimistic",
    queryOptions: {
      meta: {
        idField: "divisionId",
      },
    },
  })

  const onDelete = (): Promise<void> =>
    deleteOne(
      `universe/division`,
      { id: divisionData?.id },
      {
        mutationMode: "optimistic",
        onSuccess: () => {
          navigate("..", { replace: true })
          // delay to not create a 404 error by trying to refetch the division we just deleted
          setTimeout(() => {
            refresh()
          }, 200)

          notify(`adminPanel.divisions.deleteSuccess`, {
            type: "success",
          })
        },
        onError: (e: any) => {
          notify(
            e.response?.data?.code
              ? `adminPanel.divisions.divisionDeleteError`
              : "ra.notification.http_error",
            {
              type: "error",
            },
          )
        },
      },
    )

  return {
    save,
    saving,
    onDelete,
    divisionData,
    isLoading: isDivisionLoading,
  }
}
