import { datadogLogs } from "@datadog/browser-logs"
import { datadogRum } from "@datadog/browser-rum"
import { DATADOG_SITE_EU1 } from "@datadog/datadog-ci/dist/constants.js"
import { getVersion } from "@newpv/js-common"

import packageJson from "../../package.json"
import { apiUrl, bffUrl, datadogClientToken, ddSampleRate } from "../features/Constants/index"

const { version } = getVersion(packageJson)

const start = (): void => {
  datadogLogs.init({
    clientToken: datadogClientToken,
    site: DATADOG_SITE_EU1,
    forwardErrorsToLogs: true,
    sessionSampleRate: ddSampleRate,
  })

  datadogRum.init({
    applicationId: "7dbb1779-3746-4a48-86bc-8fdd9509a478",
    clientToken: datadogClientToken,
    site: DATADOG_SITE_EU1,
    service: "suivi",
    env: process.env.NODE_ENV ? "dev" : "prd",
    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    sessionSampleRate: ddSampleRate,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [apiUrl, bffUrl],
  })

  if (ddSampleRate > 0) {
    // eslint-disable-next-line no-console
    console.log("Datadog web started")
    datadogRum.startSessionReplayRecording()
  } else {
    // eslint-disable-next-line no-console
    console.log("Datadog web nerfed")
  }
}

export default start
