import { TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"
import type { FC } from "react"
import React, { useEffect } from "react"
import { required, useInput, useLocaleState, useTranslate } from "react-admin"
import { useWatch } from "react-hook-form"
import { useCommonStyles } from "utils"

export interface DateAndTimeProps {
  label: string
  source: string
  name?: string
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  isCreation?: boolean
  displayError?: boolean
}

const validateDates = ({ startDate, dueDate, fieldName, isCreation }): string | undefined => {
  if (!dayjs(startDate).isValid() || !dayjs(dueDate).isValid()) {
    return "invalidDates"
  }

  if (isCreation && fieldName === "startDate" && dayjs(startDate).isBefore(dayjs())) {
    return "startDateLimit"
  }

  if (!dayjs(dueDate).isBefore(dayjs()) && fieldName === "dueDate") {
    return "dateIsPassed"
  }

  if (dayjs(startDate).isAfter(dueDate)) {
    return fieldName === "startDate" ? "wrongStartDate" : "wrongEndDate"
  }

  return
}

export const DateInput: FC<DateAndTimeProps> = ({
  label,
  source,
  minDate,
  name,
  maxDate,
  isCreation,
  disabled,
  displayError,
}) => {
  const [locale] = useLocaleState()
  const t = useTranslate()

  const { field } = useInput({ label, source, name, validate: required("ra.validation.required") })

  const startDate = useWatch({ name: "startDate" })
  const dueDate = useWatch({ name: "dueDate" })

  useEffect(() => {
    dayjs.locale(locale)
  }, [locale])

  const error = validateDates({ startDate, dueDate, fieldName: field.name, isCreation })

  const cs = useCommonStyles()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DatePicker
        {...field}
        disabled={disabled}
        minDate={minDate ? dayjs(minDate) : undefined}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
        InputProps={{ style: cs.textFieldHeight }}
        renderInput={params => (
          <TextField
            {...params}
            error={error !== undefined && !disabled && displayError}
            helperText={error && !disabled && displayError ? t(`fields.${error}`) : undefined}
            variant="outlined"
          />
        )}
      />
    </LocalizationProvider>
  )
}
