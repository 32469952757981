import { Typography } from "@mui/material"
import _ from "lodash"
import type { FC } from "react"
import React from "react"
import { useRecordContext } from "react-admin"
import type { RAFieldProps } from "utils"
import { useGradeColor } from "utils"

export const PercentField: FC<RAFieldProps> = ({ source }) => {
  const record = useRecordContext()

  const percent = _.floor(_.get(record, source, 0))

  const { getPercentColor } = useGradeColor()

  const color = getPercentColor(percent)

  return !record ? null : (
    <Typography variant="subtitle1" color={color}>{`${percent} %`}</Typography>
  )
}
