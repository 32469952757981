import { Checkbox, FormControlLabel } from "@mui/material"
import type { Division, DivisionId, UniverseId } from "@newpv/js-common"
import _ from "lodash"
import type { FC } from "react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useInput } from "react-admin"
import { useWatch } from "react-hook-form"
import { useCommonStyles, useStyles } from "utils"

import { ns, role } from "./constants"
import type { RoleContent } from "./useAdministratorFunctions"

interface Props {
  divisions: Division[]
  roleContent?: RoleContent
  universe: { id: UniverseId; name: string }
}

const validate = (ids: Record<DivisionId, boolean>): undefined | string =>
  Object.keys(_.pickBy(ids, id => id))?.length > 0 ? undefined : `${ns}.error.email`

const updateDivisionValues = (
  divisions: Division[],
  value: boolean,
  currentPermissions?: DivisionId[],
): Record<DivisionId, boolean> =>
  _.reduce(
    divisions,
    (res, division) => ({
      ...res,
      [division.id]: _.find(currentPermissions, ["id", division.id]) ? true : value,
    }),
    {},
  )
const PermissionCheckboxGroup: FC<Props> = ({ divisions, roleContent, universe }) => {
  const watchedRole = useWatch({ name: "role" })

  const {
    field: { onChange },
  } = useInput({ name: "divisionsIds", source: "divisionsIds", validate })

  const [divisionChecked, setDivisionChecked] = useState<Record<DivisionId, boolean>>(
    updateDivisionValues(divisions, false, roleContent?.divisionsIds),
  )
  const isUniverseChecked = useMemo(() => {
    const checkedIds = _(divisionChecked)
      .map((divisionId, key) => (divisionId ? key : undefined))
      .compact()
      .value()
    return _.difference(divisions?.map(d => d.id), checkedIds).length === 0
  }, [divisionChecked, divisions])
  const creatingUniverseAdmin = useMemo(() => watchedRole === role.UNIVERSE, [watchedRole])

  const handleUniverseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const values = updateDivisionValues(divisions, event.target.checked)
      setDivisionChecked(values)
      onChange(values)
    },
    [divisions, onChange],
  )

  const handleDivisionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: DivisionId): void => {
      setDivisionChecked(values => ({
        ...values,
        [id]: event.target.checked,
      }))
      onChange({ ...divisionChecked, [id]: event.target.checked })
    },
    [divisionChecked, onChange],
  )

  useEffect(() => {
    if (creatingUniverseAdmin) {
      const values = updateDivisionValues(divisions, true)
      setDivisionChecked(values)
      onChange(values)
    }
  }, [divisions, creatingUniverseAdmin, onChange])

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing, palette: { text, action, primary }, typography }) => ({
      checkbox: {
        "& .MuiCheckbox-root.Mui-checked": {
          color: creatingUniverseAdmin ? action.disabled : primary[500],
        },
        "& .MuiCheckbox-root.MuiCheckbox-indeterminate": {
          color: primary[500],
        },
        "& .MuiFormControlLabel-label": {
          color: text.secondary,
          ...typography.subtitle1,
        },
        "& .RaCheckboxGroupInputItem-checkbox": cs.marginLeft32,
      },
      group: {
        display: "flex",
        flexDirection: "column",
        marginLeft: spacing(4),
      },
    }),
    [creatingUniverseAdmin, cs.marginLeft32],
  )

  return (
    <div>
      {universe && divisions ? (
        <>
          <FormControlLabel
            disabled={creatingUniverseAdmin}
            control={
              <Checkbox
                checked={isUniverseChecked}
                disabled={creatingUniverseAdmin}
                indeterminate={!isUniverseChecked && !_.every(divisionChecked, dc => !dc)}
                onChange={handleUniverseChange}
              />
            }
            key={universe.id}
            label={universe.name}
            sx={s.checkbox}
          />
          <div style={s.group}>
            {divisions.map(division => (
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={creatingUniverseAdmin}
                    onChange={e => handleDivisionChange(e, division.id)}
                    checked={divisionChecked?.[division.id]}
                  />
                }
                disabled={creatingUniverseAdmin}
                key={division.id}
                label={division.name}
                sx={s.checkbox}
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default PermissionCheckboxGroup
