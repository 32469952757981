import type { Theme as MuiTheme } from "@mui/material"
import { useTheme } from "@mui/material"
import type { SxProps } from "@mui/system"
import type { CSSProperties, DependencyList } from "react"
import { useMemo } from "react"

import type { ThemeOptions } from "../features/Theme/theme"

/**
 * Inverse of a Partial
 * */
type Full<T> = { [P in keyof T]-?: T[P] }

type Theme = Full<ThemeOptions> & MuiTheme

/**
 * Return a memoized version of the current theme through the passed dependencies
 *
 * @param create Imperative function which returns the current theme
 * @param deps If present, effect will only activate if the values in the list change.
 *
 * @example
 *
 * const s = useStyles(({ spacing }) => ({
 *     div: { padding: spacing(0, 1) },
 *   }))
 * */
export const useStyles = <
  AppTheme extends Theme,
  T extends Record<string, CSSProperties | SxProps<Theme>>,
>(
  // eslint-disable-next-line no-shadow
  create: (theme: Theme & AppTheme) => T,
  deps: DependencyList = [],
): T => {
  const theme = useTheme<Theme & AppTheme>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => create(theme), [...deps, theme])
}
