import { logger } from "@newpv/js-common"
import { Empty } from "components"
import { ConfirmDialog } from "components/ConfirmDialog"
import type { FC } from "react"
import React, { useCallback, useState } from "react"
import {
  Button,
  Datagrid,
  FunctionField,
  TextField,
  useListContext,
  useTranslate,
} from "react-admin"
import type { SetState } from "utils/CommonTypes"

import { commonNs } from "../../../features/i18n/fr"
import { ns } from "./constants"
import type { Administrator } from "./useAdministratorFunctions"

const AdministratorBulkActionButton: FC<{ setOpen: SetState<boolean> }> = ({ setOpen }) => {
  const t = useTranslate()

  const onClick = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  return <Button label={t(`${ns}.revoke`)} {...{ onClick }} />
}

const AdministratorDataGrid: FC = () => {
  const t = useTranslate()
  const { data, isLoading } = useListContext<Administrator>()
  const [open, setOpen] = useState(false)

  const onClose = useCallback(() => setOpen(false), [])

  const onConfirm = useCallback(async () => {
    logger("revoke user's permission")
    setOpen(false)
  }, [])

  return (
    <>
      <ConfirmDialog namespace={ns} {...{ open, onClose, onConfirm }} />
      <Datagrid
        bulkActionButtons={<AdministratorBulkActionButton {...{ setOpen }} />}
        empty={<Empty isList={true} subtitle={t(`${ns}.empty`)} />}
        rowClick={id => id.toString()}
        {...{ data, isLoading }}
      >
        <TextField
          label={t(`${commonNs}.name`)}
          sortBy="name"
          source="lastName"
          variant="subtitle1"
          emptyText="-"
        />
        <TextField
          label={t(`${commonNs}.firstName`)}
          sortBy="firstName"
          source="firstName"
          variant="subtitle1"
          emptyText="-"
        />
        <TextField
          label={t(`${ns}.email`)}
          sortBy="email"
          source="email"
          variant="subtitle1"
          emptyText="-"
        />
        <FunctionField
          label={t(`${ns}.role`)}
          render={(record: Administrator) =>
            t(`${ns}.${record.role.role === "universeadmin" ? "universe" : "division"}`)
          }
          sortBy="role"
        />
        <FunctionField
          label={t(`${ns}.divisionsNumber`)}
          render={(record: Administrator) =>
            record.role.role === "universeadmin"
              ? t(`${ns}.all`)
              : `${record.role.divisionsIds?.length ?? 0}`
          }
        />
      </Datagrid>
    </>
  )
}

export default AdministratorDataGrid
