import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import { Tab, Tabs } from "@mui/material"
import { ContentContainer, ScreenContainer } from "components"
import EmptyScreen from "components/EmptyScreen"
import type { FC } from "react"
import { useMemo, useState } from "react"
import React from "react"
import { usePermissions, useTranslate } from "react-admin"
import { useCommonStyles, useDivisionId, useScenarioId, useUniverseId } from "utils"

import type { Permission } from "../../features/Providers"
import { Dashboard } from "./Dashboard"
import { StatsExport } from "./StatsExport"

const ns = "stats"
import { UniverseStatsGlobal } from "./UniverseStatsGlobal"

const StatsScreen: FC = () => {
  const t = useTranslate()
  const { permissions } = usePermissions<Permission[]>()
  const isUniverseAdmin = permissions.includes("universeAdmin")

  const valueToTab = useMemo(
    () =>
      isUniverseAdmin
        ? {
            0: "universe",
            1: "division",
            2: "exports",
          }
        : {
            0: "division",
            1: "exports",
          },
    [isUniverseAdmin],
  )

  const [value, setValue] = useState(0)

  const [universeId] = useUniverseId()
  const [divisionId] = useDivisionId()
  const [scenarioId] = useScenarioId()

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  const cs = useCommonStyles()

  const EmptyStatsScreen = useMemo(
    () => (
      <EmptyScreen
        title={t(`${ns}.${valueToTab[value] === "universe" ? "noData" : "noDataDivision"}`)}
        subtitle={t(
          `${ns}.${valueToTab[value] === "universe" ? "noDataSubtitle" : "noDataSubtitleDivision"}`,
        )}
      />
    ),
    [t, value, valueToTab],
  )
  return (
    <ScreenContainer
      icon={<TrendingUpIcon sx={cs.primary600} fontSize="large" />}
      title={t("stats.title")}
      topActionBar={
        <Tabs textColor="inherit" value={value} onChange={handleChange} aria-label="stats-tabs">
          {isUniverseAdmin ? <Tab label={t("stats.menu.universe")} /> : null}
          <Tab label={t("stats.menu.division")} />
          <Tab label={t("stats.menu.exports")} />
        </Tabs>
      }
    >
      <ContentContainer hasTopBar>
        {(!scenarioId || !divisionId || !universeId) && valueToTab[value] !== "exports" ? (
          EmptyStatsScreen
        ) : (
          <div style={cs.fullWidth}>
            {isUniverseAdmin ? <UniverseStatsGlobal selectedTab={valueToTab[value]} /> : null}
            {!scenarioId ? null : <Dashboard selectedTab={valueToTab[value]} />}
            <StatsExport selectedTab={valueToTab[value]} />
          </div>
        )}
      </ContentContainer>
    </ScreenContainer>
  )
}

export default StatsScreen
