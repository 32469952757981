import type { Group } from "@newpv/js-common"
import { MuiSelect } from "components"
import EmptyScreen from "components/EmptyScreen"
import { useLocalGroupListContext } from "features/Providers/LocalGroupListProvider"
import _ from "lodash"
import React, { type FC, useEffect } from "react"
import {
  CreateButton,
  Datagrid,
  FunctionField,
  List,
  TextField,
  useListContext,
  useNotify,
  usePermissions,
  useTranslate,
} from "react-admin"
import { Route, Routes } from "react-router-dom"
import { useDivisions } from "screens/Layout/hooks/useDivisions"
import { useDivisionId, useStyles } from "utils"

import { GroupDetailsScreen } from "./GroupDetailsScreen"
import { ListDeleteButton } from "./ListDeleteButton"

const ns = "adminPanel.groups"

const GroupDataGrid: FC = () => {
  const t = useTranslate()
  const { data, isLoading } = useListContext<Group>()
  const { setLocalGroupList } = useLocalGroupListContext()
  const { permissions } = usePermissions()

  useEffect(() => {
    if (data) {
      setLocalGroupList(data)
    }
  }, [data, setLocalGroupList])

  return (
    <Datagrid
      data={data}
      isLoading={isLoading}
      bulkActionButtons={<ListDeleteButton />}
      rowClick={id => id.toString()}
      empty={
        <EmptyScreen
          title={t(`${ns}.empty`)}
          subtitle={t(`${ns}.emptySubtitle`)}
          button={
            permissions.includes("universeAdmin") ? (
              <CreateButton
                icon={<></>}
                resource="adminPanel/groups"
                label={`${ns}.create`}
                variant="contained"
              />
            ) : undefined
          }
        />
      }
    >
      <TextField
        variant="subtitle1"
        sortable={true}
        source="name"
        label={t(`${ns}.name`)}
        sortBy="name"
      />
      <FunctionField
        label={t(`${ns}.nbOfLearners`)}
        render={(record: Group) => record?.learners?.length ?? "-"}
      />
    </Datagrid>
  )
}

export const GroupTable: FC = () => {
  const notify = useNotify()
  const { divisions } = useDivisions()
  const [localDivisionId, setLocalDivisionId] = useDivisionId()
  const { localGroupList } = useLocalGroupListContext()
  const s = useStyles(({ spacing }) => ({
    list: {
      "& .RaList-main": {
        boxShadow: "none",
      },
      "& .RaList-actions": {
        display: "none",
      },
      "& .RaList-content": {
        borderRadius: "2px",
      },
      "& .RaDatagrid-tableWrapper": {
        borderRadius: "2px",
        boxShadow: "none",
        overflowX: "hidden",
      },
    },

    boxGroup: {
      margin: spacing(0, 1),
      maxWidth: "250px",
      minWidth: "114px",
    },
    buttonRight: {
      boxShadow: "none",
      height: "36px",
      position: "absolute",
      right: spacing(2),
      top: -56,
      zIndex: 2,
    },
    main: {
      height: "100%",
      marginTop: spacing(2),
      position: "relative",
      width: "100%",
    },
  }))

  useEffect(() => {
    if (divisions.length === 1) {
      setLocalDivisionId(divisions?.[0].id)
    }
  }, [divisions, setLocalDivisionId])

  return (
    <div style={s.main}>
      {(divisions?.length ?? 0) === 0 ? null : (
        <MuiSelect
          id="divisions"
          variant="outlined"
          disabled={(divisions?.length ?? 0) === 1}
          value={localDivisionId ?? divisions?.[0].id}
          boxStyle={s.boxGroup}
          onValueChange={elem => {
            setLocalDivisionId(elem)
          }}
          items={divisions?.map(elem => ({ value: elem.id, text: elem.name }))}
        />
      )}
      {!_.isEmpty(localGroupList) ? (
        <CreateButton
          resource="adminPanel/groups"
          label="adminPanel.groups.create"
          variant="contained"
          style={s.buttonRight}
        />
      ) : null}

      <List<Group>
        actions={false}
        resource={`universe/division/${localDivisionId}/groups`}
        sx={s.list}
        exporter={false}
        empty={false}
        queryOptions={{
          onError: () => notify("ra.notification.http_error", { type: "error" }),
          meta: { idField: "groupId" },
          enabled: !!localDivisionId,
        }}
        sort={{ field: "name", order: "ASC" }}
      >
        <GroupDataGrid />
      </List>
      <Routes>
        <Route path="/create" element={<GroupDetailsScreen isCreation={true} />} />
        <Route path="/:id" element={<GroupDetailsScreen isCreation={false} />} />
      </Routes>
    </div>
  )
}
export default GroupTable
