import { TableCell, TableSortLabel } from "@mui/material"
import { styled } from "@mui/material/styles"
import clsx from "clsx"
import { FieldTitle, useResourceContext } from "ra-core"
import type { DatagridHeaderCellProps } from "ra-ui-materialui"
import type { ReactElement } from "react"
import * as React from "react"

export const CustomHeaderCell = (props: DatagridHeaderCellProps): ReactElement => {
  const { className, field, sort, updateSort, ...rest } = props
  const resource = useResourceContext(props)

  if (!field) {
    return <></>
  }

  return (
    <StyledTableCell
      className={clsx(className, field.props.headerClassName)}
      align={field.props.textAlign}
      variant="head"
      {...rest}
    >
      {updateSort &&
      field.props.sortable !== false &&
      (field.props.sortBy || field.props.source) ? (
        <TableSortLabel
          active={sort.field === (field.props.sortBy || field.props.source)}
          direction={sort.order === "ASC" ? "asc" : "desc"}
          data-field={field.props.sortBy || field.props.source}
          data-order={field.props.sortByOrder || "ASC"}
          onClick={updateSort}
          classes={DatagridHeaderCellClasses}
        >
          <FieldTitle label={field.props.label} source={field.props.source} resource={resource} />
        </TableSortLabel>
      ) : (
        <FieldTitle label={field.props.label} source={field.props.source} resource={resource} />
      )}
    </StyledTableCell>
  )
}

const PREFIX = "RaDatagridHeaderCell"

export const DatagridHeaderCellClasses = {
  icon: `${PREFIX}-icon`,
}

// Remove the sort icons when not active
const StyledTableCell = styled(TableCell, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  [`& .MuiTableSortLabel-icon`]: {
    display: "none",
  },
  [`& .Mui-active .MuiTableSortLabel-icon`]: {
    display: "inline",
  },
})
