import { Typography, useTheme } from "@mui/material"
import type { EvaluationContent } from "@newpv/js-common"
import { fetchLevel, fetchOneScenario, parseInt10 } from "@newpv/js-common"
import { Separator } from "components"
import type { ChangeEvent, FC } from "react"
import React from "react"
import {
  FormDataConsumer,
  Loading,
  required,
  SelectInput,
  useInput,
  useTranslate,
} from "react-admin"
import { formatDuration, useCommonStyles, useQueryRequest, useScenarioId, useStyles } from "utils"

export const ContentCreate: FC<{ isCreation?: boolean }> = ({ isCreation }) => {
  const t = useTranslate()
  const [scenarioId] = useScenarioId()

  const { field } = useInput({ source: "duration" })

  const { isLoading, data: contents } = useQueryRequest<
    Array<EvaluationContent & { rulesNbr: number }>
  >(async () => {
    const scenario = await fetchOneScenario(undefined, scenarioId)
    return Promise.all(
      scenario.evaluations.map(async evaluation => {
        const evalContentDetail = await fetchLevel(undefined, evaluation.id)

        return {
          ...evaluation,
          rulesNbr: evalContentDetail.rules.length,
        }
      }),
    )
  }, ["evaluations", "scenarioId", scenarioId])

  const {
    palette: {
      text: { secondary },
    },
  } = useTheme()

  const cs = useCommonStyles()

  const s = useStyles(({ spacing }) => ({
    commonInput: {
      margin: spacing(0, 2),
      maxWidth: "100%",
    },
  }))

  // The following rule is necessary to avoid form errors
  // noinspection RequiredAttributes
  return isLoading ? (
    <Loading />
  ) : (
    <>
      {!isCreation ? <Separator style={cs.marginVertical16} /> : null}
      <Typography style={{ ...s.commonInput, ...cs.marginBottom16 }} variant="subtitle1">
        {t("eval.evalModal.generalTab.content")}
      </Typography>
      <SelectInput
        label={t("eval.evalModal.generalTab.chooseContent")}
        optionText="title"
        variant="outlined"
        source="contentId"
        choices={contents}
        isLoading={!contents}
        style={s.commonInput}
        // changes specifically the duration field
        onChange={(change: ChangeEvent<HTMLInputElement>) => {
          field.onChange(
            contents?.find(elem => elem.id === parseInt10(change.target.value))?.timeLimit ?? 0,
          )
        }}
        validate={required("ra.validation.required")}
        disabled={!isCreation}
      />
      <FormDataConsumer>
        {({ formData }) => {
          const currentContent = contents?.find(elem => elem.id === formData.contentId)

          if (!currentContent) {
            return null
          }

          return (
            <>
              <Typography variant="body2" color={secondary} style={s.commonInput}>
                {`${formatDuration({
                  t,
                  duration: currentContent.timeLimit * 1000,
                  customFormat: "common.durationFormats.shortMin",
                })} · ${currentContent?.rulesNbr ?? 0} règles · ${
                  (currentContent?.rulesNbr ?? 0) * 2
                } exercices max`}
              </Typography>
            </>
          )
        }}
      </FormDataConsumer>
      <Separator style={cs.marginVertical16} />
    </>
  )
}
