import { Checkbox, useTheme } from "@mui/material"
import type { User, UserId } from "@newpv/js-common"
import _ from "lodash"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useMemo } from "react"
import { useNotify, useRecordContext } from "react-admin"
import type { RAFieldProps } from "utils"

import type { UserWithLicenses } from "./AdminLicensesScreen"

interface Props {
  nbOfAvailableLicenses: number
  setNewlySelectedIds: Dispatch<SetStateAction<UserId[]>>
  newlyUnselectedIds: UserId[]
  selectedUsersOnPage: UserId[]
  setNewlyUnselectedIds: Dispatch<SetStateAction<UserId[]>>
  globalDisable: boolean
  nbOfLicensesToRemove: number
  nbOfLicensesToAdd: number
}

export const AdminCheckboxField: FC<Partial<RAFieldProps<User>> & Props> = ({
  nbOfAvailableLicenses,
  newlyUnselectedIds,
  setNewlyUnselectedIds,
  setNewlySelectedIds,
  globalDisable,
  selectedUsersOnPage,
  nbOfLicensesToRemove,
  nbOfLicensesToAdd,
}) => {
  const notify = useNotify()
  const {
    palette: { secondary },
  } = useTheme()

  const record = useRecordContext<UserWithLicenses>()
  const userId = record.id

  const userIsNewlyUnselected = newlyUnselectedIds?.includes(userId)

  const checked = useMemo(
    () => !!_.find(selectedUsersOnPage, elem => elem === userId),
    [selectedUsersOnPage, userId],
  )

  const disabled = useMemo(
    () => globalDisable || (record.selectedLicense?.isDefinitive ?? false),
    [globalDisable, record],
  )

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // not enough licenses
    if (
      event.target.checked &&
      nbOfAvailableLicenses + nbOfLicensesToRemove - nbOfLicensesToAdd <= 0
    ) {
      notify("ra.notification.assign_licenses_err", { type: "error", autoHideDuration: 1500 })

      return
    }

    // if enough licenses, we change the status
    if (event.target.checked) {
      setNewlyUnselectedIds(prev => _.without(prev, userId))
      if (!record.selectedLicense) {
        setNewlySelectedIds(prev => [...prev, userId])
      }
    } else {
      // in case it was a newly selected user
      setNewlySelectedIds(prev => _.without(prev, userId))
      // in case the user had a license, so it's a newly unselected user
      if (record.selectedLicense) {
        setNewlyUnselectedIds(prev => [...prev, userId])
      }
    }
  }

  return (
    <Checkbox
      sx={{ color: userIsNewlyUnselected ? secondary.main : undefined }}
      {...{ onChange, disabled, checked }}
    />
  )
}
