import { Typography, useTheme } from "@mui/material"
import type { ISOTime } from "@newpv/js-common"
import { Empty } from "components"
import dayjs from "dayjs"
import type { FC, ReactElement } from "react"
import React from "react"
import { useStyles } from "utils"

import { DATE_FORMAT_LNG } from "../../../features/Constants"
import type { UserWithLicenses } from "./AdminLicensesScreen"

interface Props {
  learnerData?: UserWithLicenses
}

const RowItem: FC<{ resourceName?: string; startDate: ISOTime; endDate: ISOTime }> = ({
  resourceName,
  startDate,
  endDate,
}): ReactElement => {
  const { palette } = useTheme()

  const s = useStyles(({ spacing, palette: { surface } }) => ({
    rowItem: {
      alignItems: "flex-start",
      borderBottom: `1px solid ${surface.outline}`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: spacing(1),
      padding: spacing(1.5, 2),
    },
  }))

  return (
    <div style={s.rowItem}>
      <Typography variant="body2">{resourceName ?? "-"}</Typography>
      <Typography color={palette.text.secondary} variant="caption">
        {`${dayjs(startDate).format(DATE_FORMAT_LNG)} - ${dayjs(endDate).format(DATE_FORMAT_LNG)}`}
      </Typography>
    </div>
  )
}
export const LicenseTab: FC<Props> = ({ learnerData }) => {
  const s = useStyles(
    ({ spacing }) => ({
      container: {
        paddingBottom: spacing(2),
      },
    }),
    [],
  )

  return (
    <div style={s.container}>
      {!learnerData?.licenses || learnerData?.licenses.length === 0 ? (
        <Empty />
      ) : (
        learnerData?.licenses.map((license, index) => (
          <RowItem
            key={index}
            resourceName={license.resourceName}
            startDate={license.startDate}
            endDate={license.endDate}
          />
        ))
      )}
    </div>
  )
}
