import { AccessTime } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import GroupsIcon from "@mui/icons-material/Groups"
import InfoIcon from "@mui/icons-material/Info"
import StarIcon from "@mui/icons-material/Star"
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import type { TooltipProps } from "@mui/material/Tooltip"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import type { ResourceId, ScenarioId, UniverseProgression } from "@newpv/js-common"
import { Empty, MuiSelect } from "components"
import dayjs from "dayjs"
import _ from "lodash"
import type { Dispatch, FC, ReactElement, SetStateAction } from "react"
import { useEffect } from "react"
import { useState } from "react"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { Loading, useTranslate } from "react-admin"
import { useResourceId, useScenarioId, useStyles } from "utils"
import { useResources } from "utils/useResources"

import type { ThemeOptions } from "../../features/Theme/theme"
import { getScenarioId } from "../Layout/AppBar"
import { useUniverseStats } from "./hooks/useUniverseStats"
import { universeStatsNs } from "./UniverseStatsGlobal"

const CustomTooltip = styled(
  ({
    className,
    textColor: _textColor,
    customColor: _customColor,
    ...props
  }: TooltipProps & { customColor: string; textColor: string }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ),
)(({ customColor, textColor }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: customColor,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: customColor,
    color: textColor,
  },
}))

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  universeProgress?: UniverseProgression
}

const UniverseStatsDetailed: FC<Props> = ({ isOpen, setIsOpen, universeProgress }) => {
  const t = useTranslate()

  const [globalScenarioId] = useScenarioId()
  const [globalResourceId] = useResourceId()

  const { resources } = useResources()

  const [localScenarioId, setLocalScenarioId] = useState<ScenarioId | undefined>(globalScenarioId)
  const [localResourceId, setLocalResourceId] = useState<ResourceId | undefined>(globalResourceId)

  useEffect(() => {
    setLocalScenarioId(globalScenarioId)
    setLocalResourceId(globalResourceId)
  }, [globalResourceId, globalScenarioId])

  const {
    palette: { primary, neutral, text: themeText },
  } = useTheme<ThemeOptions>()

  const { universeProgress: localUniverseProgress, isLoading } = useUniverseStats({
    scenarioId: localScenarioId,
    resourceId: localResourceId,
  })

  const finalUniverseProgress = useMemo(
    () => localUniverseProgress ?? universeProgress,
    [localUniverseProgress, universeProgress],
  )

  const s = useStyles(
    ({ palette: { text, surface, divider, secondary }, spacing }) => ({
      main: { flex: 1, overflow: "auto" },
      dialogDiv: {
        borderRadius: "8px",
        maxHeight: 587,
        maxWidth: 1180,
      },
      icon: {
        color: text.secondary,
      },
      header: {
        display: "flex",
        justifyContent: "space-between",
        padding: spacing(2),
        width: "100%",
      },
      topSection: {
        display: "flex",
        padding: spacing(1, 8, 2, 8),
        width: "100%",
        maxWidth: "920px",
        justifyContent: "space-between",
        flexWrap: "wrap",
        margin: "auto",
        marginVertical: spacing(2),
      },
      topCard: {
        border: "1px solid",
        borderColor: surface.outline,
        borderRadius: "8px",
        padding: spacing(2),
        margin: spacing(0, 2),
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        maxWidth: "230px",
      },
      topIcon: { fontSize: "32px", color: secondary?.[300] },
      tooltip: { color: "black" },
      tableHeader: {
        fontSize: "12px",
      },
      commonProgress: { height: "12px", position: "absolute" },
      progressContainer: {
        width: "90%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
      },
      bottomContainer: {
        borderTop: "1px solid",
        borderTopColor: divider,
        widh: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: spacing(1, 3),
      },
      bottomItem: { display: "flex", alignItems: "center", margin: spacing(1) },
      spot: {
        width: "40px",
        height: "16px",
        borderRadius: "24px",
        marginRight: spacing(1),
      },
      boxResource: {
        minWidth: "275px",
        padding: spacing(2),
        width: "min-content",
      },
      headerWithTooltip: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      },
      centerText: {
        textAlign: "center",
      },
    }),
    [],
  )

  const avgTrainingDuration = useMemo(() => {
    if (!finalUniverseProgress?.avgTrainingDuration) {
      return 0
    }
    const convertedTime = dayjs.duration(finalUniverseProgress.avgTrainingDuration, "milliseconds")
    return convertedTime.format(
      t(`common.durationFormats.${convertedTime.hours() < 1 ? "shortMin" : "long"}`),
    )
  }, [t, finalUniverseProgress?.avgTrainingDuration])

  const topContents = useMemo(
    () => [
      {
        label: finalUniverseProgress?.nbLearners ?? 0,
        subtitle: t(`${universeStatsNs}.learners`),
        icon: <GroupsIcon sx={s.topIcon} />,
      },
      {
        label: avgTrainingDuration,
        subtitle: t(`${universeStatsNs}.averageTraining`),
        icon: <AccessTime sx={s.topIcon} />,
      },
      {
        label: t(`${universeStatsNs}.percentage`, {
          value: _.floor(finalUniverseProgress?.avgCompletionPercentage ?? 0),
        }),
        subtitle: t(`${universeStatsNs}.averageProgress`),
        icon: <StarIcon sx={s.topIcon} />,
      },
    ],
    [
      avgTrainingDuration,
      s.topIcon,
      t,
      finalUniverseProgress?.avgCompletionPercentage,
      finalUniverseProgress?.nbLearners,
    ],
  )

  const renderTopCard = useCallback(
    (label: string | number, subtitle: string, icon: ReactElement) => (
      <div key={subtitle} style={s.topCard}>
        {icon}
        <Typography style={s.centerText} variant="subtitle1">
          {label}
        </Typography>
        <Typography style={s.centerText} variant="caption">
          {subtitle}
        </Typography>
      </div>
    ),
    [s],
  )

  const bottomContents = useMemo(
    () => [
      {
        label: t(`${universeStatsNs}.preKnownRules`),
        color: primary?.[300],
      },
      {
        label: t(`${universeStatsNs}.knownRules`),
        color: primary?.[400],
      },
      {
        label: t(`${universeStatsNs}.totalRules`),
        color: neutral?.[50],
      },
    ],
    [t, neutral, primary],
  )

  const renderBottomItem = useCallback(
    (label: string | number, color: string) => (
      <div key={label} style={s.bottomItem}>
        <div style={{ ...s.spot, backgroundColor: color }} />
        <Typography variant="caption">{label}</Typography>
      </div>
    ),
    [s],
  )

  const onClose = useCallback(() => {
    setIsOpen(false)
    setLocalScenarioId(globalScenarioId)
    setLocalResourceId(globalResourceId)
  }, [globalResourceId, globalScenarioId, setIsOpen])

  const emptyResources = useMemo(
    () => [
      {
        value: 0,
        text: t("menu.no_resources"),
      },
    ],
    [t],
  )

  return (
    <Dialog
      fullScreen={true}
      open={isOpen}
      onClose={onClose}
      // Cannot use the "sx" or "style" prop, as the PaperStyle override this style
      PaperProps={{ style: s.dialogDiv }}
    >
      <div style={s.main}>
        <div style={s.header}>
          <Typography variant="h6">{t(`${universeStatsNs}.detailedStats`)}</Typography>
          <CloseIcon cursor="pointer" style={s.icon} onClick={onClose} />
        </div>
        <MuiSelect
          id="resource"
          variant="outlined"
          value={localResourceId}
          boxStyle={s.boxResource}
          onValueChange={elem => {
            const resource = resources?.find(sc => sc.resourceId === elem)
            setLocalResourceId(elem)
            setLocalScenarioId(getScenarioId(resource))
          }}
          items={
            _.isEmpty(resources)
              ? emptyResources
              : resources?.map(resource => ({
                  value: resource.resourceId,
                  text: resource.resourceName,
                }))
          }
          disabled={(resources?.length ?? 0) <= 1}
        />
        {isLoading ? (
          // TODO: better loading states
          <Loading />
        ) : (
          <>
            <div style={s.topSection}>
              {topContents.map(({ label, subtitle, icon }) => renderTopCard(label, subtitle, icon))}
            </div>
            {!finalUniverseProgress?.divisions?.length ? (
              <Empty isList={true} subtitle={t(`${universeStatsNs}.empty`)} />
            ) : (
              <TableContainer>
                <Table aria-label="universe stats">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={s.tableHeader}> {t(`${universeStatsNs}.header0`)}</TableCell>
                      <TableCell sx={s.tableHeader}>
                        <div style={s.headerWithTooltip}>
                          {t(`${universeStatsNs}.header1`)}
                          <Tooltip title={t(`${universeStatsNs}.header1Info`)}>
                            <InfoIcon color="disabled" />
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell sx={s.tableHeader}>{t(`${universeStatsNs}.header2`)}</TableCell>
                      <TableCell sx={s.tableHeader}>{t(`${universeStatsNs}.header3`)}</TableCell>
                      <TableCell sx={s.tableHeader}>{t(`${universeStatsNs}.header4`)}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {finalUniverseProgress?.divisions?.map(division => {
                      const averagePreKnownRulesPercent = Math.floor(division.initialLevel)

                      const averageNewKnownRulesPercent = Math.floor(
                        division.completionPercentage - division.initialLevel,
                      )

                      const remainingProgressToAchieve =
                        100 - Math.floor(division.completionPercentage)

                      const formatAvgTrainingDuration = dayjs.duration(
                        division.avgTrainingDuration,
                        "ms",
                      )
                      return (
                        <TableRow key={division.divisionName}>
                          <TableCell>{division.divisionName}</TableCell>
                          <TableCell>{`${division.nbLearners}/${division.nbLicenses}`}</TableCell>
                          <TableCell>{`${division.nbLearnersOneHour}/${division.nbLicenses}`}</TableCell>
                          <TableCell>
                            {division.avgTrainingDuration === 0
                              ? " - "
                              : formatAvgTrainingDuration.format(
                                  t(
                                    `common.durationFormats.${
                                      formatAvgTrainingDuration.hours() < 1 ? "shortMin" : "long"
                                    }`,
                                  ),
                                )}
                          </TableCell>
                          <TableCell>
                            {division.avgRulesTotal ? (
                              <div style={s.progressContainer}>
                                <div
                                  style={{
                                    width: "100%",
                                    borderRadius: "24px",
                                    overflow: "hidden",
                                    ...s.commonProgress,
                                  }}
                                >
                                  <CustomTooltip
                                    arrow={true}
                                    customColor={neutral[50]}
                                    textColor={themeText?.primary ?? ""}
                                    title={t(`${universeStatsNs}.tooltip_total`, {
                                      value: remainingProgressToAchieve,
                                    })}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        backgroundColor: neutral[50],
                                        ...s.commonProgress,
                                      }}
                                    />
                                  </CustomTooltip>
                                  <CustomTooltip
                                    arrow={true}
                                    customColor={primary?.["400"]}
                                    textColor={themeText?.primary ?? ""}
                                    title={t(`${universeStatsNs}.tooltip_known`, {
                                      value: averageNewKnownRulesPercent,
                                    })}
                                    sx={s.tooltip}
                                  >
                                    <div
                                      style={{
                                        left: `${averagePreKnownRulesPercent}%`,
                                        width: `${averageNewKnownRulesPercent}%`,
                                        backgroundColor: primary?.["400"],
                                        ...s.commonProgress,
                                      }}
                                    />
                                  </CustomTooltip>
                                  <CustomTooltip
                                    arrow={true}
                                    customColor={primary?.["300"]}
                                    textColor={themeText?.primary ?? ""}
                                    title={t(`${universeStatsNs}.tooltip_preKnown`, {
                                      value: averagePreKnownRulesPercent,
                                    })}
                                  >
                                    <div
                                      style={{
                                        width: `${averagePreKnownRulesPercent}%`,
                                        backgroundColor: primary?.["300"],
                                        ...s.commonProgress,
                                      }}
                                    />
                                  </CustomTooltip>
                                </div>
                              </div>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </div>
      <div style={s.bottomContainer}>
        {bottomContents.map(({ label, color }) => renderBottomItem(label, color))}
      </div>
    </Dialog>
  )
}

export default UniverseStatsDetailed
