import type { DivisionProgressionForScenarioRule } from "@newpv/js-common"
import { axios, parseInt10 } from "@newpv/js-common"
import _ from "lodash"
import { stringify } from "query-string"
import type { DataProvider } from "ra-core"
import { fetchUtils } from "ra-core"
import type { QueryKey } from "react-query"
import { getAuth } from "utils"
import { searchWords } from "utils/search"

import { queryClient } from "../../index"
/**
 * If necessary, use another ID field and/or another request method.
 *
 * @example
 *
 * { idField: evaluationId, altMethod: "PATCH" }
 */
interface MetaParams {
  idField?: string
  altMethod?: string
  isLocal?: boolean
  all?: boolean
  extraSortField?: string
}

// unfortunately the data provider is not a hook, so it can't use the proper storage values
// https://github.com/marmelab/react-admin/pull/8226
export const dataProviderToken = { current: "" }

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @param apiUrl
 *
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts?id=123&id=456&id=789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 */
export const dataProvider = (apiUrl: string): DataProvider => ({
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const meta = params.meta as MetaParams

    const query = meta?.isLocal
      ? { scenarioId: params.filter.scenarioId }
      : {
          ...fetchUtils.flattenObject(params.filter),
          // TODO: check for regressions here
          _sort: meta?.idField === "evaluationId" && field === "id" ? "name" : field,
          _order: order,
          _start: (page - 1) * perPage,
          _end: page * perPage,
          page,
          pageSize: meta?.all ? -1 : perPage,
        }

    const url = `${apiUrl}/${resource}?${stringify(query)}`

    if (meta?.isLocal) {
      const queryKey: QueryKey = [resource, "scenarioId", params.filter.scenarioId, "getList"]
      let searchFilteredList = queryClient
        .getQueryCache()
        .find<DivisionProgressionForScenarioRule[]>(queryKey)?.state?.data

      if (searchFilteredList == null) {
        searchFilteredList = (await axios.get(url, getAuth(dataProviderToken.current))).data

        queryClient.setQueryData(queryKey, () => searchFilteredList)
      }

      if (params.filter.choice) {
        const choices = params.filter.choice.split("_")
        const selectedModuleId = parseInt10(choices[0])
        const selectedLevelId = parseInt10(choices[1])
        searchFilteredList = _.filter(
          searchFilteredList,
          sfData =>
            sfData.moduleId === selectedModuleId &&
            (_.isNaN(selectedLevelId) || selectedLevelId === sfData.levelId),
        )
      }

      if (params.filter.search) {
        searchFilteredList = _.filter(
          searchFilteredList,
          (rule: DivisionProgressionForScenarioRule) =>
            _.every(searchWords(params.filter.search), word =>
              searchWords(rule.title).includes(word),
            ),
        )
      }

      const sortOrder = order.toLowerCase() as "asc" | "desc"
      searchFilteredList =
        field === "moduleTitle"
          ? _.orderBy(
              searchFilteredList,
              ["moduleId", "levelId", "name"],
              [sortOrder, sortOrder, "asc"],
            )
          : _.orderBy(
              searchFilteredList,
              [field, meta?.extraSortField ?? "name"],
              [sortOrder, meta?.extraSortField ? sortOrder : "asc"],
            )

      return {
        data: searchFilteredList,
        total: _.size(searchFilteredList),
      }
    }

    const data = (await axios.get(url, getAuth(dataProviderToken.current))).data

    const idField = meta?.idField
    if (idField) {
      data.results = data.results.map(item => ({ ...item, id: item[idField] }))
    }

    return {
      data: data.results,
      total: data.total,
    }
  },

  getOne: async (resource, params) => {
    const meta = params.meta as MetaParams

    if (!params.id) {
      return { data: undefined }
    }

    let { data } = await axios.get(
      `${apiUrl}/${resource}/${params.id}`,
      getAuth(dataProviderToken.current),
    )

    const idField = meta?.idField
    if (idField) {
      data = { ...data, id: data[idField] }
    }

    return {
      data,
    }
  },

  getMany: async (resource, params) => {
    const query = {
      id: params.ids,
    }

    const { data } = await axios.get(
      `${apiUrl}/${resource}?${stringify(query)}`,
      getAuth(dataProviderToken.current),
    )

    return { data }
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    }

    const { data } = await axios.get(
      `${apiUrl}/${resource}?${stringify(query)}`,
      getAuth(dataProviderToken.current),
    )

    return {
      data: data.results,
      total: data.total,
    }
  },

  create: async (resource, params) => {
    const meta = params.meta as MetaParams
    const res = await axios(`${apiUrl}/${resource}`, {
      method: "POST",
      data: params.data,
      ...getAuth(dataProviderToken.current),
    })

    const idField = meta?.idField

    return { data: { ...res.data, id: idField ? res.data[idField] : res.data.id } }
  },

  update: async (resource, params) => {
    const meta = params.meta as MetaParams
    const res = await axios(`${apiUrl}/${resource}/${params.id}`, {
      method: meta?.altMethod ?? "PUT",
      data: params.data,
      ...getAuth(dataProviderToken.current),
    })

    return { ...res, data: { ...res.data, id: params.id } }
  },

  // json-server doesn't handle filters on UPDATE route, so we fall back to calling UPDATE n times instead
  updateMany: async (resource, params) => {
    const res = await Promise.all(
      params.ids.map(async id => {
        const { data } = await axios.put(
          `${apiUrl}/${resource}/${id}`,
          params.data,
          getAuth(dataProviderToken.current),
        )

        return data
      }),
    )

    return { data: res }
  },

  delete: async (resource, params) =>
    axios(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
      ...getAuth(dataProviderToken.current),
    }),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: async (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        axios(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
          ...getAuth(dataProviderToken.current),
        }),
      ),
    ).then(responses => ({ data: responses.map(({ data }) => data.id) })),
})
