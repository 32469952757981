import ActionDelete from "@mui/icons-material/Delete"
import { alpha } from "@mui/material/styles"
import type { FC } from "react"
import React from "react"
import {
  Button,
  useDeleteMany,
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
} from "react-admin"
import { useStyles, useUniverseId } from "utils"

export const ListDeleteButton: FC = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [universeId] = useUniverseId()
  const { selectedIds } = useListContext()
  const [deleteMany, { isLoading }] = useDeleteMany()

  /**
   * Unselect is manual because the targeted list is different from the query path
   * */
  const unselectAll = useUnselectAll(`evaluation/universe/${universeId}/evaluation`)

  const handleClick = async (): Promise<void> =>
    deleteMany(
      "evaluation/evaluation",
      {
        ids: selectedIds,
      },
      {
        onSuccess: () => {
          notify("ra.notification.deleted", {
            type: "error",
            messageArgs: { smart_count: selectedIds.length },
          })
          unselectAll()
          // Refresh is not automatic on non-related lists
          refresh()
        },
        onError: (e: any) => {
          notify(`ra.notification.${e.response?.data?.code ? "eval_delete_err" : "http_error"}`, {
            type: "error",
          })
          refresh()
        },
        // The undoable mode is not possible for the moment, as the paths are different
        mutationMode: "pessimistic",
      },
    )

  const s = useStyles(({ palette }) => ({
    button: {
      color: palette.error.main,
      "&:hover": {
        backgroundColor: alpha(palette.error.main, 0.12),
        // Reset on mouse devices
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  }))

  return (
    <Button sx={s.button} disabled={isLoading} onClick={handleClick} label="ra.action.delete">
      <ActionDelete />
    </Button>
  )
}
