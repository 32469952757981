import { Typography } from "@mui/material"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useStyles } from "utils"

const TopSection: FC = () => {
  const t = useTranslate()

  const s = useStyles(({ palette: { divider, text }, spacing }) => ({
    subtitle: {
      color: text.secondary,
      marginBottom: spacing(1.5),
      marginLeft: spacing(2),
      marginRight: spacing(2),
      marginTop: spacing(1),
    },
    separator: {
      backgroundColor: divider,
      height: 1,
      width: "100%",
    },
  }))

  return (
    <div>
      <Typography style={s.subtitle} variant="body2">
        {t(`rules.description`)}
      </Typography>
      <div style={s.separator} />
    </div>
  )
}

export default TopSection
