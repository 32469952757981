import type { CSSProperties, FC } from "react"
import React from "react"
import { useStyles } from "utils"

/**
 * A simple section separator
 */
export const Separator: FC<{ style?: CSSProperties }> = ({ style }) => {
  const s = useStyles(
    ({ palette }) => ({
      div: {
        ...style,
        backgroundColor: palette.divider,
        minHeight: 1,
        width: "100%",
      },
    }),
    [style],
  )

  return <div style={s.div} />
}
