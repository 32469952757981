import { type AuthenticationType, decodeJwt, getCurrentUser } from "@newpv/js-common"
import axios from "axios"
import _ from "lodash"
import { useCallback, useEffect, useRef, useState } from "react"
import type { AuthProvider } from "react-admin"
import { useResetStore } from "react-admin"
import { useAuthType, useDivisionIds, useMode, useToken, useUniverseId, useUserInfos } from "utils"
import { logger } from "utils/logger"

import { apiUrl } from "../Constants"
import { dataProviderToken } from "./dataProvider"

const axiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
})

interface LoginIds {
  username: string
  password: string
}

interface LoginToken {
  token: string
  mode?: Mode
}

type Login = LoginIds | LoginToken

export type Permission = "licenses" | "stats" | "universeAdmin" | "ENT"

export type Mode = "licenses" | undefined

/**
 * Handles all the auth operations. Requires the store hooks functions to work properly.
 */
export const useAuthProvider = (): AuthProvider => {
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [_divisionIds, setDivisionIds] = useDivisionIds()
  const [_universeId, setUniverseId] = useUniverseId()
  const [userInfos, setUserInfos] = useUserInfos()
  const [authType, setAuthType] = useAuthType()
  const authTypeRef = useRef<AuthenticationType>()
  const [token, setToken] = useToken()
  const [mode, setMode] = useMode()
  const resetStore = useResetStore()

  useEffect(() => {
    dataProviderToken.current = token
  }, [token])

  const login = useCallback(
    async (l: Login) => {
      try {
        setIsLoggingIn(true)
        resetStore()
        let jwt
        if ("token" in l) {
          jwt = l.token
        } else {
          const res = await axiosInstance.post<{ jwt: string }>("/authentication/login", {
            username: l.username,
            password: l.password,
          })
          if (res.status !== 200) {
            return Promise.reject(new Error("Login returned an error"))
          }
          jwt = res.data.jwt
        }
        const currentUser = await getCurrentUser(`Bearer ${jwt}`)

        if (!currentUser) {
          // eslint-disable-next-line no-console
          console.log("No user infos")
          return
        }
        const decodedJwt = decodeJwt(jwt)

        if (_.isEmpty(currentUser.roles)) {
          return Promise.reject(new Error("error.noRole"))
        }

        if (currentUser.roles[0].role !== "supervisor") {
          return Promise.reject(new Error("error.supervisor"))
        }

        const currentUserDivisionIds = currentUser.roles?.[0]?.divisionsIds

        /**
         * Consider userInfos holds the necessary login information if it possesses a role
         */
        setUserInfos(currentUser)
        setUniverseId(currentUser.roles?.[0]?.universeId)
        setToken(jwt)
        setAuthType(decodedJwt?.identities[0].authenticationType ?? "credentials")
        authTypeRef.current = decodedJwt?.identities[0].authenticationType ?? "credentials"
        if ("mode" in l) {
          setMode(l.mode)
          // we don't want to set the divisionIds if we are in licenses mode (ED)
          if (currentUserDivisionIds && l.mode !== "licenses") {
            setDivisionIds(currentUserDivisionIds)
          }
        } else {
          if (currentUserDivisionIds) {
            setDivisionIds(currentUserDivisionIds)
          }
        }

        return Promise.resolve()
      } catch (e) {
        logger(e)
        return Promise.reject({ message: "error.login" })
      } finally {
        setIsLoggingIn(false)
      }
    },
    [resetStore, setAuthType, setDivisionIds, setMode, setToken, setUniverseId, setUserInfos],
  )

  const logout = useCallback(() => {
    if (!isLoggingIn) {
      resetStore()
    }
    return Promise.resolve()
  }, [isLoggingIn, resetStore])

  /** each time the user navigates to a list, edit, create or show page, react-admin calls the authProvider.checkAuth() method.
   * If this method returns a rejected Promise, react-admin calls authProvider.logout() and redirects the user to the login page */
  const checkAuth = useCallback(async () => {
    if (!token) {
      logger("No token in checkAuth function")
      return Promise.reject(
        isLoggingIn
          ? {
              message: false,
            }
          : authTypeRef.current === "credentials"
          ? { message: "error.logout" }
          : authTypeRef.current === "gar" || authTypeRef.current === "ecoledirecte"
          ? { message: "error.entLogout" }
          : {},
      )
    }

    return Promise.resolve()
  }, [isLoggingIn, token])

  const checkError = useCallback(
    status => {
      if (status === 401) {
        resetStore()
        return Promise.reject(new Error("Http status 401"))
      }
      /**
       * other errors (403, 404, 500, etc.) should not log out the user
       */
      return Promise.resolve()
    },
    [resetStore],
  )

  const getIdentity = useCallback(() => {
    const id = userInfos?.userId
    if (!id) {
      return Promise.reject(new Error("No ID"))
    }
    return Promise.resolve({ id })
  }, [userInfos])

  const getPermissions = useCallback((): Promise<Permission[]> => {
    if (!authType || !userInfos || _universeId == null) {
      return Promise.resolve([])
    }

    const permissions: Permission[] = []

    const currentUniverseRole = _.find(userInfos.roles, role => role.universeId === _universeId)

    if (
      currentUniverseRole &&
      currentUniverseRole.role === "supervisor" &&
      currentUniverseRole.divisionsIds == null
    ) {
      permissions.push("universeAdmin")
    }

    // recognise ENT admins
    if (["gar", "ecoledirecte"].includes(authType)) {
      permissions.push("ENT")
    }

    if (authType === "ecoledirecte" && mode === "licenses") {
      permissions.push("licenses")
    } else {
      permissions.push("stats")
    }

    return Promise.resolve(permissions)
  }, [_universeId, authType, mode, userInfos])

  return {
    login,
    logout,
    checkAuth,
    checkError,
    getIdentity,
    getPermissions,
  }
}
