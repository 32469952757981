import React from "react"
import { useLocation } from "react-router-dom"

/**
 * Search for a param in a URL
 */
export const useQueryParams = (): URLSearchParams => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
