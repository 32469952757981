import { Grid, Typography, useTheme } from "@mui/material"
import { logger } from "@newpv/js-common"
import type { AxiosError } from "axios"
import axios from "axios"
import BoxWrapper from "components/BoxWrapper"
import type { FC } from "react"
import React, { useCallback, useEffect, useMemo } from "react"
import { CreateBase, Form, SaveButton, useNotify, useRefresh, useTranslate } from "react-admin"
import { useFormContext } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import { useCommonStyles, useStyles, useUniverseId } from "utils"

import ScreenWrapper from "../AddLearnerScreen/ScreenWrapper"
import ConnectionTypeGroup from "./ConnectionTypeGroup"
import type { ConnectionType } from "./constants"
import { ns } from "./constants"
import PersonalInformationGroup from "./PersonalInformationGroup"

interface LearnerCreationFormFieldValues {
  connectionType: ConnectionType
  email?: string
  firstName: string
  lastName: string
}

const defaultValues = {
  connectionType: "email",
  email: null,
  firstName: null,
  lastName: "",
}

const AddLearnerManualContent: FC = () => {
  const t = useTranslate()
  const {
    palette: { text },
  } = useTheme()

  const { reset, formState } = useFormContext()

  const cs = useCommonStyles()
  const s = useStyles(({ spacing, palette: { background, action } }) => ({
    buttonContainer: {
      display: "flex",
      flex: 1,
      gap: spacing(2),
      justifyContent: "flex-end",
    },
    buttonLeft: {
      backgroundColor: background.default,
      borderColor: action.outlined,
      borderStyle: "solid",
      borderWidth: 1,
      padding: spacing(1, 2),
    },
  }))

  const notify = useNotify()
  const refresh = useRefresh()
  const navigate = useNavigate()

  const onError = useCallback(
    (e: unknown) => {
      logger("add learner manual error", e)
      if (axios.isAxiosError(e)) {
        notify(
          (e as AxiosError<{ code: string; message: string }>).response?.data?.code
            ? `${ns}.error.${(e as AxiosError<{ code: string; message: string }>).response?.data
                ?.code}`
            : "ra.notification.http_error",
          { type: "error" },
        )
      }
    },
    [notify],
  )

  const rightElement = useMemo(
    () => (
      <div style={s.buttonContainer}>
        <SaveButton
          disabled={!formState.isValid}
          disableElevation={true}
          form="add-manual-learner"
          icon={<div />}
          label={t(`${ns}.saveAndAdd`)}
          style={s.buttonLeft}
          variant="contained"
          type="button"
          mutationOptions={{
            meta: { idField: "userId" },
            onSuccess: () => {
              notify(`${ns}.success`, { type: "success" })
              reset()
            },
            onError,
          }}
        />
        <SaveButton
          disabled={!formState.isValid}
          disableElevation={true}
          form="add-manual-learner"
          icon={<div />}
          label={t(`${ns}.saveAndClose`)}
          style={cs.paddingButton}
          variant="contained"
          type="button"
          mutationOptions={{
            meta: { idField: "userId" },
            onSuccess: () => {
              notify(`${ns}.success`, { type: "success" })
              refresh()
              navigate(-2)
            },
            onError,
          }}
        />
      </div>
    ),
    [
      navigate,
      notify,
      onError,
      refresh,
      reset,
      cs.paddingButton,
      s.buttonContainer,
      s.buttonLeft,
      t,
      formState.isValid,
    ],
  )

  return (
    <ScreenWrapper bottomProps={{ showPrevious: true, rightElement }}>
      <BoxWrapper>
        <Typography variant="h4">{t(`${ns}.title`)}</Typography>
        <Typography color={text.secondary} style={cs.marginSubtitle} variant="subtitle1">
          {t(`${ns}.subtitle`)}
        </Typography>
        <Grid container columnSpacing={{ md: 5 }} rowSpacing={{ md: 1 }}>
          <PersonalInformationGroup />
          <ConnectionTypeGroup />
        </Grid>
      </BoxWrapper>
    </ScreenWrapper>
  )
}

const AddLearnerManualScreen: FC = () => {
  const navigate = useNavigate()

  const { state } = useLocation()
  const [universeId] = useUniverseId()

  useEffect(() => {
    if (!state || (state && state.divisionId == null)) {
      navigate("/addLearner")
    }
  }, [navigate, state])

  return (
    <CreateBase
      resource="universe/user"
      transform={(data: LearnerCreationFormFieldValues) => ({
        ...data,
        divisionsIds: state && state.divisionId ? [state.divisionId] : [],
        role: "learner",
        universeId,
      })}
    >
      <Form
        mode="onBlur"
        {...{ defaultValues }}
        id="add-manual-learner"
        resetOptions={{ keepErrors: true }}
      >
        <AddLearnerManualContent />
      </Form>
    </CreateBase>
  )
}

export default AddLearnerManualScreen
