import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined"
import { CircularProgress, Grid, Tooltip, Typography } from "@mui/material"
import List from "@mui/material/List"
import type { BOEvaluation, LearnerExaminationResult } from "@newpv/js-common"
import { apiUrl, axios, getUserExaminationsByEval, logger } from "@newpv/js-common"
import { ConfirmDialog } from "components/ConfirmDialog"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import _ from "lodash"
import type { FC } from "react"
import React, { useCallback, useMemo, useState } from "react"
import { RefreshButton, useGetList, useNotify, useTranslate } from "react-admin"
import {
  formatDuration,
  getAuth,
  useCommonStyles,
  useDivisionId,
  useGradeColor,
  useQueryRequest,
  useScenarioId,
  useStyles,
  useToken,
  useUniverseId,
} from "utils"

import { commonNs } from "../../features/i18n/fr"
import type { TabProps } from "./StudentDetails"

dayjs.extend(duration)

const ns = "students.studentModal.resultsTab"

interface InitialEvaluationResult {
  score: number
  isInitialEvaluation: boolean
  examinationId: string
  trainingDuration: number
  name: string
}

function isInitialEvaluationResult(
  learnerResult:
    | (LearnerExaminationResult & Pick<BOEvaluation, "calculationMethod" | "name">)
    | InitialEvaluationResult,
): learnerResult is InitialEvaluationResult {
  return (learnerResult as InitialEvaluationResult).isInitialEvaluation ?? false
}

export const ResultsTab: FC<TabProps> = ({ value, userData, progress, progressRefetch }) => {
  const t = useTranslate()
  const notify = useNotify()
  const [token] = useToken()
  const [universeId] = useUniverseId()
  const [divisionId] = useDivisionId()
  const [scenarioId] = useScenarioId()
  const [openModal, setOpenModal] = useState(false)
  const { getPercentColor } = useGradeColor()

  const { data: evaluations } = useGetList<BOEvaluation>(
    `evaluation/universe/${universeId}/evaluation`,
    {
      meta: {
        idField: "evaluationId",
      },
      filter: {
        divisionId,
        scenarioId,
      },
    },
  )

  const disabled = useMemo(() => {
    const endDate = dayjs(progress?.progression.initialEvaluation?.endDate).toDate()
    return dayjs().diff(endDate, "day") > 14 || progress?.progression?.initialEvaluation == null
  }, [progress?.progression])

  const cs = useCommonStyles()
  const s = useStyles(
    ({
      spacing,
      palette: {
        text: { secondary },
      },
    }) => ({
      caption: {
        fontSize: 16,
      },
      refresh: {
        paddingLeft: spacing(1),
        typography: "button",
      },
      grade: {
        fontWeight: "bold",
        paddingLeft: spacing(2),
      },
      resetDiv: {
        alignItems: "center",
        display: "flex",
        paddingBottom: spacing(2),
      },
      mainDiv: {
        display: value === 2 ? "flex" : "none",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        margin: spacing(1),
        padding: spacing(2, 0, 0, 0),
      },
      list: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflowY: "auto",
        padding: spacing(1),
      },
      titleDiv: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        padding: spacing(0, 1, 0, 1),
      },
      gridLine: {
        height: "100%",
        padding: spacing(1, 0),
        width: "100%",
      },
      item: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
      moreTimeIcon: {
        color: secondary,
        fontSize: "medium",
        marginRight: "4px",
      },
    }),
    [value],
  )

  const { data: learnerEvalRes, isLoading } = useQueryRequest<
    Array<LearnerExaminationResult & Pick<BOEvaluation, "name" | "calculationMethod" | "duration">>
  >(
    async () =>
      !userData || !evaluations
        ? undefined
        : Promise.all(
            evaluations.map(async evaluation => {
              const learnerRes = await getUserExaminationsByEval(
                userData.userId,
                evaluation.id,
                getAuth(token),
              )
              return {
                ...learnerRes,
                ..._.pick(evaluation, ["name", "calculationMethod", "duration"]),
              }
            }),
          ),
    ["learnerEvaluation", "evaluations", evaluations, "userId", userData?.userId],
  )

  const onClose = useCallback(() => setOpenModal(false), [])

  const refreshInitialEvaluation = useCallback(async () => {
    if (scenarioId == null || userData?.userId == null) {
      return
    }
    try {
      await axios.delete(
        `${apiUrl}/evaluation/initial/scenario/${scenarioId}/user/${userData.userId}`,
        getAuth(token),
      )
    } catch (error) {
      notify("error.refreshInitial", { type: "error" })
      logger("refreshInitialEvaluation error", error)
    } finally {
      progressRefetch?.()
      onClose()
    }
  }, [notify, onClose, progressRefetch, scenarioId, token, userData])

  const evaluationsWithLearnerResults = useMemo(
    () =>
      [
        progress?.progression?.initialEvaluation
          ? {
              isInitialEvaluation: true,
              examinationId: "initialEvaluation",
              score: progress.progression.initialEvaluation.score,
              trainingDuration: progress.progression.initialEvaluation.duration,
              name: t("stats.dashboardTab.initial"),
              extraTime: progress.progression.initialEvaluation.extraTime ?? false,
            }
          : undefined,
        ...(learnerEvalRes ? learnerEvalRes : []),
      ].filter(e => e),
    [learnerEvalRes, progress, t],
  )

  return (
    <div role="tabpanel" hidden={value !== 2} id={`tab-panel-${2}`} aria-labelledby={`tab-${2}`}>
      <ConfirmDialog
        {...{ onClose }}
        onConfirm={refreshInitialEvaluation}
        open={openModal}
        namespace={ns}
      />
      <div style={s.mainDiv}>
        <div style={s.resetDiv}>
          <RefreshButton
            disabled={disabled}
            label={t(`${ns}.reset`)}
            onClick={() => setOpenModal(true)}
            size="medium"
            sx={s.refresh}
          />
        </div>
        <div style={s.titleDiv}>
          <Grid container style={cs.paddingTop16}>
            <Grid item xs={7}>
              <Typography variant="body1">{t(`${ns}.eval`)}</Typography>
            </Grid>
            <Grid style={s.item} item xs={2.5}>
              <Typography variant="body1" style={s.caption}>
                {t(`${commonNs}.delay`)}
              </Typography>
            </Grid>
            <Grid style={s.item} item xs={2.5}>
              <Typography variant="body1" style={s.caption}>
                {t(`${commonNs}.grade`)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={s.list}>
          <List component="nav" aria-labelledby="list">
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                {evaluationsWithLearnerResults?.map(learnerEvalResult => {
                  if (
                    !learnerEvalResult ||
                    (!isInitialEvaluationResult(learnerEvalResult) && !learnerEvalResult.grade) ||
                    (isInitialEvaluationResult(learnerEvalResult) &&
                      learnerEvalResult.score == null)
                  ) {
                    return null
                  }
                  const grade = isInitialEvaluationResult(learnerEvalResult)
                    ? learnerEvalResult.score
                    : learnerEvalResult.grade?.[
                        learnerEvalResult.calculationMethod === "bonusenabled"
                          ? "gradeOn20Improved"
                          : "gradeOn20"
                      ]

                  const extraTimeFactor = learnerEvalResult?.extraTime ? 1 + 1 / 3 : 1
                  const examDuration = isInitialEvaluationResult(learnerEvalResult)
                    ? formatDuration({ t, duration: learnerEvalResult.trainingDuration })
                    : formatDuration({
                        t,
                        duration:
                          (learnerEvalResult.consumedTime != null
                            ? Math.min(
                                learnerEvalResult.consumedTime,
                                learnerEvalResult.duration * extraTimeFactor,
                              )
                            : 0) * 1000,
                      })

                  return (
                    <Grid key={learnerEvalResult.examinationId} container style={s.gridLine}>
                      <Grid item xs={7}>
                        <Typography variant="subtitle1">{learnerEvalResult.name}</Typography>
                      </Grid>
                      <Grid style={s.item} item xs={2.5}>
                        {learnerEvalResult?.extraTime ? (
                          <Tooltip title={t("stats.dashboardTab.extraTime")}>
                            <MoreTimeOutlinedIcon sx={s.moreTimeIcon} />
                          </Tooltip>
                        ) : null}
                        <Typography variant="subtitle2">{examDuration}</Typography>
                      </Grid>
                      <Grid style={s.item} item xs={2.5}>
                        <Typography
                          variant="subtitle2"
                          color={getPercentColor(
                            grade,
                            isInitialEvaluationResult(learnerEvalResult) ? 100 : 20,
                          )}
                        >
                          {isInitialEvaluationResult(learnerEvalResult)
                            ? `${grade} %`
                            : `${grade}/20`}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                })}
              </>
            )}
          </List>
        </div>
      </div>
    </div>
  )
}
