import InfoIcon from "@mui/icons-material/Info"
import { TableCell, TableHead, TableRow, Tooltip, useTheme } from "@mui/material"
import clsx from "clsx"
import { useListContext, useResourceContext } from "ra-core"
import { DatagridClasses } from "ra-ui-materialui/src/list/datagrid/useDatagridStyles"
import type { FC } from "react"
import { useCallback } from "react"
import * as React from "react"
import type { DatagridHeaderProps, FieldProps } from "react-admin"
import { DatagridHeaderCell, useTranslate } from "react-admin"

import type { ThemeOptions } from "../../../features/Theme/theme"
import { ns } from "./AdminLicenseDatagrid"

export const DatagridHeader: FC<DatagridHeaderProps> = props => {
  const { children } = props
  const t = useTranslate()
  const {
    palette: { neutral },
  } = useTheme<ThemeOptions>()

  const resource = useResourceContext(props)

  const { sort, setSort } = useListContext(props)

  const updateSortCallback = useCallback(
    event => {
      event.stopPropagation()
      const newField = event.currentTarget.dataset.field
      const newOrder =
        sort.field === newField
          ? sort.order === "ASC"
            ? "DESC"
            : "ASC"
          : event.currentTarget.dataset.order

      setSort({ field: newField, order: newOrder })
    },
    [sort.field, sort.order, setSort],
  )

  // copied the code from RA, with the necessary ts-ignores
  // @ts-ignore
  const updateSort = setSort ? updateSortCallback : null

  return (
    <TableHead>
      <TableRow>
        {React.Children.map(children, (child, index) =>
          React.isValidElement<FieldProps>(child) ? (
            child.props.label === t(`${ns}.license`) ? (
              <Tooltip
                title={
                  child.props.label === t(`${ns}.license`)
                    ? t("adminPanel.licenses.tooltip")
                    : undefined
                }
                placement="left-end"
              >
                <TableCell key={child.props.source}>
                  {child.props.label}
                  {child.props.label === t(`${ns}.license`) ? (
                    <span style={{ padding: "4px" }}>
                      <InfoIcon
                        sx={{
                          color: neutral[500],
                          position: "absolute",
                        }}
                      />
                    </span>
                  ) : null}
                </TableCell>
              </Tooltip>
            ) : (
              <DatagridHeaderCell
                className={clsx(
                  DatagridClasses.headerCell,
                  `column-${(child.props as any).source}`,
                )}
                sort={sort}
                field={child}
                isSorting={
                  sort.field === ((child.props as any).sortBy || (child.props as any).source)
                }
                key={(child.props as any).source || index}
                resource={resource}
                // copied the code from RA, with the necessary ts-ignores
                // @ts-ignore
                updateSort={updateSort}
              />
            )
          ) : null,
        )}
      </TableRow>
    </TableHead>
  )
}
