import { Tab, Tabs, Typography } from "@mui/material"
import { AsideView } from "components"
import { ConfirmDialog } from "components/ConfirmDialog"
import type { FC } from "react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Loading, usePermissions, useRefresh, useTranslate } from "react-admin"
import { useNavigate } from "react-router-dom"
import { useCommonStyles, useStyles } from "utils"
import delay from "utils/delay"

import { useDivisions } from "../../Layout/hooks/useDivisions"
import { AccessRights } from "./AccessRights"
import { GeneralTab } from "./GeneralTab"
import { useDivisionCreate, useDivisionEdit } from "./useDivisionFunctions"

interface Props {
  isCreation?: boolean
}

export const BUTTON_DIV_HEIGHT = 68

const divisionNs = "adminPanel.divisions"

export const DivisionDetailsScreen: FC<Props> = ({ isCreation = false }) => {
  /**
   * Contexts
   * */
  const t = useTranslate()
  const refresh = useRefresh()
  const navigate = useNavigate()

  const { permissions } = usePermissions()
  const { refreshDivisions } = useDivisions()
  /**
   * States
   * */
  const [tabIndex, setTabIndex] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [open, setOpen] = useState(false)

  const onClose = (): void => {
    setOpen(false)
    setTimeout(() => {
      navigate("..", { replace: true })
      refresh()
    }, 300)
  }

  /**
   * The following hook is necessary to trigger the opening animation
   * */
  useEffect((): void => {
    setOpen(true)
  }, [])

  const cs = useCommonStyles()
  const s = useStyles(({ spacing }) => ({
    tabs: { display: isCreation ? "none" : undefined, marginBottom: spacing(2) },
  }))

  const tabs = useMemo(
    () =>
      isCreation ? [] : [t(`${divisionNs}.generalTab.title`), t(`${divisionNs}.authTab.title`)],
    [isCreation, t],
  )

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: number): void => {
    setTabIndex(newValue)
  }, [])

  const onCloseConfirmModal = useCallback(() => setOpenModal(false), [])

  const { save, divisionData, isLoading, saving, onDelete } = (
    isCreation ? useDivisionCreate : useDivisionEdit
  )()

  const onConfirm = useCallback(async () => {
    onDelete?.()
    await delay(200)
    refreshDivisions()
  }, [onDelete, refreshDivisions])

  return (
    <>
      <AsideView
        {...{ open, onClose }}
        onDelete={
          permissions.includes("universeAdmin") && divisionData?.nbLearners === 0
            ? async () => {
                setOpenModal(true)
              }
            : undefined
        }
        title={t(`${divisionNs}.${isCreation ? "new" : "details"}`)}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {isCreation ? null : (
              <ConfirmDialog
                namespace="adminPanel.divisions"
                onClose={onCloseConfirmModal}
                onConfirm={onConfirm}
                open={openModal}
              />
            )}
            <div style={cs.mainDiv}>
              <Tabs
                value={tabIndex}
                variant="fullWidth"
                textColor="inherit"
                onChange={handleChange}
                style={s.tabs}
              >
                {tabs.map((elem, index) => (
                  <Tab
                    disabled={isCreation && index === 1}
                    key={index}
                    label={
                      <Typography style={cs.screenTitle} variant="subtitle1">
                        {elem}
                      </Typography>
                    }
                    id={`tab-division-${index}`}
                    aria-controls={`division-panel-${index}`}
                  />
                ))}
              </Tabs>
              {tabIndex === 0 || isCreation ? (
                <GeneralTab
                  {...{
                    isCreation,
                    onClose,
                    saving,
                    save,
                    divisionData,
                  }}
                />
              ) : isCreation ? null : (
                <AccessRights />
              )}
            </div>
          </>
        )}
      </AsideView>
    </>
  )
}
