import type { FC } from "react"
import React, { useCallback, useState } from "react"
import type { LayoutProps } from "react-admin"
import { Layout as RALayout, UserMenu } from "react-admin"
import { useLocation } from "react-router-dom"

import { AdminAppBar } from "./AdminAppBar"
import { AppBar } from "./AppBar"
import { LogoutButton } from "./components/LogoutButton"
import { Menu } from "./Menu"
import { Sidebar } from "./Sidebar"

export const Layout: FC<LayoutProps> = props => {
  const [menuOpen, setMenuOpen] = useState(true)

  const location = useLocation()

  const appBar = useCallback(
    appBarProps => (
      <AppBar
        {...appBarProps}
        setMenuOpen={setMenuOpen}
        userMenu={
          <UserMenu>
            <LogoutButton />
          </UserMenu>
        }
      />
    ),
    [],
  )

  const adminAppBar = useCallback(
    appBarProps => <AdminAppBar userMenu={<></>} {...appBarProps} />,
    [],
  )

  return location.pathname.includes("adminPanel") ? (
    <RALayout {...props} menu={() => <div />} appBar={adminAppBar} sidebar={() => <div />} />
  ) : (
    <RALayout
      {...props}
      menu={menuProps => <Menu {...menuProps} menuOpen={menuOpen} />}
      appBar={appBar}
      sidebar={Sidebar}
    />
  )
}
