import { datadogLogs } from "@datadog/browser-logs"

// eslint-disable-next-line prefer-const
let loggerEnabled = process.env.NODE_ENV ?? false
let datadogEnabled = true

export const setLoggerEnabled = (enabled: boolean): void => {
  loggerEnabled = enabled
  datadogEnabled = enabled
}

export type LoggerFn = (message?: any, ...optionalParams: any[]) => void

export const logger: LoggerFn = (message?: unknown, ...optionalParams: any[]) => {
  if (loggerEnabled) {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams)
  }

  if (datadogEnabled) {
    let p
    try {
      p = JSON.stringify(optionalParams)
      // eslint-disable-next-line no-empty
    } catch {}
    datadogLogs.logger.debug(`${message} ${p}`, { optionalParams })
  }
}
