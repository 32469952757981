import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { Button, Divider } from "@mui/material"
import type { FC, ReactElement } from "react"
import { useTranslate } from "react-admin"
import { useNavigate } from "react-router-dom"
import { useCommonStyles, useStyles } from "utils"

import { BAR_HEIGHT } from "../../features/Constants"

export interface BottomBarProps {
  next?: {
    disabled?: boolean
    hideIcon?: boolean
    label?: string
    onClick?: () => void
  }
  rightElement?: ReactElement
  showPrevious?: boolean
}

const AddLearnerBottomBar: FC<BottomBarProps> = ({ showPrevious = false, rightElement, next }) => {
  const navigate = useNavigate()
  const t = useTranslate()

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing, palette: { background, text } }) => ({
      buttonContainer: {
        backgroundColor: background.neutral,
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        padding: spacing(2),
      },
      buttonLeft: {
        borderStyle: "none",
        borderWidth: 0,
      },
      container: {
        bottom: 0,
        display: "flex",
        flex: 1,
        flexDirection: "column",
        maxHeight: BAR_HEIGHT,
        minHeight: BAR_HEIGHT,
        width: "100%",
      },
      icon: {
        color: next?.disabled ? text.disabled : text.secondary,
      },
    }),
    [next?.disabled],
  )

  return (
    <div style={s.container}>
      <Divider style={cs.separator} />
      <div style={s.buttonContainer}>
        {showPrevious ? (
          <Button
            disableElevation={true}
            onClick={() => navigate(-1)}
            startIcon={<ChevronLeft sx={s.icon} />}
            style={s.buttonLeft}
            variant="outlined"
          >
            {t(`common.previous`)}
          </Button>
        ) : (
          <div />
        )}
        {rightElement ?? (
          <Button
            disableElevation={true}
            disabled={next?.disabled}
            endIcon={next?.hideIcon ? undefined : <ChevronRight sx={s.icon} />}
            onClick={next?.onClick}
            style={cs.paddingButton}
            variant="contained"
          >
            {next?.label}
          </Button>
        )}
      </div>
    </div>
  )
}

export default AddLearnerBottomBar
