import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Menu as MuiMenu, MenuItem } from "@mui/material"
import type { FC, MouseEvent } from "react"
import React, { useState } from "react"
import { useCommonStyles } from "utils"

interface Props {
  actions: [{ onClick: (...args: any) => any; title: string }]
}

/**
 * Menu used for quick actions
 */
export const Menu: FC<Props> = ({ actions }) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement>()
  const openMenu = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<SVGSVGElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(undefined)
  }
  const cs = useCommonStyles()

  return (
    <>
      <MoreVertIcon color="disabled" onClick={handleClick} cursor="pointer" />
      <MuiMenu
        sx={{ "& .MuiPopover-paper": cs.borderRadius }}
        id="top-menu"
        open={openMenu}
        autoFocus={false}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {actions.map((elem, idx) => (
          <MenuItem key={idx} onClick={elem.onClick}>
            {elem.title}
          </MenuItem>
        ))}
      </MuiMenu>
    </>
  )
}
