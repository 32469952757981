import type { Resource, Scenario } from "@newpv/js-common"
import { apiUrl, axios, fetchAllScenarios, isNotNullish } from "@newpv/js-common"
import { useMemo } from "react"
import { usePermissions } from "react-admin"
import {
  getAuth,
  useAuthType,
  useDivisionId,
  useQueryRequest,
  useScenarioId,
  useToken,
  useUniverseId,
} from "utils/index"

import type { Permission } from "../features/Providers"
import { getScenarioId } from "../screens/Layout/AppBar"

export const useResources = (
  resourceEndpoint?: string,
): { resources?: Array<Required<Resource>> } => {
  const { permissions } = usePermissions<Permission[]>()
  const [universeId] = useUniverseId()
  const [divisionId] = useDivisionId()
  const [scenarioId] = useScenarioId()
  const [authType] = useAuthType()
  const [token] = useToken()

  const endpoint =
    resourceEndpoint ??
    (permissions?.includes("licenses") && authType === "ecoledirecte"
      ? `${apiUrl}/ecoledirecte/universe/${universeId}/resource`
      : `${apiUrl}/universe/division/${divisionId}/resource`)

  const { data: rawResources } = useQueryRequest<Resource[]>(
    async () => {
      const result = await axios.get<{ results: Resource[] }>(endpoint, getAuth(token))
      return result.data.results
    },
    [
      "divisionScenarios",
      "scenarioId",
      scenarioId,
      "universeId",
      universeId,
      "divisionId",
      divisionId,
    ],
    { enabled: divisionId !== undefined },
  )

  const { data: allScenarios } = useQueryRequest<Scenario[]>(
    async () => fetchAllScenarios(undefined),
    ["allScenarios"],
  )

  const resources = useMemo((): Array<Required<Resource>> | undefined => {
    if (rawResources == null || allScenarios == null) {
      return undefined
    }

    // division resources are missing a name, so recompose from allScenarios
    return rawResources
      .map(resource => {
        if ((resource.resourceName?.length ?? 0) > 0) {
          return resource // as Required<Resource>
        }

        const scenId = getScenarioId(resource)

        const scenario = scenId ? allScenarios.find(asc => asc.id === scenId) : undefined

        return scenario
          ? {
              ...resource,
              resourceName: scenario?.name ?? "",
            }
          : null
      })
      .filter(isNotNullish)
  }, [allScenarios, rawResources])

  return { resources }
}
