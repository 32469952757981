import { DateField } from "components"
import type { FC } from "react"
import React from "react"
import { Datagrid, TextField, useTranslate } from "react-admin"

import { commonNs } from "../../features/i18n/fr"
import { ListDeleteButton } from "./components/ListDeleteButton"

const ns = "resources.evaluations.fields"

interface Props {
  isInitialEvaluation?: boolean
}

const EvalListContent: FC<Props> = ({ isInitialEvaluation }) => {
  const t = useTranslate()

  return (
    <Datagrid
      rowClick={id =>
        isInitialEvaluation ? `/evaluations/initialEvaluation/${id}` : `/evaluations/${id}`
      }
      bulkActionButtons={isInitialEvaluation ? false : <ListDeleteButton />}
    >
      <TextField variant="subtitle1" sortBy="name" source="name" label={t(`${commonNs}.name`)} />
      <DateField sortBy="startDate" source="startDate" label={t(`${ns}.startDate`)} />
      <DateField sortBy="dueDate" source="dueDate" label={t(`${ns}.dueDate`)} />
      <TextField
        variant="subtitle1"
        sortBy="totalLearners"
        source="totalLearners"
        label={t(`${ns}.totalLearners`)}
      />
      <TextField
        variant="subtitle1"
        sortBy="evaluatedLearner"
        source="evaluatedLearner"
        label={t(`${ns}.evaluatedLearner`)}
      />
    </Datagrid>
  )
}

export default EvalListContent
