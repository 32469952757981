import DoneIcon from "@mui/icons-material/Done"
import { Box, CircularProgress, useTheme } from "@mui/material"
import type { RuleProgression } from "@newpv/js-common"
import type { FC } from "react"
import React from "react"
import { useStyles } from "utils"

import { ICON_SIZE_MEDIUM } from "../../../features/Theme/theme"

interface Props {
  ruleProgress?: RuleProgression
}

export const RuleProgress: FC<Props> = ({ ruleProgress }) => {
  const {
    palette: {
      divider,
      secondary,
      primary: { main },
    },
  } = useTheme()

  /**
   * Useful vars
   * */
  const completion = ruleProgress?.maxCompletionPercentage ?? ruleProgress?.completionPercentage
  // rule is declared as known by default until the first error
  const ruleCompleted = completion === 100
  const ruleKnown = ruleCompleted && ruleProgress?.isKnown === true

  const commonCircularProps = {
    size: 32,
    thickness: 5,
    variant: "determinate",
  } as const

  /**
   * Styling
   * */
  const s = useStyles(({}) => ({
    circular: {
      color: completion === 0 ? divider : secondary[100],
    },
    mainBox: {
      alignItems: "center",
      display: "inline-flex",
      position: "relative",
    },
    absoluteBox: {
      alignItems: "center",
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
  }))

  return (
    <Box sx={s.mainBox}>
      <CircularProgress
        value={completion}
        {...commonCircularProps}
        color={ruleCompleted ? "primary" : "secondary"}
      />
      {ruleCompleted ? null : (
        <Box sx={s.absoluteBox}>
          <CircularProgress value={100} sx={s.circular} {...commonCircularProps} />
        </Box>
      )}
      <Box sx={s.absoluteBox}>
        {ruleKnown ? <DoneIcon sx={{ fontSize: ICON_SIZE_MEDIUM, color: main }} /> : null}
      </Box>
    </Box>
  )
}
