import InfoIcon from "@mui/icons-material/Info"
import { Box, Divider, Grid, Typography } from "@mui/material"
import { isEmpty } from "@newpv/js-common"
import HoverItem from "components/HoverItem"
import _ from "lodash"
import type { FC } from "react"
import { useCallback, useMemo } from "react"
import { useTranslate } from "react-admin"
import { useCommonStyles, useStyles } from "utils"

import type { ConnectionType } from "../AddLearnerManualScreen/constants"
import { ns } from "./constants"
import type { ErrorLine } from "./interfaces"

const ErrorGrid: FC<{ authMode?: ConnectionType; errorLines: ErrorLine[] }> = ({
  authMode,
  errorLines,
}) => {
  const t = useTranslate()

  const cs = useCommonStyles()
  const s = useStyles(
    ({
      spacing,
      palette: {
        background: { neutral },
        divider,
      },
    }) => ({
      box: {
        flexGrow: 1,
        marginTop: spacing(8),
        paddingBottom: spacing(5),
      },
      container: {
        borderColor: divider,
        borderRadius: 8,
        borderStyle: "solid",
        borderWidth: 1,
      },
      divider: {
        marginBottom: spacing(1),
      },
      muiGrid: {
        "& .MuiGrid-item": {
          paddingLeft: 0,
          paddingTop: 0,
        },
      },
      totalContainer: {
        alignItems: "center",
        backgroundColor: neutral,
        display: "flex",
        padding: spacing(1.5, 1, 1.5, 1),
      },
      totalText: {
        paddingLeft: spacing(2),
      },
    }),
    [],
  )
  const total = _.sumBy(errorLines, error => error.errorCodes.length)
  const isEmail = authMode === "email"
  const displayedErrorLines = useMemo(
    () =>
      total > 10
        ? _.transform(
            errorLines,
            (res: ErrorLine[], val: ErrorLine) => {
              res.push(val)
              return _.sumBy(res, error => error.errorCodes.length) <= 10
            },
            [],
          )
        : errorLines,
    [errorLines, total],
  )

  const renderRow = useCallback(
    ({ data: user, errorCodes }: ErrorLine) => {
      const hasLastNameError = _.includes(errorCodes, "mandatoryLastName")
      const hasEmailError = _.some(errorCodes, ec =>
        _.includes(["duplicateEmail", "mandatoryEmail", "invalidEmail"], ec),
      )
      return (
        <>
          <HoverItem hasError={hasLastNameError} text={user.lastName} xs={isEmail ? 4 : 6} />
          <HoverItem text={isEmpty(user.firstName) ? "-" : user.firstName} xs={isEmail ? 4 : 6} />
          {isEmail ? <HoverItem hasError={hasEmailError} text={user.email} xs={4} /> : null}
        </>
      )
    },
    [isEmail],
  )

  return (
    <Box sx={s.box}>
      <Grid container={true} spacing={3} style={s.container} sx={s.muiGrid}>
        <Grid item={true} xs={12} style={s.totalContainer}>
          <InfoIcon sx={cs.warningMainColor} />
          <Typography variant="body1" style={s.totalText}>
            {total > 10 ? t(`${ns}.moreError`) : t(`${ns}.count_error`, { smart_count: total })}
          </Typography>
        </Grid>
        <HoverItem text={t("common.name")} variant="body2" xs={isEmail ? 4 : 6} />
        <HoverItem text={t("common.firstName")} variant="body2" xs={isEmail ? 4 : 6} />
        {isEmail ? <HoverItem text={t("common.email")} variant="body2" /> : null}
        <Grid xs={12} style={s.divider}>
          <Divider />
        </Grid>
        {_.map(displayedErrorLines, error => renderRow(error))}
      </Grid>
    </Box>
  )
}

export default ErrorGrid
