import type { DivisionId } from "@newpv/js-common"
import type { FC, PropsWithChildren } from "react"
import React, { createContext, useContext, useState } from "react"
import { useDivisionId } from "utils"

type ContextData = [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]

const divisionIdContext = createContext<ContextData>({} as ContextData)

export const DivisionIdProvider: FC<PropsWithChildren> = ({ children }) => {
  const [globalDivisionId] = useDivisionId()

  const divisionState = useState<DivisionId | undefined>(globalDivisionId)
  return <divisionIdContext.Provider value={divisionState}>{children}</divisionIdContext.Provider>
}

export const useDivisionIdContext = (): ContextData => useContext(divisionIdContext)

export default DivisionIdProvider
