import { Button, Tooltip, Typography, useTheme } from "@mui/material"
import { type Group, type MinimalUserData } from "@newpv/js-common"
import _ from "lodash"
import type { FC } from "react"
import React, { useCallback, useMemo, useState } from "react"
import type { SaveHandler } from "react-admin"
import {
  Form,
  Loading,
  required,
  SaveButton,
  TextInput,
  useNotify,
  useTranslate,
} from "react-admin"
import { useParams } from "react-router-dom"
import { SearchableCheckboxList } from "screens/SessionScreen/components/SearchableCheckboxList"
import { useCommonStyles, useStyles } from "utils"
import { logger } from "utils/logger"
import useStudentsFromDivision from "utils/useStudentsFromDivision"

interface Props {
  onClose: () => void
  isCreation?: boolean
  save?: SaveHandler<Group>
  groupData?: Partial<Group>
  saving?: boolean
}

export const ns = "adminPanel.groups"

export const GeneralTab: FC<Props> = ({ isCreation, onClose, saving, groupData, save }) => {
  const t = useTranslate()
  const notify = useNotify()

  const { id: currentGroupId } = useParams() as { id: string }

  const [searchText, setSearchText] = useState("")

  const { data: studentList, isLoading: isStudentsLoading } = useStudentsFromDivision()

  const filteredStudentList = useMemo(
    () =>
      searchText.length === 0
        ? studentList?.map(e => ({ ...e, firstName: e.firstName ?? "" }))
        : studentList?.filter(e =>
            `${e.firstName} ${e.lastName}`
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase()),
          ),
    [searchText, studentList],
  )

  const cs = useCommonStyles()
  const s = useStyles(
    ({ typography: { subtitle1 }, spacing }) => ({
      input: {
        margin: spacing(0, 2, 0, 2),
        width: `calc(100% - ${spacing(4)})`,
      },
      main: {
        height: "100%",
        overflow: "auto",
        paddingTop: spacing(2),
      },
      muiInput: {
        "& .MuiInputBase-root": subtitle1,
        "& .MuiFormLabel-root": cs.muiFormLabel12,
      },
      saveButton: {
        boxShadow: "none",
      },
      members: {
        margin: spacing(2, 2, 0, 2),
      },
    }),
    [cs.muiFormLabel12],
  )
  const {
    palette: { action, text },
  } = useTheme()

  const renderListItem = useCallback(
    (elem: MinimalUserData, disabled: boolean) => (
      <Tooltip title={disabled ? t("adminPanel.groups.noSelect") : undefined}>
        <Typography
          color={disabled ? action.disabled : text.primary}
          variant="subtitle1"
        >{`${elem.firstName} ${elem.lastName}`}</Typography>
      </Tooltip>
    ),
    [action.disabled, text.primary, t],
  )

  const disableStudentSelection = useCallback(
    (currentStudent: MinimalUserData) =>
      currentStudent.groupId != null && currentStudent.groupId !== currentGroupId,
    [currentGroupId],
  )

  const validateGroupCreationOrEdit = (values: Partial<Group>): { [P in keyof Group]?: string } => {
    const errors: { [P in keyof Group]?: string } = {}
    if (_.isEmpty(values?.name)) {
      errors.name = t("ra.validation.required")
    }

    return errors
  }

  return (
    <div style={s.main}>
      {isStudentsLoading ? (
        <Loading />
      ) : (
        <Form
          id="save_group"
          mode="onChange"
          className="form"
          defaultValues={groupData}
          validate={validateGroupCreationOrEdit}
          onSubmit={data => {
            save?.(
              { ...data, learners: data.learners ?? [] },
              {
                onSuccess: () => {
                  onClose()
                  notify(`adminPanel.groups.${isCreation ? "newSuccess" : "editSuccess"}`, {
                    type: "success",
                  })
                },
                onError: e => {
                  logger("group creation error", { e })

                  notify(
                    e.response?.data?.code
                      ? `adminPanel.groups.${
                          e.response.data.code === "DUPLICATE_NAME"
                            ? e.response.data.code
                            : "groupCreationEditionError"
                        }`
                      : "ra.notification.http_error",
                    {
                      type: "error",
                    },
                  )
                },
              },
            )
          }}
        >
          <div style={cs.upponFormContainer}>
            <TextInput
              source="name"
              size="medium"
              color="primary"
              variant="outlined"
              style={s.input}
              sx={s.muiInput}
              label={t("common.name")}
              validate={required("ra.validation.required")}
            />
            <Typography style={s.members} variant="body1">
              {t(`${ns}.members`)}
            </Typography>
            <SearchableCheckboxList
              source="learners"
              filteredOptions={filteredStudentList}
              disableGlobally={_.isEmpty(studentList)}
              disableBulkSelect={_.isEmpty(studentList)}
              disableOption={disableStudentSelection}
              {...{
                searchText,
                setSearchText,
                renderListItem,
              }}
            />
          </div>

          <div style={cs.asideButtons}>
            <SaveButton
              icon={<></>}
              disabled={saving}
              style={s.saveButton}
              label={isCreation ? "ra.action.create" : "ra.action.save"}
            />
            <Button style={cs.marginLeft16} onClick={onClose}>
              {t("ra.action.cancel")}
            </Button>
          </div>
        </Form>
      )}
    </div>
  )
}
