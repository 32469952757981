import { CircularProgress, Typography, useTheme } from "@mui/material"
import { lett, parseInt10 } from "@newpv/js-common"
import dayjs from "dayjs"
import _ from "lodash"
import type { FC } from "react"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslate } from "react-admin"
import { useStyles } from "utils"
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryClipContainer,
  VictoryContainer,
  VictoryLabel,
} from "victory"

import type { Period } from "../screens/StatsScreen/Dashboard"

export interface ChartData {
  id: string
  date: number
  percentage: number
}

interface Props {
  width: number
  height: number
  isLoading?: boolean
  data?: ChartData[]
  nbrOfDays?: Period
  responsive?: boolean
}

/**
 * Generates a victory chart based on a user or divisions stats
 * @param nbrOfDays
 * @param width
 * @param height
 * @param data
 * @param isLoading
 * @param responsive Will expand the chart to its maximum available size
 */
export const Chart: FC<Props> = ({
  nbrOfDays,
  width,
  height,
  data,
  isLoading,
  responsive = true,
}) => {
  const {
    palette: {
      divider,
      primary,
      text: { secondary, disabled },
    },
  } = useTheme()
  const t = useTranslate()

  const [graphData, setGraphData] = useState<Array<{ date: string; percentage: number }>>([
    { date: "0", percentage: 0 },
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setData = useCallback(
    /**
     * Set the Chart data from the props data
     */
    _.debounce(
      newData => {
        setGraphData(
          nbrOfDays === "0" || !nbrOfDays ? newData : _.takeRight(newData, parseInt10(nbrOfDays)),
        )
      },
      500,
      { leading: true, trailing: true },
    ),
    [nbrOfDays],
  )

  useEffect(() => {
    if (!data) {
      return
    }
    setData(data)
  }, [data, nbrOfDays, setData])

  const singleDay = lett(data?.[0]?.date, d => dayjs(d))?.isSame(
    lett(data?.[data.length - 1]?.date, d => dayjs(d)),
    "day",
  )

  const commonAxisProps = {
    tickComponent: <div />,
    crossAxis: true,
    style: {
      grid: { stroke: divider },
      axis: { stroke: "transparent" },
      tickLabels: { fill: !data ? disabled : secondary },
    },
  }

  const s = useStyles(
    () => ({
      div: {
        marginTop: "-32px",
      },
      progress: {
        marginLeft: `${width / (isLoading ? 2.22 : 2.5)}px`,
        marginTop: `-${height / 1.66}px`,
      },
      svg: {
        height: 0,
      },
    }),
    [height, isLoading, width],
  )

  // noinspection RequiredAttributes
  return (
    <div style={s.div}>
      <svg style={s.svg}>
        <defs>
          <linearGradient id="areaGradient">
            <stop offset="0%" stopColor="rgba(255, 143, 31, 0.26)" />
            <stop offset="100%" stopColor="rgba(239, 244, 207, 1)" />
          </linearGradient>
          <linearGradient id="clipGradient">
            <stop offset="0%" stopColor="rgba(255, 143, 31, 0.23)" />
            <stop offset="100%" stopColor="rgba(196, 223, 29, 1)" />
          </linearGradient>
        </defs>
      </svg>
      <VictoryChart
        {...{ width, height }}
        containerComponent={<VictoryContainer responsive={responsive} />}
      >
        <VictoryAxis
          {...commonAxisProps}
          key="y-axis"
          dependentAxis
          tickFormat={(tick, index) => (index % 2 === 0 ? `${tick}%` : "")}
          tickValues={[0, 25, 50, 75, 100]}
          tickCount={3}
        />
        <VictoryAxis
          {...commonAxisProps}
          key="x-axis"
          tickCount={singleDay ? 1 : 10}
          tickValues={graphData.map(item => item.date)}
          tickFormat={(tick, index) =>
            graphData.length === 0 || index % 2 !== 0
              ? ""
              : `${dayjs(parseInt10(tick)).format("DD/MM")}`
          }
          tickLabelComponent={<VictoryLabel dx={10} />}
        />
        <VictoryArea
          interpolation="basis"
          // @ts-ignore
          animate={{ onLoad: { delay: 0 }, duration: 500 }}
          style={{
            data: {
              strokeWidth: 3,
              fillOpacity: 0.4,
              strokeLinecap: "round",
              fill: "url(#areaGradient)",
              stroke: "url(#clipGradient)",
            },
            labels: {
              fill: "#000",
              fontSize: 12,
              fontFamily: "Montserrat",
            },
          }}
          x="date"
          y="percentage"
          groupComponent={
            <VictoryClipContainer clipPadding={{ top: 40, right: 30, bottom: 10, left: 10 }} />
          }
          data={graphData}
          labels={props =>
            props.data?.length === parseInt10(props.index) + 1
              ? Math.floor(props.datum.percentage).toString() + "%"
              : null
          }
          labelComponent={
            <VictoryLabel
              backgroundPadding={10}
              dy={-25}
              dx={-25}
              backgroundStyle={{
                fill: primary["400"],
                // @ts-ignore
                rx: 16,
              }}
            />
          }
        />
      </VictoryChart>
      <div style={s.progress}>
        {isLoading ? (
          <CircularProgress />
        ) : !_.isEmpty(data) ? null : (
          <Typography variant="body2" style={{ fontFamily: "Montserrat Bold" }}>
            {t("common.empty.title")}
          </Typography>
        )}
      </div>
    </div>
  )
}
