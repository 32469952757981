import { Grid, Typography } from "@mui/material"
import type { FC } from "react"
import { useState } from "react"
import { useStyles } from "utils"

const HoverItem: FC<{
  xs?: number
  hasError?: boolean
  text: string
  variant?: "body2" | "subtitle1"
}> = ({ hasError, text, variant = "subtitle1", xs }) => {
  const [hover, setHover] = useState(false)

  const isTitle = variant === "body2"

  const s = useStyles(
    ({ spacing, palette: { secondary } }) => ({
      error: {
        backgroundColor: secondary[50],
        borderColor: secondary[700],
        borderRadius: 8,
        borderStyle: "solid",
        borderWidth: 1,
        height: "100%",
        padding: spacing(1),
        width: "100%",
        display: "flex",
        flex: 1,
        alignItems: "center",
      },
      noError: {
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
      },
      item: {
        padding: hasError ? spacing(1, 1, 1, 2) : undefined,
        paddingLeft: spacing(2),
        paddingTop: 0,
      },
      subtitle: {
        cursor: "pointer",
        overflow: hover ? "visible" : "hidden",
        paddingBottom: spacing(isTitle ? 1 : 0),
        paddingTop: spacing(isTitle ? 1 : 0),
        textOverflow: "ellipsis",
      },
    }),
    [hover, isTitle, hasError],
  )

  return (
    <Grid item={true} style={s.item} {...{ xs }}>
      <div style={hasError ? s.error : s.noError}>
        <Typography
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={s.subtitle}
          {...{ variant }}
        >
          {text}
        </Typography>
      </div>
    </Grid>
  )
}

export default HoverItem
