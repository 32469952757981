import type { FC, PropsWithChildren, ReactElement } from "react"
import React from "react"
import type { SidebarProps } from "react-admin"
import { Sidebar as RASidebar } from "react-admin"
import { useStyles } from "utils"

import { APP_BAR_HEIGHT, APP_TOP_BAR_HEIGHT } from "../../features/Theme/theme"

export const Sidebar: FC<PropsWithChildren<SidebarProps>> = ({ children, ...props }) => {
  const s = useStyles(({ palette: { divider, background } }) => ({
    barSx: {
      "& .RaSidebar-fixed": {
        backgroundColor: background.paper,
        boxShadow: `inset -1px 0 ${divider}`,
      },
    },
    bar: {
      height: `calc(100vh - ${APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT}px)`,
    },
  }))

  return (
    <RASidebar sx={s.barSx} {...props} style={s.bar}>
      {children as ReactElement}
    </RASidebar>
  )
}
