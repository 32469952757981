import { Button, Tooltip, Typography } from "@mui/material"
import type { UserId } from "@newpv/js-common"
import _ from "lodash"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useCallback } from "react"
import { useNotify, useTranslate } from "react-admin"
import { useCommonStyles, useStyles } from "utils"

interface Props {
  nbOfAvailableLicenses: number
  setNewlySelectedIds: Dispatch<SetStateAction<UserId[]>>
  setNewlyUnselectedIds: Dispatch<SetStateAction<UserId[]>>
  disabled: boolean
  unselectedUsersOnPage: UserId[]
  nbOfLicensesToRemove: number
  nbOfNewlySelectedLicenses: number
  initiallySelectedIdsOnPage: UserId[]
  allUsersOnPageIds: UserId[]
  usersWithoutDefinitiveLicenseOnPage: UserId[]
}

const AdminSelectAllButton: FC<Props> = ({
  nbOfAvailableLicenses,
  setNewlyUnselectedIds,
  setNewlySelectedIds,
  disabled,
  unselectedUsersOnPage,
  nbOfLicensesToRemove,
  nbOfNewlySelectedLicenses,
  initiallySelectedIdsOnPage,
  allUsersOnPageIds,
  usersWithoutDefinitiveLicenseOnPage,
}) => {
  const notify = useNotify()
  const t = useTranslate()

  const allSelected = unselectedUsersOnPage.length === 0

  // there could be not enough licenses if we already selected other students one by one - possibly on other pages
  const notEnoughLicensesForAllUsersOnPage =
    nbOfAvailableLicenses +
      nbOfLicensesToRemove -
      nbOfNewlySelectedLicenses -
      unselectedUsersOnPage.length <
    0

  /** FUNCTIONS */
  const onSelectAllClick = useCallback(() => {
    // if "select all" action
    if (!allSelected) {
      if (notEnoughLicensesForAllUsersOnPage) {
        notify("ra.notification.not_enough_licenses_err", {
          type: "error",
          autoHideDuration: 1500,
        })
        return
      }
      // else we select all users
      setNewlySelectedIds(prev =>
        _(prev).concat(_.difference(allUsersOnPageIds, initiallySelectedIdsOnPage)).uniq().value(),
      )
      setNewlyUnselectedIds(prev => _.difference(prev, allUsersOnPageIds))
      return
    }

    // if "unselect all" action
    setNewlySelectedIds(prev => _.difference(prev, allUsersOnPageIds))
    setNewlyUnselectedIds(prev =>
      _(prev)
        .concat(_.intersection(usersWithoutDefinitiveLicenseOnPage, initiallySelectedIdsOnPage))
        .uniq()
        .value(),
    )
    return
  }, [
    allSelected,
    allUsersOnPageIds,
    initiallySelectedIdsOnPage,
    notEnoughLicensesForAllUsersOnPage,
    notify,
    setNewlySelectedIds,
    setNewlyUnselectedIds,
    usersWithoutDefinitiveLicenseOnPage,
  ])

  const buttonIsDisabled =
    disabled ||
    (!allSelected &&
      // ?
      (notEnoughLicensesForAllUsersOnPage || unselectedUsersOnPage.length === 0))

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing, palette: { primary, secondary, text } }) => ({
      button: {
        alignSelf: "center",
        borderColor: buttonIsDisabled
          ? text.disabled
          : allSelected
          ? secondary["800"]
          : primary["800"],
        color: buttonIsDisabled ? text.disabled : allSelected ? secondary["800"] : primary["800"],
        height: "40px",
        margin: spacing(1),
        padding: "10px",
      },
    }),
    [allSelected, buttonIsDisabled],
  )

  return !_.isUndefined(usersWithoutDefinitiveLicenseOnPage?.length) &&
    !_.isUndefined(unselectedUsersOnPage) &&
    allUsersOnPageIds?.length > 0 ? (
    <div style={cs.displayFlex}>
      <Tooltip
        title={
          disabled
            ? undefined
            : !allSelected && notEnoughLicensesForAllUsersOnPage
            ? t("license.notEnoughTooltip")
            : !allSelected
            ? t("license.assignAllTooltip")
            : t("license.unassignAllTooltip")
        }
        enterDelay={500}
      >
        <div style={{ display: "flex" }}>
          <Button
            disabled={buttonIsDisabled}
            style={s.button}
            variant="outlined"
            onClick={onSelectAllClick}
          >
            <Typography variant="button">
              {allSelected
                ? t("license.unselectAll", {
                    total: usersWithoutDefinitiveLicenseOnPage?.length,
                  })
                : t("license.selectAll", { total: unselectedUsersOnPage.length })}
            </Typography>
          </Button>
        </div>
      </Tooltip>
    </div>
  ) : null
}

export default AdminSelectAllButton
