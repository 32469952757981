import CloseIcon from "@mui/icons-material/Close"
import { Drawer, Typography } from "@mui/material"
import { APP_BAR_HEIGHT } from "features/Theme/theme"
import type { FC, ReactElement } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useCommonStyles, useStyles } from "utils"

import { Separator } from "./"
import { Menu } from "./Menu"

interface Props {
  open: boolean
  title: string
  onClose?: () => void
  children: ReactElement
  onDelete?: () => Promise<void>
}

/**
 * Creates a view which will be displayed at the right of the current screen
 */
export const AsideView: FC<Props> = ({ title, children, onDelete, onClose, open }) => {
  const t = useTranslate()

  const cs = useCommonStyles()
  const s = useStyles(({ spacing }) => ({
    backdrop: {
      backgroundColor: "transparent",
    },
    leftHeader: {
      alignItems: "center",
      display: "flex",
      height: "100%",
    },
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      height: "100%",
      overflow: "clip",
      width: "400px",
    },
    header: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: spacing(0, 1),
      minHeight: APP_BAR_HEIGHT,
    },
  }))

  return (
    <Drawer
      anchor="right"
      {...{ open, onClose }}
      transitionDuration={300}
      ModalProps={{ disableAutoFocus: true }}
      componentsProps={{ backdrop: { style: s.backdrop } }}
    >
      <div style={s.container}>
        <div style={s.header}>
          <div style={s.leftHeader}>
            <CloseIcon cursor="pointer" style={cs.secondaryTextColor} onClick={onClose} />
            <Typography style={cs.marginLeft32} variant="h6">
              {title}
            </Typography>
          </div>
          {!onDelete ? null : (
            <Menu
              actions={[
                {
                  title: t("ra.action.delete"),
                  onClick: onDelete,
                },
              ]}
            />
          )}
        </div>
        <Separator />
        {children}
      </div>
    </Drawer>
  )
}
