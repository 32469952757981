/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {
  AuthenticationType,
  CurrentUser,
  Division,
  DivisionId,
  ResourceId,
  ScenarioId,
  UniverseId,
} from "@newpv/js-common"
import { useDivisionIdContext } from "features/Providers/DivisionIdProvider"
import _ from "lodash"
import { useStore } from "react-admin"

import type { Mode } from "../features/Providers"

export const getAuth = (token: string): { headers: { Authorization: string } } => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

export const useUniverseId = () => useStore<UniverseId>("universeId")
export const useDivisionId = () => {
  const contextValue = useDivisionIdContext()
  const store = useStore<DivisionId | undefined>("divisionId")

  if (!_.isEmpty(contextValue)) {
    return contextValue
  }
  return store
}

export const useResourceId = () => useStore<ResourceId>("resourceId")
// A resource can have an empty scenarioId. The resourceId will be the one to use if it is the case.
export const useScenarioId = () => useStore<ScenarioId | undefined>("scenarioId")

export const useScenarioName = () => useStore<string>("scenarioName")
export const useDivisionIds = () => useStore<Array<Pick<Division, "id">>>("divisionIds")
export const useUserInfos = () => useStore<CurrentUser>("userInfos")
export const useToken = () => useStore<string>("token")
export const useAuthType = () => useStore<AuthenticationType>("authType")
export const useMode = () => useStore<Mode>("mode")
