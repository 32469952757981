import { AsideView } from "components"
import { ConfirmDialog } from "components/ConfirmDialog"
import type { FC } from "react"
import React, { useCallback, useEffect, useState } from "react"
import { Loading, useRefresh, useTranslate } from "react-admin"
import { useNavigate } from "react-router-dom"
import { useDivisionId } from "utils"

import { GeneralTab } from "./GeneralTab"
import { useGroupCreate, useGroupEdit } from "./useGroupFunctions"

interface Props {
  isCreation?: boolean
}

export const BUTTON_DIV_HEIGHT = 68

const groupNs = "adminPanel.groups"

export const GroupDetailsScreen: FC<Props> = ({ isCreation = false }) => {
  /**
   * Contexts
   * */
  const t = useTranslate()
  const refresh = useRefresh()
  const navigate = useNavigate()
  const [localDivisionId] = useDivisionId()

  /**
   * States
   * */
  const [open, setOpen] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const onClose = (): void => {
    setOpen(false)
    setTimeout(() => {
      navigate("..", { replace: true })
      refresh()
    }, 300)
  }

  const onCloseConfirmDialog = useCallback(() => setOpenConfirmDialog(false), [])

  /**
   * The following hook is necessary to trigger the opening animation
   * */
  useEffect((): void => {
    setOpen(true)
  }, [])

  const { save, groupData, isLoading, saving, onDelete } = (
    isCreation ? useGroupCreate : useGroupEdit
  )({
    divisionId: localDivisionId,
  })

  return (
    <>
      <AsideView
        {...{ open, onClose }}
        onDelete={
          isCreation
            ? undefined
            : async () => {
                setOpenConfirmDialog(true)
              }
        }
        title={t(`${groupNs}.${isCreation ? "new" : "details"}`)}
      >
        <>
          {onDelete ? (
            <ConfirmDialog
              onClose={onCloseConfirmDialog}
              onConfirm={onDelete}
              open={openConfirmDialog}
              namespace={groupNs}
            />
          ) : null}
          {isLoading ? (
            <Loading />
          ) : (
            <GeneralTab
              {...{
                isCreation,
                onClose,
                saving,
                save,
                groupData,
              }}
            />
          )}
        </>
      </AsideView>
    </>
  )
}
