import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useStyles } from "utils"

interface Props {
  open: boolean
  onClose: () => void
}

const tooltipNs = "eval.evalModal.generalTab.calcMethodInfos"

export const CalcMethodDialog: FC<Props> = ({ onClose, open }) => {
  const theme = useTheme()
  const t = useTranslate()

  const s = useStyles(({ palette: { background, divider }, spacing }) => ({
    pre: {
      fontFamily: "inherit",
      marginTop: 0,
    },
    modalBox: {
      backgroundColor: background.neutral,
      borderColor: divider,
      borderRadius: "8px",
      borderStyle: "solid",
      borderWidth: 1,
      margin: spacing(1, 0),
      padding: spacing(1),
    },
  }))

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t(`${tooltipNs}.title`)}</DialogTitle>
      <DialogContent>
        <pre style={s.pre}>
          <Typography variant="subtitle1" color={theme.palette.text.secondary}>
            {t(`${tooltipNs}.example`)}
          </Typography>
        </pre>
        <Box sx={s.modalBox}>
          <Typography variant="subtitle1" color={theme.palette.text.secondary}>
            {t(`${tooltipNs}.tooltip`)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("common.ok")}</Button>
      </DialogActions>
    </Dialog>
  )
}
