import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined"
import { Typography } from "@mui/material"
import type { LevelId, LevelRevision, LevelStatic, RuleId, RuleProgression } from "@newpv/js-common"
import { fetchLevel } from "@newpv/js-common"
import { type LevelProgression, parseInt10 } from "@newpv/js-common"
import { Empty, Progress, Separator } from "components"
import parse from "html-react-parser"
import _ from "lodash"
import type { FC } from "react"
import React, { useMemo } from "react"
import { useTranslate } from "react-admin"
import { useQueryRequest, useStyles } from "utils"

import { PROGRESS_NS } from "../ProgressTab"
import { RuleProgress } from "./RuleProgress"

interface Props {
  progress?: Record<LevelId, LevelProgression>
  selectedFormattedLevel: (LevelStatic & { concernedLevels?: LevelStatic[] }) | LevelRevision
}

const ns = "students.studentModal.progressTab"
const LevelRulesList: FC<Props> = ({ progress, selectedFormattedLevel }) => {
  const t = useTranslate()
  const isRevision =
    selectedFormattedLevel.type === "revision" || selectedFormattedLevel.type === "finalRevision"
  const revisionRules = useMemo(
    () =>
      selectedFormattedLevel
        ? isRevision
          ? _.flatten(progress?.[selectedFormattedLevel.id]?.revisionRuleIds)
          : progress?.[selectedFormattedLevel.id]?.rules
        : undefined,
    [isRevision, progress, selectedFormattedLevel],
  )
  const isAutoValidated =
    isRevision &&
    progress?.[selectedFormattedLevel.id] != null &&
    _.isEmpty(progress?.[selectedFormattedLevel.id].revisionRuleIds)

  const {
    data: rulesToShow,
    isFetching,
    isLoading,
    isRefetching,
  } = useQueryRequest<Array<RuleProgression & { id: RuleId }>>(async () => {
    if (
      !selectedFormattedLevel ||
      isAutoValidated ||
      (isRevision && progress?.[selectedFormattedLevel.id] == null)
    ) {
      return
    }

    const rulesProgress = (progress?.[selectedFormattedLevel.id] as LevelProgression | undefined)
      ?.rules
    if (!_.isEmpty(revisionRules) && isRevision) {
      const allRules = _(progress)
        .filter(level => !level.revisionRuleIds)
        .map(level => level.rules)
        .flatten()
        .value()
      const ruleTitles = _.reduce(allRules, (acc, levelRules) => ({ ...acc, ...levelRules }), {})
      return Object.entries(revisionRules).map(([id, revRuleId]) => ({
        id: parseInt10(id),
        ...revRuleId,
        title: ruleTitles?.[revRuleId]?.title,
        completionPercentage: rulesProgress?.[revRuleId]?.maxCompletionPercentage ?? 0,
        isKnown: rulesProgress?.[revRuleId]?.isKnown ?? false,
      }))
    }

    const res = await fetchLevel(undefined, selectedFormattedLevel.id)
    const rules = res.rules

    return rules?.map(elem => ({
      ...elem,
      completionPercentage: rulesProgress?.[elem.id].maxCompletionPercentage ?? 0,
      isKnown: rulesProgress?.[elem.id].isKnown ?? false,
    }))
  }, ["rulesToShow", "levelId", selectedFormattedLevel?.id, "revisionRules", revisionRules])

  const s = useStyles(
    ({ spacing, palette: { secondary, text } }) => ({
      container: {
        flex: 1,
      },
      icon: {
        color: text.secondary,
      },
      information: {
        alignItems: "center",
        backgroundColor: secondary["50"],
        borderRadius: 8,
        display: "flex",
        flexDirection: "row",
        gap: spacing(1),
        margin: spacing(0, 1),
        padding: spacing(1),
      },
      list: {
        display: "flex",
        flexDirection: "column",
        flewGrow: 1,
        maxHeight: "100%",
        overflowY: "auto",
        padding: 0,
      },
      row: {
        display: "flex",
        flexDirection: "row",
        padding: spacing(0.5, 2),
      },
      rowDescription: { padding: spacing(1, 2) },
    }),
    [],
  )

  return (
    <div style={s.container}>
      {isRevision ? (
        <div style={s.information}>
          <LightbulbOutlinedIcon style={s.icon} />
          <Typography variant="subtitle1">{t(`${ns}.informationValidation`)}</Typography>
        </div>
      ) : null}
      {(isLoading || isRefetching || isFetching) && !rulesToShow ? (
        <Progress />
      ) : !rulesToShow ? (
        <Empty
          title={isAutoValidated ? t(`${ns}.validateTitle`) : undefined}
          subtitle={isAutoValidated ? t(`${ns}.validateSubtitle`) : undefined}
        />
      ) : (
        <div style={s.list}>
          {rulesToShow.map((elem, index) => (
            <div key={elem.id}>
              <div style={s.row}>
                <RuleProgress ruleProgress={elem} />
                <div style={s.rowDescription}>
                  <Typography variant="subtitle1">
                    {t(`${PROGRESS_NS}.ruleTitle`, { ruleNbr: index + 1 })}
                  </Typography>
                  <Typography variant="body2">{parse(elem.title ?? "")}</Typography>
                </div>
              </div>
              <Separator />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default LevelRulesList
