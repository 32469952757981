import "./StudentDetails.css"

import DownloadIcon from "@mui/icons-material/Download"
import { Tab, Tabs, Typography, useTheme } from "@mui/material"
import Switch from "@mui/material/Switch"
import type { CurrentUser, LearnerProgression } from "@newpv/js-common"
import { axios, logger } from "@newpv/js-common"
import type { AxiosResponse } from "axios"
import { AsideView, Separator } from "components"
import React, { useCallback, useEffect, useState } from "react"
import { Confirm, Loading, useNotify, useTranslate } from "react-admin"
import type {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query/types/core/types"
import { useNavigate, useParams } from "react-router-dom"
import { BUTTON_DIV_HEIGHT } from "screens/EvalScreen/EvalDetailsScreen"
import { exportNs } from "screens/StatsScreen/StatsExport"
import { generateReportPdf } from "screens/StatsScreen/utils/ExportsUtils"
import {
  getAuth,
  useDivisionId,
  useQueryRequest,
  useScenarioId,
  useStyles,
  useToken,
  useUniverseId,
} from "utils"

import { apiUrl, bffUrl } from "../../features/Constants"
import { APP_BAR_HEIGHT } from "../../features/Theme/theme"
import { PROGRESS_NS, ProgressTab } from "./ProgressTab"
import { ResultsTab } from "./ResultsTab"
import { StatsTab } from "./StatsTab"

export interface TabProps {
  value: number
  progress?: LearnerProgression
  userData?: CurrentUser
  progressRefetch?: (
    options?: RefetchOptions & RefetchQueryFilters<any>,
  ) => Promise<QueryObserverResult<LearnerProgression | undefined>>
}

export const StudentDetails = React.memo(() => {
  /**
   * Contexts
   * */
  const t = useTranslate()
  const notify = useNotify()
  const navigate = useNavigate()
  const [token] = useToken()
  const [scenarioId] = useScenarioId()
  const [universeId] = useUniverseId()
  const [divisionId] = useDivisionId()

  const {
    palette: {
      text: { secondary },
    },
  } = useTheme()

  /**
   * States
   * */
  const [open, setOpen] = useState(false)

  const onClose = (): void => {
    setOpen(false)
    setDialogOpen(false)
    setTimeout(() => {
      navigate("..", { replace: true })
    }, 300)
  }

  /**
   * Data functions
   * */
  const { id: learnerId } = useParams() as { id: string }

  const {
    data: learnerData,
    isLoading,
    refetch: refetchUserData,
  } = useQueryRequest<CurrentUser>(
    async () => {
      const res = await axios.get<any, AxiosResponse<CurrentUser>>(
        `${apiUrl}/universe/user/${learnerId}`,
        getAuth(token),
      )
      return res.data
    },
    ["learner", learnerId],
    {
      onSuccess: result => {
        setHasExtraTime(result.extraTime ?? false)
      },
    },
  )

  const { data: progress, refetch } = useQueryRequest<LearnerProgression | undefined>(async () => {
    if (!learnerId || !scenarioId) {
      return
    }
    return (
      await axios.get<any, AxiosResponse<LearnerProgression>>(
        `${bffUrl}/stats/${learnerId}?scenarioId=${scenarioId}`,
        getAuth(token),
      )
    ).data
  }, ["learnerProgression", "scenarioId", scenarioId, "studentId", learnerId])

  const updateStudent = useCallback(
    async (extraTimeValue: boolean) => {
      if (!learnerId) {
        return
      }
      try {
        await axios.put(
          `${apiUrl}/universe/universe/${universeId}/learner/${learnerId}`,
          { extraTime: extraTimeValue },
          getAuth(token),
        )

        setHasExtraTime(extraTimeValue)
        refetchUserData()
        return
      } catch (err) {
        logger("error udpating a student", err)
        notify("ra.notification.learner_update_err", { type: "error" })
        return
      }
    },
    [learnerId, notify, refetchUserData, token, universeId],
  )

  /**
   * Components hooks
   * */

  /**
   * This syntax is necessary to trigger the opening animation
   * */
  useEffect(() => {
    setOpen(true)
  }, [])

  const s = useStyles(
    ({
      palette: {
        common: { white },
      },
      spacing,
    }) => ({
      icon: {
        paddingRight: spacing(1),
      },
      mainDiv: {
        alignSelf: "flex-start",
        backgroundColor: white,
        display: "flex",
        flex: 1,
        flexDirection: "column",
        // TODO: remove calc max height here
        maxHeight: `calc(100vh - ${APP_BAR_HEIGHT + 2}px)`,
        width: "400px",
      },
      buttons: {
        alignItems: "center",
        backgroundColor: white,
        cursor: "pointer",
        display: "flex",
        height: BUTTON_DIV_HEIGHT,
        justifyContent: "start",
        paddingLeft: spacing(2),
        zIndex: 100,
      },
      student: {
        overflow: "hidden",
        padding: spacing(2, 2),
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      switchLabel: {
        padding: spacing(0, 1),
        verticalAlign: "center",
      },
      switchWrapper: {
        alignItems: "center",
        display: "flex",
        padding: spacing(0, 0, 0, 1.5),
      },
      error: {
        margin: spacing(4, 2, 2, 2),
        textAlign: "center",
      },
    }),
  )

  const [value, setValue] = useState(0)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [hasExtraTime, setHasExtraTime] = useState(learnerData?.extraTime ?? false)

  const openConfirmDialog = useCallback((newStatus: boolean) => {
    setHasExtraTime(newStatus)
    setDialogOpen(true)
  }, [])

  // close = cancel
  const handleDialogClose = useCallback(async () => {
    setHasExtraTime(oldState => !oldState)
    setDialogOpen(false)
  }, [])

  const handleConfirm = useCallback(async () => {
    await updateStudent(hasExtraTime)
    setDialogOpen(false)
  }, [hasExtraTime, updateStudent])

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }
  const tabs = [
    t("students.studentModal.progressTab.title"),
    t("students.studentModal.statsTab.title"),
    t("students.studentModal.resultsTab.title"),
  ]

  const exportPdf = useCallback(async () => {
    try {
      const res = await axios.get<any, AxiosResponse<Blob>>(
        `${apiUrl}/training/scenario/${scenarioId}/learning-report`,
        {
          responseType: "blob",
          params: {
            divisionId,
            learnerId,
          },
          ...getAuth(token),
        },
      )
      if (learnerData) {
        generateReportPdf(
          `${t(`${exportNs}.report.title`)} - ${learnerData.lastName} ${learnerData.firstName}`,
          res.data,
        )
      }
    } catch (err) {
      logger("error generating the pdf", err)
      notify("ra.notification.http_error", { type: "error" })
    }
  }, [divisionId, learnerData, learnerId, notify, scenarioId, t, token])

  // The following rule is necessary to avoid form errors
  // noinspection RequiredAttributes
  return !learnerData ? null : (
    <>
      <Confirm
        isOpen={open && dialogOpen}
        loading={isLoading}
        title={t("students.studentModal.extraTime.extraTime")}
        content={
          <Typography style={{ whiteSpace: "pre-wrap" }}>
            {t(
              `students.studentModal.extraTime.${hasExtraTime ? "setExtraTime" : "unsetExtraTime"}`,
            )}
          </Typography>
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        confirmColor="warning"
        ConfirmIcon={() => <></>}
        CancelIcon={() => <></>}
      />
      <AsideView open={open} onClose={onClose} title={t(`students.studentModal.title`)}>
        {isLoading ? (
          <Loading />
        ) : (
          <div style={s.mainDiv}>
            <div>
              <Typography style={s.student} variant="body1">{`${learnerData.lastName} ${
                learnerData.firstName ?? ""
              }`}</Typography>
              <div style={s.switchWrapper}>
                <Switch
                  id="extraTime"
                  name="extraTime"
                  color="primary"
                  onChange={event => {
                    openConfirmDialog(event.target.checked)
                  }}
                  checked={hasExtraTime}
                  inputProps={{ "aria-label": t("students.studentModal.extraTime.extraTime") }}
                  size="small"
                />
                <Typography style={s.switchLabel} variant="body2">
                  {t("students.studentModal.extraTime.extraTime")}
                </Typography>
              </div>
              {scenarioId != null ? (
                <Tabs value={value} variant="fullWidth" textColor="inherit" onChange={handleChange}>
                  {tabs.map((elem, index) => (
                    <Tab
                      key={index}
                      label={elem}
                      id={`tab-${index}`}
                      aria-controls={`tab-panel-${index}`}
                    />
                  ))}
                </Tabs>
              ) : (
                <Typography style={s.error} variant="subtitle1">
                  {t(`${PROGRESS_NS}.noRoute`)}
                </Typography>
              )}
            </div>
            {scenarioId != null ? (
              <>
                {/* // TODO: remove fixed height here */}
                <div style={{ height: "calc(100vh - 216px)" }}>
                  <ProgressTab {...{ value, progress }} />
                  <StatsTab {...{ value, progress }} userData={learnerData} />
                  <ResultsTab
                    progressRefetch={refetch}
                    userData={learnerData}
                    {...{ value, progress }}
                  />
                </div>
                <Separator />
                <div style={s.buttons} onClick={exportPdf}>
                  <DownloadIcon style={s.icon} sx={{ color: secondary }} />
                  <Typography variant="button" textTransform="uppercase">
                    {t("students.studentModal.download")}
                  </Typography>
                </div>
              </>
            ) : null}
          </div>
        )}
      </AsideView>
    </>
  )
})
