import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material"
import clsx from "clsx"
import { useListContext, useResourceContext, useTranslate } from "ra-core"
import { DatagridClasses } from "ra-ui-materialui/src/list/datagrid/useDatagridStyles"
import * as React from "react"
import { Children, type FC, isValidElement, useCallback } from "react"
import type { DatagridHeaderProps } from "react-admin"

import { CustomHeaderCell } from "./CustomHeaderCell"

/**
 * Local custom datagrid header for rule datagrid
 */
export const CustomDatagridHeader: FC<DatagridHeaderProps> = props => {
  const { children, className, hasBulkActions = false, isRowSelectable } = props
  const resource = useResourceContext(props)
  const translate = useTranslate()
  const { sort, data, onSelect, selectedIds, setSort } = useListContext(props)

  const updateSortCallback = useCallback(
    event => {
      event.stopPropagation()
      const newField = event.currentTarget.dataset.field
      const newOrder =
        sort.field === newField
          ? sort.order === "ASC"
            ? "DESC"
            : "ASC"
          : event.currentTarget.dataset.order

      setSort({ field: newField, order: newOrder })
    },
    [sort.field, sort.order, setSort],
  )

  // @ts-ignore
  const updateSort = setSort ? updateSortCallback : null

  const handleSelectAll = useCallback(
    event =>
      onSelect(
        event.target.checked
          ? selectedIds.concat(
              data
                .filter(record => !selectedIds.includes(record.id))
                .filter(record => (isRowSelectable ? isRowSelectable(record) : true))
                .map(record => record.id),
            )
          : [],
      ),
    [data, onSelect, isRowSelectable, selectedIds],
  )

  const selectableIds = Array.isArray(data)
    ? isRowSelectable
      ? data.filter(record => isRowSelectable(record)).map(record => record.id)
      : data.map(record => record.id)
    : []

  // @ts-ignore
  return (
    <TableHead className={clsx(className, DatagridClasses.thead)}>
      <TableRow className={clsx(DatagridClasses.row, DatagridClasses.headerRow)}>
        {hasBulkActions && selectedIds && (
          <TableCell padding="checkbox" className={DatagridClasses.headerCell}>
            <Checkbox
              aria-label={translate("ra.action.select_all", {
                _: "Select all",
              })}
              className="select-all"
              color="primary"
              checked={
                selectedIds.length > 0 &&
                selectableIds.length > 0 &&
                selectableIds.every(id => selectedIds.includes(id))
              }
              onChange={handleSelectAll}
              onClick={e => e.stopPropagation()}
            />
          </TableCell>
        )}
        {Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <CustomHeaderCell
              className={clsx(DatagridClasses.headerCell, `column-${(field.props as any).source}`)}
              sort={sort}
              field={field}
              key={(field.props as any).source || index}
              resource={resource}
              // @ts-ignore
              updateSort={updateSort}
            />
          ) : null,
        )}
      </TableRow>
    </TableHead>
  )
}
