import ErrorIcon from "@mui/icons-material/Error"
import { Typography } from "@mui/material"
import type { UserId } from "@newpv/js-common"
import EmptyScreen from "components/EmptyScreen"
import dayjs from "dayjs"
import _ from "lodash"
import type { Dispatch, FC, SetStateAction } from "react"
import { useMemo } from "react"
import { useCallback } from "react"
import * as React from "react"
import { Button, Datagrid, FunctionField, useListContext, useTranslate } from "react-admin"
import { useNavigate } from "react-router-dom"
import { useStyles } from "utils"

import { DATE_FORMAT_SHT } from "../../../features/Constants"
import { commonNs } from "../../../features/i18n/fr"
import { NameField } from "../../StudentScreen/components/NameField"
import { AdminCheckboxField } from "./AdminCheckboxField"
import type { ResourceSummary, UserWithLicenses } from "./AdminLicensesScreen"
import { DatagridHeader } from "./DatagridHeader"

export const ns = "resources.licenses.fields"

interface DataProps {
  newlySelectedIds: UserId[]
  setNewlySelectedIds: Dispatch<SetStateAction<UserId[]>>
  newlyUnselectedIds: UserId[]
  setNewlyUnselectedIds: Dispatch<SetStateAction<UserId[]>>
  nbOfAvailableLicenses: number
  selectedResource?: ResourceSummary
}

export const AdminLicenseDataGrid: FC<DataProps> = ({
  nbOfAvailableLicenses,
  newlySelectedIds,
  newlyUnselectedIds,
  setNewlyUnselectedIds,
  setNewlySelectedIds,
  selectedResource,
}) => {
  const t = useTranslate()
  const navigate = useNavigate()

  const s = useStyles(({ spacing }) => ({
    licenseCount: {
      display: "flex",
      alignItems: "center",
      gap: spacing(1),
    },
    rowItem: {
      cursor: "pointer",
      width: "100%",
    },
  }))

  const openDetail = useCallback(
    (userId: UserId): void => {
      navigate(`/adminPanel/licenses/${userId}`)
    },
    [navigate],
  )

  const { data: userWithLicenses } = useListContext<UserWithLicenses>()
  const initiallySelectedIdsOnPage = useMemo(
    () => userWithLicenses?.filter(user => user.selectedLicense).map(user => user.id) ?? [],
    [userWithLicenses],
  )

  const selectedUsersOnPage = useMemo(
    () =>
      _(initiallySelectedIdsOnPage).concat(newlySelectedIds).difference(newlyUnselectedIds).value(),
    [initiallySelectedIdsOnPage, newlySelectedIds, newlyUnselectedIds],
  )

  return (
    <Datagrid
      empty={
        <EmptyScreen
          button={
            <Button
              label="adminPanel.divisions.divisionAccess"
              variant="contained"
              onClick={() => navigate("/adminPanel/divisions")}
            />
          }
          subtitle={t(`${ns}.emptySubtitle`)}
          title={t(`adminPanel.licenses.empty`)}
          isSmallHeight={true}
        />
      }
      bulkActionButtons={false}
      header={<DatagridHeader />}
    >
      <AdminCheckboxField
        {...{
          nbOfAvailableLicenses,
          newlyUnselectedIds,
          setNewlyUnselectedIds,
          setNewlySelectedIds,
          selectedUsersOnPage,
        }}
        nbOfLicensesToAdd={newlySelectedIds.length}
        nbOfLicensesToRemove={newlyUnselectedIds.length}
        globalDisable={selectedResource == null}
        label={t(`${ns}.license`)}
      />
      <NameField
        onClick={openDetail}
        source="lastName"
        label={t(`${commonNs}.name`)}
        emptyText="-"
      />
      <NameField
        onClick={openDetail}
        source="firstName"
        label={t(`${commonNs}.firstName`)}
        emptyText="-"
      />
      <FunctionField
        label={t(`adminPanel.licenses.columns.division`)}
        sortable={true}
        sortBy="divisionName"
        render={(record: UserWithLicenses) => (
          <Typography onClick={() => openDetail(record.id)} variant="subtitle1" sx={s.rowItem}>
            {record.divisionName ?? "-"}
          </Typography>
        )}
      />
      <FunctionField
        label={t(`adminPanel.licenses.columns.endDate`)}
        sortable={false}
        render={(record: UserWithLicenses) => (
          <Typography onClick={() => openDetail(record.id)} variant="subtitle1" sx={s.rowItem}>
            {record.selectedLicense?.endDate
              ? dayjs(record.selectedLicense.endDate).format(DATE_FORMAT_SHT)
              : "-"}
          </Typography>
        )}
      />
      <FunctionField
        label={t(`adminPanel.licenses.columns.attributionDate`)}
        sortable={false}
        render={(record: UserWithLicenses) => (
          <Typography onClick={() => openDetail(record.id)} variant="subtitle1" sx={s.rowItem}>
            {
              // TODO: using startDate according to Jordan but I'm not sure about this
              record.selectedLicense?.startDate
                ? dayjs(record.selectedLicense.startDate).format(DATE_FORMAT_SHT)
                : "-"
            }
          </Typography>
        )}
      />
      <FunctionField
        label={t(`adminPanel.licenses.columns.licenseCount`)}
        sortable={false}
        render={(record: UserWithLicenses) => (
          <div style={s.licenseCount}>
            <Typography
              onClick={() => openDetail(record.id)}
              color={record.licenseCount === 0 ? "secondary" : undefined}
              variant={record.licenseCount === 0 ? "body1" : "subtitle1"}
              sx={s.rowItem}
            >
              {record.licenseCount}
            </Typography>
            {record.licenseCount === 0 ? <ErrorIcon color="secondary" /> : null}
          </div>
        )}
      />
    </Datagrid>
  )
}
