import "./SessionScreen.css"

import { Typography, useTheme } from "@mui/material"
import type {
  Group,
  GroupId,
  MinimalUserData,
  SessionCreate,
  SessionEdit,
  TmpSession,
  UserId,
} from "@newpv/js-common"
import { isEmpty } from "@newpv/js-common"
import { AsideView, MuiSelect } from "components"
import dayjs from "dayjs"
import _ from "lodash"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Form,
  FormDataConsumer,
  Loading,
  required,
  SaveButton,
  TextInput,
  useGetList,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin"
import { useCommonStyles, useDivisionId, useStyles } from "utils"
import type { Modify, SetState } from "utils/CommonTypes"
import useStudentsFromDivision from "utils/useStudentsFromDivision"

import { DATE_FORMAT_SHT } from "../../features/Constants"
import { sessionNs } from "../../features/i18n"
import { DateInput } from "./components/DateInput"
import { SearchableCheckboxList } from "./components/SearchableCheckboxList"
import { TimeInput } from "./components/TimeInput"
import { defaultSession, useSessionCreate, useSessionEdit } from "./useSessionsFunctions"

interface Props {
  open: boolean
  evalId?: string
  sessionId?: string
  setOpen: SetState<boolean>
  newEvalSessions: TmpSession[]
  setNewEvalSessions: SetState<TmpSession[]>
  setSessionId: SetState<string | undefined>
  defaultLearnerId?: string
  setRetryLearnerId: Dispatch<SetStateAction<string | undefined>>
  usersWithFutureSessionsPlanned?: Record<UserId, boolean>
}

export const SessionScreen: FC<Props> = ({
  open,
  evalId,
  setOpen,
  sessionId,
  setSessionId,
  newEvalSessions,
  setNewEvalSessions,
  defaultLearnerId,
  setRetryLearnerId,
  usersWithFutureSessionsPlanned,
}) => {
  const t = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const currentDate = dayjs()

  const {
    palette: { action, text },
  } = useTheme()
  const [divisionId] = useDivisionId()
  const [submitting, setSubmitting] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [localOpen, setLocalOpen] = useState(false)
  // "" default value = all groups/no filter
  const [groupId, setGroupId] = useState<GroupId>("")

  const onSuccess = useCallback((): void => {
    setLocalOpen(false)
    setRetryLearnerId(undefined)

    setTimeout(() => {
      setOpen(false)
      setSessionId(undefined)
      refresh()
    }, 300)
  }, [refresh, setOpen, setRetryLearnerId, setSessionId])

  const noEvalValue = useMemo(
    () => ({
      sessionData: newEvalSessions.find(elem => elem.tmpId === sessionId) ?? defaultSession(),
      save: (data: SessionCreate & { tmpId?: string }) => {
        setNewEvalSessions(prev =>
          _.uniqBy([...[{ tmpId: `${prev.length}`, ...data }], ...prev], "tmpId"),
        )
        onSuccess()
      },
      saving: false,
      isLoading: false,
    }),
    [newEvalSessions, onSuccess, sessionId, setNewEvalSessions],
  )

  const evalValue = (sessionId ? useSessionEdit : useSessionCreate)(evalId, sessionId)

  /**
   * Data functions
   * */
  const {
    sessionData,
    save,
    saving,
    isLoading: isSessionLoading,
  } = !evalId ? noEvalValue : evalValue

  const { data: studentListRaw, isLoading: isStudentsLoading } = useStudentsFromDivision()

  const { data: allGroups } = useGetList<Group>(`universe/division/${divisionId}/groups`, {
    meta: {
      idField: "groupId",
      pageSize: -1,
    },
  })

  useEffect(() => {
    // This will trigger the opening setState with a 300ms delay to wait for the animation to end
    setTimeout(() => setLocalOpen(open), open ? 0 : 300)
  }, [open])

  /** useMemos */
  // if defaultLearnerId but no session id, it's because it's a retry session
  const replayEvalSessionData: Omit<SessionCreate, "id"> | undefined = useMemo(
    () =>
      defaultLearnerId
        ? {
            startDate: dayjs().add(1, "minute").toISOString(),
            dueDate: dayjs().add(1, "year").add(1, "minute").toISOString(),
            evaluationId: evalId ?? "",
            learnersIds: [defaultLearnerId],
            name: t("eval.sessionModal.retrySessionName", {
              date: dayjs().format(DATE_FORMAT_SHT),
            }),
          }
        : undefined,
    [defaultLearnerId, evalId, t],
  )

  const isDueDateReached = useMemo(
    () => currentDate.isAfter(sessionData?.dueDate),
    [currentDate, sessionData?.dueDate],
  )
  const isSessionDone = !!sessionId && isDueDateReached

  const studentList = useMemo(
    () =>
      defaultLearnerId
        ? studentListRaw?.filter(student => usersWithFutureSessionsPlanned?.[student.id] != null)
        : studentListRaw,
    [defaultLearnerId, studentListRaw, usersWithFutureSessionsPlanned],
  )

  const filteredStudentList = useMemo(
    () =>
      searchText.length === 0 && !groupId
        ? studentList?.map(e => ({ ...e, firstName: e.firstName ?? "" }))
        : studentList?.filter(
            e =>
              `${e.firstName} ${e.lastName}`
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase()) &&
              (!groupId ? true : groupId === "0" ? e.groupId == null : e.groupId === groupId),
          ),
    [groupId, searchText, studentList],
  )

  /** Functions */

  const onSubmit: (data) => Promise<any> = useCallback(
    async data => {
      setSubmitting(true)
      return save?.(
        {
          ..._.omit(data, ["evaluationId", "id"]),
          learnersIds: data.learnersIds ?? [],
          dueDate: new Date(data.dueDate).toISOString(),
          startDate: new Date(data.startDate).toISOString(),
        },
        // @ts-ignore
        {
          onSuccess,
          onError: () => {
            notify("ra.notification.http_error", { type: "error" })
          },
        },
      )
    },
    [notify, onSuccess, save],
  )

  // this is an important check for form validation, but won't be displayed directly, see DateInput component
  const validateUserCreation = useCallback(
    (values: SessionCreate): Modify<SessionCreate, string> => {
      const dueDate = dayjs(values.dueDate)
      const startDate = dayjs(values.startDate)
      const errors: Modify<SessionCreate, string> = {}
      if (isSessionDone) {
        return {}
      }
      if (dueDate.isBefore(dayjs())) {
        errors.dueDate = t("fields.dateIsPassed")
      }

      if (startDate.isAfter(dueDate)) {
        errors.dueDate = t("fields.wrongEndDate")
        errors.startDate = t("fields.wrongStartDate")
      }
      if (sessionId == null && startDate.isBefore(dayjs())) {
        errors.startDate = t("fields.startDateLimit")
      }

      if (isEmpty(values?.name)) {
        errors.name = t("ra.validation.required")
      }
      if (!startDate.isValid() || !dueDate.isValid()) {
        errors.startDate = t("fields.invalidDates")
        errors.dueDate = t("fields.invalidDates")
      }
      return errors
    },
    [isSessionDone, sessionId, t],
  )

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const dateTimeInfos = useCallback(
    (formData: { startDate?: Date; dueDate?: Date }) => [
      {
        title: t(`${sessionNs}.sessionStart`),
        name: "startDate",
      },
      {
        title: t(`${sessionNs}.sessionEnd`),
        name: "dueDate",
        minDate: formData?.startDate,
      },
    ],
    [t],
  )

  const renderListItem = useCallback(
    (elem: MinimalUserData, disabled: boolean) => (
      <Typography
        color={disabled ? action.disabled : text.primary}
        variant="subtitle1"
      >{`${elem.firstName} ${elem.lastName}`}</Typography>
    ),
    [action.disabled, text.primary],
  )

  const disableStudentSelection = useCallback(
    (elem: MinimalUserData) =>
      // retry session creation, and the user already has a planned session - so we cannot select them for a new session
      (defaultLearnerId != null && usersWithFutureSessionsPlanned?.[elem.id]) ||
      // or the user has started their session, so we cannot unselect them anymore
      (sessionData as SessionEdit)?.learnersToNotStartedStatus?.[elem.id] === false,
    [defaultLearnerId, sessionData, usersWithFutureSessionsPlanned],
  )

  /**
   * Styling
   * */
  const cs = useCommonStyles()
  const s = useStyles(({ typography: { subtitle1 }, spacing, palette: { background } }) => ({
    topDiv: {
      padding: spacing(2),
    },
    nameDiv: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      marginTop: spacing(3),
    },
    mainDiv: {
      alignSelf: "flex-start",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      minHeight: 0,
    },
    formInnerDiv: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    dateDiv: {
      display: "flex",
      flexDirection: "row",
      marginBottom: spacing(1),
      marginTop: spacing(1),
    },
    bottomDiv: {
      alignItems: "center",
      backgroundColor: background.default,
      bottom: "0",
      display: "flex",
      justifyContent: "flex-start",
      left: 0,
      minHeight: 68,
      paddingLeft: spacing(2),
      position: "absolute",
      width: "100%",
    },
    muiInput: {
      "& .MuiInputBase-root": subtitle1,
      "& .MuiFormLabel-root": { ...subtitle1, lineHeight: "22px" },
    },
    boxGroup: { margin: spacing(0, 2) },
  }))

  return (
    <AsideView
      open={localOpen}
      onClose={onSuccess}
      title={t(`${sessionNs}.${!sessionId ? "new" : "details"}`)}
    >
      {isStudentsLoading || isSessionLoading ? (
        <Loading />
      ) : (
        <div style={s.mainDiv}>
          <Form
            id="save_session"
            mode="onChange"
            className="form"
            defaultValues={defaultLearnerId != null ? replayEvalSessionData : sessionData}
            validate={validateUserCreation}
            onSubmit={onSubmit}
          >
            <div style={s.formInnerDiv}>
              <div style={s.topDiv}>
                <FormDataConsumer>
                  {({ formData }) =>
                    dateTimeInfos(formData).map(({ name, title, ...rest }) => (
                      <div key={name}>
                        <Typography variant="subtitle1">{title}</Typography>
                        <div style={s.dateDiv}>
                          <DateInput
                            disabled={isSessionDone}
                            source={name}
                            label="date"
                            name={name}
                            isCreation={sessionId == null}
                            {...rest}
                          />
                          <div style={cs.marginLeft}>
                            <TimeInput
                              disabled={isSessionDone}
                              source={name}
                              label="date"
                              {...rest}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </FormDataConsumer>
                <TextInput
                  fullWidth
                  name="name"
                  size="small"
                  source="name"
                  color="primary"
                  variant="outlined"
                  style={cs.marginBottom16}
                  sx={s.muiInput}
                  label={t("common.name")}
                  validate={required("ra.validation.required")}
                />
                <Typography variant="body1">{t(`${sessionNs}.users`)}</Typography>
              </div>
              {(allGroups?.length ?? 0) === 0 ? null : (
                <MuiSelect
                  id="groups"
                  variant="outlined"
                  disabled={(allGroups?.length ?? 0) === 0}
                  value={groupId ?? ""}
                  boxStyle={s.boxGroup}
                  onValueChange={elem => {
                    setGroupId(elem)
                  }}
                  items={_.concat(
                    [{ value: "", text: t("adminPanel.groups.allGroups") }],
                    allGroups?.map(elem => ({ value: elem.id, text: elem.name })) ?? [],
                    [{ value: "0", text: t("adminPanel.groups.withoutGroups") }],
                  )}
                  displayEmpty={true}
                />
              )}
              <SearchableCheckboxList
                source="learnersIds"
                filteredOptions={filteredStudentList}
                disableGlobally={isDueDateReached}
                // if retry session creation
                disableBulkSelect={defaultLearnerId != null}
                disableOption={disableStudentSelection}
                {...{
                  searchText,
                  setSearchText,
                  renderListItem,
                }}
              />
            </div>
            <div style={s.bottomDiv}>
              <SaveButton
                icon={<></>}
                disabled={saving || submitting}
                alwaysEnable={defaultLearnerId != null}
              />
            </div>
          </Form>
        </div>
      )}
    </AsideView>
  )
}
