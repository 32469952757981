import ExitIcon from "@mui/icons-material/PowerSettingsNew"
import { Typography } from "@mui/material"
import MuiMenuItem from "@mui/material/MenuItem"
import type { FC } from "react"
import React, { useCallback } from "react"
import { useLogout, useTranslate } from "react-admin"
import { useCommonStyles, useStyles } from "utils"

export const LogoutButton: FC = () => {
  const logout = useLogout()
  const t = useTranslate()
  const handleClick = useCallback((): void => {
    logout()
  }, [logout])

  const cs = useCommonStyles()
  const s = useStyles(
    ({
      spacing,
      palette: {
        text: { primary },
      },
    }) => ({
      logout: {
        color: primary,
        marginLeft: spacing(1),
      },
    }),
  )

  return (
    <MuiMenuItem onClick={handleClick}>
      <ExitIcon sx={cs.secondaryTextColor} />
      <Typography variant="subtitle1" style={s.logout}>
        {t("menu.logout")}
      </Typography>
    </MuiMenuItem>
  )
}
