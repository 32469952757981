import GroupsIcon from "@mui/icons-material/Groups"
import type { Group } from "@newpv/js-common"
import { type LearnerProgression, type User } from "@newpv/js-common"
import { DateField, ScreenContainer } from "components"
import EmptyScreen from "components/EmptyScreen"
import { HeaderWithTooltip } from "components/HeaderWithTooltip"
import _ from "lodash"
import type { FC } from "react"
import React, { useMemo } from "react"
import {
  Datagrid,
  List,
  SearchInput,
  SelectInput,
  TextField,
  useGetList,
  useListContext,
  useNotify,
  usePermissions,
  useTranslate,
} from "react-admin"
import { Route, Routes } from "react-router-dom"
import { useCommonStyles, useDivisionId, useScenarioId, useStyles } from "utils"

import { commonNs } from "../../features/i18n/fr"
import { NameField } from "./components/NameField"
import { PercentField } from "./components/PercentField"
import { TimeField } from "./components/TimeField"
import { StudentDetails } from "./StudentDetails"

const ns = "resources.users.fields"

const UserStatsDataGrid: FC = () => {
  const t = useTranslate()
  const { data, isLoading } = useListContext<User & Partial<LearnerProgression>>()

  return (
    <Datagrid
      data={data}
      isLoading={isLoading}
      rowClick={id => id.toString()}
      empty={
        <EmptyScreen title={t("students.empty")} subtitle={t("stats.noDataSubtitleDivision")} />
      }
      bulkActionButtons={false}
    >
      <NameField source="lastName" label={t(`${commonNs}.name`)} sortBy="lastName" />
      <NameField
        source="firstName"
        label={t(`${commonNs}.firstName`)}
        sortBy="firstName"
        emptyText="-"
      />
      <TextField
        source="groupName"
        variant="subtitle1"
        label={t(`${ns}.stats.group`)}
        sortable={true}
        sortBy="groupName"
        emptyText="-"
      />
      <DateField sortable={false} source="lastUseDate" label={t(`${ns}.stats.lastUseDate`)} />
      <TimeField
        sortable={false}
        source="trainingDuration"
        label={t(`${ns}.stats.trainingDuration`)}
      />
      <PercentField
        sortable={false}
        source="progression.initialLevel"
        label={
          <HeaderWithTooltip
            title={t(`${ns}.stats.initialCompletionPercentage`)}
            tooltip={t(`${ns}.stats.tooltip.initialCompletionPercentage`)}
          />
        }
      />
      <PercentField
        sortable={false}
        source="completionPercentage"
        label={
          <HeaderWithTooltip
            title={t(`${ns}.stats.completionPercentage`)}
            tooltip={t(`${ns}.stats.tooltip.completionPercentage`)}
          />
        }
      />
    </Datagrid>
  )
}

export const UserStatTable: FC = () => {
  const t = useTranslate()
  const notify = useNotify()
  const { permissions } = usePermissions()
  const [divisionId] = useDivisionId()
  const [scenarioId] = useScenarioId()

  const isENT = permissions?.includes("ENT")

  const s = useStyles(() => ({
    list: {
      "& .RaList-main": {
        boxShadow: "none",
      },
      "& .RaList-content": {
        borderRadius: "2px",
      },
      "& .RaDatagrid-tableWrapper": {
        borderRadius: "2px",
        boxShadow: 4,
        overflowX: "hidden",
      },
    },
  }))

  const { data: allGroups } = useGetList<Group>(
    `universe/division/${divisionId}/groups`,
    {
      meta: {
        idField: "groupId",
        pageSize: -1,
      },
    },
    { enabled: !!divisionId },
  )

  const postFilters = useMemo(
    () =>
      _.compact([
        <SearchInput variant="outlined" source="search" key={0} alwaysOn={true} />,
        isENT ? null : (
          <SelectInput
            key={1}
            sx={{ width: "200px" }}
            source="groupId"
            choices={_.sortBy(allGroups, "name").concat([
              { id: "0", name: t("adminPanel.groups.withoutGroups") } as Group,
            ])}
            label={t("adminPanel.groups.selectGroup")}
            variant="outlined"
            alwaysOn={true}
            emptyText={t("adminPanel.groups.allGroups")}
            emptyValue=""
          />
        ),
      ]),
    [allGroups, t, isENT],
  )

  return !divisionId ? (
    <EmptyScreen title={t("students.empty")} subtitle={t("stats.noDataSubtitleDivision")} />
  ) : (
    <>
      <List<User & Partial<LearnerProgression>>
        actions={false}
        resource={`stats/division/${divisionId}/users`}
        sx={s.list}
        exporter={false}
        empty={
          <EmptyScreen title={t("students.empty")} subtitle={t("stats.noDataSubtitleDivision")} />
        }
        queryOptions={{
          onError: () => notify("ra.notification.http_error", { type: "error" }),
        }}
        sort={{ field: "lastName", order: "ASC" }}
        filter={{ scenarioId }}
        // "source" has to be "search" to correctly filter in the API
        filters={postFilters}
        filterDefaultValues={{ search: "", groupId: "" }}
        // disable filter sync with url
        // disableSyncWithLocation also disables the persistence of the list parameters in the Store - which is what we want
        disableSyncWithLocation={true}
      >
        <UserStatsDataGrid />
      </List>
      <Routes>
        <Route path="/:id" element={<StudentDetails />} />
      </Routes>
    </>
  )
}

const StudentListScreen: FC = () => {
  const t = useTranslate()

  const cs = useCommonStyles()

  return (
    <ScreenContainer
      icon={<GroupsIcon sx={cs.primary600} fontSize="large" />}
      title={t("students.title")}
    >
      <div style={cs.fullView}>
        <UserStatTable />
      </div>
    </ScreenContainer>
  )
}

export default StudentListScreen
