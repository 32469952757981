import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import { Tooltip, Typography } from "@mui/material"
import type { FC, ReactElement } from "react"
import React from "react"
import { useCommonStyles, useStyles } from "utils"

interface IProps {
  title: string
  tooltip: string
}

export const HeaderWithTooltip: FC<IProps> = ({ tooltip, title }): ReactElement => {
  const cs = useCommonStyles()
  const s = useStyles(
    () => ({
      container: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
      },
    }),
    [],
  )

  return (
    <div style={s.container}>
      <Typography variant="body2">{title}</Typography>
      <Tooltip title={tooltip}>
        <HelpOutlineOutlinedIcon style={cs.marginLeft} />
      </Tooltip>
    </div>
  )
}
