import { ContentCopy } from "@mui/icons-material"
import RefreshIcon from "@mui/icons-material/Refresh"
import { Button, Typography, useTheme } from "@mui/material"
import { apiUrl, axios, logger } from "@newpv/js-common"
import { Empty } from "components"
import type { FC, ReactElement } from "react"
import React, { useCallback, useState } from "react"
import { useNotify, useRefresh, useTranslate } from "react-admin"
import { useQueryClient } from "react-query"
import type { QueryObserverResult } from "react-query/types/core/types"
import { getAuth, useCommonStyles, useStyles, useToken, useUniverseId } from "utils"
import delay from "utils/delay"
import { hideText } from "utils/hideText"

import type { UserWithLicenses } from "./AdminLicensesScreen"

interface Props {
  learnerData?: UserWithLicenses
  refetchLearnerData: () => Promise<QueryObserverResult<UserWithLicenses, unknown>>
}

const RowItem: FC<{ label: string; value?: string; leftIcon?: ReactElement }> = ({
  label,
  value,
  leftIcon,
}): ReactElement => {
  const { palette } = useTheme()

  const s = useStyles(({ spacing, palette: { surface } }) => ({
    content: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    rowItem: {
      alignItems: "flex-start",
      border: "1px solid",
      borderColor: surface.outline,
      borderRadius: spacing(1),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: spacing(1),
      padding: spacing(1, 2),
    },
  }))

  return (
    <div style={s.rowItem}>
      <Typography color={palette.text.secondary} variant="body2">
        {label}
      </Typography>
      <div style={s.content}>
        <Typography variant="body2">{value ?? "-"}</Typography>
        {leftIcon}
      </div>
    </div>
  )
}

const ns = "adminPanel.licenses.generalTab"
export const GeneralTab: FC<Props> = ({ learnerData, refetchLearnerData }) => {
  const [token] = useToken()
  const [universeId] = useUniverseId()
  const notify = useNotify()
  const queryClient = useQueryClient()
  const refresh = useRefresh()
  const t = useTranslate()

  const [loading, setLoading] = useState(false)
  const {
    palette: { text: themeText },
  } = useTheme()

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing, palette: { action } }) => ({
      button: {
        position: "relative",
        textTransform: "capitalize",
        top: -4,
      },
      container: {
        padding: spacing(0, 1, 2, 1),
      },
      regenerate: {
        borderColor: action.outlined,
        borderRadius: 8,
        borderStyle: "solid",
        borderWidth: 1,
        padding: spacing(1),
        textTransform: "uppercase",
        width: "100%",
      },
    }),
    [],
  )

  const leftIconGenerator = useCallback(
    (value: string) => (
      <Button
        variant="text"
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(value)
            notify("code.codeCopied", { type: "info" })
          } catch {
            notify("code.copyCodeError", { type: "error" })
          }
        }}
        endIcon={<ContentCopy sx={cs.secondaryTextColor} />}
        sx={s.button}
      >
        <Typography color={themeText.primary} variant="caption">
          {t("common.copy")}
        </Typography>
      </Button>
    ),
    [cs.secondaryTextColor, s.button, themeText.primary, t, notify],
  )

  const regenerateCode = useCallback(async () => {
    try {
      setLoading(true)
      const result = await axios.post(
        `${apiUrl}/universe/user/resetCode`,
        { userId: learnerData?.userId },
        getAuth(token),
      )
      await refetchLearnerData()
      refresh()
      await delay(200)
      queryClient.removeQueries([`universe/${universeId}/licenses/learners`, "getOne"])
      notify(t(`${ns}.displayCode`, { code: result.data.code }))
    } catch (error) {
      logger("Regenerate code", error)
      notify(error)
    } finally {
      setLoading(false)
    }
  }, [learnerData?.userId, notify, queryClient, refetchLearnerData, refresh, t, token, universeId])

  return learnerData ? (
    <div style={s.container}>
      <Typography style={cs.marginBottom16} variant="subtitle1">
        {t(`${ns}.userInfo`)}
      </Typography>
      <RowItem value={learnerData?.lastName} label={t("common.name")} />
      <RowItem value={learnerData?.firstName || "-"} label={t("common.firstName")} />
      {learnerData?.email ? <RowItem value={learnerData?.email} label={t("common.email")} /> : null}
      {learnerData?.code ? (
        <>
          <RowItem
            value={hideText(learnerData.code)}
            label={t("common.code")}
            leftIcon={leftIconGenerator(learnerData.code)}
          />
          <Button
            disabled={loading}
            onClick={regenerateCode}
            startIcon={<RefreshIcon style={cs.secondaryTextColor} />}
            style={s.regenerate}
            variant="text"
          >
            {t(`${ns}.regenerate`)}
          </Button>
        </>
      ) : null}
    </div>
  ) : (
    <Empty />
  )
}
