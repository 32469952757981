import type { Group } from "@newpv/js-common"
import type { SaveHandler } from "ra-core"
import { useEffect } from "react"
import {
  useCreateController,
  useDelete,
  useEditController,
  useNotify,
  useRefresh,
} from "react-admin"
import type { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "react-query"
import { useNavigate } from "react-router-dom"

export interface GroupInfo {
  save?: SaveHandler<Group>
  onDelete?: () => Promise<void>
  groupData?: Partial<Group>
  saving?: boolean
  isLoading: boolean
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<Group, unknown>>
}

/** Create a group - wrapper around useCreateController from React Admin */
export const useGroupCreate = ({ divisionId }): GroupInfo => {
  const {
    save,
    record: groupData,
    isLoading: isEvalLoading,
    saving,
  } = useCreateController<Group>({
    resource: `universe/division/${divisionId}/groups`,
  })

  return {
    save,
    saving,
    isLoading: isEvalLoading,
    groupData,
  }
}

/** Edit a group - wrapper around useEditController from React Admin */
export const useGroupEdit = ({ divisionId }): GroupInfo => {
  /**
   * Contexts and hooks
   * */
  const notify = useNotify()
  const refresh = useRefresh()
  const navigate = useNavigate()
  const [deleteOne] = useDelete()

  const {
    save,
    record: groupData,
    isLoading: isGroupLoading,
    refetch,
    saving,
  } = useEditController<Group>({
    resource: `universe/division/${divisionId}/groups`,
    mutationMode: "optimistic",
    queryOptions: {
      meta: {
        idField: "groupId",
      },
    },
  })

  // force refetch on mount
  useEffect(() => {
    refetch()
  }, [refetch])

  const onDelete = (): Promise<void> =>
    deleteOne(
      `universe/division/${divisionId}/groups`,
      { id: groupData?.id },
      {
        mutationMode: "optimistic",
        onSuccess: () => {
          navigate("..", { replace: true })
          // delay to not create a 404 error by trying to refetch the group we just deleted
          setTimeout(() => {
            refresh()
          }, 200)

          notify(`adminPanel.groups.groupDeleteSuccess`, {
            type: "success",
          })
        },
        onError: (e: any) => {
          notify(
            e.response?.data?.code
              ? `adminPanel.groups.groupDeleteError`
              : "ra.notification.http_error",
            {
              type: "error",
            },
          )
        },
      },
    )

  return {
    save,
    saving,
    onDelete,
    groupData,
    isLoading: isGroupLoading,
    refetch,
  }
}
