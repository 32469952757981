import StarIcon from "@mui/icons-material/Star"
import { Typography, useMediaQuery } from "@mui/material"
import type { LevelRevision, LevelStatic, ModuleProgression } from "@newpv/js-common"
import { Chart } from "components"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useStyles } from "utils"

import { useLevelItem } from "../hooks/useLevelItem"
import { PROGRESS_NS } from "../ProgressTab"

interface Props {
  selectedFormattedLevel:
    | (LevelStatic & {
        concernedLevels: Array<LevelStatic | undefined>
      })
    | LevelStatic
    | LevelRevision
  progress?: ModuleProgression
}

const LevelGraph: FC<Props> = ({ progress, selectedFormattedLevel }) => {
  const t = useTranslate()
  const isSmallWidth = useMediaQuery(theme => (theme as any).breakpoints.down("lg"))

  const { completedRules, graphData, levelRulesNbr, totalTrainingTime } = useLevelItem(
    selectedFormattedLevel,
    progress?.levels?.[selectedFormattedLevel.id],
  )

  const s = useStyles(
    ({ spacing, palette: { divider, text, warning } }) => ({
      block: {
        borderColor: divider,
        borderRadius: 8,
        borderStyle: "solid",
        borderWidth: 1,
        padding: spacing(1),
        width: "165px",
        margin: spacing(0, 0.5),
      },
      container: {
        flex: 1,
        padding: spacing(0, 2, 0, 1),
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      },
      graph: {
        paddingBottom: isSmallWidth ? 120 : 200,
      },
      icon: {
        color: warning.icon,
      },
      rules: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: spacing(0.5),
      },
      stats: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: spacing(0, 4.5, 1, 4.5),
      },
      subtitle: {
        color: text.disabled,
      },
      text: {
        padding: spacing(0.5),
        textAlign: "center",
      },
    }),
    [isSmallWidth],
  )

  return (
    <div style={s.container}>
      <div style={s.graph}>
        <Chart width={450} data={graphData} height={isSmallWidth ? 200 : 300} />
      </div>
      <div style={s.stats}>
        <div style={s.block}>
          <Typography variant="caption">{t(`${PROGRESS_NS}.trainingTime`)}</Typography>
          <Typography style={s.text} variant="h6">
            {totalTrainingTime}
          </Typography>
        </div>
        <div style={s.block}>
          <Typography variant="caption">{t(`${PROGRESS_NS}.masteredRules`)}</Typography>
          <div style={s.rules}>
            <StarIcon style={s.icon} />
            <Typography variant="h5">{completedRules.length}</Typography>
            <Typography style={s.subtitle} variant="subtitle2">
              /{levelRulesNbr}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LevelGraph
