import ClearIcon from "@mui/icons-material/Clear"
import { Divider, IconButton, Typography } from "@mui/material"
import type { FC } from "react"
import { useTranslate } from "react-admin"
import { useNavigate } from "react-router-dom"
import { useCommonStyles, useStyles } from "utils"

import { BAR_HEIGHT } from "../../features/Constants"
import { ns } from "./constants"

export interface TopProps {
  title?: string
}
const AddLearnerTopBar: FC<TopProps> = ({ title }) => {
  const navigate = useNavigate()
  const t = useTranslate()

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing, palette: { background } }) => ({
      container: {
        alignItems: "center",
        backgroundColor: background.default,
        display: "flex",
        flexDirection: "row",
        gap: spacing(2),
        padding: spacing(2),
        top: 0,
        width: "inherit",
      },
      top: {
        maxHeight: BAR_HEIGHT,
      },
    }),
    [],
  )

  return (
    <div style={s.top}>
      <div style={s.container}>
        <IconButton onClick={() => navigate("/adminPanel/divisions", { replace: true })}>
          <ClearIcon />
        </IconButton>
        <Typography variant="h6">{t(title ?? `${ns}.headerTitle`)}</Typography>
      </div>
      <Divider style={cs.separator} />
    </div>
  )
}

export default AddLearnerTopBar
