import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Typography } from "@mui/material"
import { Separator } from "components"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useNavigate } from "react-router-dom"
import { useCommonStyles, useStyles } from "utils"

import { ICON_SIZE_SMALL } from "../../../features/Theme/theme"
import { dashboardNs } from "../Dashboard"

interface Props {
  more: string
  title: string
  statsTitles: {
    first: string
    second: string
    third: string
  }
}

export const STATS_HEAD_SIZE = 94

export const SubStatsHeader: FC<Props> = ({ title, statsTitles, more }) => {
  /**
   * Hooks section
   * */
  const t = useTranslate()
  const navigate = useNavigate()

  /**
   * Styling
   * */
  const cs = useCommonStyles()
  const s = useStyles(
    ({
      spacing,
      palette: {
        text: { secondary },
      },
    }) => ({
      longTitle: {
        whiteSpace: "nowrap",
      },
      titleLastSubDiv: {
        display: "flex",
        justifyContent: "end",
      },
      innerDiv: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
      },
      icon: {
        color: secondary,
        fontSize: ICON_SIZE_SMALL,
        paddingLeft: spacing(1),
      },
      mainDiv: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        height: "56px",
        justifyContent: "space-between",
        padding: spacing(0, 2),
      },
      titleDiv: {
        alignItems: "center",
        display: "flex",
        flex: 1,
        maxHeight: "38px",
        padding: spacing(0, 2),
      },
    }),
  )

  return (
    <>
      <div style={s.mainDiv}>
        <Typography variant="body1">{title}</Typography>
        <div
          style={s.innerDiv}
          onClick={() => {
            navigate(more)
          }}
        >
          <Typography variant="caption">{t(`${dashboardNs}.seeMore`)}</Typography>
          <ArrowForwardIosIcon sx={s.icon} />
        </div>
      </div>
      <div style={s.titleDiv}>
        <div style={cs.fullFlex}>
          <Typography variant="body2">{statsTitles.first}</Typography>
        </div>
        <div style={cs.fullFlex}>
          <Typography variant="body2">{statsTitles.second}</Typography>
        </div>
        <div style={{ ...cs.fullFlex, ...s.titleLastSubDiv }}>
          <Typography style={s.longTitle} variant="body2">
            {statsTitles.third}
          </Typography>
        </div>
      </div>
      <Separator />
    </>
  )
}
