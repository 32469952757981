import type { FC, ReactNode } from "react"
import React from "react"
import { useStyles } from "utils"

import { APP_BAR_HEIGHT } from "../features/Theme/theme"
import { ContentContainer, TopBar } from "."

interface ScreenProps {
  children?: React.ReactNode
  topActionBar?: JSX.Element
  title: string
  icon: JSX.Element
  right?: ReactNode
}

/**
 * Container used in every screen
 */
export const ScreenContainer: FC<ScreenProps> = ({
  right,
  children,
  icon,
  title,
  topActionBar,
}) => {
  const s = useStyles(() => ({
    div: {
      height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
      width: "auto",
    },
  }))

  return (
    <div style={s.div}>
      <TopBar {...{ right, title, icon, topActionBar }} />
      <ContentContainer hasTopBar={!!topActionBar}>{children}</ContentContainer>
    </div>
  )
}
