import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined"
import GroupsIcon from "@mui/icons-material/Groups"
import MenuBookIcon from "@mui/icons-material/MenuBookOutlined"
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import { Link, Typography } from "@mui/material"
import type { OverridableComponent } from "@mui/material/OverridableComponent"
import type { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon"
import { fetchVersion, getVersion, isNotEmpty, versions } from "@newpv/js-common"
import { axios } from "@newpv/js-common"
import { Separator } from "components"
import _ from "lodash"
import type { MenuProps } from "ra-ui-materialui/dist/cjs/layout/Menu"
import { Menu as RAMenu } from "ra-ui-materialui/dist/cjs/layout/Menu"
import type { FC } from "react"
import React, { useEffect, useMemo, useState } from "react"
import { useAuthState, usePermissions, useTranslate } from "react-admin"
import { useQuery } from "react-query"
import { useLocation } from "react-router-dom"
import { useCommonStyles, useStyles } from "utils"

import packageJson from "../../../package.json"
import { bffUrl, mandatoryLinks } from "../../features/Constants"
import type { Permission } from "../../features/Providers"
import { APP_BAR_HEIGHT } from "../../features/Theme/theme"
import { MenuItem } from "./components/MenuItem"

const { version } = getVersion(packageJson)

interface Route {
  path?: string
  name: string
  Icon: OverridableComponent<SvgIconTypeMap> & { muiName: string }
}

interface Section {
  title?: string
  routes: Route[]
}

export const Menu: FC<MenuProps & { menuOpen: boolean }> = ({ menuOpen, ...props }) => {
  const t = useTranslate()

  const location = useLocation()
  const { permissions } = usePermissions<Permission[]>()
  const { authenticated } = useAuthState()
  const [selectedRoute, setSelectedRoute] = useState(location.pathname)

  const { data: bffVersion } = useQuery({
    queryFn: async () =>
      (
        await axios.get<{ version: string; apiVersion: { version: string } }>(
          `${bffUrl}/healthcheck`,
        )
      ).data,
    queryKey: "version",
    refetchOnMount: false,
  })

  const { data: contentVersion } = useQuery({
    queryFn: async () => fetchVersion(undefined),
    queryKey: "contentVersion",
    refetchOnMount: false,
  })

  const sections: Section[] = useMemo(() => {
    const sectionsRes = [
      permissions?.includes("stats")
        ? {
            routes: [
              {
                path: "/stats",
                name: "stats",
                Icon: TrendingUpIcon,
              },
              {
                path: "/apprenants",
                name: "students",
                Icon: GroupsIcon,
              },
              {
                path: "/evaluations",
                name: "eval",
                Icon: AssignmentTurnedInOutlinedIcon,
              },
              {
                path: "/rules",
                name: "rules",
                Icon: MenuBookIcon,
              },
            ],
          }
        : undefined,
      permissions?.includes("stats")
        ? {
            title: t("menu.explore"),
            routes: [
              {
                path: "/mon-oral",
                name: "oral",
                Icon: ModeCommentOutlinedIcon,
              },
            ],
          }
        : undefined,
      permissions?.includes("licenses")
        ? {
            title: t("menu.licenses"),
            routes: [
              {
                path: "/student-licenses",
                name: "license.student",
                Icon: GroupsIcon,
              },
            ],
          }
        : undefined,
    ]
    return _.filter(sectionsRes, isNotEmpty)
  }, [permissions, t])

  useEffect(() => {
    if (
      authenticated &&
      !_.flatMap(sections, item => item.routes).find(elem => elem.path === location.pathname)
    ) {
      return
    }
    setSelectedRoute(location.pathname)
  }, [location, authenticated, sections])

  const cs = useCommonStyles()
  const s = useStyles(
    ({
      spacing,
      palette: {
        text: { secondary },
      },
    }) => ({
      container: {
        height: `calc(100vh - ${APP_BAR_HEIGHT}px - ${spacing(2)})`,
      },
      title: {
        color: secondary,
        marginLeft: spacing(1),
        textTransform: "uppercase",
      },
      section: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      version: {
        bottom: spacing(2),
        color: secondary,
        position: "absolute",
        marginBottom: spacing(1),
      },
      link: {
        color: secondary,
        marginRight: spacing(1),
        marginTop: spacing(0.5),
        textTransform: "none",
      },
      linkWrapper: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      },
      bottomSectionWrapper: {
        display: "flex",
        flexDirection: "column",
        marginLeft: spacing(2),
      },
    }),
  )

  return (
    <RAMenu {...props}>
      <div style={s.container}>
        {sections.map(({ routes, title }, index) => (
          <div key={`${title}-${index}`} style={s.section}>
            {!title ? null : (
              <>
                {index === 0 ? null : <Separator style={cs.marginVertical16} />}
                {!menuOpen ? null : (
                  <Typography variant="caption" style={s.title}>
                    {title}
                  </Typography>
                )}
              </>
            )}
            {_.map(routes, route => (
              <MenuItem key={route.name} {...{ ...route, selectedRoute, setSelectedRoute }} />
            ))}
          </div>
        ))}
        {!menuOpen ? null : (
          <div style={{ ...s.version, ...s.bottomSectionWrapper }}>
            <Typography variant="caption" style={{ ...s.version }}>
              {t("menu.version", {
                version: versions(
                  version,
                  bffVersion?.version,
                  bffVersion?.apiVersion?.version,
                  contentVersion?.version,
                ),
              })}
            </Typography>
            <div style={s.linkWrapper}>
              {mandatoryLinks.map(link => (
                <Link
                  target="blank"
                  style={s.link}
                  key={link.label}
                  href={link.url}
                  variant="caption"
                >
                  {t(`menu.${link.label}`)}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </RAMenu>
  )
}
