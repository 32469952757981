import { Box } from "@mui/material"
import type { Group } from "@newpv/js-common"
import _ from "lodash"
import type { ReactElement } from "react"
import { useEffect } from "react"
import * as React from "react"
import {
  BooleanInput,
  Form,
  SearchInput,
  SelectInput,
  useGetList,
  useListContext,
  useTranslate,
} from "react-admin"
import { useWatch } from "react-hook-form"

import { useDivisions } from "../../Layout/hooks/useDivisions"

const commonProps = { variant: "outlined" as const, alwaysOn: true, helperText: "" }

export const CustomFilters = ({
  localResourceId,
}: {
  localResourceId?: string
}): ReactElement | null => (
  <Form
    mode="onChange"
    id="filters_licenses"
    className="form"
    defaultValues={{ onlyLearnersWithoutSelectedResource: true }}
  >
    <FormBox {...{ localResourceId }} />
  </Form>
)

const FormBox = ({ localResourceId }: { localResourceId?: string }): ReactElement => {
  const { filterValues, setFilters } = useListContext()
  const { divisions } = useDivisions()
  const t = useTranslate()
  const watchValues = useWatch()

  useEffect(() => {
    if (Object.keys(watchValues).length > 0) {
      // necessary ts-ignore because setFilters can actually have only one argument
      // @ts-ignore
      setFilters(watchValues)
    }
    // Do not had setFilters => cause refresh of the list with pagination
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchValues])

  const { data: allGroups } = useGetList<Group>(
    `universe/division/${filterValues.divisionId}/groups`,
    {
      meta: {
        idField: "groupId",
        pageSize: -1,
      },
    },
    { enabled: !!filterValues.divisionId },
  )

  return (
    <Box display="flex" alignItems="flex-end" mb={1} gap={2} paddingLeft={2}>
      <SelectInput
        key={1}
        source="divisionId"
        choices={_.sortBy(divisions, "name")}
        label={t("adminPanel.licenses.allDivisions")}
        emptyText={t("adminPanel.licenses.allDivisions")}
        emptyValue=""
        sx={{ width: "250px" }}
        {...commonProps}
      />
      <SelectInput
        key={2}
        source="groupId"
        choices={_.sortBy(allGroups, "name")}
        label={t("adminPanel.licenses.allGroups")}
        emptyText={t("adminPanel.licenses.allGroups")}
        emptyValue=""
        sx={{ width: "250px" }}
        disabled={!filterValues.divisionId}
        {...commonProps}
      />
      <SearchInput source="search" key={0} {...commonProps} />
      <BooleanInput
        key={3}
        source="onlyLearnersWithoutSelectedResource"
        label={t("adminPanel.licenses.seeOnlyNoLicense")}
        options={{ size: "medium" }}
        sx={{ margin: "8px" }}
        disabled={!localResourceId}
        {...commonProps}
      />
    </Box>
  )
}
