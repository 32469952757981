import { Empty } from "components"
import type { FC } from "react"
import React from "react"
import {
  CreateButton,
  FilterLiveSearch,
  List,
  useNotify,
  usePermissions,
  useTranslate,
} from "react-admin"
import { Route, Routes } from "react-router-dom"
import { useStyles, useUniverseId } from "utils"

import AdministratorDataGrid from "./AdministratorDataGrid"
import AdministratorDetailsScreen from "./AdministratorDetailsScreen"
import { ns } from "./constants"
import type { Administrator } from "./useAdministratorFunctions"

const AdministratorList: FC = () => {
  const notify = useNotify()
  const t = useTranslate()

  const [universeId] = useUniverseId()
  const { permissions } = usePermissions()

  const s = useStyles(({ spacing }) => ({
    buttonRight: {
      boxShadow: "none",
      height: "36px",
      position: "absolute",
      right: spacing(1),
      top: -40,
      zIndex: 2,
    },
    main: {
      height: "100%",
      position: "relative",
      width: "100%",
    },
  }))

  return (
    <div style={s.main}>
      {permissions.includes("universeAdmin") ? (
        <CreateButton
          label={`${ns}.create`}
          resource="adminPanel/administrators"
          style={s.buttonRight}
          variant="contained"
        />
      ) : null}
      <List<Administrator>
        actions={false}
        resource={`universe/universe/${universeId}/supervisor`}
        queryOptions={{
          onError: () => notify("ra.notification.http_error", { type: "error" }),
        }}
        exporter={false}
        empty={<Empty isList={true} subtitle={t(`${ns}.empty`)} />}
        sort={{ field: "name", order: "ASC" }}
        filters={[
          <FilterLiveSearch
            alwaysOn={true}
            key={0}
            name="search"
            placeholder={t(`${ns}.placeholder`)}
            source="search"
          />,
        ]}
        pagination={false}
      >
        <AdministratorDataGrid />
      </List>
      <Routes>
        <Route path="/:id" element={<AdministratorDetailsScreen />} />
        <Route path="/create" element={<AdministratorDetailsScreen isCreation={true} />} />
      </Routes>
    </div>
  )
}

export default AdministratorList
