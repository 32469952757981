import DataUsageIcon from "@mui/icons-material/DataUsage"
import { Button, Grid, List, ListItem, Typography, useTheme } from "@mui/material"
import type { UniverseId } from "@newpv/js-common"
import { Card, Empty, Separator } from "components"
import type { FC } from "react"
import React, { Fragment, useState } from "react"
import { Loading, useGetOne, usePermissions, useTranslate } from "react-admin"
import { useResourceId, useScenarioId, useScenarioName, useStyles, useUniverseId } from "utils"

import type { ThemeOptions } from "../../features/Theme/theme"
import { APP_BAR_HEIGHT, APP_TOP_BAR_HEIGHT } from "../../features/Theme/theme"
import { useUniverseStats } from "./hooks/useUniverseStats"
import UniverseStatsDetailed from "./UniverseStatsDetailed"

export const universeStatsNs = "stats.universeTab"

export interface StatsTabProps {
  selectedTab: "universe" | "division" | "exports"
}

export const UniverseStatsGlobal: FC<StatsTabProps> = ({ selectedTab }) => {
  /**
   * Hooks section
   * */
  const t = useTranslate()
  const [universeId] = useUniverseId()
  const [scenarioId] = useScenarioId()
  const [scenarioName] = useScenarioName()
  const [resourceId] = useResourceId()
  const [isOpen, setIsOpen] = useState(false)

  const { permissions } = usePermissions()

  const {
    palette: { text },
  } = useTheme<ThemeOptions>()

  const { data: currentUniverse } = useGetOne<{ id: UniverseId; name: string }>(
    `universe/universe`,
    {
      id: universeId,
      meta: { idField: "universeId" },
    },
  )

  /**
   * Data related functions
   * */
  const { universeProgress, isLoading } = useUniverseStats({ scenarioId, resourceId })

  const openModal = (): void => {
    setIsOpen(true)
  }

  /**
   * Styling
   * */
  const s = useStyles(
    ({
      spacing,
      palette: {
        background,
        divider,
        text: { primary },
      },
    }) => ({
      button: {
        color: text?.secondary,
        margin: spacing(0, 1),
        borderColor: divider,
      },
      text: {
        color: primary,
      },
      cardContent: { width: "100%" },
      statsCardSx: {
        "& .MuiCardContent-root": { padding: 0, display: "flex", flexDirection: "column" },
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: spacing(0, 1),
      },
      listDiv: {
        width: "100%",
        flexDirection: "column",
        backgroundColor: background.secondary,
        ...(selectedTab === "universe" ? {} : { display: "none" }),
        height: `calc(100vh - ${APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT}px - ${spacing(6)})`,
        maxHeight: `calc(100vh - ${APP_BAR_HEIGHT + APP_TOP_BAR_HEIGHT}px - ${spacing(6)})`,
      },
      list: {
        flexGrow: 1,
        display: "flex",
        maxHeight: "100%",
        overflowY: "scroll",
        flexDirection: "column",
      },
      grid: {
        display: "flex",
        // here - fixed max width
        maxWidth: "888px",
        alignSelf: "center",
        alignItems: "center",
        paddingBottom: spacing(2),
        justifyContent: "space-around",
      },
      titleCard: {
        width: "100%",
        display: "flex",
        margin: spacing(2, 1),
        justifyContent: "center",
      },
      titleCardContent: {
        paddingBottom: spacing(2),
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      },

      universeName: { marginBottom: spacing(2) },
      listComp: {},
      listItem: { padding: spacing(2) },
    }),
    [text, selectedTab],
  )

  return (
    <>
      <div style={s.listDiv}>
        <div style={s.list}>
          <Grid container sx={s.grid}>
            <Card style={s.titleCard} contentStyle={s.titleCardContent}>
              <Typography variant="h5" textAlign="center" style={s.universeName}>
                {currentUniverse?.name}
              </Typography>
              <Typography
                variant="h6"
                textAlign="center"
                style={s.universeName}
                color={text?.disabled}
              >
                {scenarioName}
              </Typography>
              {permissions?.includes("ENT") ? null : (
                <Button
                  onClick={openModal}
                  sx={s.button}
                  variant="outlined"
                  startIcon={<DataUsageIcon />}
                >
                  <Typography sx={s.text} variant="button">
                    {t(`${universeStatsNs}.detailedStats`)}
                  </Typography>
                </Button>
              )}
            </Card>
            <Card sx={s.statsCardSx} contentStyle={s.cardContent}>
              {isLoading ? (
                <Loading />
              ) : universeProgress?.divisions?.length != null &&
                universeProgress.divisions.length === 0 ? (
                <Empty isList={true} subtitle={t(`${universeStatsNs}.empty`)} />
              ) : (
                <List component="nav" aria-labelledby="list" style={s.listComp}>
                  <ListItem style={s.listItem}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography style={s.text} variant="body1">
                          {t(`${universeStatsNs}.division`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={s.text} variant="body1">
                          {t(`${universeStatsNs}.header1`)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Separator />
                  {universeProgress?.divisions?.map((divisionData, index) => (
                    <Fragment key={divisionData.divisionName}>
                      <ListItem style={s.listItem}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography style={s.text} variant="subtitle1">
                              {divisionData.divisionName}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography style={s.text} variant="subtitle1">
                              {`${divisionData.nbLearners}/${divisionData.nbLicenses}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                      {index === (universeProgress?.divisions?.length ?? 0) - 1 ? null : (
                        <Separator />
                      )}
                    </Fragment>
                  ))}
                </List>
              )}
            </Card>
          </Grid>
        </div>
      </div>
      <UniverseStatsDetailed {...{ isOpen, setIsOpen, universeProgress }} />
    </>
  )
}
