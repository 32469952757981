import "./index.css"
import "./assets/fonts/Montserrat-Medium.ttf"
import "./assets/fonts/Montserrat-MediumItalic.ttf"
import "./assets/fonts/Montserrat-Regular.ttf"
import "./assets/fonts/Montserrat-SemiBold.ttf"
import "./assets/fonts/Montserrat-Bold.ttf"
import "./assets/fonts/Montserrat-Italic.ttf"
import "./assets/fonts/Roboto-Bold.ttf"
import "./assets/fonts/Roboto-Regular.ttf"

import React, { StrictMode } from "react"
import { localStorageStore, StoreContextProvider } from "react-admin"
import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"

import App from "./App"

// @ts-ignore
const root = createRoot(document.getElementById("root") as HTMLInputElement)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchOnWindowFocus: false,
    },
  },
})

// we have to wrap the entire app in this store to avoid the authProvider
// falling back to a different Store instance (memoryStore)
const store = localStorageStore()

root.render(
  <StrictMode>
    <StoreContextProvider value={store}>
      <QueryClientProvider client={queryClient}>
        <App store={store} />
      </QueryClientProvider>
    </StoreContextProvider>
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
