import type { User } from "@newpv/js-common"
import dayjs from "dayjs"
import _ from "lodash"
import type { FC } from "react"
import React, { useMemo } from "react"
import { useRecordContext } from "react-admin"
import type { RAFieldProps } from "utils"
import type { License } from "utils/License"

import { DATE_TIME_FORMAT } from "../../../features/Constants"

export interface LicensesProps {
  licenses: License[]
}

export const LicenseDateField: FC<RAFieldProps<User> & LicensesProps> = ({ licenses }) => {
  const record = useRecordContext<User>()

  const userId = record.id

  const licenseDate = useMemo(() => {
    const license = _.find(licenses, elem => elem.userId === userId)?.attributionDate
    return license ? dayjs(license).format(DATE_TIME_FORMAT) : "-"
  }, [licenses, userId])

  return !record ? null : <span>{licenseDate}</span>
}
