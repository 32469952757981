import Add from "@mui/icons-material/Add"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import Chip from "@mui/material/Chip"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import type { SessionCreate, TmpSession, UserId } from "@newpv/js-common"
import { Separator } from "components"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useState } from "react"
import { BooleanInput, required, SelectInput, useTranslate } from "react-admin"
import { useParams } from "react-router-dom"
import { useStyles } from "utils"
import { useCommonStyles } from "utils"
import useStudentsFromDivision from "utils/useStudentsFromDivision"

import { SessionScreen } from "../SessionScreen/SessionScreen"
import { ContentCreate } from "./components/ContentCreate"
import { SessionListItem } from "./components/SessionListItem"
import { TOP_HEIGHT } from "./EvalDetailsScreen"
import { useCalcMethods } from "./useEvalFunctions"

interface Props {
  value: number
  isCreate?: boolean
  evalSessions: SessionCreate[]
  newEvalSessions: TmpSession[]
  setModalOpen: Dispatch<SetStateAction<boolean>>
  setNewEvalSessions: Dispatch<SetStateAction<TmpSession[]>>
  retryLearnerId?: string
  setRetryLearnerId: Dispatch<SetStateAction<string | undefined>>
  sessionOpen: boolean
  setSessionOpen: Dispatch<SetStateAction<boolean>>
  usersWithFutureSessionsPlanned?: Record<UserId, boolean>
}

export const evalGeneralTabNs = "eval.evalModal.generalTab"

export const GeneralTab: FC<Props> = ({
  value,
  isCreate,
  setModalOpen,
  evalSessions,
  newEvalSessions,
  setNewEvalSessions,
  retryLearnerId,
  setRetryLearnerId,
  sessionOpen,
  setSessionOpen,
  usersWithFutureSessionsPlanned,
}) => {
  const t = useTranslate()
  const { id: evalId } = useParams()
  const calculationMethods = useCalcMethods()

  const { data: students } = useStudentsFromDivision()

  const [sessionId, setSessionId] = useState<string>()

  const cs = useCommonStyles()
  const s = useStyles(
    ({ typography: { body2 }, spacing }) => ({
      bool: {
        margin: spacing(2),
      },
      booleanLabel: {
        "& .MuiFormControlLabel-label": body2,
      },
      main: value !== 0 ? { display: "none" } : {},
      input: {
        margin: spacing(0, 2),
        maxWidth: "100%",
      },
      inputsDiv: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
      },
      subtitleDiv: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
      },
      bottomDiv: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: spacing(2, 1, 0, 1),
      },
      list: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        maxHeight: "100%",
        overflowY: "auto",
        padding: spacing(0, 1),
      },
      listDiv: {
        alignSelf: "flex-start",
        flexDirection: "column",
        height: `calc(100vh - ${TOP_HEIGHT}px)`,
        maxHeight: `calc(100vh - ${TOP_HEIGHT}px)`,
        minHeight: `calc(100vh - ${TOP_HEIGHT}px)`,
        width: "100%",
      },
      muiSelect: {
        "& .MuiFormLabel-root": cs.muiFormLabel25,
      },
    }),
    [cs.muiFormLabel25, value],
  )

  // The following rule is necessary to avoid form errors on SelectInput component
  // noinspection RequiredAttributes
  return (
    <div style={s.main}>
      {!sessionOpen ? null : (
        <SessionScreen
          open={sessionOpen}
          setOpen={setSessionOpen}
          {...{
            evalId,
            sessionId,
            setSessionId,
            newEvalSessions,
            setRetryLearnerId,
            setNewEvalSessions,
            usersWithFutureSessionsPlanned,
          }}
          defaultLearnerId={retryLearnerId}
        />
      )}
      <div style={cs.marginTop16}>
        <div style={s.inputsDiv}>
          <ContentCreate isCreation={isCreate} />
          <SelectInput
            style={s.input}
            sx={s.muiSelect}
            optionText="title"
            variant="outlined"
            name="calculationMethod"
            source="calculationMethod"
            choices={calculationMethods}
            label={t(`${evalGeneralTabNs}.calculationMethod`)}
            validate={required("ra.validation.required")}
          />
        </div>
        <Chip
          style={s.input}
          icon={<HelpOutlineIcon />}
          label={t(`${evalGeneralTabNs}.calculMethodInfo`)}
          onClick={() => setModalOpen(true)}
        />
        <div style={s.bool}>
          <BooleanInput
            sx={s.booleanLabel}
            name="gradeIncluded"
            source="gradeIncluded"
            label={t(`${evalGeneralTabNs}.includeNote`)}
          />
        </div>
        <Separator />
      </div>
      <div style={s.bottomDiv}>
        <Typography variant="body1">{t(`${evalGeneralTabNs}.sessions`)}</Typography>
        <div
          style={s.subtitleDiv}
          onClick={() => {
            setSessionOpen(true)
          }}
        >
          <Add color="disabled" />
          <Typography variant="caption">{t("ra.action.create")}</Typography>
        </div>
      </div>
      <div style={s.listDiv}>
        <div style={s.list}>
          <List component="nav" aria-labelledby="list">
            {[...evalSessions, ...newEvalSessions].map(item => (
              <SessionListItem
                onClick={() => {
                  setSessionId(item["tmpId" in item ? "tmpId" : "id"])
                  setSessionOpen(true)
                }}
                key={item["tmpId" in item ? "tmpId" : "id"]}
                totalStudents={students?.length ?? 0}
                {...{ item, evalId, setNewEvalSessions }}
              />
            ))}
          </List>
        </div>
      </div>
    </div>
  )
}
