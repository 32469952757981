import type { DivisionId, User } from "@newpv/js-common"
import { apiUrl, axios } from "@newpv/js-common"
import type { AxiosResponse } from "axios"
import dayjs from "dayjs"
import { useCallback } from "react"
import { useNotify, useTranslate } from "react-admin"
import type {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query/types/core/types"
import { logger } from "utils/logger"
import { getAuth, useToken } from "utils/storage"
import { useQueryRequest } from "utils/useQueryRequest"

import { DATE_FORMAT_LNG } from "../features/Constants"
import { ns } from "../screens/AdminPanelScreen/divisions/GeneralTab"
import { exporter } from "../screens/StatsScreen/utils/ExportsUtils"

const exportHeaders = ["lastName", "firstName", "divisionName", "code"]

interface LearnerCodesState {
  areCodesForDivisionLoading: boolean
  areCodesStale: boolean
  codesCurrentData: Array<User & { code?: string }> | undefined
  exportCodes: () => Promise<void>
  refetch: (options?: RefetchOptions & RefetchQueryFilters) => Promise<QueryObserverResult>
}

const useLearnerCodes = (divisionId?: DivisionId, divisionName?: string): LearnerCodesState => {
  const notify = useNotify()
  const [token] = useToken()
  const t = useTranslate()
  const {
    data: codesCurrentData,
    isLoading: areCodesForDivisionLoading,
    isStale: areCodesStale,
    refetch,
  } = useQueryRequest<Array<User & { code?: string }>>(
    async () => {
      const divisionCodes = await axios.get<
        any,
        AxiosResponse<{ users: Array<User & { code?: string }> }>
      >(`${apiUrl}/universe/division/${divisionId}/learner/code`, getAuth(token))

      return divisionCodes.data.users
    },
    ["codes", divisionId],
    {
      enabled: false,
      onError: () => {
        logger("error during code export")
        notify(`${ns}.export.exportError`, { type: "error" })
      },
    },
  )

  const exportCodes = useCallback(async () => {
    let codesForDivision = codesCurrentData
    if (codesCurrentData == null || areCodesStale) {
      const res = await refetch()
      // that's after 3 retries
      if (res.isError) {
        return
      }
      codesForDivision = res.data
    }

    const data =
      codesForDivision?.map(user => ({
        divisionName,
        lastName: user.lastName,
        firstName: user.firstName,
        code: user.code,
      })) ?? []

    const headersNames = exportHeaders.map(headerId => t(`${ns}.export.${headerId}`))

    const docTitle = `${t(`${ns}.export.title`)} - ${divisionName} - ${dayjs().format(
      DATE_FORMAT_LNG,
    )}`

    exporter(docTitle, data, exportHeaders, headersNames)
  }, [areCodesStale, codesCurrentData, divisionName, refetch, t])

  return {
    areCodesForDivisionLoading,
    areCodesStale,
    codesCurrentData,
    exportCodes,
    refetch,
  }
}

export default useLearnerCodes
