import { Typography } from "@mui/material"
import { commonNs } from "features/i18n/fr"
import { MAX_WIDTH_TYPOGRAPHY } from "features/Theme/theme"
import { type ButtonProps } from "ra-ui-materialui/src/button/Button"
import type { FC, ReactElement } from "react"
import React, { useMemo } from "react"
import { Button, usePermissions, useTranslate } from "react-admin"
import type { Link } from "react-router-dom"
import { useStyles } from "utils"

import emptyClass from "../assets/images/emptyClass.png"
import emptyEval from "../assets/images/emptyEval.png"
interface Props {
  label?: string
  onClick?: () => void
  subtitle?: string
  title?: string
  button?: ReactElement<Omit<ButtonProps<typeof Link>, "to">>
  isSmallHeight?: boolean
  isEval?: boolean
}
const EmptyScreen: FC<Props> = ({
  label,
  button,
  onClick,
  subtitle,
  title,
  isSmallHeight = false,
  isEval = false,
}) => {
  const { permissions } = usePermissions()
  const t = useTranslate()
  const s = useStyles(
    ({ spacing }) => ({
      button: {
        padding: spacing(1, 2),
      },
      image: {
        display: "flex",
        height: 288,
        width: 401,
      },
      container: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        padding: spacing(6),
        width: "100%",
      },
      list: {
        display: "flex",
        flexDirection: "column",
        gap: 6,
      },
      textContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        gap: spacing(2),
        margin: spacing(2, 0),
        maxWidth: MAX_WIDTH_TYPOGRAPHY,
      },
    }),
    [],
  )

  const isUniverseAdmin = permissions.includes("universeAdmin")
  const emptyButton = button
    ? React.cloneElement(button, { disableElevation: true, style: s.button })
    : null

  const defaultEmptyScreen = useMemo(
    () =>
      isUniverseAdmin ? (
        <div>
          {t(`${commonNs}.emptyScreen.listTitle`)}
          <ul style={s.list}>
            {[1, 2, 3].map(e => (
              <li key={e}>{t(`${commonNs}.emptyScreen.list.step${e}`)}</li>
            ))}
          </ul>
          {t(`${commonNs}.emptyScreen.description`)}
        </div>
      ) : (
        <>
          <p>{t(`${commonNs}.emptyScreen.divisionAdminTitle`)}</p>
          {t(`${commonNs}.emptyScreen.divisionAdminDescription`)}
        </>
      ),
    [isUniverseAdmin, s.list, t],
  )

  return (
    <div style={s.container}>
      {isSmallHeight ? null : <img src={isEval ? emptyEval : emptyClass} style={s.image} />}
      <div style={s.textContainer}>
        <Typography variant="h6">{title ?? t(`${commonNs}.emptyScreen.title`)}</Typography>
        <Typography variant="subtitle1" align="center">
          {subtitle ?? defaultEmptyScreen}
        </Typography>
      </div>
      {onClick ? (
        <Button
          style={s.button}
          disableElevation={true}
          variant="contained"
          label={label}
          {...{ onClick }}
        />
      ) : (
        emptyButton
      )}
    </div>
  )
}

export default EmptyScreen
