import type { RadioProps } from "@mui/material"
import { Button, Radio, Typography, useTheme } from "@mui/material"
import type { ReactElement, ReactNode } from "react"
import React, { useCallback } from "react"
import { useStyles } from "utils"
import type { SetState } from "utils/CommonTypes"

interface Props<T> extends RadioProps {
  label: string
  description: string
  setChoice: SetState<T>
  icon?: ReactNode
  value: T
}

export function RadioButtonField<T>({
  label,
  description,
  setChoice,
  icon,
  value,
  ...rest
}: Props<T>): ReactElement {
  const {
    palette: { text: themeText },
  } = useTheme()

  const s = useStyles(
    ({ spacing, palette: { primary, divider, background, text } }) => ({
      container: {
        alignItems: "flex-start",
        backgroundColor: rest.checked ? background.neutral : undefined,
        borderColor: divider,
        borderRadius: "8px",
        borderStyle: "solid",
        borderWidth: 1,
        display: "flex",
        flexDirection: "row",
        maxWidth: 440,
        padding: spacing(6),
        textAlign: "left",
        textTransform: "none",
      },
      label: {
        alignContent: "flex-end",
        display: "flex",
        gap: spacing(1),
      },
      radio: {
        color: rest.checked ? primary[700] : text.secondary,
        display: "flex",
        justifyContent: "flex-start",
        paddingTop: spacing(0),
      },
      textContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: spacing(2),
      },
    }),
    [rest.checked],
  )

  const onChange = useCallback(() => setChoice(value), [setChoice, value])

  return (
    <Button disabled={rest.disabled} style={s.container} onClick={onChange}>
      <div style={s.textContainer}>
        <Typography variant="h6" style={s.label}>
          {icon}
          {label}
        </Typography>
        <Typography variant="subtitle1" color={themeText.secondary}>
          {description}
        </Typography>
      </div>
      <Radio {...rest} style={s.radio} {...{ onChange }} />
    </Button>
  )
}
