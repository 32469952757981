import "dayjs/plugin/duration"

import GroupsIcon from "@mui/icons-material/Groups"
import PersonIcon from "@mui/icons-material/Person"
import { Divider, Typography, useTheme } from "@mui/material"
import type { DivisionProgression, LevelPracticeTest, Scenario } from "@newpv/js-common"
import { fetchOneScenario } from "@newpv/js-common"
import { axios } from "@newpv/js-common"
import type { AxiosResponse } from "axios"
import dayjs from "dayjs"
import _ from "lodash"
import type { CSSProperties, FC } from "react"
import React, { useMemo } from "react"
import { Form, FormDataConsumer, RadioButtonGroupInput, useTranslate } from "react-admin"
import {
  formatDuration,
  getAuth,
  useDivisionId,
  useQueryRequest,
  useScenarioId,
  useStyles,
  useToken,
} from "utils"

import { bffUrl } from "../../features/Constants"
import { DateInput } from "../SessionScreen/components/DateInput"
import { PdfDownload } from "./PdfDownload"
import type { TabProps } from "./StudentDetails"

export const STATS_NS = "students.studentModal.statsTab"

const duration = require("dayjs/plugin/duration")
dayjs.extend(duration)

import "dayjs/plugin/duration"

import { useCommonStyles } from "utils"
import { convertToQuarters } from "utils/convertToQuarters"

const Elem: FC<{
  title?: string
  subtitle?: string
  titleStyle: CSSProperties
  subtitleStyle: CSSProperties
}> = ({ title, titleStyle, subtitle, subtitleStyle }) => (
  <div>
    <Typography style={titleStyle} variant="subtitle1">
      {title}
    </Typography>
    <Typography style={subtitleStyle} variant="body2">
      {subtitle ?? null}
    </Typography>
  </div>
)

export const StatsTab: FC<TabProps> = ({ value, progress, userData }) => {
  const t = useTranslate()
  const [divisionId] = useDivisionId()
  const [scenarioId] = useScenarioId()
  const [token] = useToken()

  const { data: scenario } = useQueryRequest<Scenario>(
    async () => fetchOneScenario(undefined, scenarioId),
    ["scenario", "scenarioId", scenarioId],
    { enabled: scenarioId != null },
  )

  const { data: divisionStats } = useQueryRequest<DivisionProgression>(async () => {
    if (!divisionId || !scenarioId) {
      return
    }
    const rawDivisionProgress = await axios.get<any, AxiosResponse<DivisionProgression>>(
      `${bffUrl}/stats/division/${divisionId}?scenarioId=${scenarioId}`,
      getAuth(token),
    )
    return rawDivisionProgress.data
  }, ["divisionStats", "scenarioId", scenarioId, "divisionId", divisionId])

  const scenarioTrainingBlocksDetail = useMemo(
    () =>
      _(progress?.progression?.modules)
        .toPairs()
        // flatMapDepth does not infer the type correctly- had to use flatMap and flatten
        .flatMap(([moduleId, module]) => {
          const userRouteModules =
            scenario?.routes[progress?.progression?.initialEvaluation?.routeIndex ?? 0]?.modules ??
            []

          const practiceLevel = _.find(
            userRouteModules.find(m => m.id === parseInt(moduleId))?.levels,
            l => l.id === module?.practiceTest?.levelId,
          )

          const practiceTestTrainingBlock: Array<[number, number]> = module?.practiceTest?.clientEnd
            ? [
                [
                  dayjs(module.practiceTest.clientEnd).valueOf() -
                    ((practiceLevel as LevelPracticeTest)?.timeLimit ?? 0) * 1000,
                  dayjs(module.practiceTest.clientEnd).valueOf(),
                ],
              ]
            : []

          return [
            ..._.map(module.levels, level => level.trainingBlocksDetail),
            practiceTestTrainingBlock,
          ]
        })
        .flatten()
        // data for initial eval with fake start date
        .concat(
          progress?.progression?.initialEvaluation?.endDate
            ? [
                [
                  progress?.progression?.initialEvaluation.endDate -
                    progress?.progression?.initialEvaluation.duration,
                  progress?.progression?.initialEvaluation.endDate,
                ],
              ]
            : [],
        )
        // in milliseconds - keep only blocks longer than 1 second
        .filter(([startDate, endDate]) => endDate - startDate > 1000)
        .orderBy(([start]) => start)
        .value(),
    [progress, scenario],
  )

  const convertedData = useMemo(
    () => convertToQuarters(scenarioTrainingBlocksDetail),
    [scenarioTrainingBlocksDetail],
  )

  const {
    palette: {
      success: { main },
      text: { disabled },
    },
  } = useTheme()

  const cs = useCommonStyles()
  const s = useStyles(
    ({
      palette: {
        background: { default: background },
        action,
      },
      spacing,
    }) => ({
      connectionExportTitle: {
        margin: spacing(3, 4, 1, 2),
      },
      dateInputContainer: {
        display: "flex",
        flexDirection: "row",
        gap: spacing(1),
      },
      dateInputChild: { maxWidth: 180 },
      divider: { backgroundColor: action.overlay, height: 1 },
      mainDiv: {
        // TODO: isn't that redundant with the parent?
        display: value === 1 ? "flex" : "none",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        margin: spacing(1),
        overflowY: "auto",
        padding: spacing(2, 0, 0, 0),
      },
      text: {
        paddingLeft: spacing(2),
        width: "120px",
      },
      radio: {
        flexDirection: "row",
        alignItems: "center",
        display: "none",
      },
      formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        margin: spacing(0, 2),
      },
      rightSubtitle: {
        color: disabled,
      },
      rootDiv: { height: "100%" },
      row: {
        display: "flex",
        height: "50px",
        justifyContent: "space-between",
        padding: spacing(0, 1),
      },
      statsContainer: { paddingBottom: spacing(3), margin: spacing(1) },
      title: {
        backgroundColor: background,
        borderRadius: "8px",
      },
    }),
    [value, disabled],
  )

  const sections = useMemo<
    Array<{ style?: CSSProperties; leftTitle?: string; rightTitle?: string; subtitle?: string }>
  >(
    () => [
      {
        style: s.title,
        leftTitle: t(`${STATS_NS}.value`),
        rightTitle: t(`${STATS_NS}.mean`),
      },
      {
        leftTitle: `${progress?.progression?.initialLevel ?? 0}%`,
        rightTitle: `${_.round(divisionStats?.initialLevel ?? 0, 2)}%`,
        subtitle: t(`${STATS_NS}.initialLevel`),
      },
      {
        leftTitle: `${_.round(progress?.completionPercentage ?? 0, 2)}%`,
        rightTitle: `${_.round(divisionStats?.completionPercentage ?? 0, 2)}%`,
        subtitle: t(`${STATS_NS}.reachedLevel`),
      },
      {
        leftTitle: formatDuration({ t, duration: progress?.trainingDuration }),
        rightTitle: formatDuration({ t, duration: divisionStats?.trainingDuration }),
        subtitle: t(`${STATS_NS}.training`),
      },
    ],
    [s, t, progress, divisionStats],
  )

  const data = useMemo(
    () => ({
      connections: convertedData,
      firstName: userData?.firstName ?? "-",
      lastName: userData?.lastName ?? "-",
      scenarioName: scenario?.name ?? "-",
      domainName: scenario?.applicationName ?? "-",
    }),
    [
      convertedData,
      userData?.firstName,
      userData?.lastName,
      scenario?.name,
      scenario?.applicationName,
    ],
  )

  return (
    <div
      role="tabpanel"
      hidden={value !== 1}
      id={`tab-panel-${1}`}
      aria-labelledby={`tab-${1}`}
      style={s.rootDiv}
    >
      <div style={s.mainDiv}>
        <div style={s.statsContainer}>
          {sections.map(({ leftTitle, rightTitle, style, subtitle }, index) => (
            <div key={index} style={{ ...s.row, ...(style ? style : {}) }}>
              <div style={cs.alignItemsCenter}>
                <PersonIcon sx={{ color: main }} />
                <Elem
                  title={leftTitle}
                  subtitle={subtitle}
                  titleStyle={s.text}
                  subtitleStyle={{ ...s.text, ...cs.secondaryTextColor }}
                />
              </div>
              <div style={cs.alignItemsCenter}>
                <GroupsIcon sx={{ color: disabled }} />
                <Elem
                  title={rightTitle}
                  subtitle={subtitle}
                  subtitleStyle={{ ...s.text, ...s.rightSubtitle }}
                  titleStyle={{ ...s.text, ...(index !== 0 ? cs.secondaryTextColor : {}) }}
                />
              </div>
            </div>
          ))}
        </div>
        <Divider style={s.divider} />
        <Typography style={s.connectionExportTitle} variant="body1">
          {t(`${STATS_NS}.connectionDetail`)}
        </Typography>

        <Form
          id="export_connections"
          mode="onChange"
          className="form"
          defaultValues={{
            exportPeriod: "all",
            startDate: dayjs().startOf("year"),
            dueDate: dayjs(),
          }}
        >
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <div style={s.formContainer}>
                  <RadioButtonGroupInput
                    source="exportPeriod"
                    label=""
                    helperText={false}
                    choices={[
                      { id: "all", name: t(`${STATS_NS}.allConnections`) },
                      { id: "period", name: t(`${STATS_NS}.precisePeriod`) },
                    ]}
                    optionText={record => (
                      <Typography variant="subtitle1">{record.name}</Typography>
                    )}
                  />

                  <div style={s.dateInputContainer}>
                    <div style={s.dateInputChild}>
                      <DateInput
                        disabled={formData?.exportPeriod === "all"}
                        source="startDate"
                        label="date"
                        name="startDate"
                        maxDate={formData?.dueDate}
                        displayError={false}
                      />
                    </div>
                    <div style={s.dateInputChild}>
                      <DateInput
                        disabled={formData?.exportPeriod === "all"}
                        source="dueDate"
                        label="date"
                        name="dueDate"
                        minDate={formData?.startDate}
                        maxDate={dayjs().toDate()}
                        displayError={true}
                      />
                    </div>
                  </div>
                </div>
                <PdfDownload data={data} />
              </>
            )}
          </FormDataConsumer>
        </Form>
      </div>
    </div>
  )
}
