import { Typography } from "@mui/material"
import type { RaRecord } from "ra-core/dist/cjs/types"
import type { ReactElement } from "react"
import React from "react"
import { useRecordContext } from "react-admin"
import type { RARatioProps } from "utils"
import { useGradeColor } from "utils"

export const RatioField = <T extends RaRecord = RaRecord>({
  source,
  numerator,
  denominator,
}: RARatioProps<T>): null | ReactElement => {
  const record = useRecordContext<T>()

  const ratio =
    (record?.[denominator] ?? 0) === 0 ? "-" : `${record?.[numerator]}/${record?.[denominator]}`

  const { getPercentColor } = useGradeColor()

  const color = getPercentColor(record?.[source] ?? 0, 1)

  return !record ? null : <Typography variant="subtitle1" color={color}>{`${ratio}`}</Typography>
}
