import { useStyles } from "utils/useStyles"

import { INPUT_HEIGHT } from "../features/Theme/theme"
import { BUTTON_DIV_HEIGHT } from "../screens/AdminPanelScreen/divisions/DivisionDetailsScreen"

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCommonStyles = () =>
  useStyles(
    ({
      spacing,
      palette: {
        common: { white },
        divider,
        primary,
        text,
        warning,
      },
      typography: { subtitle1 },
    }) => ({
      alignButton: {
        alignSelf: "center",
        height: "36px",
      },
      alignItemsCenter: {
        alignItems: "center",
        display: "flex",
      },
      asideButtons: {
        alignItems: "center",
        backgroundColor: white,
        borderTop: "1px solid",
        borderTopColor: divider,
        bottom: 0,
        display: "flex",
        height: BUTTON_DIV_HEIGHT,
        justifyContent: "flex-start",
        paddingLeft: spacing(2),
        position: "absolute",
        width: "100%",
      },
      asideContainer: {
        display: "flex",
        flexDirection: "column",
        padding: spacing(2, 2, 2, 2),
        rowGap: spacing(1.5),
      },
      borderRadius: {
        borderRadius: "16px",
      },
      centerText: {
        textAlign: "center",
      },
      childrenContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        overflowY: "scroll",
        width: "100%",
      },
      cursor: {
        cursor: "pointer",
      },
      displayFlex: {
        display: "flex",
      },
      fullFlex: {
        display: "flex",
        flex: 1,
      },
      fullView: {
        height: "100%",
        width: "100%",
      },
      fullWidth: {
        width: "100%",
      },
      mainDiv: {
        alignSelf: "flex-start",
        backgroundColor: white,
        display: "flex",
        flex: 1,
        flexDirection: "column",
        maxWidth: "400px",
        overflowY: "auto",
        width: "100%",
      },
      marginBottom16: {
        marginBottom: spacing(2),
      },
      marginHorizontal16: {
        margin: spacing(0, 2),
      },
      marginLeft: {
        marginLeft: spacing(1),
      },
      marginLeft16: {
        marginLeft: spacing(2),
      },
      marginLeft32: {
        marginLeft: spacing(4),
      },
      marginSubtitle: {
        marginBottom: spacing(5),
        marginTop: spacing(2),
      },
      marginTop16: {
        marginTop: spacing(2),
      },
      marginVertical16: {
        margin: spacing(2, 0),
      },
      muiFormLabel12: { ...subtitle1, lineHeight: "12px" },
      muiFormLabel25: { ...subtitle1, lineHeight: "25px" },
      paddingButton: {
        padding: spacing(1, 2),
      },
      paddingTop16: {
        paddingTop: spacing(2),
      },
      primary600: {
        color: primary[600],
      },
      screenTitle: {
        textTransform: "none",
      },
      secondaryTextColor: {
        color: text.secondary,
      },
      separator: {
        backgroundColor: divider,
        height: 1,
        minHeight: 1,
      },
      textFieldHeight: {
        height: INPUT_HEIGHT,
      },
      upponFormContainer: {
        height: "100%",
        marginBottom: BUTTON_DIV_HEIGHT,
        width: "100%",
      },
      warningMainColor: {
        color: warning.main,
      },
    }),
    [],
  )
