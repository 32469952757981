import { Typography, useTheme } from "@mui/material"
import List from "@mui/material/List"
import type { LevelRevision, LevelStatic, Module } from "@newpv/js-common"
import { fetchOneScenario, getRoute, mergeGraphs } from "@newpv/js-common"
import type { ChartData } from "components"
import { Chart, MuiSelect } from "components"
import _ from "lodash"
import type { FC } from "react"
import React, { useEffect, useMemo, useState } from "react"
import { Loading, useTranslate } from "react-admin"
import { useQueryRequest, useScenarioId, useStyles } from "utils"

import { RulesDialog } from "./components/RulesDialog"
import { StudentLevels } from "./components/StudentLevels"
import type { TabProps } from "./StudentDetails"

export const PROGRESS_NS = "students.studentModal.progressTab"

export const ProgressTab: FC<TabProps> = ({ progress, value }) => {
  const t = useTranslate()
  const theme = useTheme()

  const [scenarioId] = useScenarioId()

  const [loading, setLoading] = useState(true)

  const [graphData, setGraphData] = useState<ChartData[]>()
  const [moduleId, setModuleId] = useState<number>()

  const {
    data: modules,
    error,
    isLoading,
  } = useQueryRequest<Module[]>(
    async () => {
      if (!progress?.progression) {
        return
      }

      const scenario = await fetchOneScenario(undefined, scenarioId)

      const route = getRoute(progress?.progression, scenario)

      return route?.modules ?? []
    },
    ["modules", "scenarioId", scenarioId, "lastUseDate", progress?.lastUseDate],
    undefined,
    true,
  )

  const levels = modules?.find(m => m.id === moduleId)?.levels

  // @ts-ignore
  const formattedLevels: Array<
    (LevelStatic & { concernedLevels?: LevelStatic[] }) | LevelRevision
  > = levels?.some(l => l.type === "revision")
    ? _.map(levels, (l, index) => {
        if (l.type === "static" || l.type === "practiceTest") {
          return undefined
        }

        if (l.type === "finalRevision") {
          return l
        }

        return {
          ...l,
          concernedLevels: _.takeRightWhile(_.take(levels, index), cl => cl.type !== "revision"),
        }
      }).filter(elem => elem != null)
    : levels

  /**
   * This will set a module ID on first tab opening
   * */
  useEffect(() => {
    if (modules === undefined || _.isEmpty(modules) || moduleId !== undefined) {
      return
    }
    setModuleId(modules[0].id)
  }, [moduleId, modules])

  useEffect(() => {
    if (progress && moduleId) {
      if (progress.progression?.modules?.[moduleId]?.completionGraph) {
        const modulesSteps = mergeGraphs([
          progress.progression?.modules?.[moduleId]?.completionGraph,
        ])
        /**
         * The graph values are set twice with a 10ms delay to avoid victory issues
         * */
        setGraphData(old => old?.map(elem => ({ ...elem })))
        setTimeout(() => {
          setGraphData(
            modulesSteps?.map(({ date, percentage }, index) => ({
              date,
              percentage,
              id: `step-${index}`,
            })),
          )
          setLoading(false)
        }, 10)
      } else {
        setGraphData(old => old?.map(elem => ({ ...elem })))
        setTimeout(() => {
          setGraphData([])
          setLoading(false)
        }, 10)
      }
    }
  }, [moduleId, progress])

  /**
   * The chart is in a useMemo to avoid animation issues
   * */
  const ChartComp = useMemo(
    () => (
      <Chart width={380} data={graphData} height={250} isLoading={loading} responsive={false} />
    ),
    [graphData, loading],
  )

  const s = useStyles(
    ({ spacing, palette: { divider } }) => ({
      subtitle: {
        padding: spacing(2, 0, 1, 0),
      },
      border: {
        borderColor: divider,
        borderRadius: "8px",
        borderStyle: "solid",
        borderWidth: 1,
        minHeight: "250px",
      },
      buttons: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        minHeight: 68,
        paddingLeft: spacing(2),
      },
      error: {
        marginLeft: spacing(1),
        marginRight: spacing(1),
        marginTop: spacing(4),
        textAlign: "center",
      },
      mainDiv: {
        display: value === 0 ? "flex" : "none",
        flexDirection: "column",
        justifyContent: "space-between",
        maxHeight: `calc(100% - ${spacing(2)})`,
        overflowX: "hidden",
        overflowY: "scroll",
        padding: spacing(2),
      },
      list: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        paddingBottom: spacing(1),
        paddingLeft: "1px",
        paddingRight: "1px",
      },
    }),
    [value],
  )

  const [selectedFormattedLevel, setSelectedFormattedLevel] = useState<
    (LevelStatic & { concernedLevels?: LevelStatic[] }) | LevelRevision
  >()

  return error ? (
    <Typography style={s.error} variant="subtitle1">
      {t(`${PROGRESS_NS}.${(error as Error).message}`)}
    </Typography>
  ) : !modules || isLoading ? (
    <Loading />
  ) : _.isEmpty(modules) || moduleId === undefined ? (
    <Typography style={s.error} variant="subtitle1">
      {t(`${PROGRESS_NS}.noRoute`)}
    </Typography>
  ) : (
    <div style={s.mainDiv} role="tabpanel" id={`tab-panel-${0}`} aria-labelledby={`tab-${0}`}>
      {selectedFormattedLevel ? (
        <RulesDialog
          moduleTitle={modules.find(m => m.id === moduleId)?.title}
          onClose={() => {
            setSelectedFormattedLevel(undefined)
          }}
          open={!!selectedFormattedLevel}
          progress={progress?.progression?.modules?.[moduleId]}
          selectedFormattedLevel={selectedFormattedLevel}
        />
      ) : null}
      <MuiSelect
        id="groups"
        variant="outlined"
        value={moduleId}
        onValueChange={setModuleId}
        items={modules.map(item => ({ value: item.id, text: item.title }))}
      />
      <Typography style={s.subtitle} variant="subtitle1">
        {t(`${PROGRESS_NS}.globalProgress`)}
      </Typography>
      <div style={s.border}>{ChartComp}</div>
      <Typography style={s.subtitle} variant="subtitle1">
        {t(`${PROGRESS_NS}.levels`)}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.secondary}>
        {t(`${PROGRESS_NS}.pickLevelInfo`)}
      </Typography>
      <div style={s.list}>
        <List component="nav" aria-labelledby="list">
          {formattedLevels.map(item => (
            <StudentLevels
              level={item}
              key={item.id}
              setSelectedFormattedLevel={setSelectedFormattedLevel}
              progress={progress?.progression?.modules?.[moduleId]}
            />
          ))}
        </List>
      </div>
    </div>
  )
}
