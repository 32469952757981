import { Typography } from "@mui/material"
import _ from "lodash"
import type { FC } from "react"
import React, { useCallback, useMemo } from "react"
import { RadioButtonGroupInput, useInput, useTranslate } from "react-admin"
import { useStyles } from "utils"

import { ns, role } from "./constants"
import type { AdminRole, RoleContent } from "./useAdministratorFunctions"

interface Choice {
  description: string
  id: string
  label: string
}
const RoleRadioButtonGroup: FC<{ editable?: boolean; roleContent?: RoleContent }> = ({
  editable,
  roleContent,
}) => {
  const t = useTranslate()
  const { field } = useInput({ name: "role", source: "role" })
  const adminRole = useMemo(
    () =>
      (!_.isEmpty(roleContent?.divisionsIds)
        ? role.DIVISION
        : roleContent?.universeId
        ? role.UNIVERSE
        : undefined) as AdminRole | undefined,
    [roleContent?.divisionsIds, roleContent?.universeId],
  )

  const choices = useMemo(
    () => [
      {
        description: t(`${ns}.universeDescription`),
        id: role.UNIVERSE,
        label: t(`${ns}.universeAdmin`),
      },
      {
        description: t(`${ns}.divisionDescription`),
        id: role.DIVISION,
        label: t(`${ns}.divisionAdmin`),
      },
    ],
    [t],
  )

  const s = useStyles(
    ({ palette: { text, primary } }) => ({
      group: {
        margin: 0,
      },
      helperText: {
        color: editable ? text.secondary : text.disabled,
      },
      sx: {
        "& .MuiRadio-root.Mui-checked": {
          color: editable ? primary[500] : text.disabled,
        },
        "& .MuiFormControlLabel-root": {
          marginBottom: 2,
        },
      },
    }),
    [editable],
  )

  const optionText = useCallback(
    (choice: Choice) => (
      <>
        <Typography variant="subtitle1">{choice.label}</Typography>
        <Typography style={s.helperText} variant="body2">
          {choice.description}
        </Typography>
      </>
    ),
    [s.helperText],
  )

  return (
    <RadioButtonGroupInput
      defaultValue={adminRole ?? role.UNIVERSE}
      helperText={false}
      label={false}
      optionValue="id"
      source="role"
      style={s.group}
      disabled={!editable}
      sx={s.sx}
      {...field}
      {...{ choices, optionText }}
    />
  )
}

export default RoleRadioButtonGroup
