import { Typography } from "@mui/material"
import type { Student } from "@newpv/js-common"
import type { FC } from "react"
import React from "react"
import { useRecordContext } from "react-admin"
import type { RAFieldProps } from "utils"
import { useStyles } from "utils"

export const NameField: FC<RAFieldProps & { onClick?: (id: string) => void }> = ({
  onClick,
  ...fieldProps
}) => {
  const record = useRecordContext<Student>(fieldProps)

  const s = useStyles(() => ({
    text: {
      cursor: onClick ? "pointer" : "auto",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "200px",
    },
  }))

  return !record ? null : (
    <Typography onClick={() => onClick?.(record.id)} variant="subtitle1" style={s.text}>
      {(fieldProps.source === "firstName"
        ? record?.firstName || "-"
        : record.lastName.toLocaleUpperCase()) || "-"}
    </Typography>
  )
}
