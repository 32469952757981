import type { FC } from "react"
import React, { useEffect, useState } from "react"
import type { SelectInputProps } from "react-admin"
import { SelectInput, useListContext } from "react-admin"
import { useScenarioId } from "utils"

export const SelectLevelInput: FC<SelectInputProps> = props => {
  const [scenarioId] = useScenarioId()
  const { setFilters, displayedFilters } = useListContext()
  const [currentScenarioId, setCurrentScenarioId] = useState<number>()

  useEffect(() => {
    if (currentScenarioId === scenarioId) {
      return
    }
    setCurrentScenarioId(scenarioId)
    setFilters(
      {
        choice: ``,
      },
      displayedFilters,
    )
  }, [scenarioId, currentScenarioId, displayedFilters, setFilters])

  return (
    <>
      <SelectInput {...props} />
    </>
  )
}

export default SelectLevelInput
