import { CircularProgress } from "@mui/material"
import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { Login as RALogin, useAuthProvider, useNotify } from "react-admin"
import { useNavigate } from "react-router-dom"
import { useQueryParams, useStyles } from "utils"

import { CARD_BOX_SHADOW } from "../Theme/theme"

const Login: FC = () => {
  const query = useQueryParams()
  const notify = useNotify()
  const navigate = useNavigate()
  const authProvider = useAuthProvider()
  const [isLoggingIn, setIsLoggingIn] = useState(true)

  useEffect(() => {
    ;(async () => {
      try {
        const tokenFromUrl = query.get("jwt") ?? query.get("token")
        if (!tokenFromUrl) {
          // eslint-disable-next-line no-console
          console.log("No token found in query")
          return
        }
        const modeFromUrl =
          query.get("mode") === "licences" || query.get("mode") === "licenses"
            ? "licenses"
            : undefined
        await authProvider.login({ token: tokenFromUrl, mode: modeFromUrl })
        navigate(modeFromUrl === "licenses" ? "/student-licenses" : "/stats", {
          replace: true,
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`Sign in error ${e}`)
        notify("ra.auth.sign_in_error", { type: "error" })
      } finally {
        setIsLoggingIn(false)
      }
    })()
  }, [authProvider, query, notify, navigate])

  const s = useStyles(
    ({
      spacing,
      palette: {
        text: { primary },
        secondary: { main },
      },
    }) => ({
      loginSx: {
        "& .RaLogin-card": {
          boxShadow: CARD_BOX_SHADOW,
        },
        "& .RaLogin-icon": {
          backgroundColor: main,
        },
        "& .MuiButtonBase-root": {
          padding: spacing(1.5),
        },
        "& .MuiInputLabel-root": {
          color: primary,
        },
        "& .css-g07u62-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
          color: primary,
        },
      },
    }),
  )

  return isLoggingIn ? (
    <CircularProgress />
  ) : (
    <RALogin backgroundImage={require("../../assets/images/fff.png")} sx={s.loginSx} />
  )
}

export default Login
