import { Typography, useTheme } from "@mui/material"
import BoxWrapper from "components/BoxWrapper"
import { RadioButtonField } from "components/RadioButtonField"
import type { Dispatch, ReactElement, SetStateAction } from "react"
import React from "react"
import { useCommonStyles, useStyles } from "utils"

interface Radio<T> {
  description: string
  icon?: ReactElement
  label: string
  value: T
}

interface Props<T> {
  state: [T | undefined, Dispatch<SetStateAction<T | undefined>>]
  title: string
  subtitle: string
  radios: {
    left: Radio<T>
    right: Radio<T>
  }
}
function RadioChoiceScreen<T>({
  title,
  subtitle,
  radios,
  state: [choice, setChoice],
}: Props<T>): ReactElement {
  const { right, left } = radios
  const {
    palette: { text },
  } = useTheme()

  const cs = useCommonStyles()
  const s = useStyles(({ spacing }) => ({
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      gap: spacing(4),
      marginTop: spacing(5),
    },
  }))

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChoice(event.target.value as T)
  }

  return (
    <BoxWrapper>
      <Typography variant="h4">{title}</Typography>
      <Typography color={text.secondary} style={cs.marginTop16} variant="subtitle1">
        {subtitle}
      </Typography>
      <div style={s.radioGroup}>
        <RadioButtonField checked={choice === left.value} {...left} {...{ onChange, setChoice }} />
        <RadioButtonField
          checked={choice === right.value}
          {...right}
          {...{ onChange, setChoice }}
        />
      </div>
    </BoxWrapper>
  )
}

export default RadioChoiceScreen
