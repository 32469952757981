import type { ThemeOptions as MuiThemeOptions } from "@mui/material"
import type { PaletteOptions } from "@mui/material/styles/createPalette"
import _ from "lodash"
import { defaultTheme } from "react-admin"

export const ICON_SIZE = 32
export const ICON_SIZE_SMALL = 12
export const ICON_SIZE_MEDIUM = 24

export const APP_BAR_HEIGHT = 58
export const SCREEN_TOP_BAR_HEIGHT = 48
export const APP_TOP_BAR_HEIGHT = 64

export const MAX_WIDTH_TYPOGRAPHY = 680

export const CARD_BOX_SHADOW =
  "0px 1px 1px rgba(5, 6, 40, 0.14), 0px 2px 1px rgba(5, 6, 40, 0.12), 0px 1px 3px rgba(5, 6, 40, 0.2)"

export const INPUT_HEIGHT = "42px"

export interface ThemeOptions extends MuiThemeOptions {
  palette: PaletteOptions & {
    background: {
      secondary?: string
      neutral?: string
      outline?: string
      default: string
      paper: string
    }
    action: {
      overlay?: string
      outlined?: string
    }
    divider: string
    neutral: {
      "50": string
      "500": string
      "700": string
    }
    surface: { outline: string }
    warning: {
      icon: string
    }
  }
}

export const myTheme: ThemeOptions = _.merge({}, defaultTheme, {
  palette: {
    type: "light",
    primary: {
      main: "#C4DF1D",
      "50": "rgba(239, 244, 207, 1)",
      "300": "#DFFB6B",
      "400": "rgba(190, 237, 41, 1)",
      "500": "rgba(162, 212, 22, 1)",
      "600": "rgba(143, 186, 19, 1)",
      "700": "#71AD00",
      "800": "#558200",
      outline: "rgba(5, 6, 40, 0.16)",
    },
    secondary: {
      main: "#FF8F1F",
      "50": "#FFE7B4",
      "100": "rgba(255, 143, 31, 0.3)",
      "300": "rgba(255, 205, 138, 1)",
      "500": "rgba(254, 174, 6, 1)",
      "700": "#D97612",
      "800": "rgba(195, 82, 0, 1)",
    },
    background: {
      default: "rgba(255, 255, 255, 1)",
      paper: "#FFF",
      neutral: "rgba(240, 244, 241, 1)",
      secondary: "rgba(245, 245, 247, 1)",
    },
    divider: "rgba(5, 6, 40, 0.12)",
    surface: { outline: "rgba(5, 6, 40, 0.16)" },
    neutral: {
      "50": "#F0F4F1",
      "500": "#93B298",
      "700": "#5C7360",
    },
    action: {
      disabled: "rgba(22, 27, 39, 0.32)",
      active: "#000",
      overlay: "rgba(5, 6, 40, 0.08)",
      outlined: "rgba(5, 6, 40, 0.16)",
    },
    text: {
      primary: "rgba(22, 27, 39, 1)",
      disabled: "rgba(22, 27, 39, 0.32)",
      secondary: "rgba(22, 27, 39, 0.73)",
    },
    success: {
      main: "rgba(142, 185, 18, 1)",
    },
    error: {
      main: "#d32f2f",
    },
    warning: {
      main: "rgba(240, 140, 3, 1)",
      "400": "#FCD305",
      icon: "#F4C974",
    },
    common: {
      black: "rgba(22, 27, 39, 1)",
      white: "#FFF",
    },
  },
  typography: {
    body1: {
      fontFamily: "Montserrat SemiBold",
      fontSize: "16px",
      lineHeight: "19px",
    },
    body2: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      lineHeight: "17px",
    },
    caption: {
      fontFamily: "Montserrat",
      fontSize: "12px",
      lineHeight: "14px",
    },
    button: {
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0px",
    },
    h4: {
      fontFamily: "Montserrat SemiBold",
      fontSize: "34px",
      lineHeight: "41px",
    },
    h5: {
      fontFamily: "Montserrat SemiBold",
      fontSize: "24px",
      lineHeight: "29px",
    },
    h6: {
      fontFamily: "Montserrat SemiBold",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0px",
    },
    subtitle1: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      textAlign: "left",
      lineHeight: "19px",
    },
    subtitle2: {
      fontFamily: "Montserrat MediumItalic",
      fontSize: "14px",
      textAlign: "left",
      lineHeight: "17px",
    },
    overline: {
      fontFamily: "Montserrat SemiBold",
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  components: {
    RaLoading: {
      styleOverrides: {
        root: {
          "& .RaLoading-root": {
            height: "100%",
          },
        },
      },
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          "& .RaAppBar-toolbar": {
            height: `${APP_BAR_HEIGHT}px`,
          },
        },
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          "& .RaBulkActionsToolbar-topToolbar": {
            padding: 0,
          },
          "& .RaBulkActionsToolbar-title": {
            alignItems: "center",
          },
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          "& .RaList-content": {
            boxShadow: "none",
          },
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          "& .RaLayout-content": {
            paddingLeft: "0px",
            paddingRight: "0px",
          },
          "& .RaLayout-appFrame": {
            marginTop: `${APP_BAR_HEIGHT}px`,
          },
        },
      },
    },
    RaNotification: {
      styleOverrides: {
        root: {
          "& .RaNotification-success": {
            backgroundColor: "rgba(190, 237, 41, 1)",
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .RaDatagrid-tableWrapper": {
            boxShadow: "none",
          },
        },
      },
    }, // RaFilterForm-filterFormInput
    RaFilterForm: {
      styleOverrides: {
        root: {
          "& .RaFilterForm-filterFormInput": {
            marginLeft: "8px",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontFamily: "Montserrat",
          fontSize: "14px",
          lineHeight: "17px",
          color: "rgba(22, 27, 39, 0.73)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontFamily: "Montserrat",
          fontSize: "16px",
          color: "rgba(22, 27, 39, 0.73)",
          borderBottom: "none",
          padding: "12px 16px",
        },
        head: {
          fontSize: "14px",
          fontWeight: "400",
          fontFamily: "Montserrat",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          borderBottomColor: "transparent",
          marginBottom: "16px",
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          color: "#7B8C11",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          overflowX: "hidden",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontFamily: "Montserrat",
          fontSize: "16px",
          height: "42px",
          lineHeight: "32px",
          maxHeight: INPUT_HEIGHT,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontFamily: "Montserrat",
          fontSize: "16px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Montserrat",
          fontSize: "16px",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&:focus::after": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Montserrat",
          fontSize: "14px",
          "&:focus::after": {
            backgroundColor: "transparent",
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:hover:active::after": {
            backgroundColor: "transparent",
          },
          "&:active::before": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: "32px",
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          height: "48px",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          backgroundColor: "white",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "35px",
          height: "35px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "35px",
          height: "35px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          transform: "none",
          visibility: "visible",
          boxShadow: "inset 0 -1px rgba(5, 6, 40, 0.12)",
          backgroundColor: "white",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          color: "#000",
        },
        containedPrimary: {
          backgroundColor: "rgba(190, 237, 41, 1)",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: "Montserrat",
          fontSize: "14px",
          lineHeight: "42px",
          height: "42px",
          maxHeight: INPUT_HEIGHT,
        },
        filled: {
          fontFamily: "Montserrat",
          fontSize: "14px",
          lineHeight: "42px",
          height: "42px",
          maxHeight: INPUT_HEIGHT,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          height: "42px",
          maxHeight: INPUT_HEIGHT,
        },
      },
    },
  },
} as ThemeOptions)
