import type { ResourceId, ScenarioId, UniverseProgression } from "@newpv/js-common"
import { axios } from "@newpv/js-common"
import type { AxiosResponse } from "axios"
import { getAuth, useDivisionId, useQueryRequest, useToken, useUniverseId } from "utils"

import { bffUrl } from "../../../features/Constants"

export const useUniverseStats = ({
  scenarioId,
  resourceId,
}: {
  scenarioId?: ScenarioId
  resourceId?: ResourceId
}): {
  universeProgress: UniverseProgression | undefined
  isLoading: boolean
} => {
  const [universeId] = useUniverseId()
  const [divisionId] = useDivisionId()
  const [token] = useToken()

  const { data: universeProgress, isLoading } = useQueryRequest<UniverseProgression>(async () => {
    if (!divisionId || !scenarioId) {
      return
    }
    const rawUniverseProgress = await axios.get<any, AxiosResponse<UniverseProgression>>(
      `${bffUrl}/stats/universe/${universeId}?scenarioId=${scenarioId}&resourceId=${resourceId}`,
      getAuth(token),
    )

    return rawUniverseProgress.data
  }, [
    "universeStats",
    "scenarioId",
    scenarioId,
    "universeId",
    universeId,
    "divisionId",
    divisionId,
    "resourceId",
    resourceId,
  ])

  return { universeProgress, isLoading }
}
