import { Grid, Typography } from "@mui/material"
import List from "@mui/material/List"
import type {
  CalculationMethod,
  InitialEvalLearnerResult,
  LearnerExaminationResult,
  User,
  UserId,
} from "@newpv/js-common"
import { Empty } from "components"
import dayjs from "dayjs"
import _ from "lodash"
import type { Dispatch, FC, SetStateAction } from "react"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslate } from "react-admin"
import { useCommonStyles, useStyles } from "utils"
import useStudentsFromDivision from "utils/useStudentsFromDivision"

import { commonNs } from "../../features/i18n/fr"
import { ResultItem } from "./components/ResultItem"

/** height already used on page, by other elements */
// 68 = bottom container
const HEIGHT_NOT_AVAILABLE = 184 + 68

interface Props {
  value: number
  learners?: Array<LearnerExaminationResult & { sessionIsOver: boolean }>
  calculationMethod?: CalculationMethod
  initialEvaluationResults?: InitialEvalLearnerResult[]
  isInitialEvaluation?: boolean
  setRetryLearnerId: (learnerId: string) => void
  setSessionOpen: Dispatch<SetStateAction<boolean>>
  usersWithFutureSessionsPlanned?: Record<UserId, boolean>
  evalDuration?: number
}

const ns = "eval.evalModal.resultsTab"

type UserNames = Pick<User, "lastName" | "firstName">

export const ResultsTab: FC<Props> = ({
  /** selected tab, if !== 1, we display nothing */
  value,
  /** learners = individual examination results */
  learners,
  calculationMethod,
  /** only for initial examination */
  initialEvaluationResults,
  isInitialEvaluation,
  usersWithFutureSessionsPlanned,
  setRetryLearnerId,
  setSessionOpen,
  evalDuration,
}) => {
  const t = useTranslate()

  const { data: rawStudentsInfo } = useStudentsFromDivision()

  const [filteredLearners, setFilteredLearners] = useState<
    Array<
      LearnerExaminationResult & {
        sessionIsOver: boolean
        hasFutureSessionsPlanned: boolean
      } & UserNames
    >
  >()

  const commonXs = isInitialEvaluation ? 2.5 : 2

  useEffect(() => {
    if (isInitialEvaluation) {
      return
    }

    // TODO: see if could be improved using this suggestion
    // _(learners).groupBy(l => l.userId).map(l => l.learnerResult.filter(…).sort(…).head()

    const acc: Record<UserId, LearnerExaminationResult & { sessionIsOver: boolean } & UserNames> =
      {}

    learners
      ?.filter(
        learnerResult =>
          learnerResult.status === "graded" ||
          learnerResult.status === "started" ||
          // session is over and the user never started the examination
          (learnerResult.status === "notstarted" && learnerResult.sessionIsOver),
      )
      ?.forEach(learnerResult => {
        const currentValueInAcc = acc?.[learnerResult.userId]
        if (
          !currentValueInAcc ||
          // we keep the most recent result of the two, if it has a grade
          // in this case both exams are graded and we keep the latest grade
          (currentValueInAcc.finishExaminationDate &&
            dayjs(learnerResult.finishExaminationDate).isAfter(
              currentValueInAcc.finishExaminationDate,
            ) &&
            learnerResult.status === "graded") ||
          // in this case the acc one is not graded and the current one is so we replace it
          // TODO: check logic here if API change and there is actually an automatic (0?) grade once session is over
          (currentValueInAcc.sessionIsOver &&
            currentValueInAcc.status === "notstarted" &&
            learnerResult.status === "graded")
        ) {
          const userInfos = _.find(rawStudentsInfo, studentInfo =>
            _.isEqual(studentInfo.id, learnerResult.userId),
          )
          acc[learnerResult.userId] = {
            ...learnerResult,
            ...(_.pick(userInfos, ["firstName", "lastName"]) as Pick<
              User,
              "firstName" | "lastName"
            >),
          }
        }
      })

    setFilteredLearners(
      _.orderBy(
        Object.values(acc).map(filteredLearnerResult => ({
          ...filteredLearnerResult,
          hasFutureSessionsPlanned:
            usersWithFutureSessionsPlanned?.[filteredLearnerResult.userId] ?? false,
        })),
        o => o.lastName?.toLowerCase(),
        ["asc"],
      ),
    )
  }, [isInitialEvaluation, learners, rawStudentsInfo, usersWithFutureSessionsPlanned])

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing }) => ({
      item: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
      },
      main: {
        ...(value === 1 ? {} : { display: "none" }),
        width: "100%",
        height: "100%",
      },
      list: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        maxHeight: "100%",
        overflowY: "auto",
        padding: spacing(0, 2),
      },
      listDiv: {
        alignSelf: "flex-start",
        flexDirection: "column",
        // TODO: fix style here to avoid using fixed heights
        height: `calc(100vh - ${HEIGHT_NOT_AVAILABLE}px)`,
        maxHeight: `calc(100vh - ${HEIGHT_NOT_AVAILABLE}px)`,
        width: "100%",
      },
    }),
    [value],
  )

  const learnerResults = useMemo(
    () =>
      isInitialEvaluation
        ? initialEvaluationResults?.map(elem => ({ userId: elem.id, ...elem }))
        : filteredLearners,
    [filteredLearners, isInitialEvaluation, initialEvaluationResults],
  )

  return (
    <div style={s.main}>
      <div style={s.listDiv}>
        <div style={s.list}>
          {!learnerResults || learnerResults.length === 0 ? (
            <Empty />
          ) : (
            <div>
              <Grid container style={{ ...cs.paddingTop16 }}>
                <Grid item xs={isInitialEvaluation ? 7 : 6}>
                  <Typography variant="body1">{t(`${ns}.learner`)}</Typography>
                </Grid>
                <Grid style={s.item} item xs={commonXs}>
                  <Typography variant="body1" style={cs.centerText}>
                    {t(`${commonNs}.duration`)}
                  </Typography>
                </Grid>
                <Grid style={s.item} item xs={commonXs}>
                  <Typography variant="body1" style={cs.centerText}>
                    {t(isInitialEvaluation ? `${commonNs}.score` : `${commonNs}.grade`)}
                  </Typography>
                </Grid>
                {isInitialEvaluation ? null : (
                  <Grid style={s.item} item xs={2}>
                    <Typography variant="h6" style={cs.centerText} />
                  </Grid>
                )}
              </Grid>
              <List
                component="nav"
                aria-labelledby="list"
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                {!learnerResults || learnerResults.length === 0 ? (
                  <Empty />
                ) : (
                  learnerResults.map(
                    (
                      learnerResult:
                        | (Omit<InitialEvalLearnerResult, "id"> & { userId: UserId })
                        | (LearnerExaminationResult & {
                            sessionIsOver: boolean
                            hasFutureSessionsPlanned: boolean
                          } & UserNames),
                      idx,
                    ) => (
                      <ResultItem
                        key={`${learnerResult.userId}-${idx}`}
                        learner={learnerResult}
                        hasFutureSessionPlanned={
                          isInitialEvaluation
                            ? false
                            : usersWithFutureSessionsPlanned?.[
                                (learnerResult as LearnerExaminationResult & UserNames).userId ?? ""
                              ] === true
                        }
                        {...{
                          calculationMethod,
                          isInitialEvaluation,
                          setRetryLearnerId,
                          setSessionOpen,
                          evalDuration,
                        }}
                      />
                    ),
                  )
                )}
              </List>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
