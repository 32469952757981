import { Grid, List, ListItem, Tooltip, Typography } from "@mui/material"
import type {
  BOEvaluation,
  DivisionProgression,
  EvaluationId,
  InitialEvaluationSummary,
  LearnerProgression,
  User,
} from "@newpv/js-common"
import { Empty, Progress, Separator } from "components"
import dayjs from "dayjs"
import _ from "lodash"
import type { FC } from "react"
import React from "react"
import { useTranslate } from "react-admin"
import { useNavigate } from "react-router-dom"
import { useGradeColor, useStyles } from "utils"

import { DATE_FORMAT_LNG } from "../../../features/Constants"
import { STATS_HEAD_SIZE } from "./SubStatsHeader"

const DashboardItem: FC<{
  id?: string | number
  first?: string
  second?: string
  moreUrl?: string
  refNbr?: number
  baseColor?: number
  completion: string
  isInitialEvaluation?: boolean
}> = ({ id, first, second, refNbr, moreUrl, baseColor, completion, isInitialEvaluation }) => {
  const { getPercentColor } = useGradeColor()
  const navigate = useNavigate()

  const color = getPercentColor(refNbr, baseColor)

  /**
   * Styling
   * */
  const s = useStyles(({ spacing }) => ({
    level: {
      textAlign: "right",
    },
    text: {
      overflow: "hidden",
      paddingRight: spacing(1),
    },
    listItem: {
      cursor: "pointer",
      height: "55px",
      justifyContent: "space-between",
      padding: spacing(0, 2),
    },
  }))

  const redirect = (): void => {
    navigate(`${moreUrl}/${id}`)
  }

  return (
    <>
      <ListItem style={s.listItem} onClick={redirect}>
        <Grid container>
          <Grid item xs={4}>
            <Tooltip title={first}>
              <Typography style={s.text} variant="subtitle1">
                {first}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{ ...s.text, ...(isInitialEvaluation ? { textAlign: "center" } : undefined) }}
              variant="subtitle1"
            >
              {second}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={s.level} variant="subtitle1" {...{ color }}>
              {completion}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
      <Separator />
    </>
  )
}

interface Props {
  loading: boolean
  moreUrl: string
  data?:
    | DivisionProgression["divisionUsersProgress"]
    | Array<BOEvaluation | (InitialEvaluationSummary & { id: EvaluationId })>
}

export const ElementStats: FC<Props> = ({ data, loading, moreUrl }: Props) => {
  /**
   * Styling
   * */
  const s = useStyles(() => ({
    listComp: {
      padding: 0,
    },
    listItem: {
      height: "55px",
      justifyContent: "space-between",
    },
    list: {
      flexGrow: 1,
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
    },
    listDiv: {
      flex: 1,
      width: "100%",
      display: "flex",
      alignSelf: "flex-start",
      flexDirection: "column",
      maxHeight: `calc(100% - ${STATS_HEAD_SIZE}px)`,
    },
  }))

  const t = useTranslate()

  // TODO: Add the error condition from the data and display view related to it
  return loading && !data ? (
    <Progress />
  ) : _.isEmpty(data) ? (
    <Empty />
  ) : (
    <div style={s.listDiv}>
      <div style={s.list}>
        <List component="nav" aria-labelledby="list" style={s.listComp}>
          {_.map(data, (item: (Partial<User> & LearnerProgression) | BOEvaluation, index) => {
            const isInitialEvaluation =
              "scenarioId" in item && item.name === t("stats.dashboardTab.initial")
            const elem =
              // if item is an evaluation
              "scenarioId" in item
                ? {
                    id: item.id,
                    first: item.name,
                    refNbr: item.averageGrade,
                    isInitialEvaluation,
                    second: isInitialEvaluation
                      ? "-"
                      : dayjs(item.startDate).format(DATE_FORMAT_LNG),
                    baseColor: isInitialEvaluation ? 100 : 20,
                    completion: !_.isNumber(item.averageGrade)
                      ? "-"
                      : isInitialEvaluation
                      ? `${_.round(item.averageGrade, 2)} %`
                      : `${_.round(item.averageGrade, 2)}/20`,
                  }
                : // else item is a student
                  {
                    id: item.id,
                    refNbr: item.completionPercentage,
                    first: item.lastName?.toLocaleUpperCase(),
                    second: item.firstName || "-",
                    completion:
                      item.trainingDuration === 0
                        ? "-"
                        : `${_.round(item.completionPercentage, 2)}%`,
                  }
            return (
              <DashboardItem
                {...elem}
                key={`${elem.id}.${index}`}
                moreUrl={isInitialEvaluation ? "/evaluations/initialEvaluation" : moreUrl}
              />
            )
          })}
        </List>
      </div>
    </div>
  )
}
