import type { BOSession, SessionCreate, SessionEdit } from "@newpv/js-common"
import dayjs from "dayjs"
import _ from "lodash"
import type { SaveHandler } from "ra-core"
import { useMemo } from "react"
import { useCreateController, useEditController, useNotify } from "react-admin"
import { useNavigate } from "react-router-dom"

export interface SessionInfos<T> {
  save?: SaveHandler<T> | ((data: Partial<T>) => Promise<T>)
  onDelete?: () => Promise<void>
  sessionData?: Partial<T>
  saving?: boolean
  isLoading: boolean
}

/**
 * The default session is a function to keep up with the current date and time
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const defaultSession = () => ({
  startDate: dayjs(new Date().setSeconds(0)).add(1, "day").toISOString(),
  dueDate: dayjs(new Date().setSeconds(0)).add(1, "day").add(1, "hour").toISOString(),
})

export const useSessionCreate = (evalId?: string): SessionInfos<SessionCreate> => {
  const { save, isLoading, saving } = useCreateController<SessionCreate>({
    resource: `evaluation/evaluation/${evalId}/session`,
  })

  const defaultSessionValue = useMemo(() => defaultSession(), [])

  return {
    save,
    saving,
    isLoading,
    sessionData: defaultSessionValue,
  }
}

export const useSessionEdit = (evalId?: string, sessionId?: string): SessionInfos<SessionEdit> => {
  /**
   * Contexts
   * */
  const notify = useNotify()
  const navigate = useNavigate()

  const {
    save,
    record: sessionDetail,
    isLoading,
    saving,
  } = useEditController<BOSession>({
    resource: `evaluation/evaluation/${evalId}/session`,
    id: sessionId,
    mutationMode: "optimistic",
    mutationOptions: {
      meta: {
        altMethod: "PATCH",
      },
    },
    queryOptions: {
      meta: {
        idField: "sessionId",
      },
      onError: () => {
        notify("ra.notification.http_error", { type: "error" })
        navigate("..", { replace: true })
      },
    },
  })

  return {
    save,
    saving,
    isLoading,
    sessionData: {
      ..._.omit(sessionDetail, "learners"),
      learnersIds: sessionDetail?.learners.map(elem => elem.userId),
      learnersToNotStartedStatus: _.reduce(
        sessionDetail?.learners,
        (res, elem) => ({ ...res, [elem.userId]: elem.status === "notstarted" }),
        {},
      ),
    },
  }
}
