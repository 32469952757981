import dayjs from "dayjs"
import type { Translate } from "react-admin"

interface Duration {
  t: Translate
  alt?: string
  duration?: number
  customFormat?: string
}

/**
 * Returns the formatted duration from a timestamp in milliseconds
 *
 * @param props
 * @param props.t The translation function
 * @param props.duration Duration to parse, in milliseconds
 * @param props.alt Alternative if the duration is undefined
 * @param props.customFormat Custom string
 *
 * @example formatDuration({ t, duration: 15362718 }),
 * */
export const formatDuration = ({
  t,
  duration,
  alt = "-",
  customFormat,
}: Duration): string | undefined =>
  duration == null
    ? alt
    : dayjs
        .duration(duration, "ms")
        .format(
          t(customFormat ?? `common.durationFormats.${duration >= 3600000 ? "long" : "short"}`),
        )
