import type { Group } from "@newpv/js-common"
import type { FC, PropsWithChildren } from "react"
import React, { createContext, useContext, useState } from "react"
import type { SetState } from "utils/CommonTypes"

interface ContextData {
  localGroupList: Group[] | undefined
  setLocalGroupList: SetState<Group[]>
}

const localGroupListContext = createContext<ContextData>({} as ContextData)
// To access the group data outside List Context
export const LocalGroupListProvider: FC<PropsWithChildren> = ({ children }) => {
  const [localGroupList, setLocalGroupList] = useState<Group[]>()
  return (
    <localGroupListContext.Provider value={{ localGroupList, setLocalGroupList }}>
      {children}
    </localGroupListContext.Provider>
  )
}

export const useLocalGroupListContext = (): ContextData => useContext(localGroupListContext)

export default LocalGroupListProvider
