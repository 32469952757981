import { ListItem, Typography, useTheme } from "@mui/material"
import type { SessionCreate, TmpSession } from "@newpv/js-common"
import { Menu } from "components"
import dayjs from "dayjs"
import _ from "lodash"
import type { FC } from "react"
import React from "react"
import { useDelete, useNotify, useTranslate } from "react-admin"
import { useCommonStyles, useStyles } from "utils"
import type { SetState } from "utils/CommonTypes"

import { DATE_FORMAT, HOUR_FORMAT } from "../../../features/Constants"
import { evalNs } from "../../../features/i18n"

interface Props {
  item: SessionCreate | TmpSession
  evalId?: string
  totalStudents: number | string
  setNewEvalSessions: SetState<TmpSession[]>
  onClick: () => void
}

export const SessionListItem: FC<Props> = ({
  item,
  evalId,
  onClick,
  totalStudents,
  setNewEvalSessions,
}) => {
  const t = useTranslate()
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme()
  const notify = useNotify()

  const [deleteOne] = useDelete()

  const onDelete = (): Promise<void> | undefined => {
    if (!evalId) {
      setNewEvalSessions(prev => _.filter(prev, elem => elem.tmpId !== (item as TmpSession).tmpId))
      return
    }
    return deleteOne(
      `evaluation/evaluation/${evalId}/session`,
      { id: item.id },
      {
        onError: (error: any) => {
          notify(
            // we cannot display the message directly because it's sometimes in english, sometimes in french
            (error?.response?.data?.message ?? "").includes("Au moins un apprenant a déjà")
              ? "ra.notification.eval_session_err_2"
              : "ra.notification.eval_session_err",
            { type: "error" },
          )
        },
      },
    )
  }

  const cs = useCommonStyles()
  const s = useStyles(({ spacing }) => ({
    list: {
      justifyContent: "space-between",
      padding: spacing(1, 1),
    },
    listInnerDiv: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      width: "100%",
    },
  }))

  return (
    <ListItem style={s.list} key={item.id}>
      <div style={s.listInnerDiv}>
        <div style={cs.cursor} onClick={onClick}>
          <Typography variant="subtitle1">{item.name}</Typography>
          <Typography variant="body2" color={secondary}>
            {t(`${evalNs}.generalTab.learners`, {
              nbr: item.learnersIds.length,
              total: totalStudents,
            })}
          </Typography>
          <Typography variant="body2" color={secondary}>
            {t(`${evalNs}.generalTab.sessionTime`, {
              startDay: dayjs(item.startDate).format(DATE_FORMAT),
              startTime: dayjs(item.startDate).format(HOUR_FORMAT),
              dueDay: dayjs(item.dueDate).format(DATE_FORMAT),
              dueTime: dayjs(item.dueDate).format(HOUR_FORMAT),
            })}
          </Typography>
        </div>
        <Menu
          actions={[
            {
              title: t("ra.action.delete"),
              onClick: onDelete,
            },
          ]}
        />
      </div>
    </ListItem>
  )
}
