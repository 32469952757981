import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined"
import { CircularProgress, Typography } from "@mui/material"
import type { Division, Scenario } from "@newpv/js-common"
import { fetchOneScenario } from "@newpv/js-common"
import { ScreenContainer } from "components"
import EmptyScreen from "components/EmptyScreen"
import _ from "lodash"
import type { FC } from "react"
import React, { useMemo } from "react"
import { Button, CreateButton, List, SearchInput, useGetOne, useTranslate } from "react-admin"
import { Route, Routes } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import {
  useAuthType,
  useCommonStyles,
  useDivisionId,
  useQueryRequest,
  useScenarioId,
  useStyles,
  useUniverseId,
} from "utils"

import { commonNs } from "../../features/i18n/fr"
import { EvalDetailsScreen } from "./EvalDetailsScreen"
import EvalListContent from "./EvalListContent"

const EvalListScreen: FC = () => {
  const t = useTranslate()
  const navigate = useNavigate()

  const [universeId] = useUniverseId()
  const [divisionId] = useDivisionId()
  const [scenarioId] = useScenarioId()
  const [authType] = useAuthType()

  const { data: scenario, isLoading } = useQueryRequest<Scenario>(
    async () => fetchOneScenario(undefined, scenarioId),
    ["scenario", "scenarioId", scenarioId],
    { enabled: scenarioId != null },
  )

  const { data: divisionData } = useGetOne<Division>(
    `universe/division`,
    {
      id: divisionId ?? "",
      meta: { idField: "divisionId" },
    },
    { enabled: !!divisionId },
  )

  const EmptyScreenDisplay = useMemo(() => {
    if (authType === "gar" && (divisionData?.nbLearners === 0 || !scenarioId)) {
      return <EmptyScreen title={t("eval.emptyScenario")} subtitle="" />
    }
    if (divisionData?.nbLearners === 0) {
      return (
        <EmptyScreen title={t("eval.emptyLearner")} subtitle={t("eval.emptySubtitleLearner")} />
      )
    }
    if (!scenarioId) {
      // Display when learners aren't connected yet
      return (
        <EmptyScreen title={t("eval.emptyScenario")} subtitle={t("eval.emptyScenarioSubtitle")} />
      )
    }
    return (
      <EmptyScreen
        title={t(`eval.${_.isEmpty(scenario?.evaluations) ? "emptyNoEval" : "empty"}`)}
        subtitle={t(
          `eval.${_.isEmpty(scenario?.evaluations) ? "emptySubtitleNoEval" : "emptySubtitle"}`,
        )}
        button={
          _.isEmpty(scenario?.evaluations) ? undefined : (
            <Button
              label="eval.create"
              resource="evaluations"
              onClick={() => navigate("/evaluations/create")}
              variant="contained"
            />
          )
        }
      />
    )
  }, [divisionData?.nbLearners, scenarioId, t, scenario?.evaluations, authType, navigate])

  const scenarioHasInitialEvaluation = scenario?.initialEvaluation != null

  const cs = useCommonStyles()
  const s = useStyles(() => ({
    list: {
      "& .RaList-main": {
        boxShadow: "none",
      },
      "& .RaList-content": {
        borderRadius: "2px",
      },
      "& .RaDatagrid-tableWrapper": {
        borderRadius: "2px",
        boxShadow: 4,
        overflowX: "hidden",
      },
    },
    initialEvalTitle: {
      marginLeft: "0.5rem",
    },
  }))

  return !universeId ? null : isLoading ? (
    <CircularProgress />
  ) : (
    <ScreenContainer
      icon={<AssignmentTurnedInOutlinedIcon sx={cs.primary600} fontSize="large" />}
      title={t(`${commonNs}.evaluations`)}
      right={
        !_.isEmpty(scenario?.evaluations) ? (
          <CreateButton variant="contained" style={cs.alignButton} />
        ) : undefined
      }
    >
      {divisionId == null ? (
        EmptyScreenDisplay
      ) : (
        <div style={cs.fullView}>
          {scenarioHasInitialEvaluation ? (
            <>
              <Typography sx={s.initialEvalTitle} variant="body1" component="span">
                {t("stats.dashboardTab.initial")}
              </Typography>
              <List
                actions={false}
                sx={s.list}
                empty={<EmptyScreen />}
                exporter={false}
                resource={`stats/division/${divisionId}/initialEvaluation`}
                queryOptions={{ meta: { idField: "evaluationId" } }}
                filter={{ scenarioId }}
                pagination={false}
              >
                <>
                  <EvalListContent isInitialEvaluation={true} />
                  <Routes>
                    <Route
                      path="/initialEvaluation/:id"
                      element={<EvalDetailsScreen isInitialEvaluation={true} />}
                    />
                  </Routes>
                </>
              </List>
              <div style={{ marginBottom: 20 }} />
              <Typography sx={{ marginLeft: "0.5rem" }} variant="body1" component="span">
                {t("common.evaluations")}
              </Typography>
            </>
          ) : null}
          <List
            actions={false}
            sx={s.list}
            empty={EmptyScreenDisplay}
            exporter={false}
            resource={`evaluation/universe/${universeId}/evaluation`}
            queryOptions={{ meta: { idField: "evaluationId" } }}
            filter={{ divisionId, scenarioId }}
            // "source" has to be "search" to correctly filter in the API
            filters={[<SearchInput variant="outlined" source="search" key={0} alwaysOn />]}
          >
            <EvalListContent />
          </List>
          <Routes>
            <Route path="/:id" element={<EvalDetailsScreen />} />
            <Route path="/create" element={<EvalDetailsScreen isCreate />} />
          </Routes>
        </div>
      )}
    </ScreenContainer>
  )
}

export default EvalListScreen
