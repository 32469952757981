import { useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import type { SelectProps } from "@mui/material/Select/Select"
import type { ChangeEvent, CSSProperties, LiHTMLAttributes, ReactElement } from "react"
import React, { useEffect, useState } from "react"

import { INPUT_HEIGHT } from "../features/Theme/theme"

interface Props<T> {
  id: string
  value?: T
  boxStyle?: CSSProperties
  onValueChange?: (newId: T) => void
  items?: Array<{ value: LiHTMLAttributes<Record<string, unknown>>["value"]; text: string }>
}

/**
 * Override the MUI Select component. Used with a list of MUI's MenuItem.
 */
export const MuiSelect = <T,>({
  id,
  items,
  value,
  boxStyle,
  onValueChange,
  ...props
}: Props<T> & SelectProps<T>): ReactElement | null => {
  const [selectedValue, setSelectedValue] = useState(value)
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme()

  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value)
    }
  }, [selectedValue, value])

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedValue(event.target.value as unknown as T)
    onValueChange?.(event.target.value as unknown as T)
  }

  // The component must not be rendered with an undefined value to be controllable
  return !items ? null : (
    <Box style={boxStyle}>
      <Select
        // @ts-ignore
        value={selectedValue ?? items?.[0].value}
        {...{ id, onChange, ...props }}
        fullWidth
        sx={{
          "& .MuiSelect-icon": {
            color: secondary,
          },
          height: INPUT_HEIGHT,
        }}
      >
        {items?.map(({ value: itemVal, text }, index) => (
          <MenuItem key={`${value}-${index}`} value={itemVal}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
