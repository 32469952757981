import { Box } from "@mui/material"
import type { FC, PropsWithChildren } from "react"
import { useStyles } from "utils"

import { boxSize } from "../features/Constants"

const BoxWrapper: FC<PropsWithChildren> = ({ children }) => {
  const s = useStyles(({ spacing }) => ({
    container: {
      ...boxSize,
      alignSelf: "center",
      paddingBottom: spacing(13),
      paddingTop: spacing(13),
    },
  }))

  return <Box style={s.container}>{children}</Box>
}

export default BoxWrapper
