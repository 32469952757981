import { Typography } from "@mui/material"
import dayjs from "dayjs"
import type { RaRecord } from "ra-core/dist/cjs/types"
import type { ReactElement } from "react"
import React from "react"
import { useRecordContext } from "react-admin"
import type { RAFieldProps } from "utils"

import { DATE_TIME_FORMAT } from "../features/Constants"

/**
 * DateField using dayjs and the DATE_TIME_FORMAT
 * @param source Must exist inside the current record
 */
export const DateField = <T extends RaRecord = RaRecord>({
  source,
}: RAFieldProps<T>): null | ReactElement => {
  const record = useRecordContext<T>()

  const dateTimestamp = record[source]

  return !record ? null : (
    <Typography variant="subtitle1">
      {!dateTimestamp ? "-" : dayjs(dateTimestamp).format(DATE_TIME_FORMAT)}
    </Typography>
  )
}
