import type {
  BOEvaluation,
  BOSession,
  LearnerExaminationResult,
  LearnerProgression,
  Module,
  ModuleProgression,
  UniverseId,
  User,
  UserId,
} from "@newpv/js-common"
import { fetchOneScenario, getRoute, parseInt10, roundPercent } from "@newpv/js-common"
import { axios } from "@newpv/js-common"
import type { AxiosResponse } from "axios"
import dayjs from "dayjs"
import _ from "lodash"
import type { Dispatch, SetStateAction } from "react"
import { useCallback, useEffect, useState } from "react"
import { useGetList, useNotify, usePermissions, useTranslate } from "react-admin"
import type { Administrator } from "screens/AdminPanelScreen/administrators/useAdministratorFunctions"
import {
  formatDuration,
  getAuth,
  useDivisionId,
  useQueryRequest,
  useScenarioId,
  useScenarioName,
  useToken,
  useUniverseId,
} from "utils"
import { logger } from "utils/logger"

import { apiUrl, DATE_FORMAT_LNG, DATE_TIME_FORMAT } from "../../../features/Constants"
import { useDivisions } from "../../Layout/hooks/useDivisions"
import { exportNs } from "../StatsExport"
import type { ArrToType } from "../utils/ArrToType"
import type { DataType } from "../utils/ExportsUtils"
import {
  exporter,
  generateReportPdf,
  getClosest,
  HEADERS,
  isOfDataType,
} from "../utils/ExportsUtils"
/**
 * For each exported list, convert every item of the list to the desired type
 */
interface ExportData {
  userStats: Array<
    Partial<ArrToType<(typeof HEADERS.userStats)[number], string> & Record<string, any>>
  >
  periodOfUse: Array<ArrToType<(typeof HEADERS.periodOfUse)[number], string>>
  initialGaps: Array<ArrToType<(typeof HEADERS.initialGaps)[number], string>>
  detailedUserStats: Array<Partial<ArrToType<(typeof HEADERS.detailedUserStats)[number], string>>>
  adminList: Array<Partial<ArrToType<(typeof HEADERS.adminList)[number], string>>>
}

/**
 * A list matching the type of data passed to useExports
 */
export type ExportFn<T extends DataType> = () => Promise<{
  list: ExportData[T]
  headersIds?: (typeof HEADERS)[T]
  headersNames?: string[]
}>

export interface Export {
  loading: boolean
  isEvalListFetching: boolean
  isModuleInfosFetching: boolean
  isUserListFetching: boolean
  isUniverseInfoFetching: boolean
}

/**
 * Returns the CSV data for the corresponding lists
 *
 * @param dataType Can be one of initialGaps, periodOfUse, detailedUserStats, userStats or adminList
 * @returns {{getData?: ExportFn<DataType>, loading: boolean}}
 */
export const useExports = <T extends DataType>(
  dataType: T | "pdf" | "csv" | undefined,
  setDataType: Dispatch<SetStateAction<T | "pdf" | "csv" | undefined>>,
  exportScope: "universe" | "division" | undefined,
): Export => {
  const t = useTranslate()
  const notify = useNotify()
  const [token] = useToken()
  const [universeId] = useUniverseId()
  const [divisionId] = useDivisionId()
  const { divisions } = useDivisions()
  const { permissions } = usePermissions()
  const [scenarioId] = useScenarioId()
  const [scenarioName] = useScenarioName()

  const [loading, setLoading] = useState(false)
  const [triggerExport, setTriggerExport] = useState(false)

  const [evalsWithUsersLoading, setEvalsWithUsersLoading] = useState(false)
  const [evalsWithUsers, setEvalsWithUsers] =
    useState<Array<BOEvaluation & { learners: Record<UserId, LearnerExaminationResult> }>>()

  /** DATA FETCHING */
  // isLoading => initial
  // isFetching => during refetch
  const {
    data: evalList,
    isLoading: isEvalListLoading,
    refetch: refetchEvalList,
    isFetching: isEvalListFetching,
    isStale: isEvalListStale,
  } = useGetList<BOEvaluation>(
    `evaluation/universe/${universeId}/evaluation`,
    {
      filter: { divisionId, scenarioId },
      meta: { idField: "evaluationId", all: true },
    },
    {
      // warning: even with enabled set to false, evalList will not be initially undefined
      enabled: false,
    },
  )

  useEffect(() => {
    if (evalList && evalList.length === 0) {
      setEvalsWithUsers([])
    }
    if ((evalList?.length ?? 0) > 0) {
      ;(async () => {
        setEvalsWithUsersLoading(true)
        try {
          const evaluationList = _.orderBy(evalList, ["dueDate"], ["asc"])
          const evalWithLearnersResult = await Promise.all(
            !evaluationList
              ? []
              : evaluationList.map(async evalElem => {
                  const allEvalSessions = await axios.get<
                    any,
                    AxiosResponse<{ results: BOSession[] }>
                  >(`${apiUrl}/evaluation/evaluation/${evalElem.id}/session`, getAuth(token))
                  return {
                    ...evalElem,
                    learners: _(allEvalSessions.data.results)
                      .map(session => session.learners)
                      .flatMap()
                      .filter(learnerResult => learnerResult.status === "graded")
                      .orderBy(["finishExaminationDate"], ["desc"])
                      .uniqBy(item => item.userId)
                      .reduce((acc, current) => {
                        acc[current.userId] = current
                        return acc
                      }, {}),
                  }
                }),
          )
          setEvalsWithUsers(evalWithLearnersResult)
        } catch (err) {
          logger("err while setting evalWithUsers", err)
        } finally {
          setEvalsWithUsersLoading(false)
        }
      })()
    }
  }, [evalList, token])

  const {
    data: userList,
    isLoading: isUserListLoading,
    refetch: refetchUserList,
    isFetching: isUserListFetching,
    isStale: isUserListStale,
  } =
    // here LearnerProgression is not Partial because scenarioId won't be nullish
    useGetList<User & LearnerProgression>(
      `stats/division/${divisionId}/users`,
      {
        filter: { scenarioId },
        sort: { field: "lastName", order: "ASC" },
        meta: { all: true },
      },
      { enabled: false },
    )

  const {
    data: modulesInfos,
    refetch: refetchModuleInfos,
    isFetching: isModuleInfosFetching,
    isStale: isModuleInfosStale,
  } = useQueryRequest<{
    modules: Module[]
    hasInit: boolean
  }>(
    async () => {
      const scenario = await fetchOneScenario(undefined, scenarioId)

      return {
        modules: _.uniqBy(scenario.routes.map(sc => sc.modules).flat(), elem => elem.id),
        hasInit: !!scenario.initialEvaluation,
      }
    },
    ["moduleInfos", "scenarioId", scenarioId, universeId],
    { enabled: false },
  )

  const {
    data: universeInfo,
    refetch: refetchUniverseInfo,
    isFetching: isUniverseInfoFetching,
    isStale: isUniverseInfoStale,
  } = useQueryRequest<{
    universeName: string
  }>(
    async () => {
      const { data: universe } = await axios.get<{ universeId: UniverseId; name: string }>(
        `${apiUrl}/universe/universe/${universeId}`,
        getAuth(token),
      )

      return {
        universeName: universe.name,
      }
    },
    ["universeInfo", universeId],
    { enabled: false },
  )

  const {
    data: adminList,
    isLoading: isAdminListLoading,
    refetch: refetchAdminList,
    isFetching: isAdminListFetching,
    isStale: isAdminListStale,
  } = useGetList<Administrator>(
    `universe/universe/${universeId}/supervisor`,
    {
      meta: { all: true },
    },
    { enabled: permissions.includes("universeAdmin") },
  )

  const globalIsFetching =
    isEvalListFetching ||
    isModuleInfosFetching ||
    isUserListFetching ||
    isUniverseInfoFetching ||
    isAdminListFetching

  /** FUNCTIONS */

  const getInitialGaps: ExportFn<T> = useCallback(async () => {
    const division = divisions?.find(item => item.id === divisionId)?.name

    if (!division || !isOfDataType(dataType, "initialGaps")) {
      return { list: [] }
    }

    const res: ExportData[typeof dataType] = []

    userList?.map(user => {
      Object.values(user.progression.modules).map(module =>
        Object.values(module.levels).map(level =>
          !level.rules
            ? null
            : Object.entries(level.rules).map(rule => {
                Object.entries(user.progression.preKnownRules).map(preKnownRule => {
                  if (_.isEqual(rule[0], preKnownRule[0])) {
                    res.push({
                      division,
                      lastName: user.lastName,
                      firstName: user.firstName,
                      userName: user.userName,
                      ruleTitle: rule[1]?.title ?? "",
                      isInitialKnow: t(
                        `${exportNs}.common.${preKnownRule[1] === true ? "known" : "notKnown"}`,
                      ),
                    })
                  }
                })
              }),
        ),
      )
    })
    return {
      list: res,
      headersIds: HEADERS.initialGaps as (typeof HEADERS)[typeof dataType],
      headersNames: HEADERS.initialGaps.map(elem => t(`${exportNs}.common.${elem}`)),
    }
  }, [dataType, divisionId, divisions, t, userList])

  const getPeriodsOfUse: ExportFn<T> = useCallback(async () => {
    const division = divisions?.find(item => item.id === divisionId)?.name
    // Creates type based on the const array defined in the above HEADERS

    if (!division || !isOfDataType(dataType, "periodOfUse")) {
      return { list: [] }
    }

    const res: ExportData[typeof dataType] = []

    userList?.map(user =>
      Object.values(user.progression.modules).map(module => {
        _.flatMap(Object.values(module.levels).map(level => level.trainingSessionsDetail))
          .sort()
          .map(period => {
            if (!period) {
              return
            }
            res.push({
              division,
              userName: user.userName,
              firstName: user.firstName,
              lastName: user.lastName,
              startDate: dayjs(period[0]).format(DATE_TIME_FORMAT),
              endDate: dayjs(period[1]).format(DATE_TIME_FORMAT),
            })
          })
      }),
    )
    return {
      list: res,
      headersIds: HEADERS.periodOfUse as (typeof HEADERS)[typeof dataType],
      headersNames: HEADERS.periodOfUse.map(elem => t(`${exportNs}.common.${elem}`)),
    }
  }, [divisionId, divisions, dataType, t, userList])

  // DETAILED USER STATS
  const getDetailedUserStats: ExportFn<T> = useCallback(async () => {
    try {
      setLoading(true)

      if (!userList || !isOfDataType(dataType, "detailedUserStats")) {
        return { list: [] }
      }

      const res: ExportData[typeof dataType] = []
      const allLevels = modulesInfos?.modules.map(module => module.levels).flat()

      await Promise.all(
        userList?.map(async user => {
          const lastUseDate = user.progression.lastUseDate

          const commonData = {
            universe: universeInfo?.universeName ?? "",
            division: divisions?.find(item => item.id === divisionId)?.name,
            lastName: user.lastName,
            firstName: user.firstName,
            userName: user.userName,
            // default lastUseDate = lastUseDate for the scenario
            lastUseDate: lastUseDate === 0 ? "" : dayjs(lastUseDate).format(DATE_TIME_FORMAT),
            totalDuration: dayjs
              .duration(
                user.progression.trainingDuration +
                  (user.progression.initialEvaluation?.duration ?? 0),
                "ms",
              )
              .format(t("common.durationFormats.exports")),
            scenario: scenarioName,
          }

          /** Add initial evaluation data */

          if (user.progression.initialEvaluation) {
            const initialEvaluationScore =
              user.progression.initialEvaluation == null
                ? ""
                : `${user.progression.initialEvaluation?.score ?? 0}%`
            const initialEvalEndDate =
              user.progression.initialEvaluation?.endDate == null
                ? ""
                : dayjs(user.progression.initialEvaluation.endDate).format(DATE_TIME_FORMAT)

            res.push({
              ...commonData,
              lastUseDate: initialEvalEndDate,
              level: `${scenarioName} - ${t("stats.dashboardTab.initial")}`,
              gradeRatio: initialEvaluationScore,
              totalDuration: formatDuration({
                t,
                duration: user.progression.initialEvaluation?.duration,
                customFormat: "common.durationFormats.exports",
              }),
            })
          }

          /** Add next evaluations */

          evalsWithUsers?.map(evalElem => {
            if (!evalElem) {
              return
            }

            const learnerExaminationResult: LearnerExaminationResult | undefined =
              evalElem.learners?.[user.id]

            if (!learnerExaminationResult) {
              return
            }
            const evalGrade = learnerExaminationResult.grade

            const extraTimeFactor = learnerExaminationResult?.extraTime ? 1 + 1 / 3 : 1

            const evalDuration =
              (learnerExaminationResult.consumedTime != null
                ? Math.min(
                    learnerExaminationResult.consumedTime,
                    evalElem.duration * extraTimeFactor,
                  )
                : 0) * 1000
            const evalEndDate = learnerExaminationResult.finishExaminationDate
            if (!evalGrade) {
              return
            }

            res.push({
              ...commonData,
              level: evalElem.name,
              gradeOn20: `${
                evalGrade[
                  evalElem.calculationMethod === "bonusenabled" ? "gradeOn20Improved" : "gradeOn20"
                ] ?? evalGrade.gradeOn20
              }`,
              gradeRatio: `${
                evalGrade.rulesKnown && evalGrade.totalRules
                  ? roundPercent((evalGrade.rulesKnown / evalGrade.totalRules) * 100)
                  : 0
              }%`,
              lastUseDate: dayjs(evalEndDate).format(DATE_TIME_FORMAT),
              totalDuration: formatDuration({
                t,
                duration: evalDuration,
                customFormat: "common.durationFormats.exports",
              }),
            })
          })

          /** Add progression for each module */
          Object.entries(user.progression.modules).map(module => {
            const moduleTitle = modulesInfos?.modules.find(
              elem => elem.id === parseInt10(module[0]),
            )?.title

            Object.entries(module[1].levels).map(level => {
              const levelInfo = allLevels?.find(levelElem => levelElem.id === parseInt10(level[0]))
              const isRevision =
                levelInfo?.type === "revision" || levelInfo?.type === "finalRevision"
              const isAutoValidated = isRevision && _.isEmpty(level[1].revisionRuleIds)
              if (levelInfo == null) {
                // eslint-disable-next-line no-console
                console.log("level not found", level)
                return
              }

              const lastLevelUseDate = _.maxBy(
                level[1].trainingSessionsDetail,
                session => session[1],
              )?.[1]

              res.push({
                ...commonData,
                totalDuration: dayjs
                  .duration(level[1].trainingDuration, "ms")
                  .format(t("common.durationFormats.exports")),
                lastUseDate: isAutoValidated
                  ? t(`${exportNs}.common.autoValidated`)
                  : lastLevelUseDate
                  ? dayjs(lastLevelUseDate).format(DATE_TIME_FORMAT)
                  : "",
                level: `${moduleTitle} - ${levelInfo.title}`,
                levelReached: `${
                  isAutoValidated ? 100 : roundPercent(level[1].completionPercentage)
                }%`,
                scenario: scenarioName,
              })
            })
            const practiceTestGrade = module[1].practiceTest?.lastGrade
            if (practiceTestGrade) {
              // TODO: correct here once tickle is available for practice test too
              const practiceTestDuration = dayjs(module[1].practiceTest?.clientEnd).diff(
                module[1].practiceTest?.clientStart,
                "ms",
              )
              res.push({
                ...commonData,
                level: t(`${exportNs}.common.practiceTest`, { moduleTitle }),
                gradeOn20: undefined,
                gradeRatio: `${roundPercent(
                  (practiceTestGrade.rulesKnown / practiceTestGrade.totalRules) * 100,
                )}%`,
                totalDuration: dayjs
                  .utc(practiceTestDuration)
                  .format(t("common.durationFormats.exports")),
                lastUseDate: dayjs(module[1].practiceTest?.clientEnd).format(DATE_TIME_FORMAT),
              })
            }
          })
        }),
      )
      return {
        list: res,
        headersIds: HEADERS.detailedUserStats as (typeof HEADERS)[typeof dataType],
        headersNames: HEADERS.detailedUserStats.map(elem => t(`${exportNs}.common.${elem}`)),
      }
    } catch (err) {
      logger(err)
      notify("ra.notification.http_error", { type: "error" })
      return { list: [] }
    } finally {
      setLoading(false)
    }
  }, [
    dataType,
    divisionId,
    divisions,
    evalsWithUsers,
    modulesInfos?.modules,
    notify,
    scenarioName,
    t,
    universeInfo?.universeName,
    userList,
  ])

  /** USER STATS */
  const getUserStats: ExportFn<T> = useCallback(async () => {
    const scenario = await fetchOneScenario(undefined, scenarioId)
    const hasInit = !!scenario.initialEvaluation
    const division = divisions?.find(item => item.id === divisionId)?.name
    const universe = universeInfo?.universeName ?? ""
    const headersNames = [...HEADERS.userStats].map(elem => t(`${exportNs}.common.${elem}`))
    try {
      setLoading(true)

      if (!division || !scenarioId || !userList || !isOfDataType(dataType, "userStats")) {
        return { list: [] }
      }

      const res: ExportData[typeof dataType] = []
      const headersIds = [...HEADERS.userStats] as (typeof HEADERS)[typeof dataType]

      if (evalList && evalsWithUsers) {
        /**
         * Creating multiple headers matching the currently iterated evaluation
         */
        const headerIdsToIns = evalsWithUsers
          .map(evalElem => [
            `trainingLevel-${evalElem.id}`,
            `trainingTime-${evalElem.id}`,
            `evalScore-${evalElem.id}`,
            `gradeOn20-${evalElem.id}`,
            `evalDuration-${evalElem.id}`,
          ])
          .flat()
        /**
         * Mapping each header to its translation
         */
        const headNamesToIns = evalsWithUsers
          .map(evalElem => [
            t(`${exportNs}.common.trainingLevel`),
            t(`${exportNs}.common.trainingTime`),
            t(`${exportNs}.common.evalScore`, { evaluation: evalElem.name }),
            t(`${exportNs}.common.gradeOn20`, { evaluation: evalElem.name }),
            t(`${exportNs}.common.evalDuration`, { evaluation: evalElem.name }),
          ])
          .flat()
        // Ignore the replacement of the readonly headerIds to add custom ones
        // @ts-ignore
        headersIds.splice(12, 0, ...headerIdsToIns)
        headersNames.splice(12, 0, ...headNamesToIns)
      }

      userList.map(user => {
        const route = getRoute(user.progression, scenario)
        const evalResults = !evalList
          ? []
          : _(evalsWithUsers)
              .filter(evalWithResults => evalWithResults.learners[user.id] !== undefined)
              .map(evalWithResults => {
                const learnerRes = evalWithResults.learners[user.id]

                const grade = learnerRes?.grade

                const extraTimeFactor = learnerRes.extraTime ? 1 + 1 / 3 : 1

                const evalDuration =
                  (learnerRes.consumedTime != null
                    ? Math.min(learnerRes.consumedTime, evalWithResults.duration * extraTimeFactor)
                    : 0) * 1000

                /**
                 * Using the previously created headers, fill the required columns
                 */
                return !grade || learnerRes.status !== "graded"
                  ? {
                      [`trainingLevel-${evalWithResults.id}`]: "",
                      [`trainingTime-${evalWithResults.id}`]: "",
                      [`evalScore-${evalWithResults.id}`]: "",
                      [`gradeOn20-${evalWithResults.id}`]: "",
                      [`evalDuration-${evalWithResults.id}`]: "",
                    }
                  : {
                      [`trainingLevel-${evalWithResults.id}`]: `${
                        user.graph
                          ? _.round(
                              getClosest(user.graph, dayjs(learnerRes.startDate).valueOf())
                                .percentage,
                              2,
                            )
                          : 0
                      }%`,
                      // TODO: Add training time before eval
                      // will be available in the data after https://app.shortcut.com/woonoz/story/6756
                      [`trainingTime-${evalWithResults.id}`]: 0,
                      [`evalDuration-${evalWithResults.id}`]: formatDuration({
                        t,
                        duration: evalDuration,
                        customFormat: "common.durationFormats.exports",
                      }),
                      [`evalScore-${evalWithResults.id}`]:
                        grade.rulesKnown && grade.totalRules
                          ? roundPercent((grade.rulesKnown / grade.totalRules) * 100)
                          : 0,
                      [`gradeOn20-${evalWithResults.id}`]:
                        grade[
                          evalWithResults.calculationMethod === "bonusenabled"
                            ? "gradeOn20Improved"
                            : "gradeOn20"
                        ] ?? grade.gradeOn20,
                    }
              })
              .value()

        const data = {
          universe,
          division,
          lastName: user.lastName,
          firstName: user.firstName,
          userName: user.userName,
          inscriptionDate: dayjs(user.inscriptionDate).format(DATE_TIME_FORMAT),
        }

        const learnerEvalRes = !evalResults
          ? {}
          : evalResults.reduce((acc, currentEvalResult) => ({ ...acc, ...currentEvalResult }), {})

        const lastUseDate = user.progression.lastUseDate

        const hasTrainingDuration = user.progression.trainingDuration > 0
        const preKnownRules = _.countBy(user.progression.preKnownRules, value => value).true
        const totalNbrRules = _.sumBy(route?.modules, m => m.rulesNbr)
        const initialLevel =
          totalNbrRules === 0 ? NaN : roundPercent((preKnownRules / totalNbrRules) * 100)

        // = one row in the export
        const dataPushed = {
          ...data,
          module: t(`${exportNs}.common.allModules`, { scenario: scenarioName }),
          lastUseDate:
            lastUseDate === 0 ? "" : dayjs(user.progression.lastUseDate).format(DATE_TIME_FORMAT),
          // TODO: Add evaluations durations
          ...(!hasTrainingDuration
            ? {}
            : {
                levelReached: `${_.round(user.progression.completionPercentage)}%`,
                trainingDuration: dayjs
                  .duration(user.progression.trainingDuration)
                  .format(t("common.durationFormats.exports")),
                totalDuration: dayjs
                  .duration(
                    user.progression.trainingDuration +
                      (user.progression.initialEvaluation?.duration ?? 0),
                    "ms",
                  )
                  .format(t("common.durationFormats.exports")),
              }),
          ...(hasInit
            ? {
                initialEvaluationScore:
                  user.progression.initialEvaluation == null
                    ? ""
                    : `${user.progression.initialEvaluation?.score ?? 0}%`,
                initialEvaluationDuration:
                  user.progression.initialEvaluation?.duration != null
                    ? formatDuration({
                        t,
                        duration: user.progression.initialEvaluation?.duration,
                        customFormat: "common.durationFormats.exports",
                      })
                    : "",
              }
            : {}),
          initialLevel: isNaN(initialLevel) ? "" : `${initialLevel}%`,
          ...learnerEvalRes,
        }

        res.push(dataPushed)

        // If a level is started/done in another scenario, the module will have the data after the initial evaluation.
        if (!route?.modules || (hasInit && user.progression.initialEvaluation == null)) {
          return
        }
        // TODO: maybe don't display modules with no data?
        route.modules.map(module => {
          const moduleElem = user.progression.modules[module.id] as ModuleProgression | undefined
          const lastDate = _.last(moduleElem?.completionGraph)?.date
          const duration = moduleElem?.trainingDuration
          const modulePreKnownRules = _.countBy(moduleElem?.preKnownRules, value => value).true
          const moduleInitialLevel = (modulePreKnownRules / module.rulesNbr) * 100
          res.push({
            ...data,
            module: t(`${exportNs}.common.specificModule`, {
              scenario: scenarioName,
              module: module.title,
            }),
            lastUseDate: lastDate ? dayjs(lastDate).format(DATE_TIME_FORMAT) : "",
            ...(!duration || duration === 0 || !lastDate
              ? {}
              : {
                  totalDuration: dayjs
                    .duration(duration, "ms")
                    .format(t("common.durationFormats.exports")),
                  trainingDuration: dayjs
                    .duration(duration, "ms")
                    .format(t("common.durationFormats.exports")),
                  levelReached: `${_.round(moduleElem?.completionPercentage ?? 0)}%`,
                }),
            initialLevel: isNaN(moduleInitialLevel) ? "" : `${roundPercent(moduleInitialLevel)}%`,
          })
        })
      })

      return {
        list: res,
        headersIds,
        headersNames,
      }
    } catch {
      notify("ra.notification.http_error", { type: "error" })
      return { list: [] }
    } finally {
      setLoading(false)
    }
  }, [
    dataType,
    divisionId,
    divisions,
    evalList,
    evalsWithUsers,
    notify,
    scenarioId,
    scenarioName,
    t,
    universeInfo?.universeName,
    userList,
  ])
  /** ADMIN LIST */
  const getAdminList: ExportFn<T> = useCallback(async () => {
    const universe = universeInfo?.universeName ?? ""
    const divisionExport = exportScope === "division"
    const divisionExportName = divisions?.find(item => item.id === divisionId)?.name

    if (adminList === undefined || !isOfDataType(dataType, "adminList")) {
      return { list: [] }
    }

    const adminsByDivision = adminList?.filter(admin => {
      if (admin.role.role === "universeadmin") {
        return true
      }
      return divisionId && admin.role.divisionsIds?.includes(divisionId)
    })

    const admins = divisionExport ? adminsByDivision : adminList

    const res: ExportData[typeof dataType] = []

    admins
      ?.sort(admin => (admin.role.role === "universeadmin" ? -1 : 1))
      .map(admin => {
        const commonData = {
          universe,
          lastName: admin.lastName,
          firstName: admin.firstName,
          id: admin.id,
          email: admin.email,
          role: t(`${exportNs}.common.${admin.role.role}`),
        }
        if (admin.role.role === "universeadmin") {
          res.push({
            ...commonData,
          })
        } else {
          if (divisionExport) {
            res.push({
              ...commonData,
              division: divisionExportName,
            })
          } else {
            admin.role.divisionsIds?.map(division => {
              const divisionName = divisions?.find(item => item.id === division)?.name ?? ""
              res.push({
                ...commonData,
                division: divisionName,
              })
            })
          }
        }
      })

    return {
      list: res,
      headersIds: HEADERS.adminList as (typeof HEADERS)[typeof dataType],
      headersNames: HEADERS.adminList.map(elem => t(`${exportNs}.common.${elem}`)),
    }
  }, [adminList, dataType, divisionId, divisions, exportScope, t, universeInfo?.universeName])

  /** USEEFFECTS */

  const getData = useCallback(
    (dt: DataType | "csv"): ExportFn<T> | undefined =>
      dt === "initialGaps"
        ? getInitialGaps
        : dataType === "userStats"
        ? getUserStats
        : dataType === "periodOfUse"
        ? getPeriodsOfUse
        : dataType === "detailedUserStats"
        ? getDetailedUserStats
        : dataType === "adminList"
        ? getAdminList
        : undefined,
    [dataType, getAdminList, getDetailedUserStats, getInitialGaps, getPeriodsOfUse, getUserStats],
  )

  // trigger data fetching
  useEffect(() => {
    if (dataType === undefined || triggerExport) {
      return
    }
    ;(async () => {
      await Promise.all([
        userList === undefined || isUserListStale ? refetchUserList() : undefined,
        (dataType === "detailedUserStats" ||
          dataType === "userStats" ||
          dataType === "adminList") &&
        (universeInfo === undefined || isUniverseInfoStale)
          ? refetchUniverseInfo()
          : undefined,
        dataType === "adminList" && (adminList === undefined || isAdminListStale)
          ? refetchAdminList()
          : undefined,
        (dataType === "detailedUserStats" || dataType === "userStats") &&
        (evalList === undefined || isEvalListStale)
          ? refetchEvalList()
          : undefined,
        dataType === "detailedUserStats" && (modulesInfos === undefined || isModuleInfosStale)
          ? refetchModuleInfos()
          : undefined,
      ])

      setTriggerExport(true)
    })()
  }, [
    adminList,
    dataType,
    evalList,
    isAdminListStale,
    isEvalListStale,
    isModuleInfosStale,
    isUniverseInfoStale,
    isUserListStale,
    modulesInfos,
    refetchAdminList,
    refetchEvalList,
    refetchModuleInfos,
    refetchUniverseInfo,
    refetchUserList,
    triggerExport,
    universeInfo,
    userList,
  ])

  // trigger export
  useEffect(() => {
    if (globalIsFetching || !triggerExport || evalsWithUsersLoading) {
      // we wait until data is ready
      return
    }

    // if data is ready and export is triggered
    ;(async () => {
      if (dataType === undefined) {
        setTriggerExport(false)
        return
      }
      if (dataType === "pdf") {
        try {
          const res = await axios.get<any, AxiosResponse<Blob>>(
            `${apiUrl}/training/scenario/${scenarioId}/learning-report`,
            {
              responseType: "blob",
              params: {
                divisionId,
              },
              ...getAuth(token),
            },
          )
          generateReportPdf(t(`${exportNs}.report.title`), res.data)
        } catch {
          logger("error generating the pdf")
        } finally {
          setTriggerExport(false)
          setDataType(undefined)
        }

        return
      }

      const func = getData(dataType)
      if (func) {
        const { list, headersNames, ...data } = await func()
        const headersIds = data.headersIds as string[] | undefined
        if (!headersIds || !list || !headersNames) {
          // TODO: check type - for csv?
          logger("Missing headers id/Names or type is not csv")
          setTriggerExport(false)
          setDataType(undefined)
          return
        }

        const docTitle = `${t(`${exportNs}.${dataType}.title`)} - ${
          exportScope === "universe"
            ? universeInfo?.universeName
            : divisions.find(d => d.id === divisionId)?.name
        }${scenarioName ? ` - ${scenarioName}` : ""} - ${dayjs().format(DATE_FORMAT_LNG)}`

        exporter(docTitle, list, headersIds, headersNames)
      }

      setTriggerExport(false)
      setDataType(undefined)
      return
    })()
  }, [
    dataType,
    divisionId,
    divisions,
    getData,
    scenarioId,
    scenarioName,
    setDataType,
    t,
    token,
    triggerExport,
    globalIsFetching,
    evalsWithUsersLoading,
    exportScope,
    universeInfo?.universeName,
  ])

  return {
    loading: loading || isEvalListLoading || isUserListLoading || isAdminListLoading,
    isEvalListFetching,
    isModuleInfosFetching,
    isUserListFetching,
    isUniverseInfoFetching,
  }
}
