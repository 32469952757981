import type {
  BOEvaluation,
  BOSession,
  Evaluation,
  LearnerExaminationResult,
  SessionCreate,
} from "@newpv/js-common"
import dayjs from "dayjs"
import _ from "lodash"
import type { SaveHandler } from "ra-core"
import {
  useCreateController,
  useDelete,
  useEditController,
  useGetList,
  useNotify,
  useRefresh,
  useTranslate,
} from "react-admin"
import { useNavigate, useParams } from "react-router-dom"
import { useDivisionId, useScenarioId } from "utils"

export interface EvalInfos {
  save?: SaveHandler<Evaluation>
  onDelete?: () => Promise<void>
  evalData?: Partial<Evaluation>
  saving?: boolean
  evalSessions: SessionCreate[]
  learners?: Array<LearnerExaminationResult & { sessionIsOver: boolean }>
  evalCalcMethod?: Evaluation["calculationMethod"]
  isLoading: boolean
}

export const CALC_METHODS = ["bonusenabled", "bonusdisabled"]

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCalcMethods = () => {
  const t = useTranslate()

  return CALC_METHODS.map(id => ({
    id,
    title: t(`eval.evalModal.generalTab.calcMethodTitles.${id}`),
  }))
}

/**
 * These two functions allows both create and update environnement for the EvalForm screen
 * */
export const useEvalCreate = (_isInitialEvaluation?: boolean): EvalInfos => {
  const [divisionId] = useDivisionId()
  const [scenarioId] = useScenarioId()

  const evalDefaultValues = {
    // This duration is not used in the front
    duration: 3000,
    divisionId,
    scenarioId,
  }

  /**
   * Data
   * */
  const {
    save,
    record: evalData,
    isLoading: isEvalLoading,
    saving,
  } = useCreateController<BOEvaluation>({
    resource: "evaluation/evaluation",
  })

  /**
   * Function specific variables
   * */

  return {
    save,
    saving,
    evalSessions: [],
    isLoading: isEvalLoading,
    evalData: { ...evalDefaultValues, ...evalData },
  }
}

export const useEvalEdit = (isInitialEvaluation?: boolean): EvalInfos => {
  /**
   * Contexts and hooks
   * */
  const notify = useNotify()
  const refresh = useRefresh()
  const navigate = useNavigate()
  const [deleteOne] = useDelete()
  const { id: evalId } = useParams()

  /**
   * Data
   * */
  const { data: evalSessions, isLoading: isSessionsLoading } = useGetList<BOSession>(
    `evaluation/evaluation/${evalId}/session`,
    {
      meta: {
        idField: "sessionId",
      },
    },
    {
      onError: () => {
        notify("ra.notification.http_error", { type: "error" })
      },
      enabled: !isInitialEvaluation,
    },
  )

  const {
    save,
    record: evalData,
    isLoading: isEvalLoading,
    saving,
  } = useEditController<BOEvaluation>({
    resource: "evaluation/evaluation",
    evalId,
    mutationMode: "optimistic",
    queryOptions: {
      meta: {
        idField: "evaluationId",
      },
      enabled: !isInitialEvaluation,
    },
  })

  /**
   * functions
   * */
  const onDelete = (): Promise<void> =>
    deleteOne(
      "evaluation/evaluation",
      { id: evalData?.id },
      {
        onSuccess: () => {
          navigate("..", { replace: true })
          refresh()
        },
        onError: (e: any) => {
          notify(`ra.notification.${e.response?.data?.code ? "eval_delete_err" : "http_error"}`, {
            type: "error",
          })
        },
      },
    )

  return {
    save,
    saving,
    onDelete,
    evalData: {
      ...evalData,
      // TODO: This is to fix an API issue. Remove the following lines once resolved.
      // @ts-ignore
      calculationMethod: evalData?.calculationMethod.toLocaleLowerCase(),
    },
    evalSessions:
      evalSessions?.map(elem => ({
        ...elem,
        learnersIds: elem.learners.map(item => item.userId),
      })) ?? [],
    learners: _.flatMap(evalSessions, session =>
      session.learners.map(learner => ({
        ...learner,
        sessionIsOver: dayjs(session.dueDate).isBefore(dayjs()),
      })),
    ),
    isLoading: isSessionsLoading || isEvalLoading,
  }
}
