import { Typography, useTheme } from "@mui/material"
import _ from "lodash"
import type { FC } from "react"
import { useMemo } from "react"
import React from "react"
import { Loading, useGetList, useTranslate } from "react-admin"
import { useParams } from "react-router-dom"
import { useStyles, useUniverseId } from "utils"

import { role } from "../administrators/constants"
import type { Administrator } from "../administrators/useAdministratorFunctions"

export const ns = "adminPanel.divisions.accessRights"

const rolesToValue = {
  [role.DIVISION]: 1,
  [role.UNIVERSE]: 0,
}

export const AccessRights: FC = () => {
  const t = useTranslate()

  const [universeId] = useUniverseId()
  const { id: divisionId } = useParams()

  const { data: adminList, isLoading } = useGetList<Administrator>(
    `universe/universe/${universeId}/supervisor`,
    {
      filter: { divisionId },
      meta: { all: true },
    },
  )

  const sortedUserList = useMemo(
    () =>
      _.sortBy(adminList, admin => admin.lastName).sort(
        (admin1, admin2) => -rolesToValue[admin2.role.role] + rolesToValue[admin1.role.role],
      ),
    [adminList],
  )

  const {
    palette: { text: themeText },
  } = useTheme()

  const s = useStyles(
    ({ spacing, palette: { action } }) => ({
      main: {
        marginVertical: spacing(2),
        overflowY: "auto",
      },
      listItem: {
        borderBottomColor: action.overlay,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        padding: spacing(2),
      },
    }),
    [],
  )

  return isLoading ? (
    <Loading />
  ) : (
    <div id="division-panel-1" style={s.main}>
      {sortedUserList?.map(sortedAdmin => (
        <div key={sortedAdmin.id} style={s.listItem}>
          <Typography variant="overline" color={themeText.secondary}>
            {t(`${ns}.${sortedAdmin.role.role}`)}
          </Typography>
          <Typography variant="subtitle1">{`${sortedAdmin?.lastName.toUpperCase()} ${
            sortedAdmin?.firstName ?? ""
          }`}</Typography>
          <Typography variant="body2" color={themeText.secondary}>
            {sortedAdmin.email ?? "-"}
          </Typography>
        </div>
      ))}
    </div>
  )
}
