import { logger } from "@newpv/js-common"
import { AsideView } from "components"
import _ from "lodash"
import type { FC } from "react"
import { useCallback, useEffect, useState } from "react"
import { Form, Loading, useNotify, useRefresh, useTranslate } from "react-admin"
import { useNavigate } from "react-router-dom"
import { useCommonStyles, useUniverseId } from "utils"

import { ns } from "./constants"
import DetailsContent from "./DetailsContent"
import type { FormAdministrator } from "./useAdministratorFunctions"
import { useAdministratorCreate, useAdministratorEdit } from "./useAdministratorFunctions"

interface Props {
  isCreation?: boolean
}
const AdministratorDetailsScreen: FC<Props> = ({ isCreation }) => {
  const navigate = useNavigate()
  const notify = useNotify()
  const refresh = useRefresh()
  const t = useTranslate()
  const [universeId] = useUniverseId()

  const [open, setOpen] = useState(false)

  const { isLoading, save, saving, administratorData } = (
    isCreation ? useAdministratorCreate : useAdministratorEdit
  )()

  /**
   * The following hook is necessary to trigger the opening animation
   * */
  useEffect((): void => {
    setOpen(true)
  }, [])

  const onClose = useCallback((): void => {
    setOpen(false)
    setTimeout(() => {
      navigate("..", { replace: true })
      refresh()
    }, 300)
  }, [navigate, refresh])

  const onSubmit = useCallback(
    (data: FormAdministrator) => {
      // We use deburr to remove accents because RA doesn't handle them on the left side of the email.
      data.email = _.deburr(data.email)
      const divisionsIds = data.divisionsIds
        ? Object.keys(_.pickBy(data.divisionsIds, val => val))
        : null
      save?.(
        { ..._.omit(data, "divisions"), universeId, divisionsIds, connectionType: "email" },
        {
          onSuccess: () => {
            onClose()
            notify(`${ns}.newSuccess`, { type: "success" })
          },
          onError: e => {
            logger("administrator creation error", { e })
            notify(
              e.response?.data?.code
                ? `${ns}.${
                    e.response.data.code === "CONFLICT" || e.response.data.code === "ALREADY_EXISTS"
                      ? e.response.data.code
                      : "administratorCreationError"
                  }`
                : "ra.notification.http_error",
              { type: "error" },
            )
          },
        },
      )
    },
    [notify, onClose, save, universeId],
  )

  const cs = useCommonStyles()
  return (
    <div style={cs.mainDiv}>
      <AsideView {...{ open, onClose }} title={t(`${ns}.${isCreation ? "create" : "details"}`)}>
        {isLoading ? (
          <Loading />
        ) : (
          <Form className="form" id="save_administrator" mode="onChange" {...{ onSubmit }}>
            <DetailsContent {...{ onClose, isCreation, saving, administratorData }} />
          </Form>
        )}
      </AsideView>
    </div>
  )
}

export default AdministratorDetailsScreen
