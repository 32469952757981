import MenuBookIcon from "@mui/icons-material/MenuBookOutlined"
import type { DivisionProgressionForScenarioRule, LevelId, ModuleId } from "@newpv/js-common"
import { fetchOneScenario } from "@newpv/js-common"
import { ScreenContainer } from "components"
import EmptyScreen from "components/EmptyScreen"
import { HeaderWithTooltip } from "components/HeaderWithTooltip"
import _ from "lodash"
import type { FC } from "react"
import React, { useEffect } from "react"
import {
  Datagrid,
  List,
  SearchInput,
  useListContext,
  useNotify,
  useTranslate,
  useUnselectAll,
} from "react-admin"
import { useCommonStyles, useDivisionId, useQueryRequest, useScenarioId, useStyles } from "utils"

import { RatioField } from "../StudentScreen/components/RatioField"
import { CustomDatagridHeader } from "./components/CustomDatagridHeader"
import PresentialBulkActionButton from "./components/PresentialBulkActionButton"
import { RuleNameField } from "./components/RuleNameField"
import SelectLevelInput from "./components/SelectLevelInput"
import TopSection from "./components/TopSection"
const ns = "resources.rules.fields"

interface SelectModuleAndLevel {
  id: LevelId | ModuleId
  name: string
  /** moduleId_LevelId => use for filter by module or level inside module */
  choice: string
}

const RuleStatsDataGrid: FC = () => {
  const t = useTranslate()
  const { data, isLoading, resource } = useListContext<DivisionProgressionForScenarioRule>()
  const [scenarioId] = useScenarioId()
  const unselectAll = useUnselectAll(resource)

  useEffect(unselectAll, [scenarioId, unselectAll])

  const s = useStyles(({ palette: { common } }) => ({
    bulkActions: {
      boxShadow: "none",
      "& .RaBulkActionsToolbar-toolbar": {
        color: common.black,
      },
    },
  }))

  return (
    <Datagrid
      {...{ data, isLoading }}
      rowClick={id => id.toString()}
      bulkActionButtons={<PresentialBulkActionButton />}
      optimized={true}
      hover={false}
      empty={
        <EmptyScreen title={t("students.empty")} subtitle={t("stats.noDataSubtitleDivision")} />
      }
      sx={s.bulkActions}
      header={<CustomDatagridHeader />}
    >
      <RuleNameField source="title" label={t(`${ns}.title`)} />
      <RuleNameField source="moduleTitle" label={t(`${ns}.moduleTitle`)} />
      <RuleNameField sortable={false} source="levelTitle" label={t(`${ns}.levelTitle`)} />
      <RatioField<DivisionProgressionForScenarioRule>
        source="initialRatio"
        denominator="seen"
        numerator="initialKnown"
        label={
          <HeaderWithTooltip
            title={t(`${ns}.initialKnown`)}
            tooltip={t("rules.tooltip.initialKnown")}
          />
        }
      />
      <RatioField<DivisionProgressionForScenarioRule>
        source="eventualRatio"
        denominator="seen"
        numerator="eventualKnown"
        label={
          <HeaderWithTooltip
            title={t(`${ns}.eventualKnown`)}
            tooltip={t("rules.tooltip.eventualKnown")}
          />
        }
      />
    </Datagrid>
  )
}

const RuleListScreen: FC = () => {
  const t = useTranslate()
  const notify = useNotify()
  const [scenarioId] = useScenarioId()
  const [divisionId] = useDivisionId()

  const { data: moduleLevels, isLoading: isModuleLevelsLoading } = useQueryRequest<
    SelectModuleAndLevel[]
  >(
    async () => {
      const scenario = await fetchOneScenario(undefined, scenarioId)
      const modules = _(scenario.routes)
        .map(route => route.modules)
        .flatten()
        .uniqBy(m => m.id)
        .value()

      return _.reduce(
        modules,
        (result, module) => [
          ...result,
          {
            id: module.id,
            name: t("rules.allLevels", { title: module.title }),
            choice: `${module.id}`,
          },
          ..._(module.levels)
            .filter(lvl => lvl.type === "static")
            .map(lvl => ({
              id: lvl.id,
              name: `${module.title} - ${lvl.title}`,
              choice: `${module.id}_${lvl.id}`,
            }))
            .value(),
        ],
        [],
      )
    },
    ["moduleLevels", "scenarioId", scenarioId],
    { enabled: scenarioId != null },
  )

  const cs = useCommonStyles()
  const s = useStyles(
    ({ spacing }) => ({
      list: {
        "& .RaList-main": {
          boxShadow: "none",
          minHeight: 0,
          paddingBottom: spacing(3),
        },
        "& .RaList-content": {
          borderRadius: "2px",
        },
        "& .RaDatagrid-tableWrapper": {
          borderRadius: "2px",
          boxShadow: 4,
          overflowX: "hidden",
        },
      },
      main: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      },
      search: {
        minWidth: 300,
      },
      muiSelect: {
        "& .MuiFormLabel-root": cs.muiFormLabel25,
      },
      select: {
        minWidth: 400,
      },
      listContainer: {
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        overflow: "auto",
      },
    }),
    [cs.muiFormLabel25],
  )

  return (
    <ScreenContainer
      title={t(`rules.title`)}
      icon={<MenuBookIcon sx={cs.primary600} fontSize="large" />}
    >
      <div style={s.main}>
        <TopSection />
        {!divisionId || !scenarioId ? (
          <EmptyScreen title={t("students.empty")} subtitle={t("stats.noDataSubtitleDivision")} />
        ) : (
          <div style={s.listContainer}>
            <List<DivisionProgressionForScenarioRule>
              actions={false}
              sx={s.list}
              disableSyncWithLocation={true}
              pagination={false}
              exporter={false}
              emptyWhileLoading={isModuleLevelsLoading}
              empty={
                <EmptyScreen
                  title={t("students.empty")}
                  subtitle={t("stats.noDataSubtitleDivision")}
                />
              }
              queryOptions={{
                meta: { isLocal: true, extraSortField: "seen" },
                onError: () => notify("ra.notification.http_error", { type: "error" }),
              }}
              filterDefaultValues={{
                choice: "",
              }}
              sort={{ field: "eventualRatio", order: "ASC" }}
              resource={`stats/division/${divisionId}/rules`}
              filter={{ scenarioId }}
              filters={
                moduleLevels
                  ? [
                      <SearchInput
                        key={0}
                        // "source" has to be "search" - used in dataProvider when isLocal
                        source="search"
                        name="search"
                        alwaysOn={true}
                        variant="outlined"
                        placeholder={t("rules.search")}
                        style={s.search}
                      />,
                      <SelectLevelInput
                        key={1}
                        name="choice"
                        source="levelId"
                        alwaysOn={true}
                        variant="outlined"
                        style={s.select}
                        sx={s.muiSelect}
                        label={t("rules.selectLevel")}
                        optionValue="choice"
                        choices={moduleLevels}
                      />,
                    ]
                  : undefined
              }
            >
              <RuleStatsDataGrid />
            </List>
          </div>
        )}
      </div>
    </ScreenContainer>
  )
}

export default RuleListScreen
