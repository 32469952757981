import { Typography } from "@mui/material"
import type { DivisionProgressionForScenarioRule } from "@newpv/js-common"
import type { FC } from "react"
import React from "react"
import { useRecordContext } from "react-admin"
import type { RAFieldProps } from "utils"
import { useStyles } from "utils"

export const RuleNameField: FC<RAFieldProps<DivisionProgressionForScenarioRule>> = field => {
  const record = useRecordContext<DivisionProgressionForScenarioRule>(field)
  const name = (record?.[field.source] ?? "") as string
  const s = useStyles(() => ({
    text: {
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "3",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }))

  return !record ? null : (
    <Typography
      component="div"
      variant="subtitle1"
      style={s.text}
      dangerouslySetInnerHTML={{ __html: name }}
    />
  )
}
